import React, { useEffect, useRef } from 'react';
import $ from 'jquery'; // Ensure you have jQuery installed

const Scroll2Bottom = () => {
  const buttonRef = useRef(null);

    // useEffect(() => {
    //   const handleScroll = () => {
    //     if (window.scrollY + window.innerHeight < document.documentElement.scrollHeight - 300) {
    //       $(buttonRef.current).addClass('show');
    //     } else {
    //       $(buttonRef.current).removeClass('show');
    //     }
    //   };
  
    //   $(window).on('scroll', handleScroll);
  
    //   return () => {
    //     $(window).off('scroll', handleScroll);
    //   };
    // }, []);
  
    // const handleClick = (e) => {
    //   e.preventDefault();
    //   $('html, body').animate({ scrollTop: $(document).height() }, 300);
    // };
  
    return (
      // <div className='text-end toptboymBtn'><span className='countMsgT'>10</span><a id="Scroll2BottomButton" ref={buttonRef} className="mdi mdi-chevron-double-down" onClick={handleClick}></a></div>
      <></>
    );
}

export default Scroll2Bottom