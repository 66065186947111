import config from 'src/config';
import { getRequest, postRequest, putRequest } from 'src/utils/RequestUtil';

export const userSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/user/search`;
    return postRequest({
        url,
        data,
    });
};
export const fetchUserDetailsAPI = function (data) {
    const url = `${config.baseUrl}user/Details`;
    return getRequest({
        url,
        data,
    });
};

export const fetchUserScopesAPI = ({
    userId,
}) => {
    const url = `${config.baseUrl}auth/scope/${userId}`;
    return getRequest({
        url,
    });
};





















export const userProfilePicAPI = function ({
    userId,
    data,
}) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}admin/user/userProfilePicAPI/${userId}`;
    return putRequest({
        url,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const userUploadAPI = function ({data}) {
    const fd = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
            const jsonString = JSON.stringify(data[key]);
            fd.append(key, jsonString); 
        } else {
            fd.append(key, data[key]);
        }
    });
    const url = `${config.baseUrl}admin/user/userBulkUploadAPI`;
    return postRequest({
        url,
        data: fd,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const userCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/user/save`;
    return postRequest({
        url,
        data,
    });
};

export const userUpdateAPI = function ({
    userId,
    data,
}) {
    const url = `${config.baseUrl}admin/user/update/${userId}`;
    return putRequest({
        url,
        data,
    });
};

export const getReportingManager = function (data) {
    const url = `${config.baseUrl}user/getReportingManager`;
    return postRequest({
        url,
        data,
    });
};

export const invitationResend = function (data) {
    const url = `${config.baseUrl}admin/user/reSendInvitation`;
    return postRequest({
        url,
        data,
    });
};
