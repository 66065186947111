import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { AccountSettingDefaultProps } from 'src/containers/account-container/AccountPropTypes';
import { ACCOUNT_ACTIONS } from '../actions/AccountActions';

const initialState = () => ({
    currentRecord: { ...AccountSettingDefaultProps },
    upsertReqStatus: null,    
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case ACCOUNT_ACTIONS.ACCOUNT_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ACCOUNT_ACTIONS.ACCOUNT_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ACCOUNT_ACTIONS.ACCOUNT_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }
        case ACCOUNT_ACTIONS.SET_ACCOUNT_SETTING: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }

        
        case ACCOUNT_ACTIONS.ACCOUNT_RESET: {
            return {
                ...state,
                currentRecord: { ...AccountSettingDefaultProps },
            };
        } 
        default:
            return state;
    }
};
