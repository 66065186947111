import React from 'react';
import PropTypes from 'prop-types';

const ErrorPageComponent = ({
    code,
    title,
}) => (
    <div
        className="d-flex justify-content-center align-items-center"
        id="main"
    >
        <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">{code}</h1>
        <div className="inline-block align-middle">
            <h2
                className="font-weight-normal lead"
                id="desc"
            >
                {title}
            </h2>
        </div>
    </div>
);

ErrorPageComponent.propTypes = {
    code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default ErrorPageComponent;
