

const getTipTapEditorValue = (editor) => {
    const msgHtml = editor.getHTML();
    const div = document.createElement("div");
    div.innerHTML = msgHtml;
    const codeElement = div.querySelector("code");
    if (codeElement) {
      codeElement.classList.add("language-javascript");
    }
    const modifiedMsgHtml = div.innerHTML;
    const msg = editor.getJSON();
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = msgHtml;
    const innerText = tempDiv.innerText;
    return ( {
      messageText: innerText,
      msgHtml: modifiedMsgHtml,
      msgJson: msg, 
    });
  };

  export  {
    getTipTapEditorValue, 
}