import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { grep } from 'jquery';

export const NotificationPropTypes = PropTypes.shape({
    _id: PropTypes.string,  
    receiverType: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.shape({
        groupId: PropTypes.string, 
        userId: PropTypes.string,
        departmentId: PropTypes.string,
        organizationId: PropTypes.string,
    })),
});

export const NotificationReceipentPropTypes = PropTypes.shape({
    groupId: PropTypes.string, 
    userId: PropTypes.string,
    departmentId: PropTypes.string,
    organizationId: PropTypes.string,   
});

export const NotificationReceipentDefaultProps = { 
    groupId: null, 
    userId: null,
    departmentId: null,
    organizationId: null, 
};
export const NotificationDefaultProps = { 
    receiverType: '', 
    title: '',
    content: '',
    contentHtml: '',
    position  : '',
    noticeTimeStamp: '',
    noticeTime: '',
    recipients: NotificationReceipentDefaultProps,
    _id: undefined
};

export const NotificationYupSchema = Yup.object().shape({ 
    receiverType: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    
    
    position: Yup.string().required('Required'),
    noticeTimeStamp: Yup.string().required('Required'),
    noticeTime: Yup.string().required('Required'),
    
});

export default {
    NotificationDefaultProps,
    NotificationPropTypes,
    NotificationYupSchema,
};
