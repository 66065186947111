import { useState } from "react";
import AccountSetting from "./AccountSetting";
import ChatSetting from "./ChatSetting";
import ActivitySetting from "./ActivitySetting";
import PreferencesSetting from "./PreferencesSetting";
import NotificationsSetting from "./NotificationsSetting";
import TitlesDisplaySetting from "./TitlesDisplaySetting";
import ToastComponent from "src/components/ToastComponent";

const AccountSettingContainer=({toggleDrawer})=>{
  const [openedSubDrawer, setOpenedSubDrawer] = useState({type:null,isOpen:false});
  const toggleSubDrawer = (type,isOpen) =>setOpenedSubDrawer({type,isOpen})
     
  return (
    <>
    <div className="chatlist"  style={{display:"block"}} id="mySettings">
         <ToastComponent/>
        {}
        <div className="modal-dialog-scrollable">
            <div className="modal-content">
                <div className="slide-header">
                    <div className="HeadSlidName d-flex justify-content-between align-items-center">
                    <div className="slidName font-24 color1a">Settings <span className="smdia font-14 color1a d-block fw-normal">Change your app setting.</span></div>
                       <button className=""  onClick={() => toggleDrawer()}>&times;</button>
                    </div>
                </div>
                <div className="modal-body settinghndl p-0">
                    <AccountSetting openedSubDrawer={openedSubDrawer} toggleSubDrawer={toggleSubDrawer}/>
                    <ChatSetting openedSubDrawer={openedSubDrawer} toggleSubDrawer={toggleSubDrawer}/>
                    <ActivitySetting openedSubDrawer={openedSubDrawer} toggleSubDrawer={toggleSubDrawer}/>
                    <PreferencesSetting openedSubDrawer={openedSubDrawer} toggleSubDrawer={toggleSubDrawer}/>
                    <NotificationsSetting openedSubDrawer={openedSubDrawer} toggleSubDrawer={toggleSubDrawer}/>
                    <TitlesDisplaySetting openedSubDrawer={openedSubDrawer} toggleSubDrawer={toggleSubDrawer}/>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}
export default AccountSettingContainer