import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { RoleDefaultProps } from 'src/containers/admin-containers/role-container/RolePropTypes';
import { ROLE_ACTIONS } from '../actions/RoleActions';

const initialState = () => ({
    currentRecord: { ...RoleDefaultProps },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case ROLE_ACTIONS.ROLE_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ROLE_ACTIONS.ROLE_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ROLE_ACTIONS.ROLE_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case ROLE_ACTIONS.ROLE_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ROLE_ACTIONS.ROLE_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ROLE_ACTIONS.ROLE_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case ROLE_ACTIONS.ROLE_RESET: {
            return {
                ...state,
                currentRecord: { ...RoleDefaultProps },
            };
        }

        default:
            return state;
    }
};
