import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const locationSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/location/search`;
    return postRequest({
        url,
        data,
    });
};
export const getLocationAPI = function (data) {
    const url = `${config.baseUrl}admin/location/getLocation`;
    return postRequest({
        url,
        data,
    });
};

export const locationCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/location/save`;
    return postRequest({
        url,
        data,
    });
};

export const locationUpdateAPI = function ({
    locationId,
    data,
}) {
    const url = `${config.baseUrl}admin/location/update/${locationId}`;
    return putRequest({
        url,
        data,
    });
};
