import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { NOTIFICATION_ACTIONS } from '../actions/NotificationActions';
import { NotificationDefaultProps } from '../NotificationPropTypes';


const initialState = () => ({
    currentRecord: { ...NotificationDefaultProps },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case NOTIFICATION_ACTIONS.NOTIFICATION_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case NOTIFICATION_ACTIONS.NOTIFICATION_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case NOTIFICATION_ACTIONS.NOTIFICATION_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case NOTIFICATION_ACTIONS.NOTIFICATION_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case NOTIFICATION_ACTIONS.NOTIFICATION_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case NOTIFICATION_ACTIONS.NOTIFICATION_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case NOTIFICATION_ACTIONS.NOTIFICATION_RESET: {
            return {
                ...state,
                currentRecord: { ...NotificationDefaultProps },
            };
        }

        default:
            return state;
    }
};
