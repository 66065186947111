import React from "react";
import UserMediaLinks from "./UserMediaLinks";
import MediaDocuments from "./MediaDocuments";
import { checkIfLink, getFileType } from "src/utils/utils";
import VideoPlayer from "./VideoPlayer";
import ImageViewer from "./ImageViewer";
import AudioPlayer from "./AudioPlayer";

const Gallery = ({
  conversationMedia,
  handleRevealInConversation,
  handleSelectMedia,
  isSelection,
  handleDelete,
  handleForward,
  setIsSelection,
  handlePin,
  selectedFileMedias,
  copyMessageToClipboard,
  tabValue,
  handleDeleteLinks,
  handlePinLinks,
  userMediaRev,
  scrollToItem,
  isListShow,
}) => {
  const showAllMedia = () => {
    return (
      <>
        {conversationMedia?.map((media) => {
          let fileType = getFileType(media);
          switch (fileType) {
            case "video":
              return (
                <VideoPlayer
                   key={`${media?._id}`}
                   id={`md_${media?._id}`}
                  media={media}
                  handleRevealInConversation={handleRevealInConversation}
                  handleSelectMedia={handleSelectMedia}
                  isSelection={isSelection}
                  handleDelete={handleDelete}
                  handleForward={handleForward}
                  setIsSelection={setIsSelection}
                  handlePin={handlePin}
                  selectedFileMedias={selectedFileMedias}
                  copyMessageToClipboard={copyMessageToClipboard}
                  userMediaRev={userMediaRev}
                  scrollToItem={scrollToItem}

                />
              );
            case "document":
              if (tabValue !== "Media") {
                return (
                  <MediaDocuments
                     key={`${media?._id}`}
                     id={`md_${media?._id}`}
                    media={media}
                    handleRevealInConversation={handleRevealInConversation}
                    handleSelectMedia={handleSelectMedia}
                    isSelection={isSelection}
                    handleDelete={handleDelete}
                    handleForward={handleForward}
                    setIsSelection={setIsSelection}
                    handlePin={handlePin}
                    selectedFileMedias={selectedFileMedias}
                    copyMessageToClipboard={copyMessageToClipboard}
                    userMediaRev={userMediaRev}
                    scrollToItem={scrollToItem}
                  />
                );
              }
              return null;
            case "image":
              return (
                <ImageViewer
                   key={`${media?._id}`}
                   id={`md_${media?._id}`}
                  media={media}
                  handleRevealInConversation={handleRevealInConversation}
                  handleSelectMedia={handleSelectMedia}
                  isSelection={isSelection}
                  handleDelete={handleDelete}
                  handleForward={handleForward}
                  setIsSelection={setIsSelection}
                  handlePin={handlePin}
                  selectedFileMedias={selectedFileMedias}
                  copyMessageToClipboard={copyMessageToClipboard}
                  userMediaRev={userMediaRev}
                  scrollToItem={scrollToItem}
                />
              );
            case "audio":
              return (
                <AudioPlayer
                   key={`${media?._id}`}
                   id={`md_${media?._id}`}
                  media={media}
                  handleRevealInConversation={handleRevealInConversation}
                  handleSelectMedia={handleSelectMedia}
                  isSelection={isSelection}
                  handleDelete={handleDelete}
                  handleForward={handleForward}
                  setIsSelection={setIsSelection}
                  handlePin={handlePin}
                  selectedFileMedias={selectedFileMedias}
                  copyMessageToClipboard={copyMessageToClipboard}
                  userMediaRev={userMediaRev}
                  scrollToItem={scrollToItem}
                />
              );
            default:
              return null;
          }
        })}
      </>
    );
  };

  const showDocumentsTab = () => {
    return (
      <>
        {conversationMedia?.map((media) => {
          let fileType = getFileType(media);
          switch (fileType) {
            case "document":
              return (
                <MediaDocuments
                   key={`${media?._id}`}
                   id={`md_${media?._id}`}
                  media={media}
                  handleRevealInConversation={handleRevealInConversation}
                  handleSelectMedia={handleSelectMedia}
                  isSelection={isSelection}
                  handleDelete={handleDelete}
                  handleForward={handleForward}
                  setIsSelection={setIsSelection}
                  handlePin={handlePin}
                  selectedFileMedias={selectedFileMedias}
                  copyMessageToClipboard={copyMessageToClipboard}
                  userMediaRev={userMediaRev}
                  scrollToItem={scrollToItem}
                />
              );
            default:
              return null;
          }
        })}
      </>
    );
  };

  const showLinksTab = () => {
    return (
      <>
        {conversationMedia?.map((media) => {
          let fileType = checkIfLink(media.message);
          switch (true) {
            case fileType:
              return (
                <UserMediaLinks
                   key={`${media?._id}`}
                   id={`md_${media?._id}`}
                  media={media}
                  handleRevealInConversation={handleRevealInConversation}
                  handleSelectMedia={handleSelectMedia}
                  isSelection={isSelection}
                  handleDelete={handleDeleteLinks}
                  handleForward={handleForward}
                  setIsSelection={setIsSelection}
                  handlePin={handlePinLinks}
                  selectedFileMedias={selectedFileMedias}
                  copyMessageToClipboard={copyMessageToClipboard}
                  userMediaRev={userMediaRev}
                  scrollToItem={scrollToItem}
                />
              );
            default:
              return null;
          }
        })}
      </>
    );
  };

  const RenderTabContent = () => {
    switch (tabValue) {
      case "All Files":
        return showAllMedia();
      case "Media":
        return showAllMedia();
      case "Docs":
        return showDocumentsTab();
      case "Link":
        return showLinksTab();
      default:
        return null;
    }
  }

  return <RenderTabContent />;
};

export default Gallery;
