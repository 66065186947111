import config from "src/config";
import { patchRequest, postRequest } from "src/utils/RequestUtil";
export const getUserNotificationList = async (
  searchText = "",
  startDate,
  endDate,
  page,
  limit,
) => {
  try {
    const url = `${config.baseUrl}notice/notifications/all`;
    return postRequest({
      url,
      data: { searchText, startDate, endDate, page, limit },
    });
  } catch (error) {
    return null;
  }
};

export const userNotificationMarkAsRead = async (
  notificationId,
  notificationHistoryId
) => {
  try {
    const url = `${config.baseUrl}notice/notifications/mark-as/read`;
    return patchRequest({
      url,
      data: { notificationId, notificationHistoryId },
    });
  } catch (error) {
    return null;
  }
};
