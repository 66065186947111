import React from 'react';
import FormikSearchDropdown from 'src/components/formik/FormikSearchDropdown/FormikSearchDropdown';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import PropTypes from 'prop-types';
import { getLocationAPI } from 'src/api/LocationApi';

const LocationDropDown = ({ value, name, onChange,...rest }) => (
    <FormikSearchDropdown
        name={name}
        placeholder="Select Location"
        displayKeyPath={[
            'location',
        ]}
        valueKeyPath="_id"
        defaultValueFetchByKeyPath="_id"
        whereClauseKeysPaths={[
            'location',
        ]}
        api={getLocationAPI}
        apiCallAddonData={{
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
        }}
        value={value}
        onChange={onChange} 
        {...rest}
    />
);

LocationDropDown.propTypes = {
    
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
};

LocationDropDown.defaultProps = {
    value: '',
    readOnly: false,
};

export default LocationDropDown;
