import { time_ago } from "src/utils/CommonUtils";

const UserProfilePopup=(props)=>{
  const  {
   profileDetails,
   isUserProfileView,
   setUserProfileView
} = props
const otherInfo = profileDetails.otherInformation;
const reportingManager = (otherInfo?.reportingManagerId?.firstName)?`${otherInfo?.reportingManagerId?.firstName} ${otherInfo.reportingManagerId.lastName}`:"NA";

   return (
    <>
<div
className="chat-sidebar chat-animate-right"
style={{ display:`${(isUserProfileView)?'block':'none'}`, right: 0, top: 0, marginTop: 43, zIndex: 10,width:'20%' }}
id="ViewProfile"
>
<div className="modal-dialog-scrollable">
   <div className="modal-content">
      <div className="slide-header border-bottom mb-2">
         <div className="HeadSlidName d-flex justify-content-between align-items-center">
            <div className="slidName">User Details</div>
            <button className="" onClick={()=>{setUserProfileView(false)}}>
            ×
            </button>
         </div>
      </div>
      <div className="modal-body pt-0">
         <div className="beforeActivity" id="beforeActivity">
            <div className="text-center profText">
               <div className="profImg" style={{backgroundImage:`url(${(otherInfo.profilePicture)?otherInfo.profilePicture:"/assets/images/faces/face8.jpg"})`}} />
                  <div className="LastSeen font-12 color80 pt-2 pb-1">
                     Last seen: {time_ago(otherInfo.lastSeen)}
                  </div>
                  <div className="userName font-18 color1a">{`${profileDetails.firstName} ${profileDetails.lastName} `}</div>
                  <div className="userdec font-14 colorb">{otherInfo?.designationId?.designationName}</div>
                  <div className="userprd font-12 color80 pt-1">{otherInfo?.departmentId?.departmentName}</div>
               </div>
               <div className="userdetails">
                  <div className="user-list pt-0 border-0">
                     <div className="font-12 color66 mb-3">
                       {otherInfo.description}
                     </div>
                  <div className="user-d pb-2 d-flex align-items-center">
                        <lable className="font-14 color80">
                           <img
                              src="/assets/images/profile/reporting-icon.svg"
                              className="img-fluid"
                              alt=""
                              width={17}
                              height={17}
                              />
                        </lable>
                        <a href="#" className="font-14 color1a d-block fw-500">
                        Employee ID {" "}
                        <span className="colorp d-block">{`${profileDetails?.employeeID}`}</span>
                        </a> 
                  </div>  
                     <div className="user-d pb-2 d-flex align-items-center">
                        <lable className="font-14 color80">
                           <img
                              src="/assets/images/profile/email-icon.svg"
                              className="img-fluid"
                              alt=""
                              width={15}
                              height={11}
                              />
                        </lable>
                        <a href="" className="font-14 color1a d-block fw-500">
                        {profileDetails.email}
                        </a>
                     </div>
                     <div className="user-d pb-2 d-flex align-items-center">
                        <lable className="font-14 color80">
                           <img
                              src="/assets/images/profile/mobile-icon.svg"
                              className="img-fluid"
                              alt=""
                              width={10}
                              height={16}
                              />
                        </lable>
                        <a href="" className="font-14 color1a d-block fw-500">
                        +91-{profileDetails.phone}
                        </a>
                     </div>
                     <div className="user-d d-flex align-items-center">
                        <lable className="font-14 color1a">
                           <img
                              src="/assets/images/profile/location-icon.svg"
                              className="img-fluid"
                              alt=""
                              width={11}
                              height={16}
                              />
                        </lable>
                        <span className="font-14 color1a d-block fw-500">{`${otherInfo.locationId.countryName},${otherInfo.locationId.location}`}</span>
                     </div>
                  </div>
               </div>
            </div>
            <div
            className="accountstg border-0 afteractivity"
            id="afteractivity"
            style={{ display: "none" }}
            >
            <div className="dflex justify-content-between align-items-center createnotes font-16 fw-bold color1a btnclr fixedtop">
               Activity
               <button
                  className="rounded-4 closetabpassword"
                  onClick="userViewProfile_close()"
                  >
               <i className="mdi mdi-chevron-left" />
               </button>
            </div>
            <div className="Achpaswd">
               <div className="accordion accordion-flush" id="accordionExample2">
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="heading3">
                        <button
                           className="accordion-button collapsed ps-0 pe-0"
                           type="button"
                           data-bs-toggle="collapse"
                           data-bs-target="#collapse3"
                           aria-expanded="true"
                           aria-controls="collapse3"
                           >
                        <span>Usage</span>
                        </button>
                     </h2>
                     <div
                        id="collapse3"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading3"
                        data-bs-parent="#accordionExample2"
                        >
                        <div className="accordion-body p-0">
                           <ul
                              className="nav nav-tabs filetabs"
                              id="myTab"
                              role="tablist"
                              >
                              <li className="nav-item" role="presentation">
                                 <button
                                    className="nav-link active"
                                    id="allmessages-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#allmessages"
                                    type="button"
                                    role="tab"
                                    aria-controls="allmessages"
                                    aria-selected="true"
                                    >
                                 All All
                                 </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button
                                    className="nav-link"
                                    id="messages-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#messages"
                                    type="button"
                                    role="tab"
                                    aria-controls="messages"
                                    aria-selected="false"
                                    tabIndex={-1}
                                    >
                                 Messages
                                 </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button
                                    className="nav-link"
                                    id="group-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#group"
                                    type="button"
                                    role="tab"
                                    aria-controls="group"
                                    aria-selected="false"
                                    tabIndex={-1}
                                    >
                                 Group
                                 </button>
                              </li>
                           </ul>
                           <div className="tab-content filetabstext" id="myTabContent">
                              <div
                                 className="tab-pane fade active show"
                                 id="allmessages"
                                 role="tabpanel"
                                 aria-labelledby="allmessages-tab"
                                 >
                                 <div className="ColorthemeBody">
                                    <div className="inflxtx mb-3">
                                       <label className="font-14 fw-bold color1a mb-2">
                                       Select Custom Time
                                       </label>
                                       <div className="d-flex align-items-center inflx gap-2">
                                          <select className="form-select font-14 color33 font-14 mxw110px fw-500">
                                             <option>Today</option>
                                          </select>
                                          <input
                                             type="date"
                                             className="form-control border"
                                             />
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between font-14 fw-normal color1a fw-500 align-items-center">
                                       Total Usage{" "}
                                       <div className="d-block font-12 color4d fw-bold">
                                          771{" "}
                                          <span className="d-block font-12 color80">
                                          92.52 KB
                                          </span>
                                       </div>
                                    </div>
                                    <div className="grid2 usergrid mb-3">
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/messages.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Messages
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/images.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Images
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/video.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Video
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/audio.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Audio
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/documents.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Documents{" "}
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/others.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Others
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                    </div>
                                    <div className="inflxtx">
                                       <label className="font-14 fw-bold color1a mb-2 d-flex justify-content-between align-items-center">
                                       Delete{" "}
                                       <button className="border-0 bg-none">
                                       <i className="mdi mdi-delete mdi-18px" />
                                       </button>
                                       </label>
                                       <select className="form-select font-14 color33 font-14 fw-500">
                                          <option disabled="" selected="">
                                             Select
                                          </option>
                                          <option>Delete all files and media</option>
                                          <option>Delete only all Messages</option>
                                          <option>Delete only all Media</option>
                                          <option>Delete all files from group</option>
                                       </select>
                                       <div className="text-center mt-3 mb-3">
                                          <button
                                             type="button"
                                             className="btnblue"
                                             data-bs-toggle="modal"
                                             data-bs-target="#Groupsdeletemsg"
                                             >
                                          Delete
                                          </button>{" "}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div
                                 className="tab-pane fade"
                                 id="messages"
                                 role="tabpanel"
                                 aria-labelledby="messages-tab"
                                 >
                                 <div className="ColorthemeBody">
                                    <div className="inflxtx mb-3">
                                       <label className="font-14 fw-bold color1a mb-2">
                                       Select Custom Time
                                       </label>
                                       <div className="d-flex align-items-center inflx gap-2">
                                          <select className="form-select font-14 color33 font-14 mxw110px fw-500">
                                             <option>Today</option>
                                          </select>
                                          <input
                                             type="date"
                                             className="form-control border"
                                             />
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between font-14 fw-normal color1a fw-500 align-items-center">
                                       Total Usage{" "}
                                       <div className="d-block font-12 color4d fw-bold">
                                          771{" "}
                                          <span className="d-block font-12 color80">
                                          92.52 KB
                                          </span>
                                       </div>
                                    </div>
                                    <div className="grid2 usergrid mb-3">
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/messages.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Messages
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/images.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Images
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/video.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Video
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/audio.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Audio
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/documents.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Documents{" "}
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/others.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Others
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                    </div>
                                    <div className="inflxtx">
                                       <label className="font-14 fw-bold color1a mb-2 d-flex justify-content-between align-items-center">
                                       Delete{" "}
                                       <button className="border-0 bg-none">
                                       <i className="mdi mdi-delete mdi-18px" />
                                       </button>
                                       </label>
                                       <select className="form-select font-14 color33 font-14 fw-500">
                                          <option disabled="" selected="">
                                             Select
                                          </option>
                                          <option>Delete all files and media</option>
                                          <option>Delete only all Messages</option>
                                          <option>Delete only all Media</option>
                                          <option>Delete all files from group</option>
                                       </select>
                                       <div className="text-center mt-3 mb-3">
                                          <button
                                             type="button"
                                             className="btnblue"
                                             data-bs-toggle="modal"
                                             data-bs-target="#Groupsdeletemsg"
                                             >
                                          Delete
                                          </button>{" "}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div
                                 className="tab-pane fade"
                                 id="group"
                                 role="tabpanel"
                                 aria-labelledby="group-tab"
                                 >
                                 <div className="ColorthemeBody">
                                    <div className="inflxtx mb-3">
                                       <label className="font-14 fw-bold color1a mb-2">
                                       Select Custom Time
                                       </label>
                                       <div className="d-flex align-items-center inflx gap-2">
                                          <select className="form-select font-14 color33 font-14 mxw110px fw-500">
                                             <option>Today</option>
                                          </select>
                                          <input
                                             type="date"
                                             className="form-control border"
                                             />
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between font-14 fw-normal color1a fw-500 align-items-center">
                                       Total Usage{" "}
                                       <div className="d-block font-12 color4d fw-bold">
                                          771{" "}
                                          <span className="d-block font-12 color80">
                                          92.52 KB
                                          </span>
                                       </div>
                                    </div>
                                    <div className="grid2 usergrid mb-3">
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/messages.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Messages
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/images.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Images
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/video.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Video
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/audio.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Audio
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/documents.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Documents{" "}
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                       {" "}
                                       <div className="usagelist usrlist1 usrlist">
                                          <div className="img font-14 fw-normal color33 fw-500">
                                             <img
                                                src="assets/images/activity-usage/others.svg"
                                                className="img-fluid"
                                                alt=""
                                                />{" "}
                                             Others
                                          </div>
                                          <div className="d-block font-14 color4d fw-bold text-center">
                                             771{" "}
                                             <span className="d-block font-14 color80">
                                             92.52 KB
                                             </span>
                                          </div>
                                          <button className="border-0 bg-none prtabltu">
                                          <i className="mdi mdi-delete mdi-18px" />
                                          </button>
                                       </div>
                                    </div>
                                    <div className="inflxtx">
                                       <label className="font-14 fw-bold color1a mb-2 d-flex justify-content-between align-items-center">
                                       Delete{" "}
                                       <button className="border-0 bg-none">
                                       <i className="mdi mdi-delete mdi-18px" />
                                       </button>
                                       </label>
                                       <select className="form-select font-14 color33 font-14 fw-500">
                                          <option disabled="" selected="">
                                             Select
                                          </option>
                                          <option>Delete all files and media</option>
                                          <option>Delete only all Messages</option>
                                          <option>Delete only all Media</option>
                                          <option>Delete all files from group</option>
                                       </select>
                                       <div className="text-center mt-3 mb-3">
                                          <button
                                             type="button"
                                             className="btnblue"
                                             data-bs-toggle="modal"
                                             data-bs-target="#Groupsdeletemsg"
                                             >
                                          Delete
                                          </button>{" "}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="accordion-item">
                     <h2 className="accordion-header" id="heading4">
                        <button
                           className="accordion-button collapsed ps-0 pe-0"
                           type="button"
                           data-bs-toggle="collapse"
                           data-bs-target="#collapse4"
                           aria-expanded="false"
                           aria-controls="collapse4"
                           >
                        Active Devices
                        </button>
                     </h2>
                     <div
                        id="collapse4"
                        className="accordion-collapse collapse"
                        aria-labelledby="heading4"
                        data-bs-parent="#accordionExample2"
                        >
                        <div className="accordion-body p-0">
                           <div className="TexturethemeBody">
                              <div className="mb-3">
                                 <div className="list-group">
                                    <div
                                       className="list-group-item d-flex gap-3 py-3 border-0 px-0"
                                       aria-current="true"
                                       >
                                       <div className="w-100">
                                          <h6 className="mb-0 color1a font-14 fw-bold">
                                             Faizal win32 (Desktop){" "}
                                             <i className="mdi mdi-check-circle text-green" />
                                          </h6>
                                          <p className="mb-0 font-14 color66">
                                             Last launched 37 minutes ago
                                          </p>
                                          <div className="d-flex">
                                             {" "}
                                             <button
                                                type="button"
                                                className="btnblue my-2 color1a bg-none border"
                                                >
                                             Remove
                                             </button>
                                             <button
                                                type="button"
                                                className="btnblue my-2 color1a bg-none border ms-2"
                                                >
                                             Block
                                             </button>
                                          </div>
                                       </div>
                                       <img
                                          src="assets/images/activity-usage/desktop.svg"
                                          alt="twbs"
                                          width={40}
                                          height={35}
                                          className="flex-shrink-0"
                                          />
                                    </div>
                                    <div
                                       className="list-group-item d-flex gap-3 py-3 border-0 px-0"
                                       aria-current="true"
                                       >
                                       <div className="w-100">
                                          <h6 className="mb-0 color1a font-14 fw-bold">
                                             Oppo F17 Pro{" "}
                                             <i className="mdi mdi-check-circle text-green" />
                                          </h6>
                                          <p className="mb-0 font-14 color66">
                                             Last launched 37 minutes ago
                                          </p>
                                          <div className="d-flex">
                                             {" "}
                                             <button
                                                type="button"
                                                className="btnblue my-2 color1a bg-none border"
                                                >
                                             Remove
                                             </button>
                                             <button
                                                type="button"
                                                className="btnblue my-2 color1a bg-none border ms-2"
                                                >
                                             Block
                                             </button>
                                          </div>
                                       </div>
                                       <img
                                          src="assets/images/activity-usage/phone.svg"
                                          alt="twbs"
                                          width={24}
                                          height={44}
                                          className="flex-shrink-0"
                                          />
                                    </div>
                                    <div
                                       className="list-group-item d-flex gap-3 py-3 border-0 px-0"
                                       aria-current="true"
                                       >
                                       <div className="w-100">
                                          <h6 className="mb-0 color1a font-14 fw-bold">
                                             Chrome 1.58{" "}
                                             <i className="mdi mdi-check-circle text-green" />
                                          </h6>
                                          <p className="mb-0 font-14 color66">
                                             Last launched 37 minutes ago
                                          </p>
                                          <div className="d-flex">
                                             {" "}
                                             <button
                                                type="button"
                                                className="btnblue my-2 color1a bg-none border"
                                                >
                                             Remove
                                             </button>
                                             <button
                                                type="button"
                                                className="btnblue my-2 color1a bg-none border ms-2 Unblock"
                                                >
                                             Unblock
                                             </button>
                                          </div>
                                       </div>
                                       <img
                                          src="assets/images/activity-usage/chrome.svg"
                                          alt="twbs"
                                          width={40}
                                          height={40}
                                          className="flex-shrink-0"
                                          />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</div>
    </>
   )
}
export default UserProfilePopup