import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

const DownloadSnackbar = ({ download, onClose }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    onClose(download.id);
  };

  return (
    <Snackbar
      open={download.open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      action={
        <React.Fragment>
          <Button color="secondary" size="small" onClick={() => handleClose(null, 'clickaway')}>
            Undo
          </Button>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
      style={{
        marginTop: download.index * 60, 
      }}
    >
      <div
        style={{
          backgroundColor: download.success ? 'rgb(50, 50, 50) '  : 'rgb(50, 50, 50)' ,
          color:"white",
          padding: '10px',
          borderRadius: '4px',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '1.5',
        }}
      >
        {download.progress < 100
          ? `Downloading ${download.fileName}: ${download.progress}% complete`
          : `${download.fileName} has been successfully downloaded. 🎉`}
      </div>
    </Snackbar>
  );
};

export default DownloadSnackbar;
