export const APP_ACTIONS = {
    APP_INIT: 'APP_INIT',
    SET_DEFAULT_HEADERS: 'SET_DEFAULT_HEADERS',
};

export const appInitAction = () => ({
    type: APP_ACTIONS.APP_INIT,
});

export const setDefaultHeadersActions = () => ({
    type: APP_ACTIONS.SET_DEFAULT_HEADERS,
});

export default {
    APP_ACTIONS,
    appInitAction,
    setDefaultHeadersActions,
};
