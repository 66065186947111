import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const DesignationPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    designationName: PropTypes.string,
});

export const DesignationDefaultProps = { 
    designationName: '',
    _id: undefined
};

export const DesignationYupSchema = Yup.object().shape({ 
    designationName: Yup.string().required('Required'),
});

export default {
    DesignationDefaultProps,
    DesignationPropTypes,
    DesignationYupSchema,
};
