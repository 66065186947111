import config from "src/config";
import { postRequest } from "src/utils/RequestUtil";
export const handleLogin = async (
  email,
  password,
  rememberme,
  loginInfo,
  lastSeen = new Date()
) => {
  try {
    const url = `${config.baseUrl}auth/sign-in`;
    return await postRequest({
      url,
      data: { email, password, rememberme, loginInfo, lastSeen },
    });
  } catch (error) {
    throw error;
  }
};

export const handleLogout = async () => {
  try {
    if (window.electron && window.electron.setBadgeCount) {
      window.electron.setBadgeCount(0);
    }
    const url = `${config.baseUrl}auth/logout/single/device`;
    return postRequest({
      url,
      data: {},
    });
  } catch (error) {
    throw error;
  }
};
