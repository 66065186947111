import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { NotificationYupSchema } from 'src/containers/admin-containers/notification-container/NotificationPropTypes';
import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { usePrevious } from 'src/utils/ReactHooksUtil';
import { notificationResetAction, notificationSearchAction, notificationUpsertAction } from './actions/NotificationActions';
import NotificationSearchList from './NotificationSearchList';
import { includeStamp } from 'src/utils/StampUtils';
import FormikSelect from 'src/components/FormikSelect';
import { RECEIVER } from './NotificationContst';
import { useDropzone } from "react-dropzone";
import { useNotification } from "src/components/Notifination/ToastNotification";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import EmojiReactions from "src/components/chatbox/EmojiReactions";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Document from "@tiptap/extension-document";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Image from "@tiptap/extension-image";
import { ReactRenderer } from "@tiptap/react";
import BulletList from "@tiptap/extension-bullet-list";
import Placeholder from "@tiptap/extension-placeholder";
import GroupWiseDropDown from './GroupWiseDropDown';
import { getFileExtension } from 'src/libs/common';
import { unAcceptedExtensions } from 'src/utils/utils';
import FormikRadioBox from 'src/components/formik/FormikRadioBox';
import FormikDateInput from 'src/components/formik/FormikDateInput';
import FormikTimeInput from 'src/components/formik/FormikTimeInput';
import { getTipTapEditorValue } from 'src/components/reactComponentUtils/ComponentUtils';
import FormikInput from 'src/components/formik/FormikInput';
import { UploadFile } from 'src/containers/profile-container/ProfileServices';



const Render=(props)=>{
 
  const {
      values,
      
      
      isSubmitting,
      onSearch,
      onClear,
      resetForm,
      setEditorContent
  } = props;
  
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const user = useSelector((state) => state.auth.user);
  const { openSuccessNotification, openErrorNotification } = useNotification();

  const [recipientsGroup,setRecipientsGroup] = useState("all");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [updatedContentIsSet, setUpdatedContentIsSet] = useState(false);
  const {
    openWarningNotification,
  } = useNotification();


  useEffect(()=>{
    setRecipientsGroup(values.receiverType)
    
    if(!updatedContentIsSet && values._id)
    {
      editor.chain().focus().setContent(values.description).run();
      setUpdatedContentIsSet(true)
    }
  },[values])

  const content = ``;
  const extensions = [
    StarterKit,
    Image,
    Placeholder.configure({
      placeholder: "Write your message...",
    }),
    Underline,
    BulletList, 
    Document,
    Paragraph,
    Text, 
  ];
  const editor = useEditor({
    extensions,
    editorProps: {
      attributes: {
        class: "input-box-tip tiptap ProseMirror",
      },
    },
    content, 
  });

  const handleFileUpload = async (files) => {
    try {
      if (!files || files.length === 0) {
        return;
      }
      const newFileNames = [];
      const newFileIdes = [];
      const newFileTypes = [];
      for (let i = 0; i < Math.min(files.length, 10); i++) {
        const file = files[i];
        newFileNames.push(file.name);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("remark", "Notes");
        formData.append("title", file.name);
        formData.append("refId", user?._id);
        formData.append("source", "notices");
        formData.append("directory", "notices");
        formData.append("by", user?._id);
        const { data } = await UploadFile(formData, authToken);
        if (data) {
          newFileIdes.push(data.data?._id);
          newFileTypes.push(data.data?.extension);
        }
      }
      values.files = [newFileIdes]; 
    } catch (error) {
      openErrorNotification("Failed to Attach Document");
    }
  };

 const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 10) {
        openWarningNotification(
          "You can only upload up to 10 files at a time."
        );
        return;
      }
      const maxFileSize = 2 * 1024 * 1024 * 1024;
      const filteredFiles = [];
      const invalidFiles = [];
      const fileNamesSet = new Set();
      acceptedFiles.forEach((file) => {
        const extension = getFileExtension(file.name);
        if (fileNamesSet.has(file.name)) {
          openWarningNotification(
            `File ${file.name} is a duplicate and cannot be uploaded.`
          );
        }
        if (unAcceptedExtensions.includes(extension?.toLocaleLowerCase())) {
          invalidFiles.push(file);
        } else if (file.size > maxFileSize) {
          openWarningNotification(
            `File ${file.name} is larger than 2 GB and cannot be uploaded.`
          );
        } else {
          filteredFiles.push(file);
          fileNamesSet.add(file.name);
        }
      });
      if (invalidFiles.length > 0) {
        const invalidExtensions = invalidFiles.map((file) =>
          getFileExtension(file.name)
        );
        openWarningNotification(
          `Unsupported file extensions: ${invalidExtensions.join(", ")}.`
        );
      } else if (filteredFiles.length === acceptedFiles.length) {
        handleFileUpload(filteredFiles);
      }
    },
  });
 

  useEffect(() => {
    if (!editor) {
      return;
    }
    const handleUpdate = () => {
      const editorGetHtml = editor.getHTML();  
      setEditorContent(editorGetHtml);
    };
    editor.on('update', handleUpdate);
    return () => {
      editor.off('update', handleUpdate);
    };
  }, [editor]);

  if (!editor) {
    return null;
  }


  return(
   <>
   <Form autoComplete="disabled">
   <div className="d-flex justify-content-between align-items-start gap-4 bgwcsmmange">
        <div className="wcsmmange1">
          <div className="mb-3">
          <label className="mb-2">Notification Title </label>
            <FormikInput
            placeholder="Title"
              type="text"
              name="title"
              className="form-control border w-100"
            /> 
          </div>
          <div className="">
            <label className="mb-2">Notification Receiver Type </label>
            <div className="d-flex gap-3 mb-3"> 
              <FormikSelect
                items={RECEIVER}
                name={"receiverType"}
                className="form-select w-50" 
                value={values.receiverType}
              />
              <GroupWiseDropDown
               recipientsGroup={recipientsGroup}
               className="form-select w-50" 
               name={"recipients.typeId"}
               values={values}
              />
            </div>
          </div>
          <div className="editor-container">
          <EditorContent
          editor={editor}
          className="form-control border w-100"           
        />
            <div className="send-btns">
              <div className="attach d-flex justify-content-between align-items-center">
                <div className="">
                <span className="ql-formats d-flex">
                          <div className="">
                            <div className="button-wrapper" {...getRootProps()}>
                              <span className="label">
                                <i
                                  className="mdi mdi-paperclip"
                                  onClick={() => {
                                    editor?.chain().focus().run();
                                  }}
                                ></i>
                              </span>
                              <input 
                              {...getInputProps()} 
                              multiple />
                            </div>
                          </div>
                          <button
                          type='button'
                            onClick={() =>
                              editor.chain().focus().toggleBold().run()
                            }
                            disabled={
                              !editor.can().chain().focus().toggleBold().run()
                            }
                            className={`button ${
                              editor.isActive("bold") ? "is-active" : ""
                            } ql-bold`}
                          >
                            <i
                              className="mdi mdi-format-bold"
                              style={{
                                background: editor.isActive("bold")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                          <button
                          type='button'
                            onClick={() =>
                              editor.chain().focus().toggleItalic().run()
                            }
                            disabled={
                              !editor.can().chain().focus().toggleItalic().run()
                            }
                            className={
                              editor.isActive("italic") ? "is-active" : ""
                            }
                          >
                            <i
                              className="mdi mdi-format-italic"
                              style={{
                                background: editor.isActive("italic")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                          <button
                          type='button'
                            onClick={() =>
                              editor.chain().focus().toggleUnderline().run()
                            }
                            className={
                              editor.isActive("underline")
                                ? "is-active"
                                : "ql-underline"
                            }
                          >
                            <i
                              className="mdi mdi-format-underline"
                              style={{
                                background: editor.isActive("underline")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                          <button
                          type='button'
                            onClick={() =>
                              editor.chain().focus().toggleBulletList().run()
                            }
                            className={
                              editor.isActive("bulletList") ? "is-active" : ""
                            }
                          >
                            <i
                              className="mdi mdi-format-list-bulleted"
                              style={{
                                background: editor.isActive("bulletList")
                                  ? "gray"
                                  : "",
                              }}
                            />
                          </button>
                        </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wcsmmange2">
          <div className="yesnolable">
            <label className="font-14 color1a fw-bold mb-2 mt-3">
              Attachment Alignment
            </label>
            <div className="d-flex flex-column align-items-start justify-content-start gap-2">
              {" "}
              <label className="cstCheck fradio"> 
                <FormikRadioBox name="position" value="top" checked={(values.position=='top')?true:false} label="Top"/>                 <span className="checkmark" />
              </label>
              <label className="cstCheck fradio">                 
                <FormikRadioBox name="position"  value="bottom" checked={(values.position=='bottom')?true:false} label="Bottom"/>
                 <span className="checkmark" />
              </label>
            </div>
          </div>
          <div className="mt-3">
            <div>
              <i className="mdi mdi-calendar mdi-24px" /> Schedule{" "}
            </div>
            <div className="inflxtx mt-0">
              <label className="font-14 color1a fw-bold mb-2 mt-0">
                Select Custom Date &amp; Time
              </label>
              <div className="d-flex align-items-center inflx">
              <FormikDateInput
                type="date"
                value={ values.noticeTimeStamp}
                name="noticeTimeStamp"
                className="form-control border"
                />
                <FormikTimeInput
                  type="time"
                  value={values.noticeTime}
                  name="noticeTime"
                  className="form-control border"
                /> 
              </div>
              <div />
            </div>
            <div className="text-start mt-3 mb-3">
              <button type="submit" className="btnblue">
                Publish
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
</Form>
   </>
  )




}

let setSubmitting = () => { };

const NotificationContainer=()=>{
    
    const dispatch = useDispatch();
    const [EditorContent,setEditorContent] =useState(null);
    const {
        currentRecord,
        search,
        upsertReqStatus,
    } = useSelector((state) => state.NotificationReducer, shallowEqual);

    const {
      user,
  } = useSelector((state) => state.auth, shallowEqual);

    
    usePrevious({
      currentRecord,
      upsertReqStatus,
  }, (prev) => {
      if (
          prev.currentRecord !== currentRecord
          || (upsertReqStatus && prev.upsertReqStatus !== upsertReqStatus && upsertReqStatus !== REQUEST_STATUS.PENDING)) {
          setSubmitting(false);
      }
  });

    const onSubmit = useCallback((
      values,
      formikUtils,
  ) => {
      ({ setSubmitting } = formikUtils);
      includeStamp(values);
      values.createdBy = user._id;
      values.organizationId = user.organization
      values.content = EditorContent; 
      values.description = EditorContent
      dispatch(notificationUpsertAction(values));
  }, [
      dispatch,
      EditorContent
  ]);

  const onSearch = useCallback(({   
   notification,
}) => {
      dispatch(notificationSearchAction(notification));
      
}, [
   ,
]);
   const onClear = useCallback(() => {
      dispatch(notificationResetAction());
  }, [
      dispatch,
  ]);
  useEffect(()=>{
   dispatch(notificationSearchAction());
  },[dispatch])
 
  return (
    <>
     <div className="user p-4" style={{ display: "block" }} id="notifications">
  <div className="d-flex align-items-center justify-content-between bgadhding">
    <div className="d-flex align-items-center adhding mb-3">
      <span className="img">
        <img
          src="/assets/images/notifications/notification-bell.svg"
          className="img-fluid"
        />
      </span>
      <div className="adhdtext ms-2">
        <div className="font-20 fw-bold color33 hd">Notifications</div>
      </div>
    </div>
  </div>
  <div className="card mb-4">
    <div className="card-body">
    <Formik
            onSubmit={onSubmit}
            onClear={onClear}
            enableReinitialize
            initialValues={currentRecord}
            validationSchema={NotificationYupSchema}
            
        >
            {(formikProps) => (
                <Render
                    onClear={onClear}
                    onSearch={onSearch}
                    setEditorContent={setEditorContent}
                    {...formikProps}
                />
            )}
   </Formik>
   <NotificationSearchList search={search}/>
    </div>
  </div>


  <div
    id="customPagination2"
    className="text-center justify-content-center d-flex mt-4"
  />
</div>

    </>
  )
}

export default NotificationContainer