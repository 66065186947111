import { put, call } from 'redux-saga/effects';
import { designationCreateAPI, designationSearchAPI, designationUpdateAPI } from 'src/api/DesignationApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { DESIGNATION_ACTIONS } from '../actions/DesignationActions';
import { toast } from 'react-toastify';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(designationSearchAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: DESIGNATION_ACTIONS.DESIGNATION_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: DESIGNATION_ACTIONS.DESIGNATION_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(designationSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {
            
            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }

        
        yield put({
            type: DESIGNATION_ACTIONS.DESIGNATION_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(designationCreateAPI, payload);

        yield put(headerShowAction({
            data: 'Created',
        })); 
        toast("Designation Created")
        
        yield put({
            type: DESIGNATION_ACTIONS.DESIGNATION_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id,
            },
        });
        yield put({
            type: DESIGNATION_ACTIONS.DESIGNATION_SEARCH
        });
    }
    catch (error) {
        // console.error('LOG: upsert error', error.response.data);
        // yield put(headerShowAction({
        //     data: error.response.data.data,
        // }));
        toast.error(error.response.data.message);
        yield put({
            type: DESIGNATION_ACTIONS.DESIGNATION_UPSERT_ERROR,
        });
    }
}

function* update(designationId, payload) {
    try {
        yield call(designationUpdateAPI, {
            designationId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));
        if(payload.deleted===true){
            toast("Designation deleted")
        }
        else{
            toast("Designation updated")
        } 
        
        yield put({
            type: DESIGNATION_ACTIONS.DESIGNATION_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: DESIGNATION_ACTIONS.DESIGNATION_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}

export default [
    takeFirstSagaUtil(DESIGNATION_ACTIONS.DESIGNATION_SEARCH, search),
    takeFirstSagaUtil(DESIGNATION_ACTIONS.DESIGNATION_UPSERT, upsert),
    takeFirstSagaUtil(DESIGNATION_ACTIONS.DESIGNATION_EDIT, edit),
];
