import { useCallback, useEffect, useMemo, useState,useContext } from "react"
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import AddUserPopup from "./AddUserPopup"
import ListAction from "./ListAction"
import EditUserPopup from "./FilterPopup"
import FilterPopup from "./FilterPopup"
import UserProfilePopup from "./UserProfilePopup"
import { userEditAction, userSearchAction, userUpsertAction } from "./actions/UserActions";
import { ExportReactCSV } from 'src/components/excel/ExportReactCSV';
import { SocketContext } from "src/context/SocketContext";
import { debounce } from 'lodash';
import { invitationResend } from "src/api/UserApi";
import { toast } from 'react-toastify';
import { stampForUpdate } from "src/utils/StampUtils";
import Pagination from "src/components/Pagination";
import { time_ago } from "src/utils/CommonUtils";

const UserContainer=()=>{
        
        const dispatch = useDispatch();
        const {
            currentRecord,
            search,
            upsertReqStatus,
        } = useSelector((state) => state.UserReducer, shallowEqual);



    const [isAddUser,setIsAddUser] = useState(false)
    const [isUserFilter,setUserFilter] = useState(false)
    const [isUserProfileView,setUserProfileView] = useState(false)
    const [profileDetails,setProfileDetails] = useState(false)
    const [currentTab,setCurrentTab] = useState("all")
    const [where,setWhere] = useState({});
    const[pageNo,setPageNo] = useState(0);
    const socket = useContext(SocketContext);

    const handleOpenProfile=(recoard)=>{
        setUserProfileView(true)
        setProfileDetails(recoard)
    }
    const handleEditProfile = useCallback((
        recoard,        
    ) => { 
        setIsAddUser(true)
        dispatch(userEditAction(recoard));
    }, [
        dispatch,
    ]);
    const handleInvitation=async(recoard)=>{
           const result =await invitationResend({email:recoard.email})
           if(result.status) toast("invitation send")
    }
    
       
    
    const handleStatusChange = useCallback((
        recoard,        
    ) => {
        stampForUpdate(recoard); 
        delete recoard.password;
        Object.assign(recoard,{status:(!recoard.status),active:(!recoard.active)});
        dispatch(userUpsertAction(recoard));
       if(!recoard.status) handleLogOut(recoard)
    }, [
        dispatch,
    ]);
    const handleLogOut= useCallback((
        recoard,        
    ) => { 
        socket?.emit("logoutAllUser", { 
            organizationId: recoard?.organization,
            userId:recoard?._id,
          });
    }, [
        socket,
    ]);

    const handleUpdate= useCallback((
        recoard,        
    ) => { 
        socket?.emit("reloadUser", { 
            organizationId: recoard?.organization,
            userUpdateId:recoard?._id,
          });
          
    }, [
        socket,
    ]);


    const handleFilterChange = (key, value, isApply = false) => {
        setWhere((prevWhere) => {
            const newWhere = { ...prevWhere };
            if (key === 'isOrange' && value === 'all') {
                newWhere[key] = { $in: [true, false] };
            } else {
                newWhere[key] = value;
            }
            return newWhere;
        });
    }; 
    
    const handelFilterChange=(payload)=> {
        setWhere({...where,...payload})
    }

    const handleTestSearch = useCallback(
        debounce((searchKey, setWhere) => {
          const whereClauseKeysPaths = ['firstName', 'lastName', 'email',];
          const whereWithOrCondition = {
            $or: whereClauseKeysPaths.map((keyPath) => ({ 
              [keyPath]: {
                $regex: searchKey,
                $options: 'i',
              },
            })),
          }; 
          setWhere(whereWithOrCondition);
        }, 300),
        []
      );
      const handleSearchInputChange = (e) => {
        const searchKey = e.target.value;
        handleTestSearch(searchKey, setWhere);
      };

    useEffect(()=>{ 
        const payload = {
            where, 
            currentPage:pageNo, 
            autopopulate:true, 
            fullTextSearch:true,
        }
        dispatch(userSearchAction(payload));
    },[where,pageNo , isAddUser])

    const ExportInExcel = useMemo(() => {
        if (search?.data?.length===0) {
            return [];
        }
        const results = [];
        search?.data?.forEach((user) => { 
                    const ts = new Date(user.ts).getTime(); 
                    const fullName = `${user.firstName} ${user.lastName}`
                    const location = `${user.otherInformation.locationId?.countryName}, ${user.locationId?.location}`;
                    results.push({
                        Name: fullName, 
                        Email:user.email,
                        Designation:user.otherInformation?.designationId?.designationName,
                        Department:user.otherInformation?.departmentId?.departmentName,
                        Location:location,                        
                        CreateAt: ts,
                    });
        });
        return results;
    }, [
        search
    ]);




    return (
      <>

                <div className="user p-4"  id="AdminUser">
                <div className="d-flex align-items-center justify-content-between bgadhding">
                    <div className="d-flex align-items-center adhding">
                    <span className="img">
                        <img
                        src="/assets/images/admin/imgs/users.svg"
                        className="img-fluid"
                        width={58}
                        height={58}
                        alt=""
                        />
                    </span>
                    <div className="adhdtext ms-2">
                        <div className="font-20 fw-bold color33 hd">Users</div>
                        <div className="font-12 fw-bold tx">
                        here's what's happening with your store today.
                        </div>
                    </div>
                    </div>
                    <div className="onlineOfine d-flex justify-content-between align-items-center">
                    <button
                        type="button"
                        className="btnblue font-14 addremvbtn fw-bold rounded-1 px-3"
                        onClick={()=>{setIsAddUser(true)}}
                    >
                        <i className="mdi mdi-plus" /> Add Users
                    </button>
                    </div>
                </div>

                <nav style={{}} aria-label="breadcrumb" className="mt-3">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="#">Projects</a>
                    </li>
                    <li className="breadcrumb-item">
                        <a href="#">Weekend Project</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Overview
                    </li>
                    </ol>
                </nav>

                <div className="d-flex gap-2 justify-content-start py-3 Tabactive">
                    <span className={`badge d-flex align-items-center p-1 pe-2 text-primary-emphasis bg-white border border-secondary-subtle rounded-1 ${(currentTab==="all")?"active":""}`}
                    onClick={()=>{
                        handleFilterChange("isOrange","all")
                        setCurrentTab("all")
                    }}
                    >
                    <img
                        className="rounded-circle me-1"
                        width={24}
                        height={24}
                        src="/assets/images/admin/imgs/all-users.svg"
                        alt=""
                    />{" "}
                    All Users {search?.groupCounts?.allUsers}
                    </span>
                    <span className={`badge d-flex align-items-center p-1 pe-2 text-secondary-emphasis bg-white border border-secondary-subtle rounded-1 ${(currentTab==="inHouseUsers")?"active":""}`}
                    onClick={()=>{
                        handleFilterChange("isOrange",false)
                        setCurrentTab("inHouseUsers")
                    }}
                    >
                    <img
                        className="rounded-circle me-1"
                        width={24}
                        height={24}
                        src="/assets/images/admin/imgs/all-users.svg"
                        alt=""
                    />{" "}
                     In House Users {search?.groupCounts?.inHouseUsers}
                    </span>
                    <span className={`badge d-flex align-items-center p-1 pe-2 text-success-emphasis bg-white border border-secondary-subtle rounded-1 ${(currentTab==="orangeUsers")?"active":""}`}
                    onClick={()=>{
                        handleFilterChange("isOrange",true)
                        setCurrentTab("orangeUsers")
                    }}
                    >
                    <img
                        className="rounded-circle me-1"
                        width={24}
                        height={24}
                        src="/assets/images/admin/imgs/all-users.svg"
                        alt=""
                    />{" "}
                       Orange Members {search?.groupCounts?.orangeUsers}
                    </span>
                </div>

                <div className="card pt-2">
                    <div className="d-flex justify-content-between align-items-center px-4 pb-2">
                    <div className="font-14 fw-bold color1a">Users Details</div>
                    <div className="d-flex tablebtns gap-3">
                        <div id="customFilter" className="customFilter rounded-2">
                        <input
                            type="search"
                            id="searchInput"
                            className="form-control w-100"
                            placeholder="Search..."
                            onChange={handleSearchInputChange}
                            
                        />
                        </div>
                        <button className="border-0 bg-none" 
                            onClick={()=>{setUserFilter(!isUserFilter)}}>
                        <i className="mdi mdi-filter-variant" />
                        </button>
                        {}
                        {}
                        
                        <ExportReactCSV 
                        csvData={ExportInExcel} 
                        fileName={"UserList-" + new Date().toLocaleDateString()} 
                        />
                    </div>
                    </div>
                    <div className="table-responsive table-height">
                    <table
                        id="usertable"
                        className="table table-striped datatable border-top small"
                    >
                        <thead>
                        <tr>
                            <th className="text-center">S.R. </th>
                            <th>User Name </th>
                            <th>Email</th>
                            <th>Designation </th>
                            <th>Department</th>
                            <th>Location </th>                           
                            <th>Created Date </th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                search.data.map((user,index)=>{
                                    return(
                            <tr key={(pageNo * 10) + index + 1}>
                            <td className="text-center" >{(pageNo * 10) + index + 1}</td>
                            <td className="align-middle customer white-space-nowrap">
                            <a
                                className="d-flex align-items-center text-body"
                                href="#"
                                onClick={()=>{handleOpenProfile(user)}}
                            >
                                <div className="avatar avatar-l">
                                <div className="avatar-name rounded-circle">
                                    <span>{user.firstName.substring(0,1).toUpperCase()}</span>
                                </div>
                                </div>
                                <h6 className="mb-0 ms-1 text-body">{`${user.firstName} ${user.lastName}`}</h6>
                            </a>
                            </td>
                            <td>{user.email}</td>
                            <td>{user.otherInformation?.designationId?.designationName} </td>
                            <td>{user.otherInformation?.departmentId?.departmentName}</td>
                            <td>{`${user.otherInformation.locationId?.countryName},${user.otherInformation.locationId?.location}`} </td>                           
                            <td>{time_ago(user.ts)}</td>
                            <td>
                            <ListAction 
                            recoard={user}
                            handleOpenProfile={handleOpenProfile}
                            handleEditProfile={handleEditProfile}
                            handleInvitation={handleInvitation}
                            handleStatusChange={handleStatusChange}
                            handlLogOut={handleLogOut}   
                            handleUpdate={handleUpdate}                         
                            />
                            </td>
                        </tr>
                            )
                        })
                    }
                        </tbody>
                    </table>
                    </div>
                </div>
                {search.data && search.data?.length>0 && <Pagination setPageNo={setPageNo} pageNo={pageNo} total={search.total}/>}
                {}
                </div>

          <AddUserPopup 
            setIsAddUser={setIsAddUser}
            isAddUser={isAddUser}
           />
          <FilterPopup 
            isUserFilter={isUserFilter} 
            setUserFilter={setUserFilter}
            where={where}
            setWhere={setWhere}
          />
          {
          isUserProfileView&&<UserProfilePopup 
          profileDetails={profileDetails}
          isUserProfileView={isUserProfileView} 
          setUserProfileView={setUserProfileView}/>
           }
          
      </>
    )
  }
  export default UserContainer