import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const TreeComponent = ({ nodes, checked, onChange }) => {
    const [
        scopesExpanded,
        setScopesExpanded,
    ] = useState([]);

    return (
        <CheckboxTree
            nodes={nodes}
            checked={checked}
            expanded={scopesExpanded}
            iconsClass="fa5"
            onCheck={onChange}
            onExpand={setScopesExpanded}
        />
    );
};

TreeComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    
    checked: PropTypes.array.isRequired,
    
    nodes: PropTypes.any.isRequired,
};

export default TreeComponent;
