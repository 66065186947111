import React from "react";

const MediaTabs = ({ tabs, tabValue, handleTabClick }) => {
  return (
    <ul className="nav nav-tabs filetabs" id="myTab" role="tablist">
      {tabs.map((tab) => (
        <li className="nav-item" key={tab} role="presentation">
          <button
            className={`nav-link ${tabValue === tab ? "active" : ""}`}
            id={`${tab.toLowerCase()}-tab`}
            data-bs-toggle="tab"
            data-bs-target={`#${tab.toLowerCase()}`}
            type="button"
            role="tab"
            aria-controls={tab.toLowerCase()}
            aria-selected={tabValue === tab ? "true" : "false"}
            onClick={handleTabClick}
            name={tab}
          >
            {tab}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default MediaTabs;
