import React from "react";
import CreateTodo from "./CreateTodo";

const AddTodo = ({
  todoCreate_close,
  addnewtask_close,
  todo,
  handleTitleChange,
  handleRemoveItem,
  handleChange,
  formatDateIntoNew,
  handleAddItem,
  handleSaveTodo,
  handleDateTimeRangeChange
}) => {
  return (
     
      <div
        className="chat-sidebar chat-animate-right"
        style={{ display: "none", right: 109 }}
        id="myTodoCreate"
      >
        <div className="modal-dialog-scrollable">
          <div className="modal-content">
            <div className="slide-header">
              <div className="HeadSlidName d-flex justify-content-between align-items-center">
                <div className="slidName">To-do List</div>
                <button className="" onClick={()=>todoCreate_close()}>
                  ×
                </button>
              </div>
              <div className="msg-search mx-0">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="Search Task"
                  aria-label="search"
                />
                <button>
                  <i className="mdi mdi-magnify" />
                </button>
              </div>
              <div className="d-flex justify-content-between align-items-center createnotes font-14 color1a btnclr">
                Add new task
                <button className="rounded-4" onClick={()=>addnewtask_close()}>
                  <i className="mdi mdi-chevron-left" />
                </button>
              </div>
            </div>
            <CreateTodo
              todo={todo}
              handleTitleChange={handleTitleChange}
              handleRemoveItem={handleRemoveItem}
              handleChange={handleChange}
              formatDateIntoNew={formatDateIntoNew}
              handleAddItem={handleAddItem}
              handleSaveTodo={handleSaveTodo}
              handleDateTimeRangeChange={handleDateTimeRangeChange}
            />
          </div>
        </div>
      </div>
    
  );
};

export default AddTodo;
