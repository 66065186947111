export const ROLE_PERMISSIONS_ACTIONS = {
    ROLE_PERMISSIONS_SEARCH: 'ROLE_PERMISSIONS_SEARCH',
    ROLE_PERMISSIONS_SEARCH_SUCCESS: 'ROLE_PERMISSIONS_SEARCH_SUCCESS',
    ROLE_PERMISSIONS_SEARCH_ERROR: 'ROLE_PERMISSIONS_SEARCH_ERROR',

    ROLE_PERMISSIONS_UPSERT: 'ROLE_PERMISSIONS_UPSERT',
    ROLE_PERMISSIONS_UPSERT_SUCCESS: 'ROLE_PERMISSIONS_UPSERT_SUCCESS',
    ROLE_PERMISSIONS_UPSERT_ERROR: 'ROLE_PERMISSIONS_UPSERT_ERROR',

    ROLE_PERMISSIONS_EDIT: 'ROLE_PERMISSIONS_EDIT',

    ROLE_PERMISSIONS_RESET: 'ROLE_PERMISSIONS_RESET',
};

export const rolePermissionsResetAction = () => ({
    type: ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_RESET,
});

export const rolePermissionsSearchAction = (payload) => ({
    type: ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_SEARCH,
    ...payload,
});

export const rolePermissionsEditAction = (payload) => ({
    type: ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_EDIT,
    ...payload,
});

export const rolePermissionsUpsertAction = (payload) => ({
    type: ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_UPSERT,
    ...payload,
});

export default {
    ROLE_PERMISSIONS_ACTIONS,
    rolePermissionsSearchAction,
    rolePermissionsUpsertAction,
    rolePermissionsEditAction,
};
