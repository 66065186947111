import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

const component = ({
    field, form, ...rest
}) => (
    <>
        <input
            {...field}
            {...rest}
            type="checkbox"
            value={field.value === undefined ? false : field.value}
            checked={field.value === undefined ? false : field.value}
        />
        <ErrorMessage
            component="div"
            className="text-danger"
            name={field.name}
        />
    </>
);

component.propTypes = {
    field: PropTypes.any.isRequired,
    form: PropTypes.any.isRequired,
};

const FormikCheckBox = (props) => (
    <Field
        {...props}
        component={component}
    />
);

export default FormikCheckBox;
