import React from 'react';
import FormikSearchDropdown from 'src/components/formik/FormikSearchDropdown/FormikSearchDropdown';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import PropTypes from 'prop-types';
import { getRoleAPI } from 'src/api/RoleApi';

const RoleDropDown = ({ value, name, onChange }) => (
    <FormikSearchDropdown
        name={name}
        placeholder="Select Role"
        displayKeyPath={[
            'roleName',
        ]}
        valueKeyPath="_id"
        defaultValueFetchByKeyPath="_id"
        whereClauseKeysPaths={[
            'roleName',
        ]}
        api={getRoleAPI}
        apiCallAddonData={{
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
        }}
        value={value}
        onChange={onChange}
    />
);

RoleDropDown.propTypes = {
    
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

RoleDropDown.defaultProps = {
    value: '',
    onChange: () => { },
};

export default RoleDropDown;
