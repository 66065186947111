export const initialState = {
  tabValue: "All Files",
  searchText: "",
  conversationMedia: [],
  startDate: "",
  endDate: "",
  selectedUsers: [],
  selectedFileMedias: [],
  showUserModal: false,
  singleForward: "",
  isSelection: false,
};

export function reducer(state, action) {
    switch (action.type) {
      case "SET_TAB_VALUE":
        return { ...state, tabValue: action.payload };
      case "SET_SEARCH_TEXT":
        return { ...state, searchText: action.payload };
      case "SET_CONVERSATION_MEDIA":
        return { ...state, conversationMedia: action.payload };
      case "SET_START_DATE":
        return { ...state, startDate: action.payload };
      case "SET_END_DATE":
        return { ...state, endDate: action.payload };
      case "SET_SELECTED_USERS":
        return { ...state, selectedUsers: action.payload };
      case "SET_SELECTED_FILE_MEDIAS":
        return { ...state, selectedFileMedias: action.payload };
      case "SET_SHOW_USER_MODAL":
        return { ...state, showUserModal: action.payload };
      case "SET_SINGLE_FORWARD":
        return { ...state, singleForward: action.payload };
      case "SET_IS_SELECTION":
        return { ...state, isSelection: action.payload };
      case "RESET_STATES":
        return initialState;
      default:
        return null;
    }
  }