import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };
  
const component = ({
    field, form, ...rest
}) => (
    <>
        <input
            {...field}
            {...rest}
            value={field.value ? formatDate(field.value) : ''}
            autoComplete="disabled"
        />
        <ErrorMessage
            component="div"
            className="text-danger"
            name={field.name}
        />
    </>
);

component.propTypes = {
    field: PropTypes.any.isRequired,
    form: PropTypes.any.isRequired,
};

const FormikDateInput = (props) => (
    <Field
        {...props}
        component={component}
    />
);

export default FormikDateInput;
