import { all } from 'redux-saga/effects'; 
import AppSaga from './containers/app-container/saga/AppSaga'; 
import HomeSaga from './containers/home-container/saga/HomeSaga';
import AuthSaga from './containers/home-container/saga/AuthSaga'; 
import AccountSaga from './containers/account-container/saga/AccountSaga'; 
import DepartmentSaga from './containers/admin-containers/department-container/saga/DepartmentSaga'; 
import DesignationSaga from './containers/admin-containers/designation-container/saga/DesignationSaga'; 
import LocationSaga from './containers/admin-containers/location-container/saga/LocationSaga'; 
import NotificationSaga from './containers/admin-containers/notification-container/saga/NotificationSaga'; 
import RoleSaga from './containers/admin-containers/role-container/saga/RoleSaga'; 
import UserSaga from './containers/admin-containers/user-container/saga/UserSaga'; 
import GroupSaga from './containers/admin-containers/group-container/saga/GroupSaga'; 
import PermissionsMasterSaga from './containers/admin-containers/permission-container/saga/PermissionsMasterSaga';
import RolePermissionsSaga from './containers/admin-containers/permission-container/saga/RolePermissionsSaga';
import ChatBoxSaga from './containers/chatbox-container/saga/ChatBoxSaga'; 

export default function* sagas() {
    yield all([        
        ...HomeSaga,
        ...AuthSaga,
        ...ChatBoxSaga,
        ...AccountSaga,
        ...DepartmentSaga,
        ...DesignationSaga,
        ...AppSaga,
        ...UserSaga,
        ...LocationSaga,
        ...RoleSaga,
        ...GroupSaga,
        ...PermissionsMasterSaga,
        ...RolePermissionsSaga,
        ...NotificationSaga
    ]);
}
