export const RECEIVER = [
    {
    value:'All SyncUp Team',
    label:'all'
    },
    {
    value:'user',
    label:'User'
    },
    {
    value:'group',
    label:'Group'
    },
    {
    value:'department',
    label:'Department'
    },     
    {
    value:'designation',
    label:'Designation'
    },     
];

export default {
    RECEIVER,
};
