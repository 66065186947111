import React, { useEffect, useState } from 'react';

const ScreenShare = () => {
  const [uuid, setUuid] = useState('');

  useEffect(() => {
    window.electron.ipcRenderer.on("uuid", (data) => {
      setUuid(data);
    });

    return () => {
      window.electron.ipcRenderer.removeAllListeners("uuid");
    };
  }, []);

  const startShare = () => {
    window.electron.ipcRenderer.send("start-share", {});
    document.getElementById("start").style.display = "none";
    document.getElementById("stop").style.display = "block";
  };

  const stopShare = () => {
    window.electron.ipcRenderer.send("stop-share", {});
    document.getElementById("stop").style.display = "none";
    document.getElementById("start").style.display = "block";
  };

  return (
    <div>
      <div className="header">
        Codeboard Screen Share
      </div>
      <div className="code" id="code">{uuid}</div>

      <div className="container">
        <button className="btn" id="start" onClick={startShare}>Start Share</button>
        <button className="btn" id="stop" onClick={stopShare} style={{ display: 'none' }}>Stop Share</button>
      </div>
    </div>
  );
};

export default ScreenShare;
