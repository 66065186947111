import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const UserPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    firstName: PropTypes.string, 
    lastName: PropTypes.string, 
    email: PropTypes.string, 
    employeeID: PropTypes.string, 
    phone: PropTypes.string, 
    
    isAdmin: PropTypes.boolean,
    username: PropTypes.string,
    otherInformation:PropTypes.shape({
        designationId: PropTypes.string,
        departmentId: PropTypes.string,
        locationId: PropTypes.string,
        reportingManagerId: PropTypes.string,
    }), 
});

export const UserDefaultProps = {
    _id: undefined, 
    firstName: '', 
    lastName: '', 
    email: '', 
    employeeID: '',
    phone: '', 
    isAdmin: false,
    status: true,
    username: '',
    password: 'acadecraft@123',
    otherInformation:{
        designationId: '',
        departmentId: '',
        locationId: '',
        reportingManagerId: null,
    }, 
};


export const UserYupSchema = Yup.object().shape({ 
    firstName: Yup.string().required('Required'),
    otherInformation: Yup.object().shape({
        designationId: Yup.string().required('Required'),
        departmentId: Yup.string().required('Required'),
    }),
    email: Yup.string().required('Required').email('Invalid email format'),
});

export default {
    UserDefaultProps,
    UserPropTypes,
    UserYupSchema,
};
