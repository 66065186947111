
const ListAction=({
    recoard,
    handleOpenProfile,
    handleEditProfile,
    handleInvitation,
    handleStatusChange,
    handlLogOut,
    handleUpdate
   })=>{
   const  handleActionClick=(type)=>{
     switch(type) {
        case "profile":
            handleOpenProfile(recoard)
          break;
        case "edit":
            handleEditProfile(recoard)
          break;
        case "invitation":
            handleInvitation(recoard) 
          break;
        case "status":
            handleStatusChange(recoard)
          break;
      case "logout":
        handlLogOut(recoard)
        case "update":
        handleUpdate(recoard)
      break;
        default:
          
      }
    }
   return (
    <>
            <div className="btn-reveal-trigger position-static dotstabl">
            <button
                className="btn btn-sm dropdown-toggle dropdown-caret-none"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-bs-reference="parent"
                >
            <i className="mdi mdi-dots-horizontal mdi-36px" />
            </button>
            <div className="dropdown-menu dropdown-menu-end py-2" >
                <a
                    className="dropdown-item font-12 fw-bold color1a"
                    href="#"
                    onClick={()=>{handleActionClick("profile")}}
                    >
                View
                </a>
                <a
                    className="dropdown-item font-12 fw-bold color1a"
                    href="#"
                    onClick={()=>{handleActionClick("edit")}}
                    >
                Edit
                </a>
                <a className="dropdown-item font-12 fw-bold color1a" 
                href="#"
                onClick={()=>{handleActionClick("invitation")}}
                >
                Resend Invite
                </a>
                <a className={`dropdown-item  font-12 fw-bold color1a ${(recoard.status)?'text-danger':'text-success'}`}
                href="#"
                onClick={()=>{handleActionClick("status")}}
                >
                {(recoard.status)?'De-Activate':'Activate'}
                </a>
                <a className={`dropdown-item  font-12 fw-bold color1a ${(recoard.status)?'text-danger':'text-success'}`}
                href="#"
                onClick={()=>{handleActionClick("logout")}}
                >
                Logout
                </a>
                <a className={`dropdown-item  font-12 fw-bold color1a ${(recoard.status)?'text-danger':'text-success'}`}
                href="#"
                onClick={()=>{handleActionClick("update")}}
                >
                Update
                </a>
            </div>
            </div>
    </>
   )
}
export default ListAction