import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const dashboardCounterAPI = function (data) {
    const url = `${config.baseUrl}admin/dashboard/counter`;
    return postRequest({
        url,
        data,
    });
};

 

