import React from 'react';
import FormikSearchDropdown from 'src/components/formik/FormikSearchDropdown/FormikSearchDropdown';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import PropTypes from 'prop-types';
import { getDesignationAPI } from 'src/api/DesignationApi';

const DesignationDropDown = ({ value, name, onChange,...rest }) => (
    <FormikSearchDropdown
        name={name}
        placeholder="Select Designation"
        displayKeyPath={[
            'designationName',
        ]}
        valueKeyPath="_id"
        defaultValueFetchByKeyPath="_id"
        whereClauseKeysPaths={[
            'designationName',
        ]}
        api={getDesignationAPI}
        apiCallAddonData={{
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
        }}
        value={value}
        onChange={onChange} 
        {...rest}
    />
);

DesignationDropDown.propTypes = {
    
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
};

DesignationDropDown.defaultProps = {
    value: '',
    readOnly: false,
};

export default DesignationDropDown;
