import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const chatBoxSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/chatBox/search`;
    return postRequest({
        url,
        data,
    });
};
export const getChatBoxAPI = function (data) {
    const url = `${config.baseUrl}admin/chatBox/getChatBox`;
    return postRequest({
        url,
        data,
    });
};
export const chatBoxCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/chatBox/save`;
    return postRequest({
        url,
        data,
    });
};

export const chatBoxUpdateAPI = function ({
    chatBoxId,
    data,
}) {
    const url = `${config.baseUrl}admin/chatBox/update/${chatBoxId}`;
    return putRequest({
        url,
        data,
    });
};
