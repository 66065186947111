import React, { useState } from "react";

const DeleteFiles = ({
  handleDeleteMedia,
  selectedUserOrGroup,
  mediaIds,
  forAllOption,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChangeMedia = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
    <div
      className="modal fade lbtk show DeleteFilesModal"
      id="deletemsg"
      style={{ display: "block" }}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-sm modal-dialog-centered"
        role="document"
      >
        <div className="modal-content rounded-3 shadow border">
          <div className="modal-body px-4 pt-4 pb-2 text-start">
            <h5 className="mb-2 font-16 color33 fw-bold">Delete file!</h5>
            <p className="mb-0 font-14 color80">
              Are you sure you want to delete this file?
            </p>
            {forAllOption && (
              <>
                <label
                  className="cstCheck fcheck font-14 color4d mt-3 fw-500"
                  htmlFor="mediaDelete"
                  onClick={(e) => handleCheckboxChangeMedia()}
                >
                  <input id="mediaDelete" type="checkbox" checked={isChecked} />
                  Also delete for {selectedUserOrGroup?.firstName?selectedUserOrGroup?.firstName:"everyone"} <br />
                  <span className="checkmark" />
                </label>
              </>
            )}
          </div>
          <div className="modal-footer flex-nowrap p-0 border-0">
            <button
              type="button"
              className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
              data-bs-dismiss="modal"
              onClick={() => {
                handleDeleteMedia();
              }}
            >
              <strong>Cancel </strong>
            </button>
            <button
              type="button"
              className="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-0 color1a"
              onClick={() => {
                handleDeleteMedia(isChecked, [mediaIds]);
              }}
            >
              <strong>Delete</strong>{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default DeleteFiles;
