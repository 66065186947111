import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Login from "./containers/login-container/Login";
import PrivateRoute from "./hoc/PrivateRouteHOC";
import Home from "./containers/home-container/Home";
import LandingPage from "./containers/landingpage-container/index";
import TermsAndConditions from "./containers/landingpage-container/TermsAndConditions";
import PrivacyPolicy from "./containers/landingpage-container/PrivacyPolicy";
import ScreenShare from "./containers/screen-sharing/ScreenShare";
import AdminContainer from "./containers/admin-containers/index";
import { appInitAction } from "./containers/app-container/actions/AppActions";
import io from "socket.io-client";
import config from "./config/index";
import { SocketProvider } from "./context/SocketContext";
import AdminPrivateRouteHOC from "./hoc/AdminPrivateRouteHOC";
import Download from "./containers/landingpage-container/download/index";
import ContactUs from "./containers/landingpage-container/contact-us/index";
import Talk2Member from "./components/Talk2Member";
import ConnectivityDialog from "./components/UserStatus/ConnectivityDialog";
import { updateLastSeen } from "./containers/profile-container/ProfileServices";

const API = config.socketUrl;

const Routings = () => {
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    dispatch(appInitAction());

    const newSocket = io(API.replace("ws://", "wss://"), {
      auth: { token: authToken },
      reconnection: true, // Enable automatic reconnection
      reconnectionDelay: 1000, // Initial delay between reconnection attempts (milliseconds)
      reconnectionDelayMax: 5000, // Maximum delay between reconnection attempts (milliseconds)
      randomizationFactor: 0.5, // Random
      // reconnectionAttempts: 5,
      // reconnectionDelay: 1000,
    });

    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("WebSocket connected");
      setIsConnected(true);
      // window.document.getElementById("spinner").classList.remove("mdi-spin");
      removeConnectingText();
    });

    newSocket.on("disconnect", (reason) => {
      console.log(`WebSocket disconnected: ${reason}`);
      setIsConnected(false);
      if (reason === "io server disconnect") {
        newSocket.connect();
        setIsConnected(false);
      }
      createConnectingElement();
    });

    newSocket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
      setIsConnected(false);
      createConnectingElement();
    });

    newSocket.io.on("error", (error) => {
      if (error.message === "xhr poll error") {
        setIsConnected(false);
        console.log("XHR Poll Error:************", error.message);
      } else {
        setIsConnected(false);
        console.log("Socket.IO error:", error.message);
      }
      createConnectingElement();
    });

    return () => {
      newSocket.off("connect");
      newSocket.off("disconnect");
      newSocket.off("connect_error");
      newSocket.io.off("error");
      newSocket.close();
    };
  }, [authToken, dispatch, API, setSocket, isConnected]);


  const createConnectingElement = () => {
    const existingText = document.querySelector(".connect_socket span.connecting-text");

    if (!existingText) {
      // Create the "Connecting..." span element
      const connectingText = document.createElement("span");
      connectingText.textContent = "Connecting...";
      connectingText.style.color = "green";
      connectingText.style.fontFamily = "Arial, sans-serif";
      connectingText.style.fontSize = "14px";
      connectingText.style.marginRight = "8px"; // Optional: space between text and icon
      connectingText.classList.add("connecting-text"); // Add a class for easy identification

      // Find the button and insert the span before the icon
      const button = window.document.querySelector(".connect_socket");
      const spinnerIcon = window.document.getElementById("spinner");

      if (button && spinnerIcon) {
        button.insertBefore(connectingText, spinnerIcon);
      }
    }

    // Add the 'mdi-spin' class to the spinner icon
    const spinnerIcon = window.document.getElementById("spinner");
    if (spinnerIcon) {
      spinnerIcon.classList.add("mdi-spin");
    }
  }

  const removeConnectingText = () => {
    const spinnerIcon = window.document.getElementById("spinner");
    const existingText = document.querySelector(".connect_socket span.connecting-text");
    if (existingText) {
      existingText.remove();
    }
    if (spinnerIcon) {
      spinnerIcon.classList.remove("mdi-spin");
    }
  };

  return (
    socket && (
      <SocketProvider socket={socket}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                window.electron ? (
                  <Login />
                ) : authToken ? (
                  <Navigate to="/home" />
                ) : (
                  <LandingPage />
                )
              }
            />
            <Route path="login" element={<Login />} />
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="download" element={<Download />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route
              path="admin/*"
              element={<AdminPrivateRouteHOC Component={AdminContainer} />}
            />
            <Route path="home" element={<PrivateRoute Component={Home} />} />
            <Route
              path="voice-call"
              element={<PrivateRoute Component={Talk2Member} />}
            />
            <Route
              path="screen"
              element={<PrivateRoute Component={ScreenShare} />}
            />
          </Routes>
          {/* {!isConnected &&   <ConnectivityDialog />} */}
        </BrowserRouter>
      </SocketProvider>
    )
  );
};

export default Routings;
