export const CHATBOX_ACTIONS = {
    CHATBOX_SEARCH: 'CHATBOX_SEARCH',
    CHATBOX_SEARCH_SUCCESS: 'CHATBOX_SEARCH_SUCCESS',
    CHATBOX_SEARCH_ERROR: 'CHATBOX_SEARCH_ERROR',

    CHATBOX_UPSERT: 'CHATBOX_UPSERT',
    CHATBOX_UPSERT_SUCCESS: 'CHATBOX_UPSERT_SUCCESS',
    CHATBOX_UPSERT_ERROR: 'CHATBOX_UPSERT_ERROR',

    CHATBOX_EDIT: 'CHATBOX_EDIT',

    CHATBOX_RESET: 'CHATBOX_RESET',

    UPDATE_CURRENT_RECOARD: 'UPDATE_CURRENT_RECOARD',
};

export const chatBoxResetAction = () => ({
    type: CHATBOX_ACTIONS.CHATBOX_RESET,
});

export const chatBoxSearchAction = (payload) => ({
    type: CHATBOX_ACTIONS.CHATBOX_SEARCH,
    ...payload,
});

export const chatBoxEditAction = (payload) => ({
    type: CHATBOX_ACTIONS.CHATBOX_EDIT,
    ...payload,
});

export const chatBoxUpsertAction = (payload) => ({
    type: CHATBOX_ACTIONS.CHATBOX_UPSERT,
    ...payload,
});
export const chatBoxUpdateCurrentRecoardAction = (payload) => ({
    type: CHATBOX_ACTIONS.UPDATE_CURRENT_RECOARD,
    data:payload,
});

export default {
    CHATBOX_ACTIONS,
    chatBoxSearchAction,
    chatBoxUpsertAction,
    chatBoxEditAction,
    chatBoxUpdateCurrentRecoardAction,
};
