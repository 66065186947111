import React, { useState, useEffect } from 'react';
import { muteChat } from 'src/containers/home-container/apis/Groupapis';
 

const MuteUnMute = ({ 
        selectedUserOrGroup,
        recipient,
        user 
    }) => {
  const [isMuted, setIsMuted] = useState(false); 
  useEffect(() => {
    if (recipient?.isMute && user) {
      setIsMuted(recipient.isMute.includes(user._id));
    }
  }, []);

  const muteNotification = async (chatHistoryId) => {
    await muteChat(recipient?.roomId);
    const userId = user?._id;
    if (!userId) return;
    if (isMuted) { 
        setIsMuted(false);
      } else { 
        setIsMuted(true);
      }    
  };

  return (
    <button
      id={`mute_chat_${selectedUserOrGroup?._id}`}
      onClick={() => muteNotification(selectedUserOrGroup?._id)}
    >
      <i
        className={`mdi-18px ${isMuted ? 'mdi mdi-volume-off' : 'mdi mdi-volume-high active'}`}
      />
    </button>
  );
};

export default MuteUnMute;