

import React, { useEffect, useRef } from "react";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import $ from "jquery";
import "bootstrap-daterangepicker";

const OnlyDateRangePicker = ({ onDateRangeChange, position }) => {
  const pickerRef = useRef(null);

  useEffect(() => {
    const $picker = $(pickerRef.current);

    const start = moment().subtract(29, "days");
    const end = moment();

    function cb(start, end) {
      onDateRangeChange(start, end);
      $("#reportrange span").html(
        start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY")
      );
    }

    $picker.daterangepicker(
      {
        startDate: start,
        endDate: end,
        opens: `${position}`,
      },

      
      
      

      
      
      
      
      
      
      
      
      cb
    );

    cb(start, end);

    return () => {
      if ($picker.data("daterangepicker")) {
        $picker.data("daterangepicker").remove();
      }
    };
  }, []);

  return (
    <div>
      <div
        id="reportrange"
        ref={pickerRef}
        className="reportrange d-flex align-items-center form-control cursor-pointer border-0 mxwxctm"
        style={{
          
          cursor: "pointer",
          padding: "5px 10px",
          border: "1px solid #ccc",
          width: "100%",
        }}
        >
        <i className="mdi mdi-calendar"></i>
        &nbsp;
        <span />
      </div>


      {}
      {}

    </div>
  );
};

export default OnlyDateRangePicker;


