import React, { useState, useEffect, useRef } from "react";
import EmojiPicker from "emoji-picker-react";

const EmojiReactions = ({
  socket,
  messageId,
  chatRoomId,
  userId,
  organizationId,
  setClickedMessageId,
  setIsReacted,
}) => {
  const handleEmojiClick = (emoji) => {
    const { imageUrl, unified, names } = emoji;
    socket.emit("emoji-reaction", {
      messageId,
      userId,
      reactionEmoji: imageUrl,
      organizationId,
      chatRoomId,
      emojiId: unified,
      emojiName: names[1] ? names[1] : names[0],
    });
    setClickedMessageId(null);
    setIsReacted(false);
  };

  return (
    <EmojiPicker
      reactionsDefaultOpen={true}
      allowExpandReactions={true}
      onReactionClick={handleEmojiClick}
      onEmojiClick={handleEmojiClick}
      style={{ backgroundColor: "rgb(239, 252, 255)", border: "none" }}
      lazyLoadEmojis={true}
    />
  );
};

export default EmojiReactions;
