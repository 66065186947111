import { useEffect, useRef } from 'react';

export const usePrevious = (value, callback) => {
    const prevValues = useRef(value);

    useEffect(() => {
        callback(prevValues.current);
        return () => (prevValues.current = value);
    }, [
        value,
        callback,
    ]);
};

 

export default {
    usePrevious
};
