import React, { useCallback, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { withFormikDevtools } from 'formik-devtools-extension';
import FormikInput from 'src/components/formik/FormikInput';
import { LocationYupSchema } from 'src/containers/admin-containers/location-container/LocationPropTypes';
import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { usePrevious } from 'src/utils/ReactHooksUtil';
import { locationResetAction, locationSearchAction, locationUpsertAction } from './actions/LocationActions';
import LocationSearchList from './LocationSearchList';
import { includeStamp } from 'src/utils/StampUtils';


const Render=(props)=>{
   




  const {
      values,
      
      
      isSubmitting,
      onSearch,
      onClear,
      resetForm,
  } = props;
   
  return(
   <>
   <Form autoComplete="disabled">
   <div className="">
   <label className="font-20 fw-bold color1a mb-2">{(values._id)?"Update":"Add new"} location</label>
   <div className="d-flex justify-content-between align-items-center addinputwbtn"> 
      <FormikInput
      name="location"
      placeholder="Enter the City Name"
      className="form-control font-14 color1a bg-white border w-100"
      />  
      <FormikInput
      placeholder="Enter the Country Name"
      name="countryName"
      className="form-control font-14 color1a bg-white border w-100"
      /> 
      
      <button type='submit'>{(values._id)?"Update":"Add"}</button>
      <button type="reset" onClick={onClear} className='btn-default'>Reset</button>
   </div>
</div>
</Form>
   </>
  )




}

let setSubmitting = () => { };

const LocationContainer=()=>{
    
    const dispatch = useDispatch();
    const {
        currentRecord,
        search,
        upsertReqStatus,
    } = useSelector((state) => state.LocationReducer, shallowEqual);
    
    usePrevious({
      currentRecord,
      upsertReqStatus,
  }, (prev) => {
      if (
          prev.currentRecord !== currentRecord
          || (upsertReqStatus && prev.upsertReqStatus !== upsertReqStatus && upsertReqStatus !== REQUEST_STATUS.PENDING)) {
          setSubmitting(false);
      }
  });

    const onSubmit = useCallback((
      values,
      formikUtils,
  ) => {
      ({ setSubmitting } = formikUtils);     
      includeStamp(values); 
      dispatch(locationUpsertAction(values));
  }, [
      dispatch,
  ]);

  const onSearch = useCallback(({   
   location,
}) => {
      dispatch(locationSearchAction(location));
      
}, [
   ,
]);
   const onClear = useCallback(() => {
      dispatch(locationResetAction());
  }, [
      dispatch,
  ]);
  useEffect(()=>{
   dispatch(locationSearchAction());
  },[dispatch])
  return (
    <>
       <div className="user p-4" id="AdminLocation">
<div className="d-flex align-items-center justify-content-between bgadhding">
   <div className="d-flex align-items-center adhding">
      <span className="img">
      <img
         src="/assets/images/admin/imgs/depertment.svg"
         className="img-fluid"
         width={58}
         height={58}
         alt=""
         />
      </span>
      <div className="adhdtext ms-2">
         <div className="font-20 fw-bold color33 hd">Location</div>
         <div className="font-12 fw-bold tx">
            here's what's happening with your store today.
         </div>
      </div>
   </div>
   <div className="onlineOfine d-flex justify-content-between align-items-center">
      {}
   </div>
</div>
<nav aria-label="breadcrumb" className="mt-3">
   <ol className="breadcrumb">
      {}
   </ol>
</nav>
<Formik
            onSubmit={onSubmit}
            onClear={onClear}
            enableReinitialize
            initialValues={currentRecord}
            validationSchema={LocationYupSchema}
            
        >
            {(formikProps) => (
                <Render
                    onClear={onClear}
                    onSearch={onSearch}
                    {...formikProps}
                />
            )}
   </Formik>
    <LocationSearchList search={search}/>
</div>
    </>
  )
}

export default LocationContainer