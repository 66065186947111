import config from "src/config";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "src/utils/RequestUtil";
export const getUserDetails = async (userId, memberId) => {
  try {
    const url = `${config.baseUrl}user/profile-details`;
    return postRequest({
      url,
      data:{ userChatId: userId, memberId: memberId },
    });
  } catch (error) {
    return null;
  }
};

export const updateUserDetails = async (userId, updatedDetails) => {
  try {
    const url = `${config.baseUrl}user/profile-details/update`;
    return patchRequest({
      url,
      data: { userId, updatedDetails },
    });
  } catch (error) {
    return null;
  }
};

export const updateLastSeen = async (userId, lastSeen) => {
  try {
    const url = `${config.baseUrl}user/profile-details/update-last-seen`;
    return patchRequest({
      url,
      data: { userId, lastSeen },
    });
  } catch (error) {
    return null;
  }
};
export const getLastSeen = async (userId) => {
  try {
    const url = `${config.baseUrl}user/profile-details/get-last-seen`;
    return postRequest({
      url,
      data: { userSeenId:userId },
    });
  } catch (error) {
    return null;
  }
};

export const UploadFile = async (formData ,  onUploadProgress) => {
  try {
    const url = `${config.baseUrl}file/upload`;
    return await postRequest({
      url,
      data: formData,
      onUploadProgress
    });
  } catch (error) {
    return null;
  }
};

export const getUserList = async () => {
  try {
    const url = `${config.baseUrl}user/profile/users`;
    return getRequest({
      url,
      data: {},
    });
  } catch (error) {
    return null;
  }
};
export const getUnreadList = async () => {
  try {
    const url = `${config.baseUrl}user/profile/getUnReadChatList`;
    return getRequest({
      url,
      data: {},
    });
  } catch (error) {
    return null;
  }
};

export const getOrangeUserList = async (paramData) => {
  try {
    const url = `${config.baseUrl}user/profile/orangeUser`;
    return getRequest({
      url,
      data: paramData,
    });
  } catch (error) {
    return null;
  }
};

export const getSearchUserList = async (searchText) => {
  try {
    const url = `${config.baseUrl}user/profile/users/search`;
    return postRequest({
      url,
      data: { searchText },
    });
  } catch (error) {
    return null;
  }
};

export const deleteFile = async (ids) => {
  try {
    const url = `${config.baseUrl}file/delete`;
    return deleteRequest({
      url,
      data: { ids: [ids] },
    });
  } catch (error) {
    return null;
  }
};


export const getDeactivateUserList = async (searchTerm) => {
    try {
      const url = `${config.baseUrl}user/profile/users/deactivatedUsers`;
      return postRequest({
        url,
        data: { searchTerm },
      });
    } catch (error) {
      return null;
    }
};


export const getDeactivateGroupsList = async (searchTerm) => {
  try {
    const url = `${config.baseUrl}user/profile/users/deactivatedGroups`;
    return postRequest({
      url,
      data: { searchTerm },
    });
  } catch (error) {
    return null;
  }
};
