export const LOCATION_ACTIONS = {
    LOCATION_SEARCH: 'LOCATION_SEARCH',
    LOCATION_SEARCH_SUCCESS: 'LOCATION_SEARCH_SUCCESS',
    LOCATION_SEARCH_ERROR: 'LOCATION_SEARCH_ERROR',

    LOCATION_UPSERT: 'LOCATION_UPSERT',
    LOCATION_UPSERT_SUCCESS: 'LOCATION_UPSERT_SUCCESS',
    LOCATION_UPSERT_ERROR: 'LOCATION_UPSERT_ERROR',

    LOCATION_EDIT: 'LOCATION_EDIT',

    LOCATION_RESET: 'LOCATION_RESET',
};

export const locationResetAction = () => ({
    type: LOCATION_ACTIONS.LOCATION_RESET,
});

export const locationSearchAction = (payload) => ({
    type: LOCATION_ACTIONS.LOCATION_SEARCH,
    ...payload,
});

export const locationEditAction = (payload) => ({
    type: LOCATION_ACTIONS.LOCATION_EDIT,
    ...payload,
});

export const locationUpsertAction = (payload) => ({
    type: LOCATION_ACTIONS.LOCATION_UPSERT,
    ...payload,
});

export default {
    LOCATION_ACTIONS,
    locationSearchAction,
    locationUpsertAction,
    locationEditAction,
};
