export const WALLPAPER_LIST = ['#E5E5E5','#FFF5EB','#F0E5F0','#F5F5FF','#E5E5F0','#FFFAE5','F9E5FF','#FFE5F7','#ECFFE5','E5F9FF'];
export const TEXTURE_LIST = ["#E5E5E5","../assets/images/theme-texture/t1.png","../assets/images/theme-texture/t2.png","../assets/images/theme-texture/t3.png","../assets/images/theme-texture/t4.png","../assets/images/theme-texture/t5.png"];
export const PreferencesOptions = [
      {section:"Auto Start",heading:"Launch app on login",value:"LaunchAppOnLogin",description:"on/off the application launch on login"},
      {section:"Auto Start",heading:"Launch app to notification area on login",value:"LaunchAppToNotificationAreaOnLogin",description:"on/off the notifications without sound"},
      {section:"Advanced",heading:"Leave app running in notification area",value:"LeaveAppRunningInNotificationArea",description:"on/off the app running in notification area when the window is closed"}
    ];
export const NotificationsOptions = [
    {section:"Minimized",heading:"Show with sound",value:"MinimizedShowWithSound",description:"on/off the notifications with sound"},
    {section:"Minimized",heading:"Show and No sound",value:"MinimizedShowAndNoSound",description:"on/off the notifications without sound"},
    {section:"Always",heading:"Show with sound",value:"AlwaysShowWithSound",description:"on/off the notifications with sound"},
    {section:"Always",heading:"Show with sound",value:"AlwaysShowAndNoSound",description:"on/off the notifications without sound"},
];

export const TitlesOptions = [
    {section:"all",heading:"CattleCall",value:"CattleCall"},
    {section:"all",heading:"Apps",value:"Apps"},
    {section:"all",heading:"AdminDashboard",value:"AdminDashboard"},
    {section:"all",heading:"Help",value:"Help"},
];


const groupedData = {
    Total: { count: 0, totalSize: 0 },
    Text: { count: 0, totalSize: 0 },
    Images: { count: 0, totalSize: 0 },
    Video: { count: 0, totalSize: 0 },
    Audio: { count: 0, totalSize: 0 },
    Documents: { count: 0, totalSize: 0 },
    Others: { count: 0, totalSize: 0 }
  };
export default {
    WALLPAPER_LIST,
    TEXTURE_LIST,
    groupedData,
    PreferencesOptions,
    NotificationsOptions,
    TitlesOptions
};
