import config from "src/config";
import {
  deleteRequest,
  patchRequest,
  postRequest,
} from "src/utils/RequestUtil";

export const getUserTodoList = async (
  todoType,
  searchText = "",
  startDate,
  endDate
) => {
  try {
    const url = `${config.baseUrl}todo/user/todo/fetch/status`;
    return postRequest({
      url,
      data: { todoType, searchText, startDate, endDate },
    });
  } catch (error) {
    return null;
  }
};

export const addTodo = async (todo) => {
  try {
    const url = `${config.baseUrl}todo/user/todo/add`;
    return postRequest({
      url,
      data: { todo },
    });
  } catch (error) {
    return null;
  }
};

export const deleteTodo = async (todoId) => {
  try {
    const url = `${config.baseUrl}todo/user/todo/delete`;
    return deleteRequest({
      url,
      data: { todoId },
    });
  } catch (error) {
    return null;
  }
};

export const markTodoItemComplete = async (todoId, todoItemId) => {
  try {
    const url = `${config.baseUrl}todo/user/todo/mark/item/completed`;
    return patchRequest({
      url,
      data: { todoId, todoItemId },
    });
  } catch (error) {
    return null;
  }
};

export const updateTodo = async (todo, editableTodoId) => {
  try {
    const url = `${config.baseUrl}todo/user/todo/update`;
    return patchRequest({
      url,
      data: {
        todoId: editableTodoId,
        updatedTodo: todo.todos[0],
      },
    });
  } catch (error) {
    return null;
  }
};

export const convertTodoFormat = (originalTodo) => {
  const convertedItems = originalTodo.items.map((item) => ({
    name: item.name.value,
    description: item.description.value,
    assignedDate: new Date(item.assignedDate.value),
    assignedTime: item.assignedTime.value,
    dueDate: item.dueDate.value,
    dueTime: item.dueTime.value,
  }));

  const convertedTodo = {
    todos: [
      {
        title: originalTodo.title.value,
        items: convertedItems,
        files: [],
      },
    ],
  };

  return convertedTodo;
};
