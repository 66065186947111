
const initialState = {
  currentGroup: {},
  recipient: {},
  userMessages: [],
};

const chatHistoryReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'RECIPIENT':
      let { receiver } = action.payload;
      return {
        ...state,
        recipient: receiver,
      };

    case 'USER_MESSAGES':
      let { userMessages, unReadMessageCounter } = action.payload;
      // console.log("userMessages", userMessages);
      return {
        ...state,
        userMessages: userMessages,
      };

    case 'LOAD_MORE_MESSAGES':
      let newPayload = action.payload.filter(data => {
        let exists = state.userMessages.some(elum => elum.id === data.id);
        return !exists; // Only include items that do not exist in userChat
      });
      return {
        ...state,
        userMessages: [...state.userMessages, ...newPayload].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)),
      };

      case 'NEW_MESSAGES': {
        let { newMessage, roomId } = action.payload;
        // console.log("state.userMessages", state.userMessages);
        // console.log("newMessage***", newMessage);
      
        // Check if userMessages is empty (first message case)
        if (state.userMessages.length === 0) {
          return {
            ...state,
            userMessages: [newMessage],
          };
        }
      
        let checkSameRoomID = state.userMessages.find(elum => elum.onetooneId == roomId || elum.groupId == roomId);
        if (checkSameRoomID) {
          const isDuplicate = state.userMessages.some(item => item?.id === newMessage?.id);
          if (!isDuplicate) {
            let updatedUserMessages = [...state.userMessages]; // Clone userMessages for updates
            
            // Check if the newMessage is for one-to-one chat
            if (newMessage?.onetooneId) {
              // Update all messages where readBy.length < 2 or deliveredTo.length < 2
              updatedUserMessages = state.userMessages.map(message => {
                if (message?.readBy?.length < 2 && newMessage?.readBy?.length == 2) {
                  return {
                    ...message,
                    readBy: [
                      ...message.readBy,
                      {
                        userId: newMessage?.senderDetails?._id,
                        userAgent: null,
                        _id: newMessage?.senderDetails?._id,
                      },
                    ],
                  };
                } else if (message?.deliveredTo?.length < 2 && newMessage?.deliveredTo?.length == 2) {
                  return {
                    ...message,
                    deliveredTo: [
                      ...message.deliveredTo,
                      {
                        userId: newMessage?.senderDetails?._id,
                        userAgent: null,
                        _id: newMessage?.senderDetails?._id,
                      },
                    ],
                  };
                }
                return message; // Return the message unchanged if no updates are made
              });
            }
            return {
              ...state,
              userMessages: [...updatedUserMessages, newMessage].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)),
            };
          }
        }
        return state; // Return the state unchanged if no updates are made
      }
      

    case 'CURRENT_GROUP':
      return {
        ...state,
        currentGroup: action.payload,
      };
    default:
      return state;
  }
};

export default chatHistoryReducer;
