import React, { useEffect, useState } from "react";
import { useNotification } from "src/components/Notifination/ToastNotification";
import useDebounce from "src/hooks/useDebounce";

import {
  createUserGroup,
  deleteUserGroup,
  getUserGroupList,
  pinUserGroup,
  getDetails,
  updateUserGroup,
} from "../../containers/home-container/apis/Groupapis";
import { useSelector } from "react-redux";
import { UploadFile } from "../../containers/profile-container/ProfileServices";

const AddGroupDrawer = ({
  toggleDrawer,
  socket,
  fetchUserGroups,
  editGroup,
  setEditGroup,
  isGroupEditable,
  setIsGroupEditable,
}) => {
  const [searchText, setSearchText] = useState("");
  const user = useSelector((state) => state.auth.user);
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const { openSuccessNotification, openErrorNotification } = useNotification();
  const [editableNoteId, setEditableNoteId] = useState(null);
  const debouncedSearch = useDebounce(searchText, 500);
  const [isEditable, setIsEditable] = useState(false);
  const [selectAllToggled, setSelectAllToggled] = useState(false);
  const [userList, setUserList] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [editGroupDetails, setEditGroupDetails] = useState(null);
  const resetState = () => {
    setSearchText("");
    setGroupData({ participants: [], title: "", content: "", files: [] });
  };
  const [groupData, setGroupData] = useState({
    participants: [],
    title: {
      value: "",
      error: "",
    },
    content: {
      value: "",
      error: "",
    },
    files: [],
  });

  const handleSocketUserListResponse = ({ list }) => {
    setUserList(list);
  };

  useEffect(() => {
    getGroupDetailsFun(editGroup);
  }, [editGroup]);

  const getGroupDetailsFun = async (editGroup) => {
    let { data } = await getDetails(editGroup);
    setEditGroupDetails(data);
    setIsEditable(true);
    setGroupData((prevGroupData) => ({
      ...prevGroupData,
      participants: data?.groups[0]?.participants,
      title: {
        value: data?.groups[0]?.title,
        error: "",
      },
      content: {
        value: data?.groups[0]?.content,
        error: "",
      },
      files: [data?.groups[0]?.groupDP?._id],
    }));
  };

  const SearchUser = (keywords = "") => {
    if (socket) {
      socket.emit("searchUser", {
        userId: user?._id,
        excludeUsers: [user?._id],
        searchString: keywords,
        searchFromList: "userList",
      });
      socket.on("searchUserResponse", handleSocketUserListResponse);
    }
  };

  const createGroupDrawer_close = () => {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("createGroup").style.display = "none";
    document.getElementById("addmembergroup").style.display = "none";
    toggleDrawer();
    setEditGroup(null);
    setIsGroupEditable(false);
  };

  const handleFileUpload = async (event) => {
    try {
      const files = event.target.files;
      if (!files || files.length === 0) {
        return;
      }
      const newFileNames = [];
      const newFileIdes = [];
      const newFileTypes = [];

      for (let i = 0; i < Math.min(files.length, 10); i++) {
        const file = files[i];
        newFileNames.push(file.name);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("remark", "Group");
        formData.append("title", file.name);
        formData.append("refId", user?._id);
        formData.append("source", "user");
        formData.append("directory", "group");
        formData.append("by", user?._id);
        const { data } = await UploadFile(formData);
        if (data) {
          newFileIdes.push(data.data?._id);
          newFileTypes.push(data.data?.extension);
          setFileUrl(data.data?.url);
        }
      }
      setGroupData((prevState) => ({
        ...prevState,
        files: [...newFileIdes],
      }));
    } catch (error) {
      openErrorNotification("Failed to Attach Document");
    }
  };

  const handleInputChange = (event) => {
    setGroupData((prevData) => ({
      ...prevData,
      [event.target.name]: {
        ...prevData[event.target.name],
        value: event.target.value,
        error: "",
      },
    }));
  };

  const validateGroupData = () => {
    let hasError = false;
    setGroupData((prevData) => ({
      ...prevData,
      title: {
        ...prevData.title,
        error: "",
      },
      content: {
        ...prevData.content,
        error: "",
      },
    }));
    if (!groupData.title.value) {
      hasError = true;
      setGroupData((prevData) => ({
        ...prevData,
        title: {
          ...prevData.title,
          error: "Title is required",
        },
      }));
    }

    return !hasError;
  };
  const removeEmptyPTags = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const emptyPTags = doc.querySelectorAll("p:empty");
    emptyPTags.forEach((emptyPTag) => {
      emptyPTag.parentNode.removeChild(emptyPTag);
    });
    const emptyLITags = doc.querySelectorAll("li:empty");
    emptyLITags.forEach((emptyLITag) => {
      emptyLITag.parentNode.removeChild(emptyLITag);
    });
    const updatedHtmlString = doc.body.innerHTML;
    return updatedHtmlString;
  };
  const isNotEmpty = (obj) => {
    return Object.keys(obj).length !== 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setEditGroup(null);
    setIsGroupEditable(false);
    setGroupData({
      participants: [],
      title: {
        value: "",
        error: "",
      },
      content: {
        value: "",
        error: "",
      },
      files: [],
    });
    try {
      if (validateGroupData()) {
        let formattedGroupData = {
          participants: groupData.participants,
          title: groupData.title.value,
          content: groupData.content.value,
          files: groupData.files || [],
        };

        let response;

        if (editGroup && isEditable) {
          formattedGroupData.groupId = editGroup;
          response = await updateUserGroup(formattedGroupData, authToken);
        } else {
          response = await createUserGroup(formattedGroupData, authToken);
        }
        if(response?.data?.inputDataGroup &&isNotEmpty(response?.data?.inputDataGroup))
        {
          const updatedHtmlString = removeEmptyPTags(response?.data.inputDataGroup?.messageHtml);
          const input = {
            chatroom_id: response?.data.inputDataGroup.chatroom_id,
            senderId: response?.data.inputDataGroup.senderId,
            receiverId: response?.data.inputDataGroup.receiverId,
            message: response?.data.inputDataGroup?.message,
            messageType: "",
            files: [],
            fileTypes: [],
            messageHtml: updatedHtmlString,
            userAgent: JSON.parse(localStorage.getItem("ipData")),
            organization: response?.data.inputDataGroup?.organization,
            replyToMessage: null,
            replyToMedia: null,
            organizationId: response?.data.inputDataGroup?.organization,
            group: true,
            taggedUsers: [],
            isNotification: true
          };
            socket.emit("message", input)
        }
        if(response?.data?.inputDataGroup &&isNotEmpty(response?.data?.fileData))
        {
          const updatedHtmlString = removeEmptyPTags(response?.data.fileData?.messageHtml);
          const input = {
            chatroom_id: response?.data.fileData.chatroom_id,
            senderId: response?.data.fileData.senderId,
            receiverId: response?.data.fileData.receiverId,
            message: response?.data.fileData?.message,
            messageType: "",
            files: [],
            fileTypes: [],
            messageHtml: updatedHtmlString,
            userAgent: JSON.parse(localStorage.getItem("ipData")),
            organization: response?.data.fileData?.organization,
            replyToMessage: null,
            replyToMedia: null,
            organizationId: response?.data.fileData?.organization,
            group: true,
            taggedUsers: [],
            isNotification: true
          };
            socket.emit("message", input)
        }
        if(isNotEmpty(response?.data?.inputData))
        {
          const updatedHtmlString = removeEmptyPTags(response?.data.inputData?.messageHtml);
          const input = {
            chatroom_id: response?.data.inputData.chatroom_id,
            senderId: response?.data.inputData.senderId,
            receiverId: response?.data.inputData.receiverId,
            message: response?.data.inputData?.message,
            messageType: "",
            files: [],
            fileTypes: [],
            messageHtml: updatedHtmlString,
            userAgent: JSON.parse(localStorage.getItem("ipData")),
            organization: response?.data.inputData?.organization,
            replyToMessage: null,
            replyToMedia: null,
            organizationId: response?.data.inputData?.organization,
            group: true,
            taggedUsers: [],
            isNotification: true
          };
            socket.emit("message", input)
        }
     

        if (response) {
          const message =
           editGroup && isEditable
              ? "Group updated successfully"
              : "Group created successfully";
          openSuccessNotification(message);
          resetState();
          setEditableNoteId(null);
          setIsEditable(false);
          fetchUserGroups();
          createGroupDrawer_close();
        } else {
          openErrorNotification("Error during submit group details");
        }
      }
    } catch (error) {
      openErrorNotification("Error during submit group details");
    }
  };

  const addmembergroup_open = (e) => {
    if(editGroup){
      handleSubmit(e)
    }
    else{
    document.getElementById("main").style.marginRight = "0";
    document.getElementById("addmembergroup").style.width = "100%";
    document.getElementById("addmembergroup").style.display = "block";
    document.getElementById("createGroup").style.display = "none";
    SearchUser();
    }
  };

  function addmemberback_close() {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("addmembergroup").style.display = "none";
    document.getElementById("createGroup").style.display = "block";
  }

  function toggleButton(button) {
    if (button.innerHTML === "Add") {
      button.innerHTML = "Remove";
      button.classList.remove("addremvbtn");
      button.classList.add("removebtn");
    } else {
      button.innerHTML = "Add";
      button.classList.remove("removebtn");
      button.classList.add("addremvbtn");
    }
  }

  const selectUserFun = (id) => {
    if (groupData?.participants) {
      setGroupData((prevState) => {
        if (prevState.participants.includes(id)) {
          return {
            ...prevState,
            participants: prevState.participants.filter(
              (msgId) => msgId !== id
            ),
          };
        } else {
          return {
            ...prevState,
            participants: [...prevState.participants, id],
          };
        }
      });
    } else {
      setGroupData((prevGroupData) => ({
        ...prevGroupData,
        participants: [id],
      }));
    }
  };
const handleSelectedAllToogle=()=>{
  userList.forEach((user)=>{
    if(!selectAllToggled && ((!groupData.participants)||(!groupData.participants?.includes(user.id)))) 
      document.getElementById(`btn_${user?._id}`).click();  
    if(selectAllToggled) 
      document.getElementById(`btn_${user?._id}`).click();
  })
  setSelectAllToggled(!selectAllToggled)
}

const renderProfilePicture = (item) => {
  const profilePicture = item?.otherInformation?.profilePicture;
  const firstNameInitial = item?.firstName?.charAt(0);
  const lastNameInitial = item?.lastName?.charAt(0);

  if (profilePicture) {
    return (
      <img
        className="img-fluid userimg"
        src={profilePicture}
        width="40"
        height="40"
        loading="eager"
        alt="user img"
        style={{
          border: `solid ${item?.isOrange ? "#ff7a00" : "#00a8ff"}`,
          borderWidth: "2px",
        }}
      />
    );
  } else {
    return (
      <div
        className="placeholder-img"
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: '#e0e0e0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `solid ${item?.isOrange ? "#ff7a00" : "#00a8ff"}`,
          borderWidth: '2px',
          color: '#555',
          fontSize: '16px',
          fontWeight: 'bold',
        }}
      >
        {`${firstNameInitial || ''}${lastNameInitial || ''}`}
      </div>
    );
  }
};
  return (
    <React.Fragment>
      <>
        <div
          className="chat-sidebar chat-animate-left"
          style={{ display: "block" }}
          id="createGroup"
        >
          <div className="modal-dialog-scrollable">
            <div className="modal-content">
              <div className="slide-header border-0">
                <div className="HeadSlidName d-flex justify-content-between align-items-center">
                  <div className="slidName font-24 color1a">
                    {}

                    {`${editGroup ? "Edit Group" : "  Create New Group"}  `}
                  </div>
                  <button
                    className="color1a"
                    onClick={() => createGroupDrawer_close()}
                  >
                    ×
                  </button>
                </div>
                <div className="profileupload text-center mt-4">
                  <div className="columnsProfile">
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        {}
                        <input
                          type="file"
                          name="groupImage"
                          id="imageUploadGroup"
                          className="upload-box"
                          placeholder="Upload File"
                          aria-label="Upload File"
                          multiple
                          onChange={handleFileUpload}
                          accept=".png, .jpg, .jpeg"
                        />
                        <label htmlFor="imageUploadGroup" className="bgcmras" />
                      </div>
                      <div className="avatar-preview">
                        <div
                          id="imagePreviewGroup"
                          style={{
                            backgroundImage: `url(${
                              fileUrl
                                ? fileUrl
                                : editGroupDetails
                                ? editGroupDetails?.groups[0]?.groupDP?.url
                                : "./assets/images/user.svg"
                            }`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body settinghndl border-0">
                <div className="todocreat">
                  <input
                    className="form-control mb-2 border w-100"
                    type="text"
                    placeholder="Group Name"
                    onChange={handleInputChange}
                    value={groupData.title.value}
                    name="title"
                    required
                  />
                  {groupData.title.error && (
                    <p className="error-message">{groupData.title.error}</p>
                  )}
                  <textarea
                    className="form-control mb-2 border w-100"
                    placeholder="Add task descriptions"
                    onChange={handleInputChange}
                    value={groupData.content.value}
                    name="content"
                  />
                  {groupData.content.error && (
                    <p className="error-message">{groupData.content.error}</p>
                  )}

                  <div className="text-center mt-3 mb-3">
                    <button
                      type="button"
                      className="btnblue wdef"
                      onClick={(e) => {
                        
                        addmembergroup_open(e);
                      }}
                    >
                      <i className="mdi mdi-chevron-right mdi-48px" />
                    </button>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="chat-sidebar chat-animate-left"
          style={{ display: "none" }}
          id="addmembergroup"
        >
          <div className="modal-dialog-scrollable">
            <div className="modal-content">
              <div className="slide-header border-0">
                <div className="HeadSlidName d-flex justify-content-between align-items-center">
                  <div className="slidName font-24 color1a">
                    {}
                    {`${editGroup ? "Edit Group" : "  Create New Group"}  `}
                  </div>
                  <button
                    className="color1a"
                    onClick={() => createGroupDrawer_close()}
                  >
                    ×
                  </button>
                </div>
                <div className="d-flex justify-content-between align-items-center createnotes font-14 color1a btnclr">
                  Add Members{" "}
                  <button
                    className="rounded-4"
                    onClick={() => {
                      addmemberback_close();
                    }}
                  >
                    <i className="mdi mdi-chevron-left" />
                  </button>
                </div>
                <div className="msg-search mx-0">
                  <input
                    type="text"
                    className="form-control"
                    id="searchUserByName"
                    placeholder="Search"
                    aria-label="search"
                    name="searchUserByName"
                    onChange={(e)=>{SearchUser(e.target.value)}}
                  />
                  <button
                    onClick={() => {
                      console.log("search user");
                    }}
                  >
                    <i className="mdi mdi-magnify" />
                  </button>
                </div>
                <div className="allSelectadd">
                  <div className="allnumuser"><span>{groupData.participants?.length||0}</span>/{userList.length}</div>
                  <div className="allselectBtn"><label className="cstCheck fradio">
                    <input 
                     type="checkbox"  name="Select All" onClick={handleSelectedAllToogle} />Select All 
                    <span className="checkmark" ></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-body settinghndl border-0">
                <div className="chat-list grpselectadd">
                  {userList &&
                    userList.map((userData, index) => {
                      return (
                        <div
                          className="d-flex align-items-start py-2"
                          key={index}
                        >
                          <div className="flex-shrink-0 position-relative">
                            {/* <img
                              className="img-fluid userimg"
                              src={
                                userData?.otherInformation?.profilePicture ||
                                "./assets/images/user.svg"
                              }
                              width={45}
                              height={45}
                              alt="user img"
                            /> */}
                          {  renderProfilePicture (userData)}
                          </div>
                          <div className="d-flex justify-content-between flex-grow-1 ms-3">
                            <div className="">
                              <h3>
                                {userData?.firstName} {userData?.lastName}{" "}
                              </h3>
                              <div className="font-14 color66">
                                {userData?.otherInformation?.department} -{" "}
                                {userData?.otherInformation?.designation}
                              </div>
                            </div>
                            <div className="onlineOfine d-flex justify-content-between align-items-center">
                              {groupData.participants &&
                              groupData.participants.includes(userData?._id) ? (
                                <button
                                  id={`btn_${userData?._id}`}
                                  type="button"
                                  className="btnblue removebtn  font-12"
                                  onClick={(e) => {
                                    toggleButton(e.target);
                                    selectUserFun(userData?._id);
                                  }}
                                >
                                  Remove
                                </button>
                              ) : (
                                <button
                                id={`btn_${userData?._id}`}
                                  type="button"
                                  className="btnblue addremvbtn  font-12"
                                  onClick={(e) => {
                                    toggleButton(e.target);
                                    selectUserFun(userData?._id);
                                  }}
                                >
                                  Add
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="modal-footer justify-content-center border">
                <div className="text-center mt-3 mb-3">
                  <button
                    type="button"
                    className="btnblue"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    {}
                    {`${editGroup ? "Save" : "  Create New Group"}  `}
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </React.Fragment>
  );
};

export default AddGroupDrawer;
