import React from "react";
import { getLastSeenString } from "../profile-container/ProfileDetailsutils";

const Profilepopup = ({ userDetails }) => {
  const profile_close = () => {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myChatProfile").style.display = "none";
  };

  return (
    <div
      className="chat-sidebar chat-animate-right"
      style={{ display: "none", right: "109px" }}
      id="myChatProfile"
    >
      <div className="modal-dialog-scrollable">
        <div className="modal-content">
          <div className="slide-header">
            <div className="HeadSlidName d-flex justify-content-between align-items-center">
              <div className="slidName">Profile </div>
              <button
                className=""
                onClick={() => {
                  profile_close();
                }}
              >
                ×
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="text-center profText">
              <div
                className="profImg"
                style={{
                  backgroundImage: `url(${
                    userDetails?.profilePicture || "./assets/images/user.svg"
                  })`,
                }}
              ></div>
              <div className="LastSeen font-12 color80 pt-2 pb-1">
                {getLastSeenString(userDetails.lastSeen) === "Online"
                  ? "Online"
                  : "Last seen: " + getLastSeenString(userDetails.lastSeen)}
              </div>
              <div className="userName font-18 color1a">
                {userDetails.firstName + " " + userDetails.lastName}
              </div>
              <div className="userdec font-14 colorb">
                {userDetails?.designation}
              </div>
              <div className="userprd font-12 color80 pt-1">
                {userDetails?.department}
              </div>
            </div>
            <div className="userdetails">
              <div className="user-list pt-0 border-0">
                <div className="font-12 color66 mb-3">
                 {userDetails?.description}
                </div>
                <div className="user-d pb-2 d-flex align-items-center">
                        <lable className="font-14 color80">
                           <img
                              src="/assets/images/profile/reporting-icon.svg"
                              className="img-fluid"
                              alt=""
                              width={17}
                              height={17}
                              />
                        </lable>
                        <a href="#" className="font-14 color1a d-block fw-500">
                        <span className="colorp d-block">{`${userDetails?.employeeID}`}</span>
                        </a> 
                  </div> 
                <div className="user-d pb-2 d-flex align-items-center">
                  <lable className="font-14 color80" htmlFor="emailIco">
                    <img
                      src="assets/images/profile/email-icon.svg"
                      className="img-fluid"
                      alt=""
                      width={15}
                      height={11}
                    />
                  </lable>
                  <a href="#" className="font-14 color1a d-block fw-500">
                    {userDetails.email}
                  </a>
                </div>
                <div className="user-d pb-2 d-flex align-items-center">
                  <lable className="font-14 color80" htmlFor="mobileIco">
                    <img
                      src="assets/images/profile/mobile-icon.svg"
                      className="img-fluid"
                      alt=""
                      width={10}
                      height={16}
                    />
                  </lable>
                  <a href="#" className="font-14 color1a d-block fw-500">
                    {userDetails?.phone?.toString().replace(/\d(?=\d{4})/g, 'X')}
                  </a>
                </div>
                <div className="user-d d-flex align-items-center">
                  <lable className="font-14 color80" htmlFor="CompanyIco">
                    <img
                      src="assets/images/profile/company-icon.svg"
                      className="img-fluid"
                      alt=""
                      width={17}
                      height={15}
                    />
                  </lable>
                  <span href="#" className="font-14 color1a d-block fw-500">
                    {userDetails.organization}
                  </span>
                </div>
                <div className="user-d d-flex align-items-center">
                  <lable className="font-14 color1a" htmlFor="LocationIco">
                    <img
                      src="assets/images/profile/location-icon.svg"
                      className="img-fluid"
                      alt=""
                      width={11}
                      height={16}
                    />
                  </lable>
                  <span className="font-14 color1a d-block fw-500">
                    {userDetails.organizationLocation}
                  </span>
                </div>
              </div>
              <div className="user-group border-0">
                <h3 className="font-14 color80">GROUP IN COMMON</h3>

                {userDetails?.commonGroups?.map((group) => (
                  <React.Fragment key={group._id}>
                    <div className="user-g font-14 color1a fw-bold py-1 d-flex align-items-center gap-2">
                     <span className="userimgbg"> <img
                        className="img-fluid userimg"
                        src={
                          group?.groupIcon?.url ??
                          "../../assets/images/no_group_image.jpg"
                        }
                        width="45"
                        height="45"
                        alt="user img"
                        loading="lazy"
                      /></span>

                     <span className="ttgrp">{group.title}</span>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profilepopup;
