import { Link } from 'react-router-dom';
const NavbarContainer=()=>{
    return (
        <>
           <ul className="nav nav-pills flex-column mb-auto m-4 admin-ex-list">
                           <li className="nav-item">
                           <Link to="/admin" className="nav-link text-white">
                              <span className="icon"><img src="/assets/images/admin/dashboard.svg" className="img-fluid" width="16" height="16" alt=""/></span>  Dashboard 
                            </Link>
                           </li>
                           <li>
                           <Link to="/admin/users" className="nav-link text-white">                             
                              <span className="icon"><img src="/assets/images/admin/users.svg" className="img-fluid" width="16" height="11" alt=""/></span>Users
                            </Link>
                           </li>
                           <li>
                           <Link to="/admin/groups" className="nav-link text-white">
                              <span className="icon"><img src="/assets/images/admin/group.svg" className="img-fluid" width="17" height="13" alt=""/></span>
                              Group 
                            </Link>
                           </li>
                           <li>
                            <Link to="/admin/departments" className="nav-link text-white">
                              <span className="icon"><img src="/assets/images/admin/department.svg" className="img-fluid" width="16" height="17" alt=""/></span>
                              Department 
                              </Link>
                           </li>
                           <li>
                           <Link to="/admin/designations" className="nav-link text-white">
                              <span className="icon"><img src="/assets/images/admin/designation.svg" className="img-fluid" width="7" height="17" alt=""/></span>
                              Designation
                            </Link>
                           </li>
                           <li>
                           <Link to="/admin/roles" className="nav-link text-white">
                              <span className="icon"><img src="/assets/images/admin/role.svg" className="img-fluid" width="14" height="15" alt=""/></span>
                              Role 
                            </Link>
                           </li>
                           <li>
                           <Link to="/admin/locations" className="nav-link text-white">
                              <span className="icon"><img src="/assets/images/admin/location.svg" className="img-fluid" width="14" height="17" alt=""/></span>
                              Location
                            </Link>
                           </li>
                           <li>
                           <Link to="/admin/permissions" className="nav-link text-white">
                              <span className="icon"><img src="/assets/images/admin/controls.svg" className="img-fluid" width="15" height="15" alt=""/></span>
                              Controls
                              </Link>
                           </li>
                           {/* <li>
                           <Link to="/admin/notifications" className="nav-link text-white">
                              <span className="icon"><img src="/assets/images/admin/my-subscription.svg" className="img-fluid" width="17" height="12" alt=""/></span>
                              Notifications
                            </Link>
                           </li>
                           <li>
                           <Link to="/admin/group" className="nav-link text-white">
                              <span className="icon"><img src="/assets/images/admin/my-subscription.svg" className="img-fluid" width="17" height="12" alt=""/></span>
                              My Subscription
                            </Link>
                           </li>
                           <li>
                           <Link to="/admin/group" className="nav-link text-white">
                              <span className="icon"><img src="/assets/images/admin/dashboard.svg" className="img-fluid" width="16" height="16" alt=""/></span>
                              Support 
                            </Link>
                           </li> */}
                        </ul>
        </>
    )
}
export default NavbarContainer