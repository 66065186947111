import config from "src/config";
import { debounceFun } from "src/utils/CommonUtils";
import {
  deleteRequest,
  patchRequest,
  postRequest,
} from "src/utils/RequestUtil";


function debounceAsync(func, delay) {
  let timeoutId;
  return (...args) => {
    return new Promise((resolve, reject) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(async () => {
        try {
          const result = await func(...args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };
}


const fetchUserGroupList = async (searchText = "") => {
  try {
    const url = `${config.baseUrl}group/all`;
    return postRequest({
      url,
      data: { searchText },
    });
  } catch (error) {
    return null;
  }
};

export const getUserGroupList = debounceAsync(fetchUserGroupList, 3000);

export const getDetails = async (groupId) => {
  try {
    const url = `${config.baseUrl}group/getDetails`;
    return patchRequest({
      url,
      data: { groupId },
    });
  } catch (error) {
    return null;
  }
};

export const updateUserGroup = async (groupData) => {
  try {
    const url = `${config.baseUrl}group/update`;
    return patchRequest({
      url,
      data: { ...groupData },
    });
  } catch (error) {
    return null;
  }
};

export const updateFavUser = async (chatHistoryId) => {
  try {
    const url = `${config.baseUrl}group/updateFavUser`;
    return postRequest({
      url,
      data: { chatHistoryId },
    });
  } catch (error) {
    return null;
  }
};

export const muteChat = async (roomId) => {
  try {
    const url = `${config.baseUrl}group/muteChat`;
    return postRequest({
      url,
      data: { roomId },
    });
  } catch (error) {
    return null;
  }
};

export const pinUserGroup = async (groupId) => {
  try {
    const url = `${config.baseUrl}group/pin`;
    return patchRequest({
      url,
      data: { groupId },
    });
  } catch (error) {
    return null;
  }
};

export const deleteUserGroup = async (groupId) => {
  try {
    const url = `${config.baseUrl}group/delete`;
    return deleteRequest({
      url,
      data: { groupId },
    });
  } catch (error) {
    return null;
  }
};

export const createUserGroup = async (groupData) => {
  try {
    const url = `${config.baseUrl}group/add`;
    return postRequest({
      url,
      data: { ...groupData },
    });
  } catch (error) {
    return null;
  }
};

export const deleteUserGroupChat = async (groupId, messageType) => {
  try {
    const url = `${config.baseUrl}group/message/delete`;
    return patchRequest({
      url,
      data: { groupId, messageType },
    });
  } catch (error) {
    return null;
  }
};

export const removeFromGroup = async (groupData) => {
  try {
    const url = `${config.baseUrl}group/removeFromGroup`;
    return postRequest({
      url,
      data:{ groupData },
    });
  } catch (error) {
    return null;
  }
};

export const deleteGroupForMe = async (groupData) => {
  try {
    const url = `${config.baseUrl}group/deleteGroupForMe`;
    return postRequest({
      url,
      data:{ groupData },
    });
  } catch (error) {
    return null;
  }
};

export const getGroupActivity = async (groupData) => {
  try {
    const url = `${config.baseUrl}group/getGroupActivity`;
    return postRequest({
      url,
      data:{ groupData },
    });
  } catch (error) {
    return null;
  }
};

export const joinGroup = (socket, userId, groupId, organization) => {
  if (socket) {
    socket.emit("joinGroup", {
      userId: userId,
      groupId: groupId,
      organization: organization,
      type: "group",
    });
  }
};
