import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const LocationPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    countryName: PropTypes.string,
    location: PropTypes.string,
});

export const LocationDefaultProps = { 
    location: '',
    countryName: '',
    _id: undefined
};

export const LocationYupSchema = Yup.object().shape({ 
    location: Yup.string().required('Required'),
});

export default {
    LocationDefaultProps,
    LocationPropTypes,
    LocationYupSchema,
};
