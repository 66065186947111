import React from "react";
import { Helmet } from "react-helmet";
const AdminHead=()=>{
    return(
    <>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="stylesheet" href="/assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/assets/css/bootstrap.min.css"/>
        <link rel="stylesheet" href="/assets/css/mdi/css/materialdesignicons.min.css"/>
        {}
        <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap" rel="stylesheet"/>
        <link href="/assets/css/sidebars.css" rel="stylesheet"/>
        <link href="/assets/css/admin.css" rel="stylesheet"/>
        <link href="https://cdn.datatables.net/1.11.5/css/dataTables.bootstrap5.min.css" rel="stylesheet"/>
    </Helmet>
  </>
);
}
export default AdminHead