import React, { useState } from "react";
import { useNotification } from "src/components/Notifination/ToastNotification";
import DownloadSnackbar from "src/containers/include-container/ChatBox/DownloadSnackbar";

const ControlsList = ({
  media,
  handleDelete,
  handleForward,
  handlePin,
  copyMessageToClipboard,
}) => {
  const [downloads, setDownloads] = useState([]);
  const { openSuccessNotification, openErrorNotification } = useNotification();

  const truncateFileName = (fileName, maxLength = 15) => {
    if (fileName.length <= maxLength) return fileName;
    const extension = fileName.slice(fileName.lastIndexOf("."));
    const baseName = fileName.slice(0, maxLength - extension.length);
    return `${baseName}...${extension}`;
  };
  const isTimestamp = (name) => {
    const timestampPattern =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|([+-]\d{2}:\d{2}))$/;
    return timestampPattern.test(name);
  };

  const updateDownloadProgress = (id, progress, success = false) => {
    setDownloads((prevDownloads) =>
      prevDownloads.map((download) =>
        download.id === id
          ? { ...download, progress, success, open: progress < 100 }
          : download
      )
    );
  };
  const handleDownload = async (url, fileName, mediaItem) => {
    const modifiedFileName = isTimestamp(fileName)
      ? `${fileName}.png`
      : fileName;
    const id = new Date().getTime();

    try {
      if (isTimestamp(fileName)) {
        try {
          const response = await fetch(url);
          if (!response.ok)
            throw new Error(`Failed to download file: ${response.statusText}`);
          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = objectUrl;
          anchor.download = modifiedFileName;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          URL.revokeObjectURL(objectUrl);
          openSuccessNotification("Download complete.");
        } catch (error) {
          openErrorNotification(
            "Slow internet connection . Please reload application."
          );
        }
      } else {
        setDownloads((prev) => [
          ...prev,
          {
            id,
            progress: 0,
            success: false,
            fileName: truncateFileName(modifiedFileName),
            open: true,
          },
        ]);

        let start = 0;
        let end = 1024 * 1024 - 1;
        let downloadedBytes = 0;

        const getFileSize = async () => {
          const response = await fetch(url);
          return parseInt(response.headers.get("Content-Length"), 10);
        };
        const totalSize = await getFileSize();
        const chunks = [];
        while (downloadedBytes < totalSize) {
          const range = `bytes=${start}-${end}`;
          const response = await fetch(url, { headers: { Range: range } });
          if (!response.ok)
            throw new Error(
              `Failed to fetch range ${range}: ${response.statusText}`
            );
          const blob = await response.blob();
          chunks.push(blob);
          downloadedBytes += blob.size;
          start = end + 1;
          end = start + 1024 * 1024 - 1;
          if (end >= totalSize) end = totalSize - 1;

          const percentCompleted = Math.round(
            (downloadedBytes * 100) / totalSize
          );
          updateDownloadProgress(
            id,
            percentCompleted,
            downloadedBytes === totalSize
          );
        }

        const finalBlob = new Blob(chunks);
        const objectUrl = URL.createObjectURL(finalBlob);
        const anchor = document.createElement("a");
        anchor.href = objectUrl;
        anchor.download = modifiedFileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(objectUrl);
      }
    } catch (error) {
      updateDownloadProgress(id, 0, false);
    }
  };

  const handleCloseSnackbar = (id) => {
    setDownloads((prevDownloads) =>
      prevDownloads.map((download) =>
        download.id === id ? { ...download, open: false } : download
      )
    );
  };

  return (
    <div>
      <div>
        {downloads.map((download) => (
          <DownloadSnackbar
            key={download.id}
            download={download}
            onClose={handleCloseSnackbar}
          />
        ))}
      </div>
      <ul
        className="dropdown-menu mx-0 shadow w-220px p-0"
        data-bs-theme="light"
      >
        <li>
          <a
            className="dropdown-item d-flex gap-2 align-items-center"
            href="#"
            onClick={() => copyMessageToClipboard(media)}
          >
            <i className="mdi mdi-content-copy" />
            Copy
          </a>
        </li>
        <li>
          <a
            className="dropdown-item d-flex gap-2 align-items-center"
            href="#"
            onClick={(e) => handleForward(e, media)}
          >
            <i className="mdi mdi-share" />
            Forward
          </a>
        </li>
        <li>
          <a
            className="dropdown-item d-flex gap-2 align-items-center"
            href="#"
            onClick={() => handleDelete(media)}
          >
            <i className="mdi mdi-delete-outline" />
            Delete
          </a>
        </li>
        <li>
          <a
            className="dropdown-item d-flex gap-2 align-items-center"
            href="#"
            onClick={() => {
              handleDownload(media?.url, media?.title, media);
            }}
          >
            <i className="mdi mdi mdi-download" />
            Download
          </a>
        </li>
        <li>
          <a
            className="dropdown-item dropdown-item-danger d-flex gap-2 align-items-center"
            href="#"
            onClick={() => handlePin(media)}
          >
            <i className="mdi mdi-pin-outline" />
            {media?.isPinned ? "Unpin" : "Pin"}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ControlsList;
