import { Formik, Form } from 'formik';
import { useCallback, useState } from 'react';
import { set } from 'lodash';
import DesignationDropDown from 'src/business-components/DesignationDropDown';
import DepartmentDropDown from 'src/business-components/DepartmentDropDown';
import LocationDropDown from 'src/business-components/LocationDropDown';
import FormikInput from 'src/components/formik/FormikInput';
import FormikRadioBox from 'src/components/formik/FormikRadioBox';

const Render=(props)=>{
   const {
      values, 
      onClear,
      resetForm,
      setFieldValue,
  } = props;
    const handleOptionChange=(value)=>{
        setFieldValue('status', value);
    }
 return (
   <>
    <Form autoComplete="disabled">
   <div className="text-end">
               {}
               <button
                onClick={(e) => {
                    onClear(e);
                    resetForm();
                }}
                type="button"
                className="text-decoration-underline bg-none border-0 colorb font-14 fw-500" 
            >
                Clear
            </button>
            </div>
    <div className="todocreat">   
               <label className="font-14 color1a fw-bold">Designation</label>
               {}
               <DesignationDropDown
                    placeholder="Filter By Designation"
                    name="otherInformation.designationId"
                    className="form-select mb-3"
                    value={values.otherInformation.designationId}
                    onChange={(id)=>{
                      values.otherInformation.designationId=id
                }}
                />
               <label className="font-14 color1a fw-bold">Department</label>
                
               <DepartmentDropDown
                    placeholder="Filter By department"
                    name="otherInformation.departmentId"
                    className="form-select mb-3"
                    value={values.otherInformation.departmentId}
                    onChange={(id)=>{
                        values.otherInformation.departmentId=id
                }}
                />
               <label className="font-14 color1a fw-bold">Location</label>
               <LocationDropDown
                    placeholder="Filter By Location"
                    name="otherInformation.locationId"
                    className="form-select mb-2"
                    value={values.otherInformation.locationId}
                    onChange={(id)=>{
                        values.otherInformation.locationId=id
                }}
                />
               {}
                <div className="yesnolable">
                 <label className="font-14 color1a fw-bold mb-2 mt-3">Status</label>
                    <div className="d-flex align-items-center">
                      <label className="cstCheck fradio"> 
                        <FormikRadioBox name="status" value={true} label="Active"/>
                         <span className="checkmark"></span>
                      </label>
                     <label className="cstCheck fradio">
                     <FormikRadioBox name="status"  value={false} label="Deactivated"/> 
                        <span className="checkmark"></span>
                       </label>
                     </div>
                 </div> 


                  <div className="modal-footer justify-content-center border-0 stickybottom">
                     <div className="text-center w-100">
                        <button type="submit" className="btnblue">
                            Apply
                        </button>{" "}
                     </div>
                  </div>
            </div>
      </Form>
   </>
 )
}
 


const FilterPopup=({
   isUserFilter,
   setUserFilter,
   where,
   setWhere
})=>{
   const UserFilterDefaultProps ={status:true,otherInformation:{departmentId:null,designationId:null,locationId:null}}
const [popupFilter,setPopupFilter] = useState(UserFilterDefaultProps);
const onSubmit = useCallback((values) => {
   const whereCond = {};
   if (values.otherInformation.designationId) {
      set(whereCond, 'otherInformation.designationId', values.otherInformation.designationId);
   }
   if (values.otherInformation.departmentId) {
      set(whereCond, 'otherInformation.departmentId', values.otherInformation.departmentId);
   }
   if (values.otherInformation.locationId) {
      set(whereCond, 'otherInformation.locationId', values.otherInformation.locationId);
   }
   set(whereCond, 'status', values.status ? true : false);


   console.log('wherewherewherewhere=>',where);
   setWhere({...where,...whereCond});

}, []);

const onClear = useCallback(() => {

setPopupFilter({status:true,otherInformation:{departmentId:null,designationId:null,locationId:null}});
}, []);

   return (
    <>
<div
className="chat-sidebar chat-animate-right"
style={{ display: `${(isUserFilter)?'block':'none'}`, right: 0, top: 0, marginTop: 43, zIndex: 10,width:'20%' }}
id="Filtertable"
>
<div className="modal-dialog-scrollable">
   <div className="modal-content">
      <div className="slide-header border-bottom mb-2">
         <div className="HeadSlidName d-flex justify-content-between align-items-center">
            <div className="slidName">Filter</div>
            <button className="" onClick={()=>{setUserFilter(!isUserFilter)}}>
            ×
            </button>
         </div>
      </div>
      <div className="modal-body pt-0">
         <div className="adduserbdyF">
            
            <Formik
               onSubmit={onSubmit}
               onClear={onClear}
               enableReinitialize
               initialValues={popupFilter} 
                >
               {(formikProps) => (
                     <Render
                        onClear={onClear} 
                        {...formikProps}
                     />
               )}
            </Formik>
         </div>
      </div>
   </div>
</div>
</div>
    </>
   )
}
export default FilterPopup