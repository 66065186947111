import NavbarContainer from "./NavbarContainer";

const LeftSideBarContainer=()=>{
   return (
    <>
        <div className="chatlist bg-black w235">
               <div className="modal-dialog-scrollable">
                  <div className="modal-content">
                     <div className="chat-header">
                        <div className="userstatus">
                           <div className="text-center">
                              <div className="mb-3"><img src="assets/images/admin/admin-icon.svg" className="img-fluid"/></div>
                              <div className="font-18 userName m-0 text-white">Hi, FAIZAL! <span className="admin green-badge font-10 fw-normal">Admin</span></div>
                              <div className="font-14 color99 fw-500">Acadecraft Pvt. Ltd.</div>
                           </div>
                        </div>
                     </div>
                     <div className="modal-body">
                        <NavbarContainer/>
                     </div>
                  </div>
               </div>
            </div>
    </>
   )
}

export default LeftSideBarContainer;
