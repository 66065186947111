import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const groupSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/group/search`;
    return postRequest({
        url,
        data,
    });
};
export const getGroupsAPI = function (data) {
    const url = `${config.baseUrl}admin/group/getGroups`;
    return postRequest({
        url,
        data,
    });
};

export const groupCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/group/save`;
    return postRequest({
        url,
        data,
    });
};
export const getGroupConvertations = function (data) {
    const url = `${config.baseUrl}admin/group/conversations`;
    return postRequest({
        url,
        data,
    });
};

export const groupUpdateAPI = function ({
    groupId,
    data,
}) {
    const url = `${config.baseUrl}admin/group/update/${groupId}`;
    return putRequest({
        url,
        data,
    });
};

