import config from "src/config";
import { postRequest } from "src/utils/RequestUtil";
export const updateLiveStatus = async (status, customStatus) => {
  try {
    const url =  `${config.baseUrl}user/profile/updateLiveStatus`;
     postRequest({
      url,
      data:{ status, customStatus },
    });
    return true;
  } catch (error) {
    return null;
  }
};
