import React, { useState, useEffect } from 'react';

const CheckInternet = ({ isFromHome = "" }) => {

    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [showBanner, setShowBanner] = useState(false);
    const [bannerMessage, setBannerMessage] = useState('');
    const [bannerColor, setBannerColor] = useState('');

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setBannerMessage('You are back online.');
            setBannerColor('green');
            setShowBanner(true);
            setTimeout(() => setShowBanner(false), 2000); // Hide after 2 seconds
        };

        const handleOffline = () => {
            setIsOnline(false);
            setBannerMessage('You are currently offline. Please check your internet connection.');
            setBannerColor('red');
            setShowBanner(true);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Clean up the event listeners
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    // Determine the background color and message based on isFromHome
    const bannerBgColor = isFromHome ? 'red' : bannerColor;
    const bannerText = isFromHome ? isFromHome : bannerMessage;

    if (!showBanner   ) {
        return null;
    }

    return (
        <div style={{ ...styles.banner, backgroundColor: bannerBgColor }}>
            {bannerText}
        </div>
    );
};

const styles = {
    banner: {
        color: 'white',
        padding: '10px',
        textAlign: 'center',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1000,
    },
};

export default CheckInternet;
