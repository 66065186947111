import { put, call } from 'redux-saga/effects';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { ACCOUNT_ACTIONS } from '../actions/AccountActions';
import { UpdateAccountAPI } from 'src/api/AccountSettingApi';


function* update(userId, payload) {
    try {
        const {
            data: {
                data,
            },
        } =  yield call(UpdateAccountAPI, {
            userId,
            data: payload,
        });
    
        yield put({
            type: ACCOUNT_ACTIONS.ACCOUNT_UPSERT_SUCCESS,
            data: {
                ...data.accountSettingInformation,
            },
        });
        
        
        
        
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        
        
        

        yield put({
            type: ACCOUNT_ACTIONS.ACCOUNT_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    
    
    
}

export default [
    takeFirstSagaUtil(ACCOUNT_ACTIONS.ACCOUNT_UPSERT, upsert)
];
