import config from 'src/config';
import { getAsyncRequest, postAsyncRequest, putRequest } from 'src/utils/RequestUtil';

export const UpdatePasswordAPI = async (data)=> {
    const url = `${config.baseUrl}auth/change-password`;
    return postAsyncRequest({
        url,
        data,
    });
};

export const HomeCreateAPI = async (data)=> {
    const url = `${config.baseUrl}home`;
    return postAsyncRequest({
        url,
        data,
    });
};

export const UpdateAccountAPI = function ({
    userId,
    data,
}) {
    const url = `${config.baseUrl}auth/update-account/${userId}`;
    return putRequest({
        url,
        data,
    });
};
export const getAccountStorageUsageAPI = function () {
    const url = `${config.baseUrl}auth/get-storage-usage`;
    return postAsyncRequest({
        url
    });
};
export const loginInfoAPI = function ({
    userId,
}) {
    const url = `${config.baseUrl}auth/get-login-info/${userId}`;
    return getAsyncRequest({
        url
    });
};
