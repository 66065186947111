import React, { useEffect, useRef } from 'react';
import HeaderNav from "./Layout/HeaderNav"
import LandingFooter from "./Layout/Footer"
import LandingHead from "./Layout/Head"

const PrivacyPolicy=()=>{

  const sectionsRef = useRef([]);
  const listsRef = useRef([]);

  useEffect(() => {
    const sections = sectionsRef.current;
    const lists = listsRef.current;

    function activeLink(li) {
      lists.forEach((item) => item.classList.remove('active'));
      li.classList.add('active');
    }

    lists.forEach((item) =>
      item.addEventListener('click', function() {
        activeLink(this);
      })
    );

    function handleScroll() {
      let scrollPosition = window.scrollY + 300; 

      sections.forEach((section) => {
        let top = section.offsetTop;
        let id = section.getAttribute('id');

        if (scrollPosition >= top) {
          const target = document.querySelector(`[href='#${id}']`).parentElement;
          activeLink(target);
        }
      });
    }

    window.addEventListener('scroll', handleScroll);

    
    return () => {
      lists.forEach((item) => item.removeEventListener('click', function() {
        activeLink(this);
      }));
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

   return (
    <>
       <>
  <title>Privacy Policy</title>
  <LandingHead />
  <HeaderNav/> 
  <div className="container">
  <div className="pvsplacysm">
      <div className="row">
      <div className="col-md-12 flhdnb border-bottom">
          <div className="text-center">
            <h2 className="color1a fw-600 d-flex justify-content-between font-40 align-items-center">
              Privacy Policy{" "}
              <span>
                <img
                  src="assets/images/privacy-policy.svg"
                  className="img-fluid"
                  alt=""
                />
              </span>
            </h2>
          </div>
        </div>
        <div className="col-md-5 col-lg-4 ps-0 pe-0 mdnone">
          <nav className="navigation tbsactvNav">
            <div className="packdv active1">
              <ul>
                {['Introduction', 'InformationWeCollect', 'HowWeUseYourInformation', 'InformationSharingAndDisclosure', 'DataSecurity', 'DataRetention', 'YourRights', 'ChangesToThisPolicy', 'ContactUs'].map((section, index) => (
                  <li key={section} className="list" ref={el => listsRef.current[index] = el}>
                    <a href={`#${section}`}><span className="title">{section.replace(/([A-Z])/g, ' $1').trim()}</span></a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
        <div className="col-md-7 col-lg-8 bgsdpriceing ps-0">
          {[
            { id: 'Introduction', content: 'Acadecraft Pvt. Ltd. is committed to protecting your privacy. This Privacy Policy ("Policy") describes how we collect, use, and disclose information when you use the SyncupTeams Messenger Application ("App").' },
            { id: 'InformationWeCollect', content: '<p><span class="fw-600 d-block">Personal Information:</span>When you register for an account or use the App, we may collect personal information such as your name, email address, phone number, and organization.</p><p><span class="fw-600 d-block">Usage Information:</span>We may collect information about your use of the App, including your IP address, browser type, device information, and usage patterns.</p><p><span class="fw-600 d-block">Communication Content:</span>We collect the content of your communications when you use the App, including messages, files, and other information you share.</p>' },
            { id: 'HowWeUseYourInformation', content: '<p><span class="fw-600 d-block">To Provide and Improve the App:</span>We use your information to operate, maintain, and improve the App, and to provide you with the services and features you request.</p><p><span class="fw-600 d-block">To Communicate with You: </span>We may use your information to send you service-related notifications, updates, and promotional materials.</p><p><span class="fw-600 d-block">To Ensure Security: </span>We use your information to monitor and enhance the security of the App and to prevent fraud and abuse.</p>' },
            { id: 'InformationSharingAndDisclosure', content: '<p><span class="fw-600 d-block">With Your Consent: </span>We may share your information with third parties when you have given us your consent to do so.</p><p><span class="fw-600 d-block">Service Providers:</span>We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, and customer support.</p><p><span class="fw-600 d-block">Legal Requirements: </span>We may disclose your information if required to do so by law or in response to a valid request by a governmental or regulatory authority.</p>' },
            { id: 'DataSecurity', content: 'We implement appropriate technical and organizational measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is completely secure, and we cannot guarantee its absolute security.' },
            { id: 'DataRetention', content: 'We retain your information for as long as necessary to provide you with the App and for legitimate business purposes, such as complying with our legal obligations, resolving disputes, and enforcing our agreements.' },
            { id: 'YourRights', content: 'You have the right to access, correct, or delete your personal information, and to restrict or object to certain data processing activities. To exercise these rights, please contact us at [Your Contact Information].' },
            { id: 'ChangesToThisPolicy', content: 'We may update this Policy from time to time. We will notify you of any changes by posting the new Policy on the App. Your continued use of the App after the effective date of the revised Policy constitutes your acceptance of the revised Policy.' },
            { id: 'ContactUs', content: 'If you have any questions about this Policy, please contact us at [Your Contact Information].' }
          ].map((section, index) => (
            <div key={section.id} id={section.id} className="dpri samenv1" ref={el => sectionsRef.current[index] = el}>
              <h2 className="d-flex align-items-start gap-3">{section.id.replace(/([A-Z])/g, ' $1').trim()}</h2>
              <div dangerouslySetInnerHTML={{ __html: section.content }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  <LandingFooter />
</>

    </>
   )
}
export default PrivacyPolicy