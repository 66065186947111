import config from "src/config";
import {
  deleteRequest,
  patchRequest,
  postRequest,
} from "src/utils/RequestUtil";
export const getUserNotesList = async (searchText = "", startDate, endDate) => {
  try {
    const url = `${config.baseUrl}notes/user/notes/all`;
    return postRequest({
      url,
      data: { searchText, sDate: startDate, eDate: endDate },
    });
  } catch (error) {
    return null;
  }
};

export const updateUserNote = async (note) => {
  try {
    const url = `${config.baseUrl}notes/user/notes/update`;
    return patchRequest({
      url,
      data: { ...note },
    });
  } catch (error) {
    return null;
  }
};

export const pinUserNote = async (noteId) => {
  try {
    const url = `${config.baseUrl}notes/user/notes/pin`;
    return patchRequest({
      url,
      data: { noteId },
    });
  } catch (error) {
    return null;
  }
};

export const deleteUserNote = async (noteId) => {
  try {
    const url = `${config.baseUrl}notes/user/notes/delete`;
    return deleteRequest({
      url,
      data: { noteId },
    });
  } catch (error) {
    return null;
  }
};

export const createUserNote = async (note) => {
  try {
    const url = `${config.baseUrl}notes/user/notes/add`;
    return postRequest({
      url,
      data: { ...note },
    });
  } catch (error) {
    return null;
  }
};
