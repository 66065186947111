import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { LocationDefaultProps } from 'src/containers/admin-containers/location-container/LocationPropTypes';
import { LOCATION_ACTIONS } from '../actions/LocationActions';

const initialState = () => ({
    currentRecord: { ...LocationDefaultProps },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case LOCATION_ACTIONS.LOCATION_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case LOCATION_ACTIONS.LOCATION_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case LOCATION_ACTIONS.LOCATION_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case LOCATION_ACTIONS.LOCATION_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case LOCATION_ACTIONS.LOCATION_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case LOCATION_ACTIONS.LOCATION_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case LOCATION_ACTIONS.LOCATION_RESET: {
            return {
                ...state,
                currentRecord: { ...LocationDefaultProps },
            };
        }

        default:
            return state;
    }
};
