import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoaderSmall = () => {
  return (
    <div className="small-skeleton">
      <h1>{<Skeleton height={40} />}</h1>
      <Skeleton count={2}/>
    </div>
  );
};

export default SkeletonLoaderSmall;
