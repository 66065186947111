import { format } from 'timeago.js';
import * as moment from 'moment';
import { useState } from 'react';
import config from 'src/config';
import { useSelector } from "react-redux";
import { updateUserGroup } from 'src/containers/home-container/apis/Groupapis';
import { groupSearchAPI } from 'src/api/GroupApi';

const UserProfilePopup=(props)=>{
  const  {
   groupDetails,
   isGroupProfileView,
   setGroupProfileView,
   socket,
} = props

const user = useSelector((state) => state.auth.user);
const authToken = useSelector((state) => state.auth.user?.jwtToken);

const [userList, setUserList] = useState([]);
  const [activityDetails, setActivityDetails] = useState([]);
  const [addRemove, setAddRemove] = useState(false);
  const [newGroupDetails, setNewGroupDetails] = useState({ ...groupDetails });
  const [deleteMessageType, setDeleteMessageType] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);



const allowOrDisallowChat = async (id, isChatAllowed) => {
    
   let res = await updateUserGroup(
     {
       groupId: id,
       isChatAllowed: !isChatAllowed,
     },
   );
   setNewGroupDetails({ ...groupDetails,isChatAllowed:(!isChatAllowed)})
   
 };

 const deactivateGroup = async (id, isDeactivated) => {
   let res = await updateUserGroup(
     {
       groupId: id,
       isDeactivated: !isDeactivated,
     },
     authToken
   );
   setNewGroupDetails({ ...groupDetails,isDeactivated:(!isDeactivated)})
 };

 const deleteGroup = async (id, isDeleted) => {
   let res = await updateUserGroup(
     {
       groupId: id,
       isDeleted: !isDeleted,
     },
   );
   if (!isDeleted) {
     newGroupDetails.isDeactivated = true;
     newGroupDetails.isDeleted = true;
   } else {
     newGroupDetails.isDeleted = false;
   }
   setNewGroupDetails({ ...groupDetails,...newGroupDetails})
 };
 
const SearchUser = (keywords="") => {
   setAddRemove(true);
   if (socket) {
     socket.emit("searchUsersInGroup", {
      searchTerm: keywords,
     });
     socket.on("searchUsersInGroupResponse", handleSocketUserListResponse);
   } 
 };
 const handleSocketUserListResponse = ({ list }) => {
   setUserList(list);
 };

 const makeAdmin = async (id) => {

   if (groupDetails.admins.includes(id)) {
      groupDetails.admins = groupDetails.admins.filter( (uId) => uId !== id);
   
     let res = await updateUserGroup(
       {
         groupId: groupDetails?._id,
         selectedUserId: id,
         adminsRemove: true,
       },
     );

     document.getElementById(`userTag_A_${id}`).style.display = "none";
     document.getElementsByClassName(`ara_${id}`)[0].textContent = "Add Admin";
   } else {
     groupDetails.admins = [...groupDetails.admins, id];
     let res = await updateUserGroup(
       {
         groupId: groupDetails?._id,
         selectedUserId: id,
         adminsAdd: true,
       },
     );
     document.getElementById(`userTag_A_${id}`).style.display = "block";
     document.getElementsByClassName(`ara_${id}`)[0].textContent = "Remove Admin";
   }
  
  
   setNewGroupDetails(groupDetails);
 };

 const makeModerator = async (id) => {
   if (groupDetails.moderators.includes(id)) {
       groupDetails.moderators = groupDetails.moderators.filter(
       (uId) => uId !== id
     );
     let res = await updateUserGroup(
       {
         groupId: groupDetails?._id,
         selectedUserId: id,
         moderatorsRemove: true,
       },
     );
     document.getElementById(`userTag_M_${id}`).style.display = "none";
     document.getElementsByClassName(`arm_${id}`)[0].textContent =
       "Add Moderator";
   } else {
     groupDetails.moderators = [...groupDetails.moderators, id];
     let res = await updateUserGroup(
       {
         groupId: groupDetails?._id,
         selectedUserId: id,
         moderatorsAdd: true,
       },
     );
     document.getElementById(`userTag_M_${id}`).style.display = "block";
     document.getElementsByClassName(`arm_${id}`)[0].textContent =
       "Remove Moderator";
   }
 };
 function toggleButton(button) {
   if (button.innerHTML === "Add") {
     button.innerHTML = "Remove";
     button.classList.remove("addremvbtn");
     button.classList.add("removebtn");
   } else {
     button.innerHTML = "Add";
     button.classList.remove("removebtn");
     button.classList.add("addremvbtn");
   }
 }
 const selectUserFun = async (id) => {
   if (groupDetails.participants.includes(id)) {
     groupDetails.participants = groupDetails.participants.filter(
       (uId) => uId !== id
     );

     let res = await updateUserGroup(
       {
         groupId: groupDetails?._id,
         selectedUserId: id,
         remove: true,
       },
     );
   } else {
     groupDetails.participants = [...groupDetails.participants, id];
     let res = await updateUserGroup(
       {
         groupId: groupDetails?._id,
         selectedUserId: id,
         add: true,
       },
     );
   }
 };

   return (
    <>
 <div
  className="chat-sidebar chat-animate-right"
  style={{ display:`${(isGroupProfileView)?'block':'none'}`, right: 0, top: 0, marginTop: 43, zIndex: 10,width: "20%" }}
  id="ViewProfileGrp"
>
  <div className="modal-dialog-scrollable">
    <div className="modal-content">
      <div className="slide-header py-0">
        <div className="HeadSlidName d-flex justify-content-end align-items-center">
          <button className="" onClick={()=>{setGroupProfileView(false)}}>
            ×
          </button>
        </div>
      </div>
      <div className="modal-body pt-0">
        <div className="text-center profText">
          <div className="profImg"
          style={{
            backgroundImage: `url(${groupDetails?.groupDP?.url})`,
          }}
           />
          <div className="userName font-16 color1a py-1">
            {groupDetails?.title}{" "}
            {}
          </div>
          <div className="userdec font-14 color99 py-1">
            Created by 
            <br />
            {groupDetails.userId.firstName}{" "}
            {groupDetails.userId.lastName}{" "}
            <button className="adminicon">A</button>
          </div>
          <div className="userprd font-12 color4d py-1">
            on {format(moment(groupDetails.createdAt).format('x'))}
          </div>
          <p className="userprd font-12 color80 py-1">
              {groupDetails?.content}
          </p>
        </div>
        <div className="">
          <div className="d-flex justify-content-between flex-grow-1 possktopnew">
            <h3 className="d-flex align-items-center font-12 fw-bold color1a">
              <span className="gropu-icon me-3" /> {groupDetails?.participants.length} Participants
            </h3>
            {!addRemove && (
                    <button
                      type="button"
                      className="btnblue addremvbtn  font-12"
                      onClick={() => {
                        
                        SearchUser();
                      }}
                    >
                      Add
                    </button>
            )}
            {addRemove && (
               <button
                  type="button"
                  className="btnblue removebtn  font-12"
                  onClick={() => {
                  setAddRemove((prevState) => !prevState);
                  }}
               >
                  X
               </button>
            )}
          </div>
          <div className="grpselectadd">

            {groupDetails?.userId && (
                     <div className="d-flex align-items-start py-2">
                        <div className="flex-shrink-0 position-relative imgUserLs">
                           <img
                           className="img-fluid userimg"
                           src={
                              groupDetails.userId.otherInformation
                                 ?.profilePicture || "/assets/images/user.svg"
                           }
                           width={45}
                           height={45}
                           alt="user img"
                           />
                        </div>
                        <div className="flex-grow-1 ms-3">
                           <h3 className="font-14 fw-500 color1a">
                           {groupDetails.userId.firstName}{" "}
                           {groupDetails.userId.lastName}
                           </h3>
                           <div className="font-14 color66">
                           {
                              groupDetails.userId.otherInformation
                                 ?.designation
                           }{" "}
                           </div>
                        </div>
                        <div className="d-flex align-items-center">
                           <button className="adminicon">A</button>
                           <div className="dropdown toggleBtns visible">
                           <button
                              className="toggledots"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="true"
                           >
                              <i className="mdi mdi-dots-vertical" />
                           </button>
                           <ul
                              className="dropdown-menu mx-0 shadow w-220px p-0"
                              data-bs-theme="light"
                              data-popper-placement="bottom-start"
                              style={{
                                 position: "absolute",
                                 inset: "0px auto auto 0px",
                                 margin: 0,
                                 transform: "translate(0px, 39px)",
                              }}
                           >
                              <li>
                                 <a
                                 className="dropdown-item d-flex gap-2 align-items-center"
                                 href="#"
                                 data-bs-toggle="modal"
                                 data-bs-target="#deletemsg"
                                 >
                                 Exit From Group
                                 </a>
                              </li>
                           </ul>
                           </div>
                        </div>
                     </div>
            )}

           {!addRemove &&
                    newGroupDetails?.participants &&
                    newGroupDetails?.participants
                      .filter(
                        (groupUser) =>
                        newGroupDetails?.userId._id !== groupUser?._id
                      )
                      .map((groupUser, index) => (
                        <div
                          className="d-flex align-items-start py-2"
                          key={index}
                        >
                          <div className="flex-shrink-0 position-relative imgUserLs">
                            <img
                              className="img-fluid userimg"
                              src={
                                groupUser?.otherInformation?.profilePicture ||
                                "/assets/images/user.svg"
                              }
                              width={45}
                              height={45}
                              alt="user img"
                            />
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h3 className="font-14 fw-500 color1a">
                              {groupUser?.firstName} {groupUser?.lastName}
                            </h3>
                            <div className="font-14 color66">
                              {groupUser?.otherInformation?.designation}{" "}
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center"
                            id={`userTag_${groupUser?._id}`}
                          >
                            {groupDetails?._id &&
                            groupDetails?.admins &&
                            groupDetails.admins.includes(groupDetails._id) ? (
                              <button
                                className="adminicon"
                                id={`userTag_A_${groupUser?._id}`}
                              >
                                A
                              </button>
                            ) : (
                              <button
                                className="adminicon"
                                id={`userTag_A_${groupUser?._id}`}
                                style={{ display: "none" }}
                              >
                                A
                              </button>
                            )}

                            {groupDetails?.moderators &&
                            groupDetails.moderators.includes(
                              groupDetails._id
                            ) ? (
                              <button
                                className="adminicon"
                                id={`userTag_M_${groupUser?._id}`}
                              >
                                M
                              </button>
                            ) : (
                              <button
                                className="adminicon"
                                id={`userTag_M_${groupUser?._id}`}
                                style={{ display: "none" }}
                              >
                                M
                              </button>
                            )}

                            <div className="dropdown toggleBtns visible">
                              <button
                                className="toggledots"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="true"
                              >
                                <i className="mdi mdi-dots-vertical" />
                              </button>
                              <ul
                                className="dropdown-menu mx-0 shadow w-220px p-0 "
                                data-bs-theme="light"
                                data-popper-placement="bottom-start"
                              
                              
                              
                              
                              
                              
                              >
                                {newGroupDetails?._id &&
                                newGroupDetails?.admins?.includes(
                                  newGroupDetails?._id
                                ) ? (
                                  <li
                                    onClick={() => {
                                      makeAdmin(groupUser?._id);
                                    }}
                                  >
                                    <a
                                      className={`dropdown-item d-flex gap-2 align-items-center ara_${groupUser._id}`}
                                      href="#"
                                    >
                                      Remove Admin
                                    </a>
                                  </li>
                                ) : (
                                  <li
                                    onClick={() => {
                                      makeAdmin(groupUser?._id);
                                    }}
                                  >
                                    <a
                                      className={`dropdown-item d-flex gap-2 align-items-center ara_${groupUser._id}`}
                                      href="#"
                                    >
                                      Make Admin
                                    </a>
                                  </li>
                                )}

                                {groupUser?._id &&
                                newGroupDetails?.moderators?.includes(
                                  groupUser?._id
                                ) ? (
                                  <li
                                    onClick={() => {
                                      makeModerator(groupUser?._id);
                                    }}
                                  >
                                    <a
                                      className={`dropdown-item d-flex gap-2 align-items-center arm_${groupUser._id}`}
                                      href="#"
                                    >
                                      Remove Moderator
                                    </a>
                                  </li>
                                ) : (
                                  <li
                                    onClick={() => {
                                      makeModerator(groupUser?._id);
                                    }}
                                  >
                                    <a
                                      className={`dropdown-item d-flex gap-2 align-items-center arm_${groupUser._id}`}
                                      href="#"
                                    >
                                      Make Moderator
                                    </a>
                                  </li>
                                )}

                                <li
                                  onClick={() =>
                                    
                                    setSelectedUser(groupUser?._id)
                                  }
                                >
                                  <a
                                    className="dropdown-item d-flex gap-2 align-items-center"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#ExitGroupmsg"
                                  >
                                    Remove From Group
                                  </a>
                                </li>
                              </ul>
                            </div> 
                          </div>
                        </div>
                      ))} 
                 {addRemove && (
                    <div className="modal-body settinghndl border-0">
                      <div className="chat-list grpselectadd">
                        {userList &&
                          userList.map((userData, index) => {
                            return (
                              <div
                                className="d-flex align-items-start py-2"
                                key={index}
                                data={userData?._id}
                              >
                                <div className="flex-shrink-0 position-relative imgUserLs">
                                  <img
                                    className="img-fluid userimg"
                                    src={
                                      userData?.otherInformation
                                        ?.profilePicture ||
                                      "/assets/images/user.svg"
                                    }
                                    width={45}
                                    height={45}
                                    alt="user img"
                                  />
                                </div>
                                <div className="d-flex justify-content-between flex-grow-1 ms-3">
                                  <div className="">
                                    <h3>
                                      {userData?.firstName} {userData?.lastName}{" "}
                                    </h3>
                                    <div className="font-14 color66">
                                      {userData?.otherInformation?.department} -{" "}
                                      {userData?.otherInformation?.designation}
                                    </div>
                                  </div>
                                  <div className="onlineOfine d-flex justify-content-between align-items-center">
                                    {newGroupDetails.participants.some(participant => participant._id === userData._id) ? (
                                      <button
                                        type="button"
                                        className="btnblue removebtn font-12"
                                        onClick={(e) => {
                                          toggleButton(e.target);
                                          selectUserFun(userData?._id);
                                        }}
                                      >
                                        Remove
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btnblue addremvbtn font-12"
                                        onClick={(e) => {
                                          toggleButton(e.target);
                                          selectUserFun(userData?._id);
                                        }}
                                      >
                                         Add
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}




          </div>
        </div>
        

        <div className="">
          <div className="dflex justify-content-between align-items-center createnotes font-16 fw-bold color1a btnclr">
            Group Settings
          </div>
          <div className="Achpaswd">
            <div className="dflex align-items-center justify-content-between py-2">
              <div className="font-14 color1a fw-500">Allow Chat</div>
              <div className="onofbtnwx">
               <button
                  type="button"
                  className={`btn btn-toggle ${
                     newGroupDetails?.isChatAllowed === true
                  ? "active"
                  : ""
                  } me-0`}
                  data-bs-toggle="button"
                  aria-pressed={`${
                     newGroupDetails?.isChatAllowed || false
                  }`}
                  autoComplete="off"
                  onClick={() => {
                  allowOrDisallowChat(
                  newGroupDetails?._id,
                  newGroupDetails?.isChatAllowed
                  );
               }}
               >
                  <div className="handle" />
                </button>
              </div>
            </div>
            <div className="dflex align-items-center justify-content-between py-2">
                          <div className="font-14 color1a fw-500">
                            Deactivate group
                          </div>
                          <div className="onofbtnwx">
                            <button
                              type="button"
                              className={`btn btn-toggle ${
                                newGroupDetails.isDeactivated === true
                                  ? "active"
                                  : ""
                              } me-0`}
                              data-bs-toggle="button"
                              aria-pressed={`${
                                newGroupDetails.isDeactivated || false
                              }`}
                              autoComplete="off"
                              onClick={() => {
                                deactivateGroup(
                                  newGroupDetails?._id,
                                  newGroupDetails.isDeactivated
                                );
                              }}
                            >
                              <div className="handle" />
                            </button>
                          </div>
                        </div>
                        <div className="dflex align-items-center justify-content-between py-2">
                          <div className="font-14 color1a fw-500">
                            Permanently Delete
                          </div>
                          <div
                            className="onofbtnwx"
                            
                            
                          >
                            <button
                              type="button"
                              className={`btn btn-toggle ${
                                newGroupDetails.isDeleted === true
                                  ? "active"
                                  : ""
                              } me-0`}
                              data-bs-toggle="button"
                              aria-pressed={`${
                                newGroupDetails.isDeleted || false
                              }`}
                              autoComplete="off"
                              onClick={() => {
                                deleteGroup(
                                  newGroupDetails?._id,
                                  newGroupDetails.isDeleted
                                );
                              }}
                            >
                              <div className="handle" />
                            </button>
                          </div>
                        </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
   )
}
export default UserProfilePopup