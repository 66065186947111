import React from "react";
import { convertTimestampToDdMmYyyy } from "src/utils/utils";

const NotificationView = ({ noticeNotification }) => {
  return (
    <>
    <div
        className="modal-dialog-scrollable"
      >
      <div
        className="modal-content chatboxMsg"
        id="notificationText"
        style={{ display: "none" }}
      >
        <div className="modal-body h-100">
          <div className="text-center bgFE notiYet pt-5 pb-5">
            <img
              src="assets/images/notifications/bell-icon.svg"
              className="img-fluid"
              alt=""
            />
            <div className="">
              <h2 className="font-24 fw-bold color1a mt-5">
                No notifications yet{" "}
              </h2>
              <p className="font-14 fw-500 color80">
                you have no notifications right now.
                <span className="d-block"> Come back later</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-content chatboxMsg bggrey"
        id="notificationTextshow"
      >
        <div className="msg-head bg-none w80 border-0">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="text onelinetext font-14 d-flex align-items-center text66">
                {noticeNotification?.user?.otherInformation && (
                  <div className="flex-shrink-0 position-relative">
                    <img
                      className="imggrp"
                      src={
                        noticeNotification?.user?.otherInformation
                          ?.profilePicture ?? "assets/images/faces/face1.jpg"
                      }
                      width={45}
                      height={45}
                      alt="user img"
                    />
                    {noticeNotification?.user?.otherInformation?.organization}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-body h-100 w80">
          <div className="p-4">
            <div className="font-16 fw-bold color1a my-5">
              {noticeNotification?.title}
            </div>
            <p className="font-12 fw-500 color1a">
              {noticeNotification?.description}
            </p>

            <div className="font-14 fw-500 color80 mt-5">
              {`${
                noticeNotification?.user?.firstName
                  ? noticeNotification?.user?.firstName
                      .charAt(0)
                      .toUpperCase() +
                    noticeNotification?.user?.firstName.slice(1).toLowerCase()
                  : ""
              } ${
                noticeNotification?.user?.lastName
                  ? noticeNotification?.user?.lastName.charAt(0).toUpperCase() +
                    noticeNotification?.user?.lastName.slice(1).toLowerCase()
                  : ""
              }`}
              {noticeNotification?.user?.firstName && ","}{" "}
              <span className="d-block">
                {noticeNotification?.user?.otherInformation?.designation}
              </span>
            </div>
            <div className="clock font-14 textb3 mt-4">
              {noticeNotification?.noticeTimeStamp && (
                <i className="mdi mdi-clock-outline" />
              )}{" "}
              {noticeNotification?.noticeTimeStamp &&
                convertTimestampToDdMmYyyy(noticeNotification?.noticeTimeStamp)}
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default NotificationView;
