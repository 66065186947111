import moment from 'moment';
export const formatDate = (timestamp) => {
  return moment(new Date(timestamp)).format('DD/MM/YYYY')
  };

 export const convertTimestampToTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;
  };

 export  const tabs = ["All Files", "Media", "Link", "Docs"];
