import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const DepartmentPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    departmentName: PropTypes.string,
});

export const DepartmentDefaultProps = { 
    departmentName: '',
    _id: undefined
};

export const DepartmentYupSchema = Yup.object().shape({ 
    departmentName: Yup.string().required('Required'),
});

export default {
    DepartmentDefaultProps,
    DepartmentPropTypes,
    DepartmentYupSchema,
};
