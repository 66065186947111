import { useCallback, useEffect,useState   } from "react";
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { notificationEditAction, notificationSearchAction, notificationUpsertAction } from './actions/NotificationActions';
import ReceiverName from "./ReceiverName";
import Pagination from "src/components/Pagination";

const NotificationSearchList=({search})=>{
    
    const dispatch = useDispatch();
    const [where,setWhere] = useState({});
    const[pageNo,setPageNo] = useState(0);
    
    const editClick = useCallback((type,record) => {
        if (type==="edit" && notificationEditAction) {
            dispatch(notificationEditAction(record));
        }
        else{
            const deletePayload = { 
                _id:record._id,
                active:false,
                deleted:true,
                deletedTs:new Date(),
            }            
            dispatch(notificationUpsertAction(deletePayload));
            
        }
    }, [
        dispatch,
        notificationEditAction,
    ]);

    const handleEditNotification = useCallback((
      recoard,        
  ) => { 
      
      dispatch(notificationEditAction(recoard));
  }, [
      dispatch,
  ]);


    const handleTestSearch = useCallback(
      debounce((searchKey, setWhere) => {
        const whereClauseKeysPaths = ['title', 'description'];
        const whereWithOrCondition = {
          $or: whereClauseKeysPaths.map((keyPath) => ({ 
            [keyPath]: {
              $regex: searchKey,
              $options: 'i',
            },
          })),
        }; 
        setWhere(whereWithOrCondition);
      }, 300),
      []
    );
    const handleSearchInputChange = (e) => {
      const searchKey = e.target.value;
      handleTestSearch(searchKey, setWhere);
    };

    useEffect(()=>{ 
      const payload = {
          where, 
          currentPage:pageNo, 
          autopopulate:true, 
          fullTextSearch:true,
      }
      dispatch(notificationSearchAction(payload));
  },[where,pageNo])

    return (
        <div className="card pt-2">
        <div className="d-flex justify-content-between align-items-center px-4 pb-2">
          <div className="font-14 fw-bold color1a">Notification Details </div>
          <div className="d-flex tablebtns">
            <div id="customFilter" className="customFilter rounded-2 me-3">
              <input
                type="search"
                id="searchInput"
                className="form-control w-100"
                placeholder="Search..."
                onChange={handleSearchInputChange}
              />
            </div>
            {}
          </div>
        </div>
        <div className="table-responsive table-height">
          <table
            id="grouptable"
            className="table datatable table-striped border-top small"
          >
            <thead>
              <tr>
                <th className="text-center">
                  <input type="checkbox" className="me-2" /> #
                </th>
                <th>Notification Title</th>
                <th>Created by </th>
                {}
                <th>Organization </th>
                <th>Receiver Type </th> 
                <th>Receiver Name </th> 
                <th>Post Time &amp; Date </th>
                <th className="text-center">Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
                {
                    search.data.map((notification,index)=>{
                        const status =notification.noticeTimestamp > new Date() ? "Send" : "Pending"
                        return(
                            <tr key={index}>
                            <td className="text-center" />
                            <td className="align-middle customer white-space-nowrap">
                              <a
                                className="d-flex align-items-center text-body"
                                href="apps/e-commerce/landing/profile.html"
                              > 
                                <h6 className="mb-0 ms-1 text-body">
                                  {notification.title}
                                </h6>
                              </a>
                            </td>
                            <td className="align-middle customer white-space-nowrap">
                              {notification.createdBy.firstName+' '+notification.createdBy.lastName}
                            </td> 
                            {}
                            <td>{notification.organizationId}</td>
                            <td>{notification.receiverType} </td>
                            <td>
                               <ReceiverName 
                               recipientsGroup={notification.receiverType}
                               notification={notification}
                               />
                            </td>                            
                            <td> {notification.noticeTimeStamp} at {notification.noticeTime} </td>
                            <td className="text-center">
                              {" "}
                              <button className={`bg-${(status==="Pending")?'y':'g'} btnstbl text-white rounded-5 border-0`}>
                                      {status}
                              </button>
                            </td>
                            <td>
                              <div className="btn-reveal-trigger position-static dotstabl">
                                <button
                                  className="btn btn-sm dropdown-toggle dropdown-caret-none"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  data-bs-reference="parent"
                                >
                                  <i className="mdi mdi-dots-horizontal mdi-36px" />
                                </button>
                                <div
                                  className="dropdown-menu dropdown-menu-end py-2"
                                  style={{}}
                                >   
                                  <a
                                    className="dropdown-item font-12 fw-bold color1a d-flex align-items-center gap-2"
                                    onClick={()=>{editClick('edit',notification)}}
                                    href="#"
                                  >
                                    <img
                                      src="/assets/images/notifications/edit.svg"
                                      className="img-fluid"
                                      alt="edit"
                                    />{" "}
                                    Edit Or Re schedule
                                  </a>
                                  <a
                                    className="dropdown-item font-12 fw-bold color1a d-flex align-items-center gap-2"
                                    onClick={()=>{editClick('delete',notification)}}
                                    href="#"
                                  >
                                    <img
                                      src="/assets/images/notifications/delete.svg"
                                      className="img-fluid"
                                      alt="edit"
                                      
                                    />{" "}
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                    })
                }
            </tbody>
          </table>
        </div>
        {search.data && search.data?.length>0 && <Pagination setPageNo={setPageNo} pageNo={pageNo} total={search.total}/>}
      </div>
    )
}

export default NotificationSearchList;