import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import sagas from "src/SagaCombine";
import ReducerCombine from "../ReducerCombine";

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  let middleware = applyMiddleware(sagaMiddleware);

  if (process.env.NODE_ENV !== "production") {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window;
    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === "function") {
      middleware = compose(middleware, __REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const store = createStore(
    ReducerCombine,

    middleware
  );

  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept("../ReducerCombine", () => {
      store.replaceReducer(require("../ReducerCombine").default);
    });
  }

  return store;
}
