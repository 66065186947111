import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const OurClients = () => {
  const options = {
    loop: true,
    margin: 10,
    dots: false,
    lazyLoad: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 5
      }
    }
  };

  return (
    <section className="sec client-icons">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h3 className="font-24 fw-bold text-center mb-4">Trusted by</h3>
            <OwlCarousel className="owl-theme client-slider1" {...options}>
              <div className="item">
                <img
                  className="owl-lazy img-fluid"
                  data-src="assets/images/landing/our-clients/algento.png"
                  alt="algento"
                />
              </div>
              <div className="item">
                <img
                  className="owl-lazy img-fluid"
                  data-src="assets/images/landing/our-clients/amazon.png"
                  alt="amazon"
                />
              </div>
              <div className="item">
                <img
                  className="owl-lazy img-fluid"
                  data-src="assets/images/landing/our-clients/codility.png"
                  alt="codility"
                />
              </div>
              <div className="item">
                <img
                  className="owl-lazy img-fluid"
                  data-src="assets/images/landing/our-clients/hock.png"
                  alt="hock"
                />
              </div>
              <div className="item">
                <img
                  className="owl-lazy img-fluid"
                  data-src="assets/images/landing/our-clients/medical-systems.png"
                  alt="medical-systems"
                />
              </div>
              <div className="item">
                <img
                  className="owl-lazy img-fluid"
                  data-src="assets/images/landing/our-clients/melocalgate.png"
                  alt="melocalgate"
                />
              </div>
              <div className="item">
                <img
                  className="owl-lazy img-fluid"
                  data-src="assets/images/landing/our-clients/mxplayer.png"
                  alt="mxplayer"
                />
              </div>
              <div className="item">
                <img
                  className="owl-lazy img-fluid"
                  data-src="assets/images/landing/our-clients/phonix.png"
                  alt="phonix"
                />
              </div>
              <div className="item">
                <img
                  className="owl-lazy img-fluid"
                  data-src="assets/images/landing/our-clients/respect-group.png"
                  alt="respect-group"
                />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurClients;
 