

import React, { useEffect, useRef } from "react";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import $ from "jquery";
import "bootstrap-daterangepicker";

const SingleDateRangePickerWithTime = ({ onDateRangeChange, position }) => {
  const pickerRef = useRef(null);

  useEffect(() => {
    const $picker = $(pickerRef.current);

    const start = moment().subtract(29, "days");
    const end = moment();

    function cb(start, end) {
      onDateRangeChange(start, end);
      $("#reportrange span").html(
        start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      );
    }

    $picker.daterangepicker(
      
      {
        opens: `${position}`,
        drops: 'auto',

        singleDatePicker: true,
        timePicker: true,
        startDate: moment().startOf("hour"),
        endDate: moment().startOf("hour").add(32, "hour"),
        locale: {
          format: "M/DD hh:mm A",
        },
      },
      cb
    );

    cb(start, end);

    return () => {
      if ($picker.data("daterangepicker")) {
        $picker.data("daterangepicker").remove();
      }
    };
  }, []);

  return (
    <div>
      {}
      <input
        type="text"
        id="reportrange"
        className="reportrange d-flex align-items-center form-control cursor-pointer border-0 mxwxctm"
        ref={pickerRef}
        name="datetimes"
      />
      <i className="mdi mdi-calendar"></i> 

    </div>
  );
};

export default SingleDateRangePickerWithTime;


