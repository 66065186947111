import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { CurrentYear } from 'src/utils/utils';
const LandingFooter=({mTop})=>{
  const navigate = useNavigate();
  const HandleHashLink=(source)=>{
    navigate(source);
  }
 return (
    <footer className={`pt-4 bggrey overflow-hidden ${mTop}`} >
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="d-flex justify-content-between">
            <a href="/" className="d-inline-block mb-3">
              <img src="assets/images/landing/logo.svg" className="img-fluid" />
            </a>
            <p className="color66 font-14">
              At SyncupTeams, we're dedicated to providing a secure, user-friendly
              messaging solution to keep your team connected and productive.
            </p>
          </div>
        </div>
        <div className="col-lg-12 d-flex justify-content-center bglinkfter mt-4">
          <div className="adress f-link1">
            <div className="font-18 color1a fw-600 d-flex align-items-center mb-2">
              {" "}
              Address{" "}
            </div>
            <ul>
              <li className="font-14 color66 fw-500 d-flex gap-2">
                <span>
                  <i className="mdi mdi-map-marker-outline mdi-24px" />
                </span>{" "}
                <div className="">
                  H-54, Sector 63, Noida, Uttar{" "}
                  <span className="d-block">Pradesh, India-201301</span>
                </div>{" "}
              </li>
            </ul>
          </div>
          <div className="adress f-link1">
            <div className="font-18 color1a fw-600 d-flex align-items-center mb-2">
              Contact us{" "}
            </div>
            <ul>
              <li className="font-14 color66 fw-500">
                <a
                  href="mailto:info@syncupteams.com"
                  className="d-flex gap-2 font-14 color1a d-flex align-items-center fw-500 text-decoration-none"
                >
                  <span>
                    <i className="mdi mdi-email-outline mdi-24px" />
                  </span>{" "}
                  info@syncupteams.com
                </a>
              </li>
              <li>
                <a
                  href="tel:+917000530247"
                  className="d-flex gap-2 font-14 color1a d-flex align-items-center fw-500 text-decoration-none"
                >
                  <span>
                    <i className="mdi mdi-phone-outline mdi-18px" />
                  </span>{" "}
                  +91-700-053-0247
                </a>
              </li>
            </ul>
          </div>
          <div className="adress f-link1">
            <div className="downloadApp">
              <h4 className="color1a font-18 fw-600 mb-2">
                Download our App from
              </h4>
              <ul className="applst d-flex justify-content-center gap-1">
                <li className="">
                  <a href="#">
                    <img
                      src="assets/images/landing/app-store.png"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="assets/images/landing/google-play.png"
                      className="img-fluid"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="f-link1">
            <div className="d-flex flex-column justify-content-end fstbckftr ">
              <div className="font-18 color1a fw-600 d-flex align-items-center mb-2">
                Social Media{" "}
              </div>
              <ul className="list-unstyled d-flex">
                <li className="ms-0">
                  <a className="link-body-emphasis" href="#">
                    <img
                      src="assets/images/landing/youtube.svg"
                      className="img-fluid"
                      alt=""
                      width={40}
                      height={28}
                    />
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#">
                    <img
                      src="assets/images/landing/instagream.svg"
                      className="img-fluid"
                      alt=""
                      width={28}
                      height={28}
                    />
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#">
                    <img
                      src="assets/images/landing/linkedin.svg"
                      className="img-fluid"
                      alt=""
                      width={28}
                      height={28}
                    />
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#">
                    <img
                      src="assets/images/landing/x.svg"
                      className="img-fluid"
                      alt=""
                      width={28}
                      height={28}
                    />
                  </a>
                </li>
                <li className="ms-3">
                  <a className="link-body-emphasis" href="#">
                    <img
                      src="assets/images/landing/facebook.svg"
                      className="img-fluid"
                      alt=""
                      width={28}
                      height={28}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="f-bggray mt-4">
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between align-items-center p-2">
            <p className="mb-0">
              <span className="font-22">©</span> {CurrentYear()} SyncupTeams
            </p>
            <ul className="d-flex gap-2">
              <li>
                <Link to="/" className="font-14 color1a fw-500">
                    Home
                </Link>
              </li>
              <li>
              <a href="#AboutUs" className="font-14 color1a fw-500" onClick={()=>{HandleHashLink('/#AboutUs')}}>
                  About Us{" "}
              </a>
              </li>
              <li>
              <a href="#Features" className="font-14 color1a fw-500" onClick={()=>{HandleHashLink('/#Features')}}>
                  Features{" "}
                </a>
              </li>
              <li>
               <a href="/#Pricing" className="font-14 color1a fw-500" onClick={()=>{HandleHashLink('/#Pricing')}}>
                  Pricing
                </a>
              </li>
              <li>
               <Link to="/download" className="font-14 color1a fw-500">
                  Download{" "}
                </Link>
              </li>
              <li>
               <Link to="/terms-and-conditions" className="font-14 color1a fw-500"> 
                  Terms &amp; Conditions 
                </Link>
              </li>
              <li>
              <Link to="/privacy-policy" className="font-14 color1a fw-500">  
                  Privacy &amp; Policy
               </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  
 )
}
export default LandingFooter 