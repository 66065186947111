import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes,Route,Navigate } from "react-router-dom";
import Head from "../layout-container/Head";
import HeaderNav from "../layout-container/HeaderNav";
import LeftSideBarContainer from "./SideBar-container/LeftSideBarContainer";
import LeftContainer from "./SideBar-container/LeftContainer";
import NavbarContainer from "./SideBar-container/NavbarContainer";
import DashboardContainer from "./dashboard-container";
import AdminHead from "./dashboard-container/AdminHead";
import DepartmentContainer from "./department-container/DepartmentContainer";
import DesignationContainer from "./designation-container/DesignationContainer";
import LocationContainer from "./location-container/LocationContainer";
import NotificationContainer from "./notification-container/NotificationContainer";
import RoleContainer from "./role-container/RoleContainer";
import PermissionContainer from "./permission-container/PermissionContainer";
import GroupContainer from "./group-container";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SocketContext } from "src/context/SocketContext";
import UserContainer from "./user-container/index";

const AdminHome=()=>{
   const socket = useContext(SocketContext);
   const user = useSelector((state) => state.auth.user);
    
    
         {}

    
    
    return (
        <>
          <Helmet>
            <section>
              <title>Admin-Panel</title>
              {}
            </section>
          </Helmet>
          <AdminHead />    
          <body>
          <ToastContainer />
            <HeaderNav />
            <main className="d-flex flex-nowrap">
            <LeftContainer />
         <div className="chat-area" id="main">
             {}
            <div className="chatlist bg-black w235">
               <div className="modal-dialog-scrollable">
                  <div className="modal-content">
                     <div className="chat-header">
                        <div className="userstatus">
                           <div className="text-center">
                              <div className="mb-3"><img src="/icons/SyncLogo.png" height={49} width={49} className="img-fluid"/></div>
                              <div className="font-18 userName m-0 text-white">Hi, {user.firstName}! <span className="admin green-badge font-10 fw-normal">Admin</span></div>
                              <div className="font-14 color99 fw-500">Acadecraft Pvt. Ltd.</div>
                           </div>
                        </div>
                     </div>
                     <div className="modal-body">
                        <NavbarContainer/> 
                     </div>
                  </div>
               </div>
            </div>
            {}
            {}
            <div className="chatbox bg-adinclr"> 
               <div className="modal-dialog-scrollable" id="adminWithoutControls">
                  <div className="modal-content chatboxMsg">
                     <div className="modal-body">
                        <Routes> 
                            <Route index element={<DashboardContainer socket={socket} />} /> 
                            <Route path="users" element={<UserContainer socket={socket}/>} />
                            <Route path="groups" element={<GroupContainer socket={socket}/>} />
                            <Route path="departments" element={<DepartmentContainer />} />
                            <Route path="designations" element={<DesignationContainer />} />
                            <Route path="locations" element={<LocationContainer />} />
                            <Route path="notifications" element={<NotificationContainer />} />
                            <Route path="roles" element={<RoleContainer />} />
                            <Route path="permissions" element={<PermissionContainer />} />
                        </Routes>
                        {}
                     </div>
                  </div>
               </div>                
                 <div className="modal-dialog-scrollable controlsList" style={{display:"none"}} id="controlsList"> 
                </div>
            </div>             
         </div>
          {}
      </main>
          </body>
        </>
      );
}
export default AdminHome