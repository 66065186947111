import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { DesignationDefaultProps } from 'src/containers/admin-containers/designation-container/DesignationPropTypes';
import { DESIGNATION_ACTIONS } from '../actions/DesignationActions';

const initialState = () => ({
    currentRecord: { ...DesignationDefaultProps },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case DESIGNATION_ACTIONS.DESIGNATION_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case DESIGNATION_ACTIONS.DESIGNATION_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case DESIGNATION_ACTIONS.DESIGNATION_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case DESIGNATION_ACTIONS.DESIGNATION_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case DESIGNATION_ACTIONS.DESIGNATION_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case DESIGNATION_ACTIONS.DESIGNATION_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case DESIGNATION_ACTIONS.DESIGNATION_RESET: {
            return {
                ...state,
                currentRecord: { ...DesignationDefaultProps },
            };
        }

        default:
            return state;
    }
};
