import { handleLogout } from "src/containers/login-container/LoginService";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "src/components/Notifination/ToastNotification";
import { logout } from "src/containers/home-container/actions/authAction";

const LeftContainer=()=>{
   const { openSuccessNotification, openErrorNotification } = useNotification();
   const auth = useSelector((state) => state.auth.user);
   const { jwtToken: authToken, otherInformation: { profilePicture } = {} } =
     auth || {};
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const handleLogoutClick = async () => {
      try {
        const response = await handleLogout(authToken);
        if (response) {
          dispatch(logout());
          navigate("/");
          openSuccessNotification("Logged out successfully.");
        }
      } catch (error) {
        return openErrorNotification(error?.message ?? "Error logging out");
      }
    };
   const sidebarItems = [
      {
        href: "#",
        title: "message",
        drawer:null,
        icon: <i className="mdi mdi-chat"></i>,
      },
  
      {
        href: "#",
        title: "Notifications",
        drawer:null,
        icon: <i className="mdi mdi-bell"></i>,
      },
  
      {
        href: "#",
        title: "Orange User",
        drawer:null,
        icon: <i className="mdi mdi-star"></i>,
      }
    ];
    return (
        <>
            <div
            className="d-flex flex-column flex-shrink-0 bg-body-tertiary align-items-center border-right leftbar"
            style={{ width: "5.5rem" }}
            >
            <a
               href="#"
               className="d-flex justify-content-center align-items-center flex-column link-body-emphasis mt-3 text-decoration-none"
               title="User"
               data-bs-toggle="tooltip"
               data-bs-placement="right"
            >
               <img 
               src={
              profilePicture?.length > 0
                ? profilePicture
                : "/assets/images/user.svg"
            }
             className="img-fluid" alt="" />
            </a>
            <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
               
         {sidebarItems.map((item, index) => (
            <li key={index}>
              <a
                href="/home"
                className="nav-link border-bottom rounded-0"
                title={item.title}
                data-bs-toggle="tooltip"                
                data-bs-placement="right"
              >
                {item.icon}
              </a>
            </li>
          ))}


               <li key="admin">
                     <a
                     href="/admin"
                     className="nav-link border-bottom rounded-0 active"
                     title="Admin"
                     data-bs-toggle="tooltip" 
                     data-bs-placement="right"
                     >
                     <i className="mdi mdi-key-variant"></i>
                     </a>
               </li> 
            </ul>
            <ul className="nav nav-pills nav-flush flex-column mt-auto text-center">
               <li>
                  <a
                  href="#"
                  className="nav-link border-bottom rounded-0"
                  title="Settings"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  onClick="settings_open()"
                  >
                  <i className="mdi mdi-settings" />
                  </a>
               </li>
               <li>
               <a
              href="#"
              className="nav-link border-bottom rounded-0 logoutftlast "
              title="Logout"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              onClick={handleLogoutClick}
            >
              <i className="mdi mdi-power"></i>
            </a>
               </li>
            </ul>
            </div>

        </>
    )
}
export default LeftContainer