import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentTime } from "src/utils/CommonUtils";
import { truncateString } from "src/utils/utils";
import PropTypes from "prop-types";

const UserChatProfile = (props) => {
  const {
    resetAndToggleModal,
    ind,
    index,
    getRootProps,
    handleUserClick,
    item,
    participantsUser,
    onlineUserList,
    one2oneTyping,
    getInputProps,
    markAsFavUser,
    setTotalUnRead,
    user,
    selectedRoomId,
    selectedUserOrGroup
  } = props;

  const currentUserRedux = useSelector(
    (state) => state.ChatBoxReducer.currentRecord
  );
  const [isUnRead, setIsUnRead] = useState(item?.unRead || 0);
  const [isSelected, setIsSelected] = useState(false);

  // const getMostRecentTime = (updated_at, lastSeen_at) => {
  //   const updatedAt = new Date(updated_at);
  //   const lastSeen = new Date(lastSeen_at);

  //   if (updatedAt > lastSeen) {
  //     return getCurrentTime(item?.updated_at);
  //   } else {
  //     return getCurrentTime(participantsUser?.otherInformation?.lastSeen);
  //   }
  // };

  useEffect(() => {
    setIsUnRead(item?.unRead);
    setTotalUnRead((prevCount) => prevCount + (item?.unRead || 0));

    return () => {
      setTotalUnRead((prevCount) => {
        if (prevCount > 0) {
          return prevCount - (item?.unRead || 0);
        } else {
          return prevCount;
        }
      });
    };
  }, [item?.unRead, setTotalUnRead]);
//  useEffect(() => {
//     setIsSelected(participantsUser._id === currentUserRedux._id);
//   }, [currentUserRedux]);

  const renderProfilePicture = () => {
    const profilePicture = participantsUser?.otherInformation?.profilePicture;
    const firstNameInitial = participantsUser?.firstName?.charAt(0);
    const lastNameInitial = participantsUser?.lastName?.charAt(0);

    if (profilePicture) {
      return (
        <img
          className="img-fluid userimg"
          src={profilePicture}
          width="40"
          height="40"
          alt="user img"
          style={{
            border: `solid ${
              participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
            }`,
            borderWidth: "2px",
          }}
          loading="lazy"
        />
      );
    } else {
      return (
        <div
          className="placeholder-img"
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#e0e0e0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `solid ${
              participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
            }`,
            borderWidth: '2px',
            color: '#555',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          {`${firstNameInitial || ''}${lastNameInitial || ''}`}
        </div>
      );
    }
  };

  return (
    <>
      <div
        className={`chat-user-list ${(selectedRoomId === item?.roomId || selectedRoomId === item?._id || item?.participants.find(elem=>elem._id===selectedRoomId) ) ? "selectedChatBox" : ""}`}
        key={ind}
        {...getRootProps()}
        onClick={(e) => e.preventDefault()}
      >
        <a
          href="#"
          className="d-flex align-items-start"
          key={index}
          onClick={(e) => {
            resetAndToggleModal();
            setIsUnRead(null);
            setTotalUnRead((prevCount) => {
              const decrement = item?.unRead || 0;
              return Math.max(prevCount - decrement, 0);
            });
            handleUserClick(e, item, participantsUser);
          }}
          onDrop={(e) => handleUserClick(e, item, participantsUser)}
        >
          {/* <div className="flex-shrink-0 position-relative imgUserLs chatListImgParent">
            <span className="chatListImg">
              <img
                className="img-fluid userimg"
                src={
                  participantsUser?.otherInformation?.profilePicture ||
                  "./assets/images/user.svg"
                }
                width="45"
                height="45"
                alt="user img"
                style={{
                  border: `solid ${
                    participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
                  }`,
                  borderWidth: "2px",
                }}
              /> 
            </span>
            {onlineUserList.includes(participantsUser?._id) && (
              <>
                {participantsUser?.liveStatus == "auto" && (
                  <>
                    <span className="d-inline-block bg-success rounded-circle p-1 mdi mdi-checkbox-blank-circle " />
                  </>
                )}
                {participantsUser?.liveStatus == "custom" && (
                  <>
                    <span className="d-inline-block bg-success rounded-circle p-1 mdi mdi-checkbox-blank-circle " />
                  </>
                )}
                {participantsUser?.liveStatus === "dnd" && (
                  <>
                    <span className="d-inline-block mdi mdi-minus-circle text-red wdhs" />
                  </>
                )}
                {participantsUser?.liveStatus === "meeting" && (
                  <>
                    <span className="d-inline-block mdi mdi-clock text-yellow wdhs" />
                  </>
                )}
                {participantsUser?.liveStatus === "wfh" && (
                  <>
                    <span className="d-inline-block mdi mdi-home text-blue wdhs" />
                  </>
                )}
                {participantsUser?.liveStatus === "away" && (
                  <>
                    <span className="d-inline-block mdi mdi-run wdhs" />
                  </>
                )}
              </>
            )}
          </div> */}
          <div className="flex-shrink-0 position-relative imgUserLs chatListImgParent">
            <span className="chatListImg">
              {renderProfilePicture()}
            </span>
            {onlineUserList?.includes(participantsUser?._id) && (
              <>
                {participantsUser?.liveStatus === "auto" && (
                  <span className="d-inline-block bg-success rounded-circle p-1 mdi mdi-checkbox-blank-circle" />
                )}
                {participantsUser?.liveStatus === "custom" && (
                  <span className="d-inline-block bg-success rounded-circle p-1 mdi mdi-checkbox-blank-circle" />
                )}
                {participantsUser?.liveStatus === "dnd" && (
                  <span className="d-inline-block mdi mdi-minus-circle text-red wdhs" />
                )}
                {participantsUser?.liveStatus === "meeting" && (
                  <span className="d-inline-block mdi mdi-clock text-yellow wdhs" />
                )}
                {participantsUser?.liveStatus === "wfh" && (
                  <span className="d-inline-block mdi mdi-home text-blue wdhs" />
                )}
                {participantsUser?.liveStatus === "away" && (
                  <span className="d-inline-block mdi mdi-run wdhs" />
                )}
              </>
            )}
          </div>

          <div className="flex-grow-1 ms-3">
            <div className="d-flex align-items-center justify-content-between">
              <input {...getInputProps()} multiple />
              <h3>{`${participantsUser?.firstName} ${participantsUser?.lastName}`}</h3>
            </div>
            {item.action && (
              <div
                className={`font-14 d-flex align-items-center text66 ${
                  item.mdiIconClass ? "image" : "miscall"
                }`}
              >
                {item.mdiIconClass && (
                  <i className={`mdi ${item.mdiIconClass} font-20 pe-1`}></i>
                )}
                {item.action}
              </div>
            )}
            <div className="text onelinetext font-14 d-flex align-items-center text66">
              {(item?.isDeletedFor && item?.isDeletedFor.includes(user?._id)) ||
              item?.isDeleteForAll ? (
                "Message deleted"
              ) : item?.messageType == "media" ? (
                <>
              {(item?.isDeletedFor && item?.isDeletedFor.includes(user?._id)) ||
              item?.isDeleteForAll ?  "Message deleted" :<i className="mdi mdi-file">File</i>   }   
                </>
              ) : (
                truncateString(item?.message, 20)
              )}
            </div>
            <div className="onlineOfine d-flex justify-content-between align-items-center">
              <div className="clock font-14 textb3">
                {one2oneTyping?.senderId === participantsUser?._id &&
                one2oneTyping?.message?.trim() ? (
                  <span style={{color:"#02bc7d", fontSize: "14px", fontWeight:"500"}}> {one2oneTyping?.message} </span>
                ) : (
                  <>
                    {}
                    {/* {getMostRecentTime(
                      item?.updated_at,
                      participantsUser?.otherInformation?.lastSeen
                    )} */}
                    {getCurrentTime(item?.updated_at)}
                  </>
                )}
              </div>
              {item.checkIcon && (
                <div className="checkonline font-14">
                  <i className="mdi mdi-check-all"></i>
                </div>
              )}
            </div>
          </div>
        </a>
        <div className="d-flex align-items-center actvnewi flex-column-reverse">
          <button
            className={`orangestar`}
            id={`str_${item?._id}`}
            onClick={() => markAsFavUser(item)}
          >
            <i
              className={`mdi mdi-star mdi-18px ${
                item?.isFav && item?.isFav.includes(user?._id) ? "active" : ""
              }`}
            ></i>
          </button>
          {item?.unRead && parseInt(item?.unRead) > 0 && isUnRead ? (
            <span className="bg1C">{isUnRead} </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

UserChatProfile.propTypes = {
  resetAndToggleModal: PropTypes.func.isRequired,
  ind: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  getRootProps: PropTypes.func.isRequired,
  handleUserClick: PropTypes.func.isRequired,
  item: PropTypes.shape({
    unRead: PropTypes.number,
    updated_at: PropTypes.string,
    isDeletedFor: PropTypes.arrayOf(PropTypes.string),
    isDeleteForAll: PropTypes.bool,
    messageType: PropTypes.string,
    message: PropTypes.string,
    _id: PropTypes.string.isRequired,
    isFav: PropTypes.arrayOf(PropTypes.string),
    action: PropTypes.string,
    mdiIconClass: PropTypes.string,
  }).isRequired,
  participantsUser: PropTypes.shape({
    otherInformation: PropTypes.shape({
      lastSeen: PropTypes.string,
      profilePicture: PropTypes.string,
    }),
    isOrange: PropTypes.bool,
    liveStatus: PropTypes.string,
    _id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  onlineUserList: PropTypes.arrayOf(PropTypes.string).isRequired,
  one2oneTyping: PropTypes.shape({
    senderId: PropTypes.string,
    message: PropTypes.string,
  }),
  getInputProps: PropTypes.func.isRequired,
  markAsFavUser: PropTypes.func.isRequired,
  setTotalUnRead: PropTypes.func.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserChatProfile;
