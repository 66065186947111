import {useState} from 'react'
import TimezoneSelect from "react-timezone-select"
import LandingHead from '../Layout/Head'
import HeaderNav from '../Layout/HeaderNav'
import LandingFooter from '../Layout/Footer'
import CountrySelector from 'src/components/CountrySelector'
import PhoneNoInput from 'src/components/PhoneNoInput'
import { ContactvalidationSchema } from './ContactConst'
import { HomeContactAPI } from 'src/api/HomeApi'

const ContactUs=()=>{
    const [selectedTimezone, setSelectedTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      );
      const [phoneNo,setPhoneNo] = useState({calling_code:null,number:null})
      const [country,setCountry] = useState(null);
      const [formData,setFormData] = useState({label: "India",value:"IN"});
      const [validationErrors,setValidationErrors] = useState([])
      const [formSubmited,setFormSubmited] = useState(false)


      const handleInputChange = (e)=>{
        setFormData({...formData,[e.target.name]:e.target.value})
      }

      const handleSubmit=async()=>{
         const FinalPayload = {
            ...formData,
            country:country?.label,
            phoneNo,
            Timezone:selectedTimezone.value
         } 
         try {
            setValidationErrors([])
            const validations = ContactvalidationSchema.validateSync(FinalPayload,{ abortEarly: false });
            const response = await HomeContactAPI(FinalPayload);
            if(response){
                setFormSubmited(true);
            }
          } catch (err) {
            const validationErrors = {};
               err.inner?.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
             setValidationErrors(validationErrors)
          }
       }
   return (
    <> 
        <title>Download</title>
            <LandingHead />
            <HeaderNav />
            <section className="sec plan-sec pb-0 mt-5">
  <div className="container">
    <div className="row">
      <div className="bglightgary">
        <div className="col-md-12 text-center">
          <h1 className={`font-40 color1a fw-bold ${(formSubmited)?"submitedContact":""}`}>
            {formSubmited?"Enquiry submitted successfully":"Schedule your free trial now!"}
          </h1>
        </div>
        <div className="mt-4 d-flex align-items-start bgform">
          <div className="">
            <div className="bgBlue d-flex justify-content-center align-items-center flex-column">
              <h2 className="font-32 text-white fw-900">
                Explore <span className="d-block">how SyncupTeam</span> can work
                for you !
              </h2>
              <p className="font-14 text-white fw-500 mx-290 mb-0">
                Our expert will assist on how Track Log can help grow your
                business. Let's get started with your 14 days free trial!
              </p>
              <img
                src="/assets/images/contactus-roket.png"
                className="img-fluid"
                alt=""
                width={175}
                height={175}
              />
            </div>
            <div className="mt-5">
              <ul className="clntrating pt-5 d-flex align-items-center gap-5">
                <li className="d-flex align-items-center gap-2 py-2">
                  <img
                    src="/assets/images/trustpilot.png"
                    className="img-fluid"
                    alt="Trustpilot - SyncupTeams"
                  />
                </li>
                <li className="d-flex align-items-center gap-2 py-2">
                  <img
                    src="/assets/images/google.png"
                    className="img-fluid"
                    alt="Google Reviews - SyncupTeams"
                  />
                </li>
              </ul>
              <div className="licon d-flex justify-content-start align-items-center mt-4">
                <div className="ktext d-block font-14 fw-bold color14 me-5">
                  <span className="d-block font-24 fw-bold color14">1 K+ </span>{" "}
                  Clients{" "}
                </div>
                <div className="ktext font-14 fw-bold color14">
                  <span className="d-block font-24 fw-bold color14c">15K+</span>{" "}
                  Installed App
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white formListinput">
            <h2 className="font-30 color1a fw-900">Hey There!</h2>
            <h3 className="font-24 color1a fw-500 mb-4">
              Let's get started with your 14 days free trial.
            </h3>
            <form className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label font-14 fw-900">Name *</label>
                <input
                 name='name'
                  type="text"
                  className="form-control"
                  placeholder="Enter Full Name"
                  onChange={handleInputChange}
                />
                <span className='error'>{(validationErrors?.name)?validationErrors.name:''}</span>
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label form-label font-14 fw-900">
                  Company Name *
                </label>
                <input
                  name='companyName'
                  type="text"
                  className="form-control"
                  placeholder="Enter Company Name"
                  onChange={handleInputChange}
                />
                <span className='error'>{(validationErrors?.companyName)?validationErrors.companyName:''}</span>
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label form-label font-14 fw-900">
                  Company Website *
                </label>
                <input
                  name='companyWebsite'
                  type="text"
                  className="form-control"
                  placeholder="Enter Company Website"
                  onChange={handleInputChange}
                />
                <span className='error'>{(validationErrors?.companyWebsite)?validationErrors.companyWebsite:''}</span>
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label form-label font-14 fw-900">
                  Email *
                </label>
                <input
                  name='email'
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  onChange={handleInputChange}
                />
                <span className='error'>{(validationErrors?.email)?validationErrors.email:''}</span>
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label form-label font-14 fw-900">
                  Contact No. *
                </label> 
                <PhoneNoInput name="phone" setPhoneNo={setPhoneNo}  />
                <span className='error'>{(validationErrors?.phone)?validationErrors.phone:''}</span>
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label form-label font-14 fw-900">
                  Country *
                </label>
                <CountrySelector  
                name="country"
                className="form-control" 
                placeholder="Country" 
                setCountry={setCountry}
                />
                <span className='error'>{(validationErrors?.country)?validationErrors.country:''}</span>
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label form-label font-14 fw-900">
                  Time Zone *
                </label>
                <TimezoneSelect name="timeZone" value={selectedTimezone} onChange={setSelectedTimezone} />
                <span className='error'>{(validationErrors?.timeZone)?validationErrors.timeZone:''}</span>
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label form-label font-14 fw-900">
                  Preferred Time *
                </label>
                <select
                  id="timeSlotSelect"
                  className="form-control js-example-basic-single custom-select"
                  name="preferredTime"
                  placeholder="Enter Preferred Time"
                  onChange={handleInputChange}
                >
                    <option
                    selected
                    value=""
                    data-select2-id="select2-data-29-j39k"
                  >
                    Please Select Time Slot
                  </option>
                  <option
                    value="12am-1am"
                    data-select2-id="select2-data-29-j39k"
                  >
                    12am-1am
                  </option>
                  <option
                    value="1am-2am"
                    data-select2-id="select2-data-40-fwlf"
                  >
                    1am-2am
                  </option>
                  <option
                    value="2am-3am"
                    data-select2-id="select2-data-41-4bax"
                  >
                    2am-3am
                  </option>
                  <option
                    value="3am-4am"
                    data-select2-id="select2-data-42-b2fi"
                  >
                    3am-4am
                  </option>
                  <option
                    value="4am-5am"
                    data-select2-id="select2-data-43-2zu5"
                  >
                    4am-5am
                  </option>
                  <option
                    value="5am-6am"
                    data-select2-id="select2-data-44-wv7g"
                  >
                    5am-6am
                  </option>
                  <option
                    value="6am-7am"
                    data-select2-id="select2-data-45-evr5"
                  >
                    6am-7am
                  </option>
                  <option
                    value="7am-8am"
                    data-select2-id="select2-data-46-hjdt"
                  >
                    7am-8am
                  </option>
                  <option
                    value="8am-9am"
                    data-select2-id="select2-data-47-r8iy"
                  >
                    8am-9am
                  </option>
                  <option
                    value="9am-10am"
                    data-select2-id="select2-data-48-7k54"
                  >
                    9am-10am
                  </option>
                  <option
                    value="10am-11am"
                    data-select2-id="select2-data-49-4js9"
                  >
                    10am-11am
                  </option>
                  <option
                    value="11am-12am"
                    data-select2-id="select2-data-50-qzxw"
                  >
                    11am-12am
                  </option>
                  <option
                    value="12pm-1pm"
                    data-select2-id="select2-data-51-ksj1"
                  >
                    12pm-1pm
                  </option>
                  <option
                    value="1pm-2pm"
                    data-select2-id="select2-data-52-3gwh"
                  >
                    1pm-2pm
                  </option>
                  <option
                    value="2pm-3pm"
                    data-select2-id="select2-data-53-xp9n"
                  >
                    2pm-3pm
                  </option>
                  <option
                    value="3pm-4pm"
                    data-select2-id="select2-data-54-i4lb"
                  >
                    3pm-4pm
                  </option>
                  <option
                    value="4pm-5pm"
                    data-select2-id="select2-data-55-a4ap"
                  >
                    4pm-5pm
                  </option>
                  <option
                    value="5pm-6pm"
                    data-select2-id="select2-data-56-s066"
                  >
                    5pm-6pm
                  </option>
                  <option
                    value="6pm-7pm"
                    data-select2-id="select2-data-57-x0lw"
                  >
                    6pm-7pm
                  </option>
                  <option
                    value="7pm-8pm"
                    data-select2-id="select2-data-58-mlh2"
                  >
                    7pm-8pm
                  </option>
                  <option
                    value="8pm-9pm"
                    data-select2-id="select2-data-59-52pu"
                  >
                    8pm-9pm
                  </option>
                  <option
                    value="9pm-10pm"
                    data-select2-id="select2-data-60-qoar"
                  >
                    9pm-10pm
                  </option>
                  <option
                    value="10pm-11pm"
                    data-select2-id="select2-data-61-cwkz"
                  >
                    10pm-11pm
                  </option>
                  <option
                    value="11pm-12pm"
                    data-select2-id="select2-data-62-umf8"
                  >
                    11pm-12pm
                  </option>
                </select>
                <span className='error'>{(validationErrors?.preferredTime)?validationErrors.preferredTime:''}</span>
              </div>
              <div className="mb-3 col-md-12">
                <label className="form-label form-label font-14 fw-900">
                  Message
                </label>
                <textarea
                  onChange={handleInputChange}
                  name='message'
                  type="text"
                  className="form-control"
                  placeholder="Enter Message..."
                  defaultValue={""}
                />
                <span className='error'>{(validationErrors?.message)?validationErrors.message:''}</span>
              </div>
              <div className="mt-3 col-md-12">
                <button
                  type="button"
                  className="font-18 border-none text-black bg-green fw-bold btnsmt w-auto mt-0"
                  onClick={handleSubmit}
                >
                  Submit <i className="mdi mdi-arrow-top-right" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<LandingFooter mTop="mt-0" />
    </>
   )
}
export default ContactUs