export const userProfileInitialState = (prevState) => {

  return {
    description: prevState?.otherInformation?.description ?? "",
    organizationId: prevState?.otherInformation?.organizationId ?? "",
    organization: prevState?.otherInformation?.organization ?? "",
    organizationLocation:
      prevState?.location?.location ?? "",
    department: prevState?.department?.departmentName ?? "",
    designation: prevState?.designation?.designationName ?? "",
    reportingManager: prevState?.otherInformation?.reportingManager ?? "",
    designationGrade: prevState?.otherInformation?.designationGrade ?? "",
    lastSeen: prevState?.otherInformation?.lastSeen ?? "",
    profilePicture: prevState?.otherInformation?.profilePicture ?? "",
    profilePictureBanner:
      prevState?.otherInformation?.profilePictureBanner ?? "",
    _id: prevState?._id ?? "",
    firstName: prevState?.firstName ?? "",
    lastName: prevState?.lastName ?? "",
    phone: prevState?.phone ?? "",
    country: prevState?.country ?? "",
    email: prevState?.email ?? "",
    status: prevState?.status ?? "",
    groupsCreatedByMe: prevState?.groupsCreatedByMe ?? [],
    commonGroups: prevState?.commonGroups ?? [],
    employeeID: prevState?.employeeID ?? "",
  };
};

export const getLastSeenString = (timestamp) => {
  const lastSeenDate = new Date(timestamp);
  const currentDate = new Date();
  const timeDifference = currentDate - lastSeenDate;
  const minutes = Math.floor(timeDifference / 1000 / 60);
  if (
    lastSeenDate.getDate() === currentDate.getDate() &&
    lastSeenDate.getMonth() === currentDate.getMonth() &&
    lastSeenDate.getFullYear() === currentDate.getFullYear()
  ) {
    const hours = lastSeenDate.getHours();
    const amOrPm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes =
      lastSeenDate.getMinutes() < 10
        ? "0" + lastSeenDate.getMinutes()
        : lastSeenDate.getMinutes();
    return `Today ${formattedHours}:${formattedMinutes} ${amOrPm}`;
  }
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (
    lastSeenDate.getDate() === yesterday.getDate() &&
    lastSeenDate.getMonth() === yesterday.getMonth() &&
    lastSeenDate.getFullYear() === yesterday.getFullYear()
  ) {
    const hours = lastSeenDate.getHours();
    const amOrPm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes =
      lastSeenDate.getMinutes() < 10
        ? "0" + lastSeenDate.getMinutes()
        : lastSeenDate.getMinutes();
    return `Yesterday ${formattedHours}:${formattedMinutes} ${amOrPm}`;
  }
  const formatWithLeadingZero = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  const formattedDate = `${formatWithLeadingZero(
    lastSeenDate.getDate()
  )}/${formatWithLeadingZero(
    lastSeenDate.getMonth() + 1
  )}/${lastSeenDate.getFullYear()}`;
  return formattedDate;
};
