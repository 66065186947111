import React from "react";
import { formatDate } from "src/utils/utils";

const Todo = ({
  userTodos,
  handleTodoEdit,
  handleMarkTodoItemComplete,
  handleDelete,
}) => {
  return (
    
      <div className="modal-body pt-0">
        <div className="accodtodo">
          <div className="tab-content" id="myTab2Content">
            {userTodos?.map((todo) => (
              <div
                key={`${todo._id}-${Math.random()}`}
                className="tab-pane fade show active"
                id="all"
                role="tabpanel"
                aria-labelledby="all-tab"
              >
                <div className="my-3">
                  <span className="font-12 color33 d-block">
                    {formatDate(todo.createdAt)}
                  </span>
                  <span className="font-12 color80 d-block">
                    {todo?.items?.length} Task
                  </span>
                  <div
                    className={`accordion accordion-flush bgA ${todo?.status.toLowerCase()}`}
                    id={todo._id}
                  >
                    <h3 className="font-14 fw-bold">{todo?.title}</h3>
                    <div className="dropdown toggleBtns toggleBtnsfile">
                      <button
                        className="toggledots"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="mdi mdi-dots-vertical" />
                      </button>
                      <ul
                        className="dropdown-menu mx-0 shadow w-220px p-0"
                        data-bs-theme="light"
                      >
                        <li>
                          <a
                            className="dropdown-item d-flex gap-2 align-items-center"
                            href="#"
                            onClick={() => {
                              handleTodoEdit(todo);
                            }}
                          >
                            <i className="mdi mdi-lead-pencil" />
                            Edit
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item d-flex gap-2 align-items-center"
                            href="#"
                            onClick={() => handleDelete(todo?._id)}
                          >
                            <i className="mdi mdi-delete-outline" />
                            Delete
                          </a>
                        </li>
                      </ul>
                    </div>
                    {todo?.items.map((item) => (
                      <div key={item._id} className="accordion-item">
                        <div
                          className="accordion-header d-flex align-items-center"
                          id={`flush-${item._id.$oid}`}
                        >
                          <label className="cstCheck addoutlin">
                            <input
                              type="checkbox"
                              className="meetingCheckbox"
                              checked={item?.isCompleted}
                              onChange={() =>
                                handleMarkTodoItemComplete(
                                  todo?._id,
                                  item?._id,
                                  !item?.isCompleted
                                )
                              }
                            />
                            <span className="checkmark" />
                          </label>
                          <div
                            className="accordion-button collapsed d-flex justify-content-between align-items-center p-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse-${item._id}`}
                            aria-expanded="false"
                            aria-controls={`flush-collapse-${item._id}`}
                          >
                            <span
                              className={
                                item?.isCompleted
                                  ? "font-12 ms-1 meeting active"
                                  : "font-12 ms-1 meeting"
                              }
                            >
                              {item.name}
                            </span>
                            <i className="mdi mdi-star priority" />
                          </div>
                        </div>
                        <div
                          id={`flush-collapse-${item._id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`flush-${item._id}`}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body pt-0">
                            <p className="font-10 fw-medium mb-1">
                              {item.description}
                            </p>
                            <div className="assigndate font-10 fw-medium">
                              Assign Date {formatDate(item.assignedDate)}
                            </div>
                            <div className="duedate font-10 fw-medium">
                              Due Date {formatDate(item.dueDate)}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    
  );
};

export default Todo;
