import React from "react";

const InfoDrawer = ({
  messageInfo,
  setMesageInfoFlag,
  selectedUserOrGroup,
  getCurrentTime,
}) => {
  const messageInfo_close = () => {
    setMesageInfoFlag(false);
    const mainElement = document.getElementById("main");
    const myProfileElement = document.getElementById("messageInfo");

    if (mainElement && myProfileElement) {
      mainElement.style.marginRight = "0px";
      myProfileElement.style.display = "none";
    }
  };



  const renderProfilePicture = (participantsUser) => {
    const profilePicture = participantsUser?.otherInformation?.profilePicture;
    const firstNameInitial = participantsUser?.firstName?.charAt(0);
    const lastNameInitial = participantsUser?.lastName?.charAt(0);

    if (profilePicture) {
      return (
        <img
          className="imggrp1"
          src={profilePicture}
          width="40"
          height="40"
          alt="user img"
          style={{
            border: `solid ${participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
              }`,
            borderWidth: "2px",
          }}
          loading="lazy"
        />
      );
    } else {
      return (
        <div
          className="imggrp1"
          style={{
            width: "80",
            height: "80",
            borderRadius: "50%",
            backgroundColor: "#e0e0e0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `solid ${participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
              }`,
            borderWidth: "2px",
            color: "#555",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {`${firstNameInitial || ""}${lastNameInitial || " "}`}
        </div>
      );
    }
  };
  return (
    <React.Fragment>
      <div
        className="chat-sidebar chat-animate-right suman"
        
        id="messageInfo"
      >
        <div className="modal-dialog-scrollable">
          <div className="modal-content">
            <div className="slide-header border-0">
              <div className="HeadSlidName d-flex justify-content-between align-items-start">
                <div className="slidName font-24  color33">
                  {renderProfilePicture(selectedUserOrGroup)}
                  <span className="d-block font-18 fw-bold color33 mt-2">
                    {selectedUserOrGroup?.firstName}{" "}
                    {selectedUserOrGroup?.lastName}
                  </span>{" "}
                </div>
                <button
                  className="bg-none color33"
                  onClick={() => {
                    messageInfo_close();
                  }}
                >
                  &times;
                </button>
              </div>
              <p className="font-12 fw-500 color33 pt-2">
                {}
                {messageInfo?.content}
              </p>
            </div>
            <div className="modal-body settinghndl border-0 mt-0">
              <div className="">
                <div className="viewinfo">
                  <div className="d-flex align-items-start wscrn">
                    {messageInfo && messageInfo?.delivered ? (
                      <>
                        <span className="winscreen">
                          <img
                            src="/assets/images/win-screen.svg"
                            className="img-fluid"
                            alt=""
                            width={16}
                            height={14}
                          />
                        </span>
                        <div className="winscreenW ms-2">
                          <div className="font-14 fw-500 color1a w1">
                            {messageInfo?.userAgentSeen?.deviceInfo?.platform}{" "}
                            {messageInfo?.userAgentSeen?.deviceInfo?.browser}
                          </div>
                          <div className="font-12 color99 w2">
                            {messageInfo?.userAgentSeen?.ip}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="winscreenW ms-2">
                        <div className="font-14 fw-500 color1a w1">
                          Not Delivered
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="font-12 color1a w2 mt-3 d-flex justify-content-between align-items-center">
                    {}
                    {messageInfo?.read == null &&
                      messageInfo?.delivered == null && (
                        <>
                          Sent
                          <div className="readmsg">
                            <i className="mdi mdi-check"></i>
                          </div>
                        </>
                      )}
                    {messageInfo?.delivered && messageInfo?.read == null && (
                      <>
                        Delivered
                        <div className="readmsg">
                          <i className="mdi mdi-check-all"></i>
                        </div>
                      </>
                    )}
                    {messageInfo?.delivered != null &&
                      messageInfo?.read != null && (
                        <>
                          Read
                          <div className="readmsg">
                            <i className="mdi mdi-check-all active"></i>
                          </div>
                        </>
                      )}
                  </div>
                </div>
                {}
                    <div className="viewinfo">
                      <div className="font-14 fw-bold color1a w1">
                      {renderProfilePicture(selectedUserOrGroup)}
                        {selectedUserOrGroup?.firstName}{" "}
                        {selectedUserOrGroup?.lastName}
                      </div>
                      <div className="winscreenW mt-2">
                        <div className="font-12 color99 w2  d-flex justify-content-between align-items-center">
                          {messageInfo?.delivered != null ? (
                              <>
                                {getCurrentTime(messageInfo?.delivered)}
                                <div className="readmsg">
                                  <i className="mdi mdi-check-all"></i>
                                </div>
                              </>
                            ):(
                              <>Not Delivered</>
                            )}
                        </div>
                        <div className="font-12 color99 w2  d-flex justify-content-between align-items-center">
                          {
                            messageInfo?.read != null ? (
                              <>
                                {getCurrentTime(messageInfo?.read)}
                                <div className="readmsg">
                                  <i className="mdi mdi-check-all active"></i>
                                </div>
                              </>
                            ):(
                              <>Not Read</>
                            )}
                        </div>
                      </div>
                    </div>
                  {}
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </React.Fragment>
  );
};

export default InfoDrawer;
