import React, { useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Testimonials() {

  const [items, setItems] = useState([
    {
      name: 'Jane Smith',
      title: 'Marketing Director at CreativeCorp',
      image: 'assets/images/landing/image.png',
      testimonial: 'SyncupTeams has been a game-changer for our marketing department. The platform fosters real-time collaboration, making idea sharing and brainstorming sessions seamless. Integrating our other tools with SyncupTeams has saved us time and improved our campaign efficiency. It\'s enhanced our team’s productivity and creativity significantly.',
    }, 

    {
      name: 'John Doe',
      title: 'Senior Manager',
      image: 'assets/images/faces/face21.jpg',
      testimonial: 'Using SyncupTeams has streamlined our development process. It has made communication between developers more efficient, and the integration with our existing tools is seamless.',
    },
  ]);

    const options = {
        loop: true,
        navText: [
            '<i class="mdi mdi-chevron-left"></i>',
            '<i class="mdi mdi-chevron-right"></i>',
          ],
        margin: 10,
        dots: false,
        nav:true,
        lazyLoad: true,
        autoplay: false,
        
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      };

  return (
    <section className="sec testimonial">
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center a-hding mb40">
          <h2 className="font-40 text-white fw-600">
            In Their Own Words{" "}
            <span className="d-block">Why Our Clients Trust Us</span>
          </h2>
        </div>
        <OwlCarousel className="owl-theme t-slider" {...options}>
        {items.map((item, index) => (
      <div key={index} className="text-center t-div">
        <div className="t-colImg">
          <div className="t-Img"> 
            <img
             src={item.image}
             className="img-fluid"
             alt={item.name}
            />
          </div>
          <div className="t-name font-18 color1a fw-600 pt-3">
          {item.name}
          
          </div>
          <div className="d-name font-14 color99">
          {item.title}
          
          </div>
        </div>
        <div className="t-text text-start"> 
          <p className="font-22 color1a fw-normal fst-italic">
          {item.testimonial} 
          </p>
          <div className="arrdul text-start">
            <img
              src="assets/images/landing/arrow-dual.svg"
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>  
       ))}
    </OwlCarousel>
      </div>
    </div>
  </section>
  )
}
