import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { SocketContext } from "src/context/SocketContext";

const UserStatus = ({ changeStatus }) => {
  const socket = useContext(SocketContext);
  const user = useSelector((state) => state.auth.user);
  const statuses = [
    {
      action: "Active",
      icon: "mdi mdi-circle",
      status: "auto",
      color: "text-green",
    },
    {
      action: "Do not disturb",
      icon: "mdi mdi-minus-circle",
      status: "dnd",
      color: "text-red",
    },
    {
      action: "Meeting",
      icon: "mdi mdi-clock",
      status: "meeting",
      color: "text-yellow",
    },
    {
      action: "Work from home",
      icon: "mdi mdi-home",
      status: "wfh",
      color: "text-blue",
    },
    { action: "Away", icon: "mdi mdi-run", status: "away", color: "" },
  ];

  const changeCurrent = (action, mdiIconClass, status) => {
    changeStatus(status);
    const dropdownToggle = document.querySelector(".dropdown-toggle");
    if (dropdownToggle) {
      dropdownToggle.innerHTML = `<i class="${mdiIconClass}"></i> ${action}`;
    }
  };

  const getStatusIcon = (liveStatus) => {
    switch (liveStatus) {
      case "auto":
      case "custom":
        return '<span class="d-inline-block mdi mdi-circle text-green"></span>';
      case "dnd":
        return '<span class="d-inline-block mdi mdi-minus-circle text-red wdhs"></span>';
      case "meeting":
        return '<span class="d-inline-block mdi mdi-clock text-yellow wdhs"></span>';
      case "wfh":
        return '<span class="d-inline-block mdi mdi-home text-blue wdhs"></span>';
      case "away":
        return '<span class="d-inline-block mdi mdi-run wdhs"></span>';
      default:
        return '<span class="d-inline-block rounded-circle p-1 mdi mdi-circle"></span>';
    }
  };

  const getStatusText = (liveStatus) => {
    switch (liveStatus) {
      case "auto":
        return "Active";
      case "custom":
        return "Custom";
      case "dnd":
        return "Do Not Disturb";
      case "meeting":
        return "Meeting";
      case "wfh":
        return "Work from Home";
      case "away":
        return "Away";
      default:
        return "Active";
    }
  };

  const handleStatusResponse = ({ status }) => {
    let userStElement = document.querySelector("#userSt");
    if (userStElement) {
      const icon = getStatusIcon(status?.liveStatus);
      const text = getStatusText(status?.liveStatus);
      userStElement.innerHTML = `${icon} ${text}`;
    }
  };

  useEffect(() => {
    if (socket) {
      socket?.emit("getMyStatus", {
        userId: user?._id,
        organizationId: user?.organization,
      });
      // socket.on("myStatusResponse", handleStatusResponse);
    }
  }, [user, socket]);

  return (
    <div className="dropdown useractive">
      <button
        className="dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        id="userSt"
      >
        <span className="d-inline-block rounded-circle p-1 mdi mdi-circle"></span>{" "}
        Active
      </button>

      <div className="dropdown-menu pt-0 mx-0 rounded-3 shadow overflow-hidden w-280px">
        <ul className="list-unstyled mb-0 dropspcelk">
          {statuses.map((status, index) => (
            <li key={index}>
              <a
                className="dropdown-item d-flex align-items-center gap-2 py-2"
                href="#"
                onClick={() =>
                  changeCurrent(status.action, status.icon, status.status)
                }
              >
                <span
                  className={`d-inline-block ${status.icon} ${status.color} rounded-circle wdhs`}
                ></span>{" "}
                {status.action}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default UserStatus;
