import React, { useEffect, useState } from "react";
import GroupItem from "./GroupItem";

const UserListPopup = ({
  isUserPopupOpen,
  setIsUserPopupOpen,
  searchActionUsersResponse,
  setSearchActionUsersResponse,
  SearchActionUser,
  getCurrentTime,
  selectedUserList,
  setSelectedUserList,
  forwardMessageToMultipleUser,
  handleSelectForward,
  groupList,
  selectedUsers,
  setSelectedUsers,
  filterGroup,
}) => {
  const tabs = [
    { name: "Users", count: null },
    { name: "Groups", count: null },
  ];
  const [tabValue, setTabValue] = useState("Users");
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {}, [selectedUserList, tabValue]);

  const handleTabClick = (value) => {
    setTabValue(value);
  };
  const renderProfilePicture = (participantsUser) => {
    const profilePicture = participantsUser?.otherInformation?.profilePicture;
    const firstNameInitial = participantsUser?.firstName?.charAt(0);
    const lastNameInitial = participantsUser?.lastName?.charAt(0);

    if (profilePicture) {
      return (
        <img
          className="img-fluid userimg"
          src={profilePicture}
          width="40"
          height="40"
          alt="user img"
          style={{
            border: `solid ${
              participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
            }`,
            borderWidth: "2px",
          }}
          loading="lazy"
        />
      );
    } else {
      return (
        <div
          className="placeholder-img"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#e0e0e0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `solid ${
              participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
            }`,
            borderWidth: "2px",
            color: "#555",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {`${firstNameInitial || ""}${lastNameInitial || ""}`}
        </div>
      );
    }
  };
  const isSelected = (userId, type) => {
    return selectedUsers.some(
      (user) => user.userOrGroupId === userId && user.type === type
    );
  };

  const searchedUserItems = searchActionUsersResponse.map((item, index) => (
    <label
      className="list-group-item d-flex justify-content-between border-0"
      key={index}
      htmlFor={`userBox${index}`}
    >
      <div className="d-flex align-items-center">
        <span className="img25">{renderProfilePicture(item)}</span>
        <div className="nm1">
          <div className="font-14 fw-500 color1a mb-0">
            {item?.firstName} {item?.lastName}
          </div>
          <small className="d-block text-body-secondary font-10">
            {getCurrentTime(item?.otherInformation?.lastSeen)}
          </small>
        </div>
      </div>{" "}
      <input
        id={`userBox${index}`}
        className="form-check-input flex-shrink-0"
        type="checkbox"
        defaultValue=""
        checked={isSelected(item?._id, "oneToOne")}
        onChange={(e) => handleSelectForward(e, item?._id, "oneToOne")}
      />
    </label>
  ));

  return (
    <>
      <div
        className="modal show userSerchNct1"
        style={{ display: "block" }}
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header flex-column pb-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setSelectedUsers([]);
                  setIsUserPopupOpen(!isUserPopupOpen);
                }}
              ></button>
              <div className="msg-search w-100 mx-0">
                <input
                  type="text"
                  className="form-control bg-none border-0"
                  id="inlineFormInputGroup"
                  placeholder="Search users"
                  aria-label="search"
                  onChange={(e) => {
                    SearchActionUser(e.target.value, tabValue);
                  }}
                />
                <button>
                  <i className="mdi mdi-magnify" />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                {tabs.map((tab, index) => (
                  <li className="nav-item" role="presentation" key={index}>
                    <button
                      className={`nav-link ${
                        tabValue === tab.name ? "active" : ""
                      }`}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "none",
                        border: "none",
                        marginRight: "20px",
                        position: "relative",
                      }}
                      id={`${tab.name.toLowerCase()}-tab`}
                      type="button"
                      role="tab"
                      aria-controls={tab.name.toLowerCase()}
                      aria-selected={tabValue === tab.name ? "true" : "false"}
                      onClick={() => {
                        handleTabClick(tab.name);
                      }}
                    >
                      {tab.name}
                      {tabValue === tab.name && (
                        <span
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            borderBottom: "2px solid #1c9dea",
                          }}
                        />
                      )}
                    </button>
                  </li>
                ))}
              </ul>

              <ul className="usserch forwordmsgs">
                <div className="fstRightGroup pb-0 border-0">
                  {}
                  <div className="pb-0 bg-body">
                    <div className="list-group border-0">
                      {tabValue === "Users" ? (
                        searchedUserItems
                      ) : (
                        <div className="chat-list">
                          {(filterGroup.length > 0
                            ? filterGroup
                            : groupList
                          ).map((group) => (
                            <GroupItem
                              key={group._id}
                              group={group}
                              isSelected={selectedGroups.includes(group.id)}
                              handleSelectForward={handleSelectForward}
                              selectedUsers={selectedUsers}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </ul>
            </div>
            <div className="modal-footer justity-content-center align-items-center flex-column">
              <div className="text-center mt-3 mb-3">
                <button
                  type="button"
                  className="btnblue"
                  onClick={() => {
                    forwardMessageToMultipleUser();
                  }}
                >
                  Send
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
      {}
    </>
  );
};

export default UserListPopup;
