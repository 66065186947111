import React, { useEffect, useRef } from 'react';
import LandingFooter from "./Layout/Footer"
import LandingHead from "./Layout/Head"
import HeaderNav from "./Layout/HeaderNav"

const TermsAndConditions=()=>{
  const sectionsRef = useRef([]);
  const listsRef = useRef([]);

  useEffect(() => {
    const sections = sectionsRef.current;
    const lists = listsRef.current;

    function activeLink(li) {
      lists.forEach((item) => item.classList.remove('active'));
      li.classList.add('active');
    }

    lists.forEach((item) =>
      item.addEventListener('click', function () {
        activeLink(this);
      })
    );

    function handleScroll() {
      let scrollPosition = window.scrollY + 300; 

      sections.forEach((section) => {
        let top = section.offsetTop;
        let id = section.getAttribute('id');

        if (scrollPosition >= top) {
          const target = document.querySelector(`[href='#${id}']`).parentElement;
          activeLink(target);
        }
      });
    }

    window.addEventListener('scroll', handleScroll);

    
    return () => {
      lists.forEach((item) =>
        item.removeEventListener('click', function () {
          activeLink(this);
        })
      );
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const sections = [
    {
      id: 'Introduction',
      title: 'Introduction',
      content: (
        <>
          <p>
            Welcome to SyncupTeams! These Terms and Conditions ("Terms") govern
            your use of the SyncupTeams Messenger Application ("App"), provided
            by Acadecraft Pvt. Ltd. By accessing or using the App, you agree to
            be bound by these Terms. If you do not agree to these Terms, please
            do not use the App.
          </p>
        </>
      ),
    },
    {
      id: 'Eligibility',
      title: 'Eligibility',
      content: (
        <>
          <div className="left50">
            <p>
              To use the App, you must be at least 18 years old and have the
              authority to enter into these Terms on behalf of yourself or the
              organization you represent.
            </p>
            <p>
              <span className="fw-600 d-block">
                1. Use of the AppAccount Registration:
              </span>
              You must register for an account to use the App. You agree to
              provide accurate and complete information during registration and
              to update your information as necessary.
            </p>
            <p>
              <span className="fw-600 d-block">2. User Responsibilities: </span>
              You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account. You agree to use the App in compliance with all
              applicable laws and not to use the App for any unlawful or
              unauthorized purposes.
            </p>
            <p>
              <span className="fw-600 d-block">3. Prohibited Activities:</span>
            </p>
            <p>
              <span className="fw-600 d-block">You agree not to:</span>
            </p>
            <ul>
              <li>Use the App to harass, abuse, or harm another person.</li>
              <li>
                Impersonate any person or entity or misrepresent your
                affiliation with any person or entity.
              </li>
              <li>
                Interfere with or disrupt the App, servers, or networks
                connected to the App.
              </li>
              <li>
                Upload or transmit any viruses, malware, or other harmful code.
              </li>
            </ul>
          </div>
        </>
      ),
    },
    {
      id: 'IntellectualProperty',
      title: 'Intellectual Property',
      content: (
        <>
          <div className="left50">
            <p>
              All content, features, and functionality of the App, including but
              not limited to text, graphics, logos, icons, images, and software,
              are the property of the Company or its licensors and are protected
              by intellectual property laws. You may not reproduce, distribute,
              or create derivative works of any content from the App without our
              prior written consent.
            </p>
          </div>
        </>
      ),
    },
    {
      id: 'Termination',
      title: 'Termination',
      content: (
        <p>
          We may terminate or suspend your access to the App, without prior
          notice or liability, for any reason, including if you breach these
          Terms. Upon termination, your right to use the App will immediately
          cease.
        </p>
      ),
    },
    {
      id: 'Limitationof',
      title: 'Limitation of Liability',
      content: (
        <div className="left50">
          <p>
            To the fullest extent permitted by law, the Company and its
            affiliates, officers, directors, employees, and agents will not be
            liable for any indirect, incidental, special, consequential, or
            punitive damages, including but not limited to loss of profits,
            data, use, goodwill, or other intangible losses, resulting from your
            use of the App.
          </p>
        </div>
      ),
    },
    {
      id: 'Changesto',
      title: 'Changes to the Terms',
      content: (
        <div className="left50">
          <p>
            We reserve the right to modify these Terms at any time. We will
            notify you of any changes by posting the new Terms on the App. Your
            continued use of the App after the effective date of the revised
            Terms constitutes your acceptance of the revised Terms.
          </p>
        </div>
      ),
    },
    {
      id: 'GoverningLaw',
      title: 'Governing Law',
      content: (
        <div className="left50">
          <p>
            These Terms are governed by and construed in accordance with the
            laws of [Your Jurisdiction], without regard to its conflict of law
            principles.
          </p>
        </div>
      ),
    },
    {
      id: 'ContactUs',
      title: 'Contact Us',
      content: (
        <div className="left50">
          <p>
            If you have any questions about these Terms, please contact us at
            [Your Contact Information].Privacy Policy
          </p>
        </div>
      ),
    },
  ];
    return (
        <>
  <title>Terms and Conditions</title>
  <LandingHead />
  <HeaderNav/>
  
        <div className="container">
      <div className="pvsplacysm">
        <div className="row">
        <div className="col-md-12 flhdnb border-bottom">
          <div className="text-center">
            <h2 className="color1a fw-600 d-flex justify-content-between font-40 align-items-center">
              Terms and Conditions{" "}
              <span>
                <img
                  src="assets/images/privacy-policy.svg"
                  className="img-fluid"
                  alt=""
                />
              </span>
            </h2>
          </div>
        </div>
          <div className="col-md-5 col-lg-4 ps-0 pe-0 mdnone">
            <nav className="navigation tbsactvNav">
              <div className="packdv active1">
                <ul>
                  {sections.map((section, index) => (
                    <li
                      key={section.id}
                      className="list"
                      ref={(el) => (listsRef.current[index] = el)}
                    >
                      <a href={`#${section.id}`}>
                        <span className="title">{section.title}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
          <div className="col-md-7 col-lg-8 bgsdpriceing ps-0">
            {sections.map((section, index) => (
              <div
                key={section.id}
                id={section.id}
                className="dpri samenv1"
                ref={(el) => (sectionsRef.current[index] = el)}
              >
                <h2 className="d-flex align-items-start gap-3">
                  {section.title}
                </h2>
                {section.content}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  <LandingFooter />
  
</>

    )
}
export default TermsAndConditions