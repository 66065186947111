import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { ChatBoxDefaultProps } from 'src/containers/chatbox-container/ChatBoxPropTypes';
import { CHATBOX_ACTIONS } from '../actions/ChatBoxActions';

const initialState = () => ({
    currentRecord: { ...ChatBoxDefaultProps },
    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case CHATBOX_ACTIONS.CHATBOX_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case CHATBOX_ACTIONS.CHATBOX_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case CHATBOX_ACTIONS.CHATBOX_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case CHATBOX_ACTIONS.CHATBOX_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case CHATBOX_ACTIONS.CHATBOX_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case CHATBOX_ACTIONS.CHATBOX_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case CHATBOX_ACTIONS.CHATBOX_RESET: {
            return {
                ...state,
                currentRecord: { ...ChatBoxDefaultProps },
            };
        }

        case CHATBOX_ACTIONS.UPDATE_CURRENT_RECOARD: {
            return {
                ...state,
                currentRecord: action.data,
            };
        }

        default:
            return state;
    }
};
