import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { AUTH_ACTIONS } from 'src/containers/auth-container/actions/AuthActions';
import { RolePermissionsDefaultProps } from '../PermissionPropTypes';
import { ROLE_PERMISSIONS_ACTIONS } from '../actions/RolePermissionsActions';

const initialState = () => ({
    currentRecord: { ...RolePermissionsDefaultProps },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case ROLE_PERMISSIONS_ACTIONS.ROLE_PERMISSIONS_RESET: {
            return {
                ...state,
                currentRecord: { ...RolePermissionsDefaultProps },
            };
        }

        
        case AUTH_ACTIONS.LOGOUT:
            return {
                ...initialState(),
            };
        default:
            return state;
    }
};
