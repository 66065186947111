export const GROUP_ACTIONS = {
    GROUP_SEARCH: 'GROUP_SEARCH',
    GROUP_SEARCH_SUCCESS: 'GROUP_SEARCH_SUCCESS',
    GROUP_SEARCH_ERROR: 'GROUP_SEARCH_ERROR',

    GROUP_UPSERT: 'GROUP_UPSERT',
    GROUP_UPSERT_SUCCESS: 'GROUP_UPSERT_SUCCESS',
    GROUP_UPSERT_ERROR: 'GROUP_UPSERT_ERROR',

    GROUP_EDIT: 'GROUP_EDIT',

    GROUP_RESET: 'GROUP_RESET',
};

export const groupResetAction = () => ({
    type: GROUP_ACTIONS.GROUP_RESET,
});

export const groupSearchAction = (payload) => ({
    type: GROUP_ACTIONS.GROUP_SEARCH,
    ...payload,
});

export const groupEditAction = (payload) => ({
    type: GROUP_ACTIONS.GROUP_EDIT,
    ...payload,
});

export const groupUpsertAction = (payload) => ({
    type: GROUP_ACTIONS.GROUP_UPSERT,
    ...payload,
});
export const groupUploadAction = (payload) => ({
    type: GROUP_ACTIONS.GROUP_UPLOAD,
    ...payload,
});

export default {
    GROUP_ACTIONS,
    groupSearchAction,
    groupUpsertAction,
    groupEditAction,
    groupUploadAction,
};
