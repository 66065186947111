export const DEPARTMENT_ACTIONS = {
    DEPARTMENT_SEARCH: 'DEPARTMENT_SEARCH',
    DEPARTMENT_SEARCH_SUCCESS: 'DEPARTMENT_SEARCH_SUCCESS',
    DEPARTMENT_SEARCH_ERROR: 'DEPARTMENT_SEARCH_ERROR',

    DEPARTMENT_UPSERT: 'DEPARTMENT_UPSERT',
    DEPARTMENT_UPSERT_SUCCESS: 'DEPARTMENT_UPSERT_SUCCESS',
    DEPARTMENT_UPSERT_ERROR: 'DEPARTMENT_UPSERT_ERROR',

    DEPARTMENT_EDIT: 'DEPARTMENT_EDIT',

    DEPARTMENT_RESET: 'DEPARTMENT_RESET',
};

export const departmentResetAction = () => ({
    type: DEPARTMENT_ACTIONS.DEPARTMENT_RESET,
});

export const departmentSearchAction = (payload) => ({
    type: DEPARTMENT_ACTIONS.DEPARTMENT_SEARCH,
    ...payload,
});

export const departmentEditAction = (payload) => ({
    type: DEPARTMENT_ACTIONS.DEPARTMENT_EDIT,
    ...payload,
});

export const departmentUpsertAction = (payload) => ({
    type: DEPARTMENT_ACTIONS.DEPARTMENT_UPSERT,
    ...payload,
});

export default {
    DEPARTMENT_ACTIONS,
    departmentSearchAction,
    departmentUpsertAction,
    departmentEditAction,
};
