import { useCallback } from "react";
import { useDispatch } from 'react-redux';


const PermissionSearchList=({search})=>{
    
    const dispatch = useDispatch();
    
    const editClick = useCallback((type,record) => {
        
        
        
        
        
        
        
        
        
        
        
        
        
    }, [
        dispatch,
        
    ]);
    return (
        <div className="">
   <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
      <div className="font-18 fw-bold color1a">{search.data.length} Location</div>
   </div>
   <div className="">
    {
        search.data.map((location,index)=>{
            return(
                <div className="card d-flex flex-row align-items-center px-3 py-2 my-2" key={index}>
                        <div className="l font-14 color1a fw-bold me-auto">{location.location}</div>
                        <div className="l font-14 color1a fw-bold me-auto">{location.countryName}</div>
                        <div className="r d-flex align-items-center ms-auto ruserDe">
                            {}
                            <div className="ruserDe3">
                            <button className="bg-none border-0 font-18 color1a" onClick={()=>{editClick("edit",location)}}>
                            <i className="mdi mdi-pencil-outline" />
                            </button>{" "}
                            </div>
                            <div className="ruserDe4">
                            <button className="bg-none border-0 font-18 color1a" onClick={()=>{editClick("delete",location)}}>
                            <i className="mdi mdi-delete" />
                            </button>{" "}
                            </div>
                        </div>
                    </div> 
            )
        })
    }
      

   </div>
</div>
    )
}

export default PermissionSearchList;