import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const HomePropTypes = PropTypes.shape({
    _id: PropTypes.string,
    field: PropTypes.string,
});

export const HomeDefaultProps = {
    name: '',    
    _id: undefined,    
};


export const HomeYupSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
});

export default {
    HomeDefaultProps,
    HomePropTypes,    
};
