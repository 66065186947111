import React, { useRef, useEffect } from 'react';
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

function Fancybox({ options = {}, delegate = '[data-fancybox]', children }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      
    };
  }, [delegate, options]);

  return <div ref={containerRef}>{children}</div>;
}

export default Fancybox;
