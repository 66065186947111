export const NOTIFICATION_ACTIONS = {
    NOTIFICATION_SEARCH: 'NOTIFICATION_SEARCH',
    NOTIFICATION_SEARCH_SUCCESS: 'NOTIFICATION_SEARCH_SUCCESS',
    NOTIFICATION_SEARCH_ERROR: 'NOTIFICATION_SEARCH_ERROR',

    NOTIFICATION_UPSERT: 'NOTIFICATION_UPSERT',
    NOTIFICATION_UPSERT_SUCCESS: 'NOTIFICATION_UPSERT_SUCCESS',
    NOTIFICATION_UPSERT_ERROR: 'NOTIFICATION_UPSERT_ERROR',

    NOTIFICATION_EDIT: 'NOTIFICATION_EDIT',

    NOTIFICATION_RESET: 'NOTIFICATION_RESET',
};

export const notificationResetAction = () => ({
    type: NOTIFICATION_ACTIONS.NOTIFICATION_RESET,
});

export const notificationSearchAction = (payload) => ({
    type: NOTIFICATION_ACTIONS.NOTIFICATION_SEARCH,
    ...payload,
});

export const notificationEditAction = (payload) => ({
    type: NOTIFICATION_ACTIONS.NOTIFICATION_EDIT,
    ...payload,
});

export const notificationUpsertAction = (payload) => ({
    type: NOTIFICATION_ACTIONS.NOTIFICATION_UPSERT,
    ...payload,
});

export default {
    NOTIFICATION_ACTIONS,
    notificationSearchAction,
    notificationUpsertAction,
    notificationEditAction,
};
