import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const rolePermissionsSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/permissions/search`;
    return postRequest({
        url,
        data,
    });
};

export const rolePermissionsCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/permissions/upsert`;
    return postRequest({
        url,
        data,
    });
};

export const rolePermissionsUpdateAPI = function ({
    rolePermissionsId,
    data,
}) {
    const url = `${config.baseUrl}admin/permissions/${rolePermissionsId}`;
    return putRequest({
        url,
        data,
    });
};
