import React, { useEffect, useState } from "react";
import { useNotification } from "src/components/Notifination/ToastNotification";
import {
  addTodo,
  convertTodoFormat,
  deleteTodo,
  getUserTodoList,
  markTodoItemComplete,
  updateTodo,
} from "src/containers/home-container/apis/TodoListapis";
import useDebounce from "src/hooks/useDebounce";
import AddTodo from "./Components/AddTodo";
import Todo from "./Components/Todo";
import moment from "moment";
import OnlyDateRangePicker from "src/components/OnlyDateRangePicker";

const initialTodoItem = {
  name: {
    value: "",
    error: "",
  },
  description: {
    value: "",
    error: "",
  },
  assignedDate: {
    value: "",
    error: "",
  },
  assignedTime: {
    value: "",
    error: "",
  },
  dueDate: {
    value: "",
    error: "",
  },
  dueTime: {
    value: "",
    error: "",
  },
};

const initialTodo = {
  title: {
    value: "",
    error: "",
  },
  items: [
    {
      name: {
        value: "",
        error: "",
      },
      description: {
        value: "",
        error: "",
      },
      assignedDate: {
        value: "",
        error: "",
      },
      assignedTime: {
        value: "",
        error: "",
      },
      dueDate: {
        value: "",
        error: "",
      },
      dueTime: {
        value: "",
        error: "",
      },
    },
  ],
  files: [],
};

const Todolist = ({ toggleRightBarDrawer, openedRightBarDrawer }) => {
  const tabs = ["All", "Finished", "Active", "Pending"];
  const [todo, setTodo] = useState(initialTodo);
  const [tabValue, setTabValue] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userTodos, setUserTodos] = useState([]);
  const { openSuccessNotification, openErrorNotification } = useNotification();
  const debouncedSearch = useDebounce(searchText, 500);
  const [isEditTable, setIsEditTable] = useState(false);
  const [editableTodoId, setEditableTodoId] = useState(null);

  useEffect(() => {
    if (
      moment(startDate, "YYYY-MM-DD", true).isValid() &&
      moment(endDate, "YYYY-MM-DD", true).isValid() &&
      openedRightBarDrawer === "todo"
    ) {
      fetchUserTodos();
    }
    
  }, [openedRightBarDrawer, startDate, endDate]);

  useEffect(() => {
    if (
      moment(startDate, "YYYY-MM-DD", true).isValid() &&
      moment(endDate, "YYYY-MM-DD", true).isValid() &&
      openedRightBarDrawer === "todo"
    ) {
    fetchUserTodos();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (
      moment(startDate, "YYYY-MM-DD", true).isValid() &&
      moment(endDate, "YYYY-MM-DD", true).isValid() &&
      openedRightBarDrawer === "todo"
    ) {
    fetchUserTodos();
    }
  }, [tabValue]);

  const fetchUserTodos = async () => {
    try {
      const { data } = await getUserTodoList(
        tabValue,
        searchText,
        startDate,
        endDate
      );
      setUserTodos(data);
    } catch (error) {
      openErrorNotification("Error while fetching Notes.");
    }
  };

  const resetState = () => {
    setTabValue("All");
    setSearchText("");
    setStartDate("");
    setEndDate("");
    setUserTodos([]);
    setIsEditTable(false);
    setTodo({
      title: {
        value: "",
        error: "",
      },
      items: [initialTodoItem],
      files: [],
    });
    setEditableTodoId(null);
    toggleRightBarDrawer("todo");
  };

  function todo_close() {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myTodo").style.display = "none";
    resetState();
  }

  function todo_closeEdit() {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myTodo").style.display = "none";
  }
  function todoCreate_open() {
    document.getElementById("main").style.marginRight = "265px";
    document.getElementById("myTodoCreate").style.width = "265px";
    document.getElementById("myTodoCreate").style.display = "block";
  }

  function todoCreate_close() {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myTodoCreate").style.display = "none";
  }

  function addnewtask_close() {
    document.getElementById("main").style.marginRight = "265px";
    document.getElementById("myTodoCreate").style.display = "none";
    document.getElementById("myTodo").style.display = "block";
    setTodo({
      title: {
        value: "",
        error: "",
      },
      items: [initialTodoItem],
      files: [],
    });
    fetchUserTodos();
  }

 
  function combinedFunctionEdit() {
    todo_closeEdit();
    todoCreate_open();
  }

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const handleTabClick = (event) => {
    const tab = event.target.name;
    setTabValue(tab);
  };

  const Tabs = () => {
    return (
      <ul className="nav nav-tabs filetabs" id="myTab2" role="tablist">
        {tabs.map((tab, index) => (
          <li className="nav-item" key={index} role="presentation">
            <button
              className={`nav-link ${index === 0 ? "active" : ""}`}
              id={`${tab.toLowerCase()}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#${tab.toLowerCase()}`}
              type="button"
              role="tab"
              name={tab}
              aria-controls={tab.toLowerCase()}
              aria-selected={index === 0 ? "true" : "false"}
              onClick={handleTabClick}
            >
              {tab}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const handleMarkTodoItemComplete = async (todoId, todoItemId) => {
    try {
      await markTodoItemComplete(todoId, todoItemId);
      await fetchUserTodos();
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };
  const handleDelete = async (todoId) => {
    try {
      await deleteTodo(todoId);
      await fetchUserTodos();
      openSuccessNotification(`Deleted Successfully.`);
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const handleTodoEdit = (todo) => {
    setIsEditTable(true);
    setEditableTodoId(todo?._id);
    const cleanTodo = {
      title: {
        value: todo.title,
        error: "",
      },
      items: todo.items.map((item) => ({
        name: {
          value: item.name,
          error: "",
        },
        description: {
          value: item.description,
          error: "",
        },
        assignedDate: {
          value: item.assignedDate,
          error: "",
        },
        assignedTime: {
          value: item.assignedTime,
          error: "",
        },
        dueDate: {
          value: item.dueDate,
          error: "",
        },
        dueTime: {
          value: item.dueTime,
          error: "",
        },
      })),
      files: [...todo.files],
    };
    setTodo(cleanTodo);
    combinedFunctionEdit();
  };

  const handleDateRangeChange = (start, end) => {
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
  };

  const handleChange = (e, index, field) => {
    const newTodo = { ...todo };
    newTodo.items[index][field].value = e.target.value;
    newTodo.items[index][field].error = "";
    setTodo(newTodo);
  };
  const handleDateTimeRangeChange = (e, index, field) => {
    const newTodo = { ...todo };
    newTodo.items[index][field].value = moment(e).format("YYYY-MM-DD");
    newTodo.items[index][field].error = "";
    setTodo(newTodo);
  };

  const handleAddItem = () => {
    if (validateTodo(todo)) {
      setTodo((prevTodo) => ({
        ...prevTodo,
        items: [
          ...prevTodo.items,
          {
            name: { value: "", error: "" },
            description: { value: "", error: "" },
            assignedDate: { value: "", error: "" },
            assignedTime: { value: "", error: "" },
            dueDate: { value: "", error: "" },
            dueTime: { value: "", error: "" },
          },
        ],
      }));
    }
  };

  const handleRemoveItem = (index) => {
    const newTodo = { ...todo };
    newTodo.items.splice(index, 1);
    setTodo(newTodo);
  };

  const validateTodo = (todo) => {
    let isValid = true;
    let newTodo = { ...todo };
    if (!todo.title.value?.trim()) {
      newTodo.title.error = "Title is required";
      isValid = false;
    }

    todo.items.forEach((item, index) => {
      if (!item.name.value?.trim()) {
        newTodo.items[index].name.error = "Name is required";
        isValid = false;
      }
      if (!item.description.value?.trim()) {
        newTodo.items[index].description.error = "Description is required";
        isValid = false;
      }

      if (!item.assignedDate.value || !item.assignedTime.value) {
        newTodo.items[index].assignedDate.error = "Assigned date is required";
        newTodo.items[index].assignedTime.error = "Assigned time is required";
        isValid = false;
      }

      if (!item.dueDate.value || !item.dueTime.value) {
        newTodo.items[index].dueDate.error = "Due date is required";
        newTodo.items[index].dueTime.error = "Due time is required";
        isValid = false;
      }

      const assignedDateTime = new Date(
        `${item.assignedDate.value}T${item.assignedTime.value}`
      ).getTime();
      const dueDateTime = new Date(
        `${item.dueDate.value}T${item.dueTime.value}`
      ).getTime();

      if (assignedDateTime >= dueDateTime) {
        newTodo.items[index].assignedDate.error =
          "Assigned date must be before due date";
        newTodo.items[index].assignedTime.error =
          "Assigned time must be before due time";
        newTodo.items[index].dueDate.error =
          "Due date must be after assigned date";
        newTodo.items[index].dueTime.error =
          "Due time must be after assigned time";
        isValid = false;
      }

      if (
        assignedDateTime === dueDateTime &&
        item.assignedTime.value >= item.dueTime.value
      ) {
        newTodo.items[index].dueTime.error =
          "Due time must be after assigned time";
        isValid = false;
      }
    });

    setTodo(newTodo);
    return isValid;
  };

  const handleSaveTodo = async () => {
    try {
      const newFormattedTodo = convertTodoFormat(todo);
      if (validateTodo(todo)) {
        const res = isEditTable
          ? await updateTodo(newFormattedTodo, editableTodoId)
          : await addTodo(newFormattedTodo);
        if (res) {
          const successMessage = isEditTable
            ? "Todo Edited Successfully"
            : "Todo Saved Successfully";
          openSuccessNotification(successMessage);
          addnewtask_close();
          setTodo({
            title: {
              value: "",
              error: "",
            },
            items: [
              {
                name: {
                  value: "",
                  error: "",
                },
                description: {
                  value: "",
                  error: "",
                },
                assignedDate: {
                  value: "",
                  error: "",
                },
                assignedTime: {
                  value: "",
                  error: "",
                },
                dueDate: {
                  value: "",
                  error: "",
                },
                dueTime: {
                  value: "",
                  error: "",
                },
              },
            ],
            files: [],
          });
        } else {
          openErrorNotification("Error While Adding todo");
        }
      }
    } catch (error) {
      openErrorNotification("Error While Adding todo");
    }
  };

  const handleTitleChange = (e) => {
    const newTodo = { ...todo };
    newTodo.title.value = e.target.value;
    newTodo.title.error = "";
    setTodo(newTodo);
  };
  function formatDateIntoNew(dateStr) {
    const date = new Date(dateStr);
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  }

  return (
    <>
      <div
        className="chat-sidebar chat-animate-right"
        style={{ display: "none", right: 109 }}
        id="myTodo"
      >
        <div className="modal-dialog-scrollable">
          <div className="modal-content">
            <div className="slide-header">
              <div className="HeadSlidName d-flex justify-content-between align-items-center">
                <div className="slidName">To-do List</div>
                <button className="" onClick={todo_close}>
                  ×
                </button>
              </div>
              <div className="msg-search mx-0">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="Search Task"
                  aria-label="search"
                  value={searchText}
                  onChange={handleSearch}
                />
                <button>
                  <i className="mdi mdi-magnify" />
                </button>
              </div>
              <div className="onlydaterange fonsdfrnt">
                <OnlyDateRangePicker
                  onDateRangeChange={handleDateRangeChange}
                  position={"left"}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center createnotes font-14 color1a">
                Create New Task
                <button type="button" onClick={todoCreate_open}>
                  <i className="mdi mdi-plus" />
                </button>
              </div>
              {Tabs()}
            </div>
            <Todo
              userTodos={userTodos}
              handleTodoEdit={handleTodoEdit}
              handleMarkTodoItemComplete={handleMarkTodoItemComplete}
              handleDelete={handleDelete}
            />
          </div>
        </div>
      </div>
      <AddTodo
        todoCreate_close={todoCreate_close}
        addnewtask_close={addnewtask_close}
        todo={todo}
        handleTitleChange={handleTitleChange}
        handleRemoveItem={handleRemoveItem}
        handleChange={handleChange}
        formatDateIntoNew={formatDateIntoNew}
        handleAddItem={handleAddItem}
        handleSaveTodo={handleSaveTodo}
        handleDateTimeRangeChange={handleDateTimeRangeChange}
      />
    </>
  );
};
export default Todolist;
