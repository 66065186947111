import React, { useEffect, useState } from "react";
import { getLastSeenString } from "../profile-container/ProfileDetailsutils";
import { pinMessageList } from "../home-container/apis/Chatboxapis";
import { useSelector, useDispatch } from "react-redux";
import {
  currentGroup,
  setUserMessages
} from "../home-container/actions/ChatHistoryAction";

const PinViewPopUp = ({
  chatRoomId,
  setIsPinDrawerOpen,
  isPinDrawerOpen,
  userDetails,
  socket,
  setRevelingConMessage,
  getCurrentTime,
}) => {

  const dispatch = useDispatch();

  const [pinnedMessage, setPinnedMessage] = useState([]);
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const user = useSelector((state) => state.auth.user);
  
  const formatMessage = (message) => {
    const formattedMessage = {
      type:
        user?._id !== "" && user?._id === message?.senderId
          ? "reply"
          : "sender",
      time: getCurrentTime(message?.timestamp),
      content: message?.message,
      messageHtml: message?.messageHtml,
      reactions: message?.reactions,
      id: message?._id,
      pinned: message?.isPinned,
      read: message?.read,
      seen: message?.seen,
      delivered: message?.delivered,
      timestamp: message?.timestamp,
      userAgentSent: message?.userAgentSent,
      userAgentSeen: message?.userAgentSeen,
      parentConversation: message?.parentConversation,
      replyMediaDetails: message?.replyMediaDetails,
      replyToMedia: message?.replyToMedia,
      messageType: message?.messageType,
      filesData: message?.filesData,
      isText: message?.isText,
      isImage: message?.isImage,
      isAudio: message?.isAudio,
      isVideo: message?.isVideo,
      isDocument: message?.isDocument,
      isLink: message?.isLink,
      isCode: message?.isCode,
      isContact: message?.isContact,
      isLocation: message?.isLocation,
      senderDetails: message?.senderDetails,
      organizationId: message?.organizationId,
      groupId: message?.groupId ?? "",
      onetooneId: message?.onetooneId ?? "",
      contactDetails: message?.contactDetails,
      isNotification: message?.isNotification,
      repliedToConversation: message?.repliedToConversation,
      deliveredTo: message?.deliveredTo,
      readBy: message?.readBy,
      deleteForMe: message?.deleteForMe,
      deleteForAll: message?.deleteForAll,
    };
    return formattedMessage;
  };

  const profile_close = () => {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myChatPinMessages").style.display = "none";
    setIsPinDrawerOpen(!isPinDrawerOpen);
  };

  const getPinMessageList = async () => {
    try {
      let { data } = await pinMessageList(chatRoomId, user?._id);
      setPinnedMessage(data);
    } catch (error) {}
  };

  useEffect(() => {
    getPinMessageList();
  }, [isPinDrawerOpen]);

  const handleConList = ({ result }) => {
    // console.log("handleConList",result)
    setTimeout(() => {
      dispatch(setUserMessages({ userMessages: result.map(formatMessage), unReadMessageCounter: null }));
    }, 2000);
  };

  const handleRevealInConversation = (msg) => {
    setRevelingConMessage(msg);
    const msgId = msg?._id;
    const roomId = msg?.onetooneId || msg?.groupId;
    socket.emit("revelPinConversation", {
      userId: user?._id,
      msgId,
      roomId,
      authToken,
    });
    socket.on("revelPinConversationResponse", handleConList);
  };

  return (
    <div
      className="chat-sidebar chat-animate-right"
      style={{ display: "none", right: "109px" }}
      id="myChatPinMessages"
    >
      <div className="modal-dialog-scrollable">
        <div className="modal-content">
          <div className="slide-header">
            <div className="HeadSlidName d-flex justify-content-between align-items-center">
              <div className="slidName">Pinned Messages </div>
              <button
                className=""
                onClick={() => {
                  profile_close();
                }}
              >
                ×
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="text-center profText" style={{ display: "none" }}>
              <div
                className="profImg"
                style={{
                  backgroundImage: `url(${
                    userDetails?.profilePicture || "./assets/images/user.svg"
                  })`,
                }}
              ></div>
              <div className="LastSeen font-12 color80 pt-2 pb-1">
                {getLastSeenString(userDetails?.lastSeen) === "Online"
                  ? "Online"
                  : "Last seen: " + getLastSeenString(userDetails?.lastSeen)}
              </div>
              <div className="userName font-18 color1a">
                {userDetails?.firstName + " " + userDetails?.lastName}
              </div>
              <div className="userdec font-14 colorb">
                {userDetails?.designation}
              </div>
              <div className="userprd font-12 color80 pt-1">
                {userDetails?.department}
              </div>
            </div>
            <div className="userdetails">
              <div className="user-group border-0">
                <h3 className="font-14 color80">
                  <span className="" />
                  <i className="mdi mdi-pin-outline"></i> Message list
                </h3>
                {pinnedMessage &&
                  pinnedMessage?.message &&
                  pinnedMessage?.message.map((item, index) => (
                    <div
                      key={index}
                      className="user-g font-14 color1a fw-bold py-1"
                      onClick={() => {
                        handleRevealInConversation(item);
                      }}
                    >
                      <span
                        className="imggrp"
                        style={{
                          backgroundImage: `url(${item?.senderDetails?.otherInformation?.profilePicture})`,
                        }}
                      />{" "}
                      {item?.messageType === "text" ? (
                        <>{item?.message}</>
                      ) : (
                        <>
                          {item?.filesData.map((file, ind) => (
                            <a key={ind} href={file.url}>
                              {file.title}
                            </a>
                          ))}
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PinViewPopUp;
