import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNotification } from "src/components/Notifination/ToastNotification";
import useDebounce from "src/hooks/useDebounce";
import { convertTimestampToDdMmYyyy } from "src/utils/utils";
import {
  getUserNotificationList,
  userNotificationMarkAsRead,
} from "../home-container/apis/notificationapis";
import OnlyDateRangePicker from "src/components/OnlyDateRangePicker";
import moment from "moment";
const notificationSound = new Audio("./assets/media/notification.mp3");

const NotificationPanel = ({
  toggleDrawer,
  setNoticeNotification,
  socket,
  openedDrawer,
}) => {
  const [notice, setNotice] = useState({ counts: 0, notices: [] });
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const { openErrorNotification } = useNotification();
  const debouncedSearch = useDebounce(searchText, 500);
  const [hasMore, setHasMore] = useState(true);
  const [remainingCounts, setRemainingCounts] = useState(0);
  const observer = useRef();
  const lastNotificationRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );
  const resetStates = () => {
    setNotice({ counts: 0, notices: [] });
    setSearchText("");
    setStartDate("");
    setEndDate("");
    setPage(0);
    setLimit(5);
    setNoticeNotification({});
  };
  const AllNoti_close = () => {
    resetStates();
    toggleDrawer();
  };
  function showNoti_open() {
    document.getElementById("main").style.marginRight = "0";
    let AllNotifications = document.querySelector("#AllNotifications")
    let chatBoxModal = document.querySelector("#chatBoxModal")
    let showNotifctn = document.querySelector("#showNotifctn")
    let notificationTextshow = document.querySelector("#notificationTextshow")
    if (chatBoxModal) {
      chatBoxModal.style.display = "none";
    }
    if (AllNotifications) {
      AllNotifications.style.width = "100%";
    }
    if (showNotifctn) {
      showNotifctn.style.display = "block";
    }
    if (notificationTextshow) {
      notificationTextshow.style.display = "block";
    }
    setPage(() => 0);
  }

  const showNotification = (title, body) => {
    return new Notification(title, { body });
  };
  const handleNewNotice = (data) => {
    const [
      notices,
      fetchedNotices,
      totalNotices,
      remainingNotices,
      information,
    ] = data;
    setNotice({ counts: totalNotices, notices: notices });
    setNoticeNotification(notices[0]);
    setRemainingCounts(remainingNotices);
    showNotification(
      information.title,
      `${information?.description?.slice(0, 30)}...`
    );
    playNotificationSound();
  };

  const playNotificationSound = () => {
    notificationSound.play();
  };



  useEffect(() => {
    if (
      moment(startDate, "YYYY-MM-DD", true).isValid() &&
      moment(endDate, "YYYY-MM-DD", true).isValid() &&
      openedDrawer === "notification"
    ) {
      fetchNoticeNotification();
    }
  }, [openedDrawer, startDate, endDate]);

  useEffect(() => {
    if (
      moment(startDate, "YYYY-MM-DD", true).isValid() &&
      moment(endDate, "YYYY-MM-DD", true).isValid() &&
      openedDrawer === "notification"
    ) {
      fetchNoticeNotification();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (remainingCounts === 0) {
      return;
    } else {
      if (
        moment(startDate, "YYYY-MM-DD", true).isValid() &&
        moment(endDate, "YYYY-MM-DD", true).isValid() &&
        openedDrawer === "notification"
      ) {
        fetchMoreNotifications();
      }
    }
  }, [page]);

  const fetchNoticeNotification = async (pageNo = false) => {
    try {
      const { data } = await getUserNotificationList(
        searchText,
        startDate,
        endDate,
        pageNo ? 0 : page,
        limit
      );
      const [notices, fetchedNotices, totalNotices, remainingNotices] = data;
      setNotice({ counts: totalNotices, notices: notices });
      setNoticeNotification(notices[0]);
      setRemainingCounts(remainingNotices);
    } catch (error) {
      openErrorNotification("Error fetching notifications.");
    }
  };

  const fetchMoreNotifications = async () => {
    try {
      const { data } = await getUserNotificationList(
        searchText,
        startDate,
        endDate,
        page,
        limit,
        authToken
      );
      const [notices, fetchedNotices, totalNotices, remainingNotices] = data;
      if (notices?.length === 0) {
        setHasMore(false);
        return;
      }
      setNotice((prevNotices) => ({
        counts: totalNotices,
        notices: [...prevNotices.notices, ...notices],
      }));
      setNoticeNotification(notices[0]);
      setRemainingCounts(remainingNotices);
    } catch (error) {
      openErrorNotification("Error fetching notifications.");
    }
  };

  const handleClickNotification = async (notification) => {
    setNoticeNotification(notification);
    showNoti_open()
    try {
      if (notification?.notificationHistory?.status === "Unread") {
        await userNotificationMarkAsRead(
          notification?._id,
          notification?.notificationHistory?._id
        );
        socket.emit("getUnreadCounts");
        fetchNoticeNotification(true);
      } else {
        return;
      }
    } catch (error) {}
  };
  const getNotificationList = (notification, index) => {
    return (
      
      <a
        href="#"
        className="d-flex align-items-start"
        onClick={() => {
          handleClickNotification(notification);
        }}
        key={notification?._id}
        ref={notice?.notices?.length === index + 1 ? lastNotificationRef : null}
      >
        <div className="flex-shrink-0 position-relative">
          <img
            className="img-fluid userimg"
            src={
              notification?.user?.otherInformation?.profilePicture ??
              "assets/images/faces/face1.jpg"
            }
            width={45}
            height={45}
            alt="user img"
          />
        </div>
        <div className="flex-grow-1 ms-3" style={{}}>
          <div className="align-items-center justify-content-between">
            <div className="font-14 color80 onelinetext">
              {notification?.user?.otherInformation?.organization ?? "NA"}
            </div>
            <h3 className="font-16 fw-bold color1a">{notification?.title} </h3>
            <div className="font-14 color80 twolinetext">
              {notification?.description?.slice(0, 80)}...
            </div>
            <div className="clock font-14 textb3">
              <i className="mdi mdi-clock-outline" />{" "}
              {convertTimestampToDdMmYyyy(notification?.noticeDay)}{" "}
              {notification?.noticeTime}
            </div>
          </div>
        </div>
      </a>
    );
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const handleDateRangeChange = (start, end) => {
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
  };

  const showNoNotification = () => {
    return (
      <div className="text-center bgFE m-3" id="notiyetLeft">
        <img
          src="assets/images/notifications/bell-icon.svg"
          className="img-fluid"
          alt=""
        />
        <div className="">
          <h2 className="font-24 fw-bold color1a mt-5">No notifications yet</h2>
          <p className="font-14 fw-500 color80">
            you have no notifications right now.{" "}
            <span className="d-block"> Come back later</span>
          </p>
        </div>
      </div>
    );
  };
  return (
    <div
      className="chat-sidebar chat-animate-left"
      style={{ display: "block" }}
      id="AllNotifications"
    >
      <div className="modal-dialog-scrollable">
        <div className="modal-content">
          <div className="slide-header">
            <div className="HeadSlidName d-flex justify-content-between align-items-center">
              <div className="slidName font-24 color1a">
                Notification{" "}
                <span className="smdia font-14 color1a d-block fw-normal">
                  List of notification
                </span>
              </div>
              <button className="" onClick={AllNoti_close}>
                ×
              </button>
            </div>
          </div>
          <div className="modal-body p-0 settinghndl">
            <div className="showNoti chat-list" id="showNotifctn">
              <div className="msg-search mx-3">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="Search"
                  aria-label="search"
                  value={searchText}
                  onChange={handleSearch}
                />
                <button className="pe-3">
                  <i className="mdi mdi-magnify"></i>
                </button>
              </div>
              <div className="onlydaterange mb-2">
                <OnlyDateRangePicker
                  onDateRangeChange={handleDateRangeChange}
                  position={"right"}
                />
              </div>
              {notice?.notices?.length <=0 ? showNoNotification() :notice &&
                notice?.counts > 0 &&
                notice?.notices?.map((info, index) =>
                  getNotificationList(info, index)
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPanel;
