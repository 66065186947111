import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import React from "react";
import Giphy from "src/libs/Giphy";

const ViewGiphy = ({toggleDrawerGiphy, anchorGiphy, handleGiphySend}) => {
  return (
    <Box className="PinMessages">
      <div className="d-flex justify-content-between align-items-center">
        <h4>Giphy Search:</h4>
        <Button color="error" classes="btn-danger" onClick={toggleDrawerGiphy(anchorGiphy, false)}>
          x
        </Button>
      </div>
      <List>
        <Giphy handleGiphySend={handleGiphySend}/>
      </List>
    </Box>
  );
};

export default ViewGiphy;
