import React from "react";

const Rightbar = ({ toggleRightBarDrawer, openedRightBarDrawer }) => {
  const notes_open = () => {
    const handleDisplay = () => {
      const mainElement = document.querySelector("#main");
      const myNotesElement = document.querySelector("#myNotes");

      if (mainElement) {
        mainElement.style.marginRight = "265px";
      }

      if (myNotesElement) {
        myNotesElement.style.width = "265px";
        myNotesElement.style.display = "block";
      }
    };

    if (openedRightBarDrawer === "notes") {
      handleDisplay();
    } else {
      setTimeout(() => {
        handleDisplay();
      }, 300);
    }
    toggleRightBarDrawer("notes");
  };
  const all_Media_open = () => {
    const handleDisplay = () => {
      const mainElement = document.querySelector("#main");
      const myMediaElement = document.querySelector("#myMediaStorage");
      if (mainElement) {
        mainElement.style.marginRight = "265px";
      }
      if (myMediaElement) {
        myMediaElement.style.width = "265px";
        myMediaElement.style.display = "block";
      }
    };

    if (openedRightBarDrawer === "appMedia") {
      handleDisplay();
    } else {
      setTimeout(() => {
        handleDisplay();
      }, 100);
    }
    toggleRightBarDrawer("appMedia");
  };

  const todo_open = () => {
    const handleDisplay = () => {
      document.getElementById("main").style.marginRight = "265px";
      document.getElementById("myTodo").style.width = "265px";
      document.getElementById("myTodo").style.display = "block";
    };
    if (openedRightBarDrawer === "todo") {
      handleDisplay();
    } else {
      setTimeout(() => {
        handleDisplay();
      }, 0);
    }
    toggleRightBarDrawer("todo");
  };

  const reminders_open = () => {
    const handleDisplay = () => {
      document.getElementById("main").style.marginRight = "265px";
      document.getElementById("myReminders").style.width = "265px";
      document.getElementById("myReminders").style.display = "block";
    };
    if (openedRightBarDrawer === "reminder") {
      handleDisplay();
    } else {
      setTimeout(() => {
        handleDisplay();
      }, 0);
    }
    toggleRightBarDrawer("reminder");
  };
  return (
    <>
      <div
        className="d-flex flex-column flex-shrink-0 bg-body-tertiary rightbar border-left"
        style={{ width: "110px" }}
      >
        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          <li className="nav-item">
            <a
              href="#"
              className="nav-link active border-bottom rounded-0"
              aria-current="page"
              onClick={() => {
                all_Media_open();
              }}
            >
              <img src="assets/images/file.svg" className="img-fluid" alt="" />
              File
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#"
              className="nav-link active border-bottom rounded-0"
              aria-current="page"
              onClick={() => {
                notes_open();
              }}
            >
              <img src="assets/images/notes.svg" className="img-fluid" alt="" />{" "}
              Notes
            </a>
          </li>

          <li>
            <a
              href="#"
              className="nav-link border-bottom rounded-0"
              aria-current="page"
              onClick={() => {
                todo_open();
              }}
            >
              <img src="assets/images/todo.svg" className="img-fluid" alt="" />{" "}
              Todo
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Rightbar;
