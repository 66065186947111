
const LandingHead=()=>{
    return(
        <>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link
          rel="stylesheet"
          href="assets/css/mdi/css/materialdesignicons.min.css"
        />
        <link rel="stylesheet" type="text/css" href="assets/font/fonts.css" />
        <link href="assets/css/owl.carousel.min.css" rel="stylesheet" />
        <link href="assets/css/owl.theme.default.min.css" rel="stylesheet" />
        <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
  />
        <link rel="stylesheet" href="assets/css/style.css" />
        <link
          rel="preload"
          href="/assets/font/Metropolis-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/assets/font/Metropolis-Black.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/assets/font/Metropolis-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/assets/font/Metropolis-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/assets/font/Metropolis-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n @font-face {\n    font-family: 'Metropolis';\n    src: url('/assets/font/Metropolis-Bold.woff2') format('woff2'),\n        url('/assets/font/Metropolis-Bold.woff') format('woff');\n    font-weight: bold;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Metropolis';\n    src: url('/assets/font/Metropolis-Black.woff2') format('woff2'),\n        url('/assets/font/Metropolis-Black.woff') format('woff');\n    font-weight: 900;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Metropolis';\n    src: url('/assets/font/Metropolis-Regular.woff2') format('woff2'),\n        url('/assets/font/Metropolis-Regular.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Metropolis';\n    src: url('/assets/font/Metropolis-Medium.woff2') format('woff2'),\n        url('/assets/font/Metropolis-Medium.woff') format('woff');\n    font-weight: 500;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'Metropolis';\n    src: url('/assets/font/Metropolis-Light.woff2') format('woff2'),\n        url('/assets/font/Metropolis-Light.woff') format('woff');\n    font-weight: 300;\n    font-style: normal;\n    font-display: swap;\n} \n"
          }}
        /> 
      </>
    )
}
export default LandingHead

