export const ROLE_ACTIONS = {
    ROLE_SEARCH: 'ROLE_SEARCH',
    ROLE_SEARCH_SUCCESS: 'ROLE_SEARCH_SUCCESS',
    ROLE_SEARCH_ERROR: 'ROLE_SEARCH_ERROR',

    ROLE_UPSERT: 'ROLE_UPSERT',
    ROLE_UPSERT_SUCCESS: 'ROLE_UPSERT_SUCCESS',
    ROLE_UPSERT_ERROR: 'ROLE_UPSERT_ERROR',

    ROLE_EDIT: 'ROLE_EDIT',

    ROLE_RESET: 'ROLE_RESET',
};

export const roleResetAction = () => ({
    type: ROLE_ACTIONS.ROLE_RESET,
});

export const roleSearchAction = (payload) => ({
    type: ROLE_ACTIONS.ROLE_SEARCH,
    ...payload,
});

export const roleEditAction = (payload) => ({
    type: ROLE_ACTIONS.ROLE_EDIT,
    ...payload,
});

export const roleUpsertAction = (payload) => ({
    type: ROLE_ACTIONS.ROLE_UPSERT,
    ...payload,
});

export default {
    ROLE_ACTIONS,
    roleSearchAction,
    roleUpsertAction,
    roleEditAction,
};
