import React, { useCallback } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Paper } from "@mui/material";
import { getMediaSlides } from "../home-container/apis/Chatboxapis";
import { useNotification } from "src/components/Notifination/ToastNotification";
import { makeUniqueById } from "src/utils/utils";

const Carousel = ({ slideUrls = [], handleClose, img, setSlider }) => {
  const { openErrorNotification } = useNotification();

  const getMediaSlidesImages = async (slide) => {
    try {
      const { data } = await getMediaSlides(slide);
      setSlider((prevState) => ({
        ...prevState,
        slides: (slide.side = "left"
          ? [...makeUniqueById([...data?.media, ...prevState.slides])]
          : [...makeUniqueById([...prevState.slides, ...data?.media])]),
      }));
    } catch (error) {
      handleClose();
      openErrorNotification("Something went wrong.");
    }
  };

  const handleSlideChange = useCallback(
    (index, event) => {
      const key = event.key.replace(".$", "");
      setSlider((prevState) => ({
        ...prevState,
        fileId: key,
      }));
      let slide = {
        roomId: slideUrls?.roomId,
        fileId: key,
        skip: 10,
        limit: 10,
        type: slideUrls.type,
        side: "right",
      };
      if (
        index === slideUrls?.slides?.length - 1 &&
        slideUrls?.counts !== slideUrls?.slides?.length
      ) {
        getMediaSlidesImages(slide);
      }

      if (index === 0 && slideUrls?.counts !== slideUrls?.slides?.length) {
        slide.side = "left";
        getMediaSlidesImages(slide);
      }
    },
    [slideUrls?.slides]
  );

  return (
    <ResponsiveCarousel
      dynamicHeight={true}
      useKeyboardArrows={true}
      infiniteLoop={true}
      onChange={handleSlideChange}
      selectedItem={img}
    >
      {slideUrls?.slides?.map((img, index) => (
        <Paper key={img?._id}>
          <img
            src={img.url}
            alt={`Slide ${index + 1}`}
            style={{ width: "100%", maxHeight: "500px" }}
            loading="lazy"
          />
          <IconButton
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8, color: "black" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Paper>
      ))}
    </ResponsiveCarousel>
  );
};

export default Carousel;
