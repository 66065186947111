import React, {
   useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux'; 
import { Formik, Field, Form } from 'formik';
import PropTypes from 'prop-types';
import FormikTree from 'src/components/formik/FormikTree';
import { withFormikDevtools } from 'formik-devtools-extension';
import FormikInput from 'src/components/formik/FormikInput';
import { RolePermissionsYupSchema,RolePermissionsDefaultProps } from 'src/containers/admin-containers/permission-container/PermissionPropTypes';
import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { usePrevious } from 'src/utils/ReactHooksUtil';

import { permissionMasterFetch } from './actions/PermissionsMasterActions';
import {
   rolePermissionsEditAction, rolePermissionsResetAction, rolePermissionsSearchAction, rolePermissionsUpsertAction,
} from './actions/RolePermissionsActions';
import { permissionsToTreeStructure } from 'src/utils/CommonUtils';
import PermissionSearchList from './PermissionSearchList';
import { includeStamp } from 'src/utils/StampUtils';
import FormikRadioBox from 'src/components/formik/FormikRadioBox';
import ReportingManagerDropDown from 'src/business-components/ReportingManagerDropDown';
import RoleDropDown from 'src/business-components/RoleDropDown';


const Render=(props)=>{
   




  const {
      values,
      
      
      isSubmitting,
      onClear,
      resetForm,
      forceRefreshSearchContainer,
      scopeTree,
  } = props;
  const [isRoleType,setIsRoleType] = useState(true);

  return(
   <>
   <Form autoComplete="disabled">
   <div className="addpermission">
   <label className="font-20 fw-bold color1a mb-2">Add new permission</label>
   <div className="d-flex justify-content-between align-items-center "> 

    <div className="yesnolable">
      <label className="font-14 color1a fw-bold mb-2 mt-3">Type</label>
         <div className="d-flex align-items-center">
            <label className="cstCheck fradio"> 
               {}
               <Field type="radio" name="isRoleType" value="role" checked={isRoleType} onClick={()=>{setIsRoleType(true)}} />
               Role  
               <span className="checkmark"></span>
            </label>
            <label className="cstCheck fradio">            
            {} 
               <Field type="radio" name="isRoleType" value="user" checked={(!isRoleType)} onClick={()=>{setIsRoleType(false)}}/>
               User  
               <span className="checkmark"></span>
            </label>
         </div>
      </div> 

       {isRoleType?<div className="form-group col">
         <label>Role Title</label>
            <RoleDropDown
               name="roleId"
               value={values.roleId}
               onChange={(id)=>{
                  values.roleId=id
              }}
            />
            {}
      </div>:<div className="form-group col">
         <label>User</label>
               <ReportingManagerDropDown
               name="userId"
               className="form-select mb-2"
               value={values.userId}
               onChange={(id)=>{
                   values.userId=id
               }}
               />  
      </div>  }
       
         <div className="form-group col">
            <FormikTree
                  nodes={scopeTree}
                  name="permissions"
                  checked={values.permissions}
            />
         </div>
      <div className='addinputwbtn'>
         <button type='submit'>Add</button>
         <button type="reset" onClick={onClear} className='btn-default'>Reset</button>
      </div>
   </div>
</div>
</Form>
   </>
  )




}

Render.propTypes = {
   scopeTree: PropTypes.array.isRequired,
   values: PropTypes.shape({
       permissions: PropTypes.array,
       permissions: PropTypes.bool,
   }),
   resetForm: PropTypes.func.isRequired,
   isSubmitting: PropTypes.bool.isRequired,
   forceRefreshSearchContainer: PropTypes.object.isRequired,
   onClear: PropTypes.func.isRequired,
};


let setSubmitting = () => { };

const LocationContainer=()=>{
    
    const dispatch = useDispatch();
    const {
      scopes,
      permissionMasterFetchReqStatus,
    } = useSelector((state) => state.PermissionsMasterReducer, shallowEqual);
     
     const {
      currentRecord,
      upsertReqStatus,
  } = useSelector((state) => state.RolePermissionsReducer, shallowEqual);

     
     usePrevious({
      currentRecord,
      upsertReqStatus,
  }, (prev) => {
      if (
          prev.currentRecord !== currentRecord
          || (upsertReqStatus && prev.upsertReqStatus !== upsertReqStatus && upsertReqStatus !== REQUEST_STATUS.PENDING)) {
          setSubmitting(false);
      }
  });

  
    
       const [
         forceRefreshSearchContainer,
         setForceRefreshSearchContainer,
     ] = useState({});
 
     usePrevious({ upsertReqStatus }, (preState) => {
         if (preState.upsertReqStatus !== upsertReqStatus && upsertReqStatus === REQUEST_STATUS.SUCCESS) {
             setForceRefreshSearchContainer({});
         }
     });
 
     useEffect(() => {
         
         if (!scopes && !permissionMasterFetchReqStatus) {
             dispatch(permissionMasterFetch({
                 where: {},
                 currentPage: 0,
                 autopopulate: false,
                 fullTextSearch: false,
             }));
         }
     }, [
         dispatch,
         scopes,
         permissionMasterFetchReqStatus,
     ]);
 
     
     const scopeTree = useMemo(() => permissionsToTreeStructure(scopes), [
         scopes,
     ]);

    const onSubmit = useCallback((
      values,
      formikUtils,
  ) => {
      ({ setSubmitting } = formikUtils);     
       includeStamp(values);
      dispatch(rolePermissionsUpsertAction(values)); 
  }, [
      dispatch,
  ]);
 
   const onClear = useCallback(() => {
      dispatch(rolePermissionsResetAction());
  }, [
      dispatch,
  ]); 



  return (
    <>
       <div className="user p-4" id="AdminLocation">
<div className="d-flex align-items-center justify-content-between bgadhding">
   <div className="d-flex align-items-center adhding">
      <span className="img">
      <img
         src="/assets/images/admin/imgs/depertment.svg"
         className="img-fluid"
         width={58}
         height={58}
         alt=""
         />
      </span>
      <div className="adhdtext ms-2">
         <div className="font-20 fw-bold color33 hd">Permission</div>
         <div className="font-12 fw-bold tx">
            here's what's happening with your store today.
         </div>
      </div>
   </div>
</div>
<nav aria-label="breadcrumb" className="mt-3">
   {}
</nav>
<Formik
            onSubmit={onSubmit}
            onClear={onClear}
            enableReinitialize
            initialValues={currentRecord || RolePermissionsDefaultProps}
            validationSchema={RolePermissionsYupSchema}
        >
            {(formikProps) => (
                <Render
                    onClear={onClear}
                    scopeTree={scopeTree}
                    forceRefreshSearchContainer={forceRefreshSearchContainer}
 
                    {...formikProps}
                />
            )}
   </Formik>
    {}
</div>
    </>
  )
}

export default LocationContainer