import React from 'react';
const HeaderNav =() =>{
    return(
        <>
        <section>
          <header className="p-2">
              <div className="container-fluid">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start align-items-center navtop">
                    <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
                    <img src="/assets/images/logo.svg" className="img-fluid logoHder" alt=""/>
                    <img src="/assets/images/logow.svg" className="img-fluid logowHder d-none" alt=""/>
                    </a>
                </div>
              </div>
          </header>
        </section>
        </>
    )
}
export default HeaderNav



