export const HOME_ACTIONS = {
    HOME_SEARCH: 'HOME_SEARCH',
    HOME_SEARCH_SUCCESS: 'HOME_SEARCH_SUCCESS',
    HOME_SEARCH_ERROR: 'HOME_SEARCH_ERROR',

    HOME_UPSERT: 'HOME_UPSERT',
    HOME_UPSERT_SUCCESS: 'HOME_UPSERT_SUCCESS',
    HOME_UPSERT_ERROR: 'HOME_UPSERT_ERROR',

    HOME_EDIT: 'HOME_EDIT',

    HOME_RESET: 'HOME_RESET',

    HOME_ONLINE_USER_LIST_SUCCESS: 'HOME_ONLINE_USER_LIST_SUCCESS',
};

export const cityResetAction = () => ({
    type: HOME_ACTIONS.HOME_RESET,
});

export const citySearchAction = (payload) => ({
    type: HOME_ACTIONS.HOME_SEARCH,
    ...payload,
});

export const cityEditAction = (payload) => ({
    type: HOME_ACTIONS.HOME_EDIT,
    ...payload,
});

export const cityUpsertAction = (payload) => ({
    type: HOME_ACTIONS.HOME_UPSERT,
    ...payload,
});

export const onlineUserListAction = (payload) => ({
    type: HOME_ACTIONS.HOME_ONLINE_USER_LIST_SUCCESS,
    ...payload,
});

export default {
    HOME_ACTIONS,
    citySearchAction,
    cityUpsertAction,
    cityEditAction,
    onlineUserListAction,
};
