import React from "react";
import { Dialog} from "@mui/material";
import Carousel from "./Carousel";

const CarouselDialog = ({
  slideUrls,
  open,
  onClose,
  img,
  setSlider
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          background: "rgb(239, 252, 255)",
        },
      }}
    >
      <Carousel
        slideUrls={slideUrls}
        handleClose={onClose}
        img={img}
        setSlider={setSlider}
      />
    </Dialog>
  );
};

export default CarouselDialog;
