import React, { useState, useEffect } from "react";
import {
  getSearchUserList,
  getUserList,
} from "src/containers/profile-container/ProfileServices";
import useDebounce from "src/hooks/useDebounce";
const initialState = {
  userList: [],
  searchedUsers: [],
  searchText: "",
};

const UserList = ({
  selectedUsers,
  handleSelect,
  dispatch,
  handleForwardMessage,
}) => {
  const [state, setState] = useState(initialState);
  const { userList, searchedUsers, searchText } = state;
  const debouncedSearch = useDebounce(searchText, 500);

  useEffect(() => {
    if (debouncedSearch) {
      fetchSearchUserList(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    try {
      const { data } = await getUserList();
    if (data) {
      setState((prevState) => ({
        ...prevState,
        userList: data.user,
      }));
    }
    } catch (error) {
    }
  };

  const fetchSearchUserList = async () => {
    const { data } = await getSearchUserList(searchText);
    if (data?.users) {
      setState((prevState) => ({
        ...prevState,
        searchedUsers: [...data.users],
      }));
    }
    if (searchText === "") {
      fetchUserList();
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      searchText: value,
    }));
  };

  const handleClick = () => {
    setState(initialState);
    dispatch({ type: "SET_SHOW_USER_MODAL", payload: false });
    dispatch({ type: "SET_SELECTED_USERS", payload: [] });
  };




  const renderProfilePicture = (participantsUser) => {
    const profilePicture = participantsUser?.otherInformation?.profilePicture;
    const firstNameInitial = participantsUser?.firstName?.charAt(0);
    const lastNameInitial = participantsUser?.lastName?.charAt(0);

    if (profilePicture) {
      return (
        <img
          className="img-fluid userimg"
          src={profilePicture}
          width="40"
          height="40"
          alt="user img"
          style={{
            border: `solid ${
              participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
            }`,
            borderWidth: "2px",
          }}
          loading="lazy"
        />
      );
    } else {
      return (
        <div
          className="placeholder-img"
          style={{
            width: "40",
            height: "40",
            borderRadius: "50%",
            backgroundColor: "#e0e0e0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `solid ${
              participantsUser?.isOrange ? "#ff7a00" : "#00a8ff"
            }`,
            borderWidth: "2px",
            color: "#555",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {`${firstNameInitial || ""}${lastNameInitial || ""}`}
        </div>
      );
    }
  };

  const getUsersList = (user) => {
    return (
      <label
        key={user?._id}
        className="d-flex justify-content-between p-2 rounded-2 urlsImgSz"
        style={{ gap: 10 }}
      >
        <span
          className={user?.otherInformation?.profilePicture ? "img" : ""}
        >
          {renderProfilePicture(user)}
        </span>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="font-14 fw-500 color1a hdv">
            <h3 className="font-14 fw-500 color1a mb-0">
              {`${user?.firstName} ${user?.lastName}`}
            </h3>
            <span className="d-block font-10 fw-500 color80">
              {`${user?.otherInformation?.designation ?? "NA"}  `}
            </span>
          </div>
          <div className="">
            <input type="checkbox" onChange={(e) => handleSelect(e, user)} />
          </div>
        </div>
      </label>
    );
  };

  return (
    <div
      id="AddMemberModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-modal="true"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content rounded-3 shadow">
          <div className="modal-header flex-column border-0 px-3 pb-0">
            <div className="d-flex align-items-center justify-content-between w-100 pt-3">
              <h5 className="mb-2 font-16 color33 fw-bold">
                {selectedUsers?.length} Members added
              </h5>
              <button
                type="button"
                className="btn btn-lg btn-link fs-6 text-decoration-none py-0 m-0 rounded-0 border-0 color1a pe-0"
                onClick={handleClick}
              >
                <i className="mdi mdi-close"></i>
              </button>
            </div>
            <div className="msg-search w-100 mx-0">
              <input
                type="text"
                className="form-control bg-none border-0"
                id="inlineFormInputGroup"
                placeholder="Search"
                aria-label="search"
                value={searchText}
                onChange={(e) => handleSearch(e)}
              />
              <button>
                <i className="mdi mdi-magnify" />
              </button>
            </div>
          </div>
          <div className="modal-body px-2 pt-0 pb-2 text-start">
            <div className="fstRightGroup pb-3 border-0">
              <div className="pb-3 bg-body">
                {searchText?.length > 0
                  ? searchedUsers.map((user) => <>{getUsersList(user)}</>)
                  : userList?.map((user) => <>{getUsersList(user)}</>)}
              </div>
              <button
                className="fw-500 stickybottom"
                onClick={handleForwardMessage}
              >
                <i className="mdi mdi-share"></i>
                Forward
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
