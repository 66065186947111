const ReceiverName = ({ recipientsGroup, notification }) => {
    const getName = (recipient) => {
      if (recipient && recipient.name) {
        return recipient.name;
      }
      return "Unknown";
    };
  
    if (recipientsGroup === "department") {
      return <>{getName(notification.recipients.departmentId)}</>;
    } else if (recipientsGroup === "group") {
      return <>{getName(notification.recipients.groupId)}</>;
    } else if (recipientsGroup === "user") {
      const user = notification.recipients.userId;
      const userName = user ? `${user.firstName} ${user.lastName}` : "Unknown User";
      return <>{userName}</>;
    } else if (recipientsGroup === "designation") {
      return <>{getName(notification.recipients.designationId)}</>;
    } else {
      return null;
    }
  };
  
  export default ReceiverName;