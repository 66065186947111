
export const currentGroup = (value) => ({
  type: 'CURRENT_GROUP',
  payload: value,
});

export const setReceiptent = ({ receiver }) => ({
  type: 'RECIPIENT', 
  payload: { receiver },
});

export const setUserMessages = ({ userMessages, unReadMessageCounter }) => ({
  type: 'USER_MESSAGES',
  payload: { userMessages, unReadMessageCounter },
});

export const loadMoreChat = (messages) => ({
  type: 'LOAD_MORE_MESSAGES',
  payload: messages,
});

export const addNewMessage = ({ newMessage, roomId }) => ({
  type: 'NEW_MESSAGES',
  payload: { newMessage, roomId },
});


