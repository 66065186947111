import { useState,useEffect } from "react"
import { useSelector } from "react-redux";
import { getAccountStorageUsageAPI, loginInfoAPI } from "src/api/AccountSettingApi";
import SignedDevices from "src/components/SignedDevices";
import { getStorageUsageGroupBy } from "src/utils/CommonUtils";


const ActivitySetting=({openedSubDrawer,toggleSubDrawer})=>{
const userID = useSelector((state) => state.auth.user?._id);
const [UsageData,setUsageData] = useState({
   Total: { count: 0, totalSize: 0 },
   Text: { count: 0, totalSize: 0 },
   Images: { count: 0, totalSize: 0 },
   Video: { count: 0, totalSize: 0 },
   Audio: { count: 0, totalSize: 0 },
   Documents: { count: 0, totalSize: 0 },
   Others: { count: 0, totalSize: 0 }
 });
 const [loginInfo,setLoginInfo] = useState([])

 
useEffect(()=>{
   (async()=>{ 
   const res = await getAccountStorageUsageAPI({userId:userID});
               await handleLogoutOtherDevices()
   if  (res && res.status) { 
      const  result = getStorageUsageGroupBy(res.data.data); 
      setUsageData(result)
   }
})();
},[])
const handleLogoutOtherDevices=async()=>{
   const loginResult = await loginInfoAPI({userId:userID});
   if(loginResult && loginResult.status){
      setLoginInfo(loginResult.data.data)
   }
}

 return (
    <>
    <div className="dflex align-items-center justify-content-between Activity slist" id="Activityset" style={{display:(openedSubDrawer.isOpen)?"none":""}}  onClick={()=>{toggleSubDrawer("activity",true)}}>
   <div className="font-16 color1a fw-bold">
      Activity <span className="d-block font-14 color66 fw-normal">Control Your Activity</span>
   </div>
   <span className="arrow"><i className="mdi mdi-chevron-right"></i></span>
</div>
<div className="accountstg p15 border-0"  id="Activityupdate" style={{display:(openedSubDrawer.type=="activity" && openedSubDrawer.isOpen)?"block":"none"}}>
   <div className="dflex justify-content-between align-items-center createnotes font-16 fw-bold color1a btnclr fixedtop">
      Activity
      <button className="rounded-4 closetabpassword" onClick={()=>toggleSubDrawer("activity",false)}><i className="mdi mdi-chevron-left"></i></button>
   </div>
   <div className="Achpaswd">
      <div className="accordion accordion-flush" id="accordionExample">
         <div className="accordion-item">
            <h2 className="accordion-header" id="heading1">
               <button className="accordion-button collapsed ps-0 pe-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
               <span>Usage</span>  
               </button>
            </h2>
            <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionExample">
               <div className="accordion-body p-0">
                  <div className="ColorthemeBody">
                     <div className="d-flex justify-content-between font-16 fw-normal color1a fw-500 align-items-center">
                        Total Usage 
                        <div className="d-block font-14 color4d fw-bold">{UsageData.Total.count} <span className="d-block font-14 color80">{UsageData.Total.totalSize}</span></div>
                     </div>
                     <div className="grid2 usergrid">
                        <div className="usagelist usrlist1 usrlist">
                           <div className="img font-14 fw-normal color33 fw-500"><img src="assets/images/activity-usage/messages.svg" className="img-fluid" alt=""/> Messages</div>
                           <div className="d-block font-14 color4d fw-bold text-center">{UsageData.Text.count} <span className="d-block font-14 color80">{UsageData.Text.totalSize}</span></div>
                        </div>
                        <div className="usagelist usrlist1 usrlist">
                           <div className="img font-14 fw-normal color33 fw-500"><img src="assets/images/activity-usage/images.svg" className="img-fluid" alt=""/> Images</div>
                           <div className="d-block font-14 color4d fw-bold text-center">{UsageData.Images.count} <span className="d-block font-14 color80">{UsageData.Images.totalSize}</span></div>
                        </div>
                        <div className="usagelist usrlist1 usrlist">
                           <div className="img font-14 fw-normal color33 fw-500"><img src="assets/images/activity-usage/video.svg" className="img-fluid" alt=""/> Video  </div>
                           <div className="d-block font-14 color4d fw-bold text-center">{UsageData.Video.count} <span className="d-block font-14 color80">{UsageData.Video.totalSize}</span></div>
                        </div>
                        <div className="usagelist usrlist1 usrlist">
                           <div className="img font-14 fw-normal color33 fw-500"><img src="assets/images/activity-usage/audio.svg" className="img-fluid" alt=""/> Audio  </div>
                           <div className="d-block font-14 color4d fw-bold text-center">{UsageData.Audio.count} <span className="d-block font-14 color80">{UsageData.Audio.totalSize}</span></div>
                        </div>
                        <div className="usagelist usrlist1 usrlist">
                           <div className="img font-14 fw-normal color33 fw-500"><img src="assets/images/activity-usage/documents.svg" className="img-fluid" alt=""/> Documents   </div>
                           <div className="d-block font-14 color4d fw-bold text-center">{UsageData.Documents.count} <span className="d-block font-14 color80">{UsageData.Documents.totalSize}</span></div>
                        </div>
                        <div className="usagelist usrlist1 usrlist">
                           <div className="img font-14 fw-normal color33 fw-500"><img src="assets/images/activity-usage/others.svg" className="img-fluid" alt=""/> Others</div>
                           <div className="d-block font-14 color4d fw-bold text-center">{UsageData.Others.count} <span className="d-block font-14 color80">{UsageData.Others.totalSize}</span></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="accordion-item">
            <h2 className="accordion-header" id="heading2">
               <button className="accordion-button collapsed ps-0 pe-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
               Active Devices
               </button>
            </h2>
            <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
               <div className="accordion-body p-0">
                  <div className="TexturethemeBody">
                     <div className="">
                        <div className="list-group">
                           {
                             loginInfo.map((LogInfo,index)=><SignedDevices LogInfo={LogInfo} handleLogoutOtherDevices={handleLogoutOtherDevices} key={index}/>)
                           }
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
    </>
 )
}
export default ActivitySetting