import { isFirefox } from "react-device-detect";
import config from "src/config";
import { postRequest } from "src/utils/RequestUtil";
export const getUserChatMediaList = async (
  fileType,
  searchText = "",
  chatRoomId,
  startDate,
  endDate,
  roomType
) => {
  try {
    const url = `${config.baseUrl}media/conversation/user/media`;
    return postRequest({
      url,
      data: {
        fileType,
        searchText,
        chatRoomId,
        sDate: startDate,
        eDate: endDate,
        roomType,
      },
    });
  } catch (error) {
    return null;
  }
};

export const getUserAppChatMediaList = async (
  fileType,
  searchText = "",
  startDate,
  endDate
) => {
  try {
    const url = `${config.baseUrl}media/conversation/user/media/app/all`;
    return postRequest({
      url,
      data: {
        fileType,
        searchText,
        sDate: startDate,
        eDate: endDate,
      },
    });
  } catch (error) {
    return null;
  }
};

export const pinChatMediaList = async (messageId) => {
  try {
    const url = `${config.baseUrl}media/conversation/user/media/pin`;
    return postRequest({
      url,
      data: {
        messageId,
      },
    });
  } catch (error) {
    return null;
  }
};

export const deleteChatMediaList = async (messageId) => {
  try {
    const url = `${config.baseUrl}media/conversation/user/media/delete`;
    return postRequest({
      url,
      data: {
        messageId,
      },
    });
  } catch (error) {
    return null;
  }
};

export const pinLinksChatMediaList = async (messageId, isPinned) => {
  try {
    const url = `${config.baseUrl}media/conversation/user/media/links/pin`;
    return postRequest({
      url,
      data: {
        messageId,
        isPinned,
      },
    });
  } catch (error) {
    return null;
  }
};

export const deleteLinksChatMediaList = async (messageId, token) => {
  try {
    const url = `${config.baseUrl}media/conversation/user/media/links/delete`;
    return postRequest({
      url,
      data: {
        messageId,
      },
    });
  } catch (error) {
    return null;
  }
};

export const pinStorageMedia = async (messageId, isPinned) => {
  try {
    const url = `${config.baseUrl}media/conversation/user/media/app/all/pin`;
    return postRequest({
      url,
      data: {
        messageId,
        isPinned,
      },
    });
  } catch (error) {
    return null;
  }
};

export const deleteStorageMedia = async (messageId, isPinned) => {
  try {
    const url = `${config.baseUrl}media/conversation/user/media/app/all/delete`;
    return postRequest({
      url,
      data: {
        messageId,
        isPinned,
      },
    });
  } catch (error) {
    return null;
  }
};

export const pinMessageList = async (roomId, userId, skip) => {
  try {
    if (!roomId) return null;
    const url = `${config.baseUrl}media/conversation/pin/messages`;
    return postRequest({
      url,
      data: {
        roomId,
        userId,
        skip
      },
    });
  } catch (error) {
    return null;
  }
};

export const getMediaSlides = async (slide) => {
  try {
    const url = `${config.baseUrl}media/conversation/user/media/chat/slides`;
    return postRequest({
      url,
      data: {
        ...slide,
      },
    });
  } catch (error) {
    return null;
  }
};
