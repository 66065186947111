import { useCallback } from "react";
import { useDispatch } from 'react-redux';
import { roleEditAction, roleUpsertAction } from './actions/RoleActions';

const RoleSearchList=({search})=>{
    
    const dispatch = useDispatch();
    
    const editClick = useCallback((type,record) => {
        if (type==="edit" && roleEditAction) {
            dispatch(roleEditAction(record));
        }
        else{
            const deletePayload = { 
                _id:record._id,
                active:false,
                deleted:true,
                deletedTs:new Date(),
            }
            dispatch(roleUpsertAction(deletePayload));
            
        }
    }, [
        dispatch,
        roleEditAction,
    ]);
    return (
        <div className="">
   <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
      <div className="font-18 fw-bold color1a">{search.data.length} Role</div>
   </div>
   <div className="">
    {
        search.data.map((role,index)=>{
            return(
                <div className="card d-flex flex-row align-items-center px-3 py-2 my-2" key={index}>
                        <div className="l font-14 color1a fw-bold me-auto">{role.roleName}</div>
                        <div className="r d-flex align-items-center ms-auto ruserDe">
                            <div className="ruserDe2 font-14 color80">{role.userCount} users </div>
                            <div className="ruserDe3">
                            <button className="bg-none border-0 font-18 color1a" onClick={()=>{editClick("edit",role)}}>
                            <i className="mdi mdi-pencil-outline" />
                            </button>{" "}
                            </div>
                            <div className="ruserDe4">
                            <button className="bg-none border-0 font-18 color1a" onClick={()=>{editClick("delete",role)}}>
                            <i className="mdi mdi-delete" />
                            </button>{" "}
                            </div>
                        </div>
                    </div> 
            )
        })
    }
      

   </div>
</div>
    )
}

export default RoleSearchList;