import { useEffect, useState } from "react";
import NotificationProvider from "./components/Notifination/ToastNotification";
import ConnectivityDialog from "./components/UserStatus/ConnectivityDialog";
import Routings from "./routes";
function App() {
  return (
    <div className="App">
      <NotificationProvider>
        <Routings />
      </NotificationProvider>
    </div>
  );
}

export default App;
