import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const notificationSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/notification/search`;
    return postRequest({
        url,
        data,
    });
};
export const getLocationAPI = function (data) {
    const url = `${config.baseUrl}admin/notification/getLocation`;
    return postRequest({
        url,
        data,
    });
};

export const notificationCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/notification/save`;
    return postRequest({
        url,
        data,
    });
};

export const notificationUpdateAPI = function ({
    notificationId,
    data,
}) {
    const url = `${config.baseUrl}admin/notification/update/${notificationId}`;
    return putRequest({
        url,
        data,
    });
};
