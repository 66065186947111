import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "src/context/SocketContext";

const configuration = {
  iceServers: [
    { urls: 'stun:stun.l.google.com:19302' },
    { urls: 'turn:syncupteams.com:3478', username: 'user', credential: 'pass' } // Replace with your TURN server credentials
  ]
};

const AudioCall = () => {
  const socket = useContext(SocketContext);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(new MediaStream());
  const [peerConnection, setPeerConnection] = useState(null);
  const [username, setUsername] = useState('');
  const [callToUsername, setCallToUsername] = useState('');

  useEffect(() => {
    socket.on('offer', async ({ offer, from }) => {
      console.log('Received offer:', offer);
      const accept = window.confirm(`${from} is calling. Do you want to accept the call?`);
      if (accept) {
        await handleAcceptCall(offer, from);
      } else {
        socket.emit('reject', { to: from });
      }
    });

    socket.on('answer', async ({ answer }) => {
      console.log('Received answer:', answer);
      if (!peerConnection) return;
      await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
    });

    socket.on('ice-candidate', async ({ candidate }) => {
      console.log('Received ICE candidate:', candidate);
      if (!peerConnection) return;
      await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
    });

    socket.on('hangup', () => {
      console.log('Received hangup');
      endCall();
    });

    return () => {
      socket.off('offer');
      socket.off('answer');
      socket.off('ice-candidate');
      socket.off('hangup');
    };
  }, [peerConnection]);

  const registerUser = () => {
    console.log('Registering user:', username);
    socket.emit('register', username);
  };

  const startCall = async () => {
    console.log('Starting call to:', callToUsername);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: true } });
      setLocalStream(stream);
      console.log('Local stream:', stream);

      const pc = new RTCPeerConnection(configuration);
      setPeerConnection(pc);

      stream.getTracks().forEach(track => pc.addTrack(track, stream));

      pc.onicecandidate = (event) => {
        if (event.candidate) {
          console.log('Sending ICE candidate:', event.candidate);
          socket.emit('ice-candidate', { candidate: event.candidate, to: callToUsername });
        }
      };

      pc.ontrack = (event) => {
        console.log('Received remote track:', event.track);
        setRemoteStream(prevStream => {
          prevStream.addTrack(event.track);
          return prevStream;
        });
      };

      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);
      console.log('Sending offer:', offer);
      socket.emit('offer', { offer, to: callToUsername });
    } catch (error) {
      console.error('Error starting call:', error);
    }
  };

  const handleAcceptCall = async (offer, from) => {
    console.log('Accepting call from:', from);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: true } });
      setLocalStream(stream);
      console.log('Local stream:', stream);

      const pc = new RTCPeerConnection(configuration);
      setPeerConnection(pc);

      stream.getTracks().forEach(track => pc.addTrack(track, stream));

      pc.onicecandidate = (event) => {
        if (event.candidate) {
          console.log('Sending ICE candidate:', event.candidate);
          socket.emit('ice-candidate', { candidate: event.candidate, to: from });
        }
      };

      pc.ontrack = (event) => {
        console.log('Received remote track:', event.track);
        setRemoteStream(prevStream => {
          prevStream.addTrack(event.track);
          return prevStream;
        });
      };

      await pc.setRemoteDescription(new RTCSessionDescription(offer));
      const answer = await pc.createAnswer();
      await pc.setLocalDescription(answer);
      console.log('Sending answer:', answer);
      socket.emit('answer', { answer, to: from });
    } catch (error) {
      console.error('Error accepting call:', error);
    }
  };

  const endCall = () => {
    console.log('Ending call');
    if (peerConnection) {
      peerConnection.close();
      setPeerConnection(null);
    }
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
      setLocalStream(null);
    }
    setRemoteStream(new MediaStream());
    socket.emit('hangup', { to: callToUsername });
  };

  return (
    <div>
      <input 
        type="text" 
        placeholder="Enter your username" 
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
      />
      <button onClick={registerUser}>Register</button>
      <br />
      <input 
        type="text" 
        placeholder="Call to username" 
        value={callToUsername} 
        onChange={(e) => setCallToUsername(e.target.value)} 
      />
      <button onClick={startCall}>Start Call</button>
      <button onClick={endCall}>End Call</button>
      <audio id="localAudio" autoPlay muted ref={(audio) => { if (audio) audio.srcObject = localStream; }}></audio>
      <audio id="remoteAudio" autoPlay ref={(audio) => { if (audio) audio.srcObject = remoteStream; }}></audio>
    </div>
  );
};

export default AudioCall;
