import { createSlice } from "@reduxjs/toolkit";

const groupsSlice = createSlice({
  name: "groups",
  initialState: { groups: [] },
  reducers: {
    addGroup: (state, action) => {
      state.groups.push(action.payload);
    },
    updateGroup: (state, action) => {
      const { id, updatedData } = action.payload;
      const index = state.groups.findIndex((group) => group._id === id);
      if (index !== -1) {
        state.groups[index] = { ...state.groups[index], ...updatedData };
      }
    },
    removeGroup: (state, action) => {
      state.groups = state.groups.filter(
        (group) => group._id !== action.payload
      );
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
  },
});

export const { addGroup, updateGroup, removeGroup, setGroups } =
  groupsSlice.actions;

export const selectGroupById = (state, id) =>
  state.groups.groups.find((group) => group._id === id);

export default groupsSlice.reducer;
