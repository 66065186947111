import React, { useEffect, useReducer, useRef } from "react";
import {
  deleteChatMediaList,
  deleteLinksChatMediaList,
  getUserChatMediaList,
  pinLinksChatMediaList,
  pinStorageMedia,
} from "../../home-container/apis/Chatboxapis";
import { useSelector, useDispatch } from "react-redux";
import { useNotification } from "src/components/Notifination/ToastNotification";
import { tabs } from "src/utils/mediaUtils";
import MediaTabs from "../components/Media/MediaTabs";
import UserList from "../components/List/UserList";
import Gallery from "../components/Media/Gallery";
import { initialState, reducer } from "../Utils/Mediautils";
import OnlyDateRangePicker from "src/components/OnlyDateRangePicker";
import moment from "moment";
import useDebounce from "src/hooks/useDebounce";
import {
  currentGroup,
  setUserMessages
} from "../../home-container/actions/ChatHistoryAction";

var resetStates = () => {};
export const resetFile = () => {
  resetStates();
};
const UserMedia = ({
  toggleRightBarDrawer,
  chatRoomId,
  roomType,
  setRevelingConMessage,
  formatMessage,
  socket,
  openedRightBarDrawer,
  scrollToItem,
  setConversationData,
  conversationData,
}) => {
  const dispatched = useDispatch();

  const { openSuccessNotification, openErrorNotification } = useNotification();
  const [state, dispatch] = useReducer(reducer, initialState);
  const user = useSelector((state) => state.auth.user);
  const userMessages = useSelector((state) => state.chatHistoryReducer.userMessages);
  
  const debouncedSearch = useDebounce(state.searchText, 500);
  const scrollref = useRef();
  const file_close = () => {
    const handleDisplay = () => {
      const mainElement = document.querySelector("#main");
      if (mainElement) {
        mainElement.style.marginRight = "0";
      }
      const myProfileElement = document.querySelector("#userFileMedia");
      if (myProfileElement) {
        myProfileElement.style.display = "none";
      }
    };

    if (openedRightBarDrawer === "media") {
      handleDisplay();
    } else {
      setTimeout(() => {
        handleDisplay();
      }, 100);
    }
    toggleRightBarDrawer("media");
  };

  useEffect(() => {
    if (
      moment(state?.startDate, "YYYY-MM-DD", true).isValid() &&
      moment(state?.endDate, "YYYY-MM-DD", true).isValid() &&
      openedRightBarDrawer == "media"
    ) {
      fetchMedia();
    }
  }, [
    state.tabValue,
    state.startDate,
    state.endDate,
    openedRightBarDrawer,
    chatRoomId,
  ]);
  useEffect(() => {
    if (
      moment(state?.startDate, "YYYY-MM-DD", true).isValid() &&
      moment(state?.endDate, "YYYY-MM-DD", true).isValid() &&
      openedRightBarDrawer == "media"
    ) {
      fetchMedia();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    const eventName = "deleteFileResponse";
    const handleDeleteMessage = (data) => {
      fetchMedia();
    };
    socket.on(eventName, handleDeleteMessage);
    return () => {
      socket.off(eventName, handleDeleteMessage);
    };
  }, [socket]);

  resetStates = () => {
    setRevelingConMessage("");
    dispatch({ type: "RESET_STATES" });
    file_close();
  };

  const handleTabClick = (event) => {
    dispatch({ type: "SET_TAB_VALUE", payload: event.target.name });
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    dispatch({ type: "SET_SEARCH_TEXT", payload: value });
  };

  const handleSelect = (e, user) => {
    if (e.target.checked) {
      dispatch({
        type: "SET_SELECTED_USERS",
        payload: [...state.selectedUsers, user?._id],
      });
    } else {
      dispatch({
        type: "SET_SELECTED_USERS",
        payload: state.selectedUsers.filter((userID) => userID !== user?._id),
      });
    }
  };

  const handleSelectMedia = (e, msg) => {
    if (e.target.checked) {
      dispatch({
        type: "SET_SELECTED_FILE_MEDIAS",
        payload: [...state.selectedFileMedias, msg?._id],
      });
    } else {
      dispatch({
        type: "SET_SELECTED_FILE_MEDIAS",
        payload: state.selectedFileMedias.filter((md) => md !== msg?._id),
      });
    }
  };

  const copyMessageToClipboard = (media) => {
    navigator.clipboard
      .writeText(state?.tabValue === "Link" ? media?.message : media?.url)
      .then(() => {
        openSuccessNotification("Message copied to clipboard!");
        setTimeout(() => {}, 200);
      })
      .catch((error) => {
        openErrorNotification("Error copying message to clipboard");
      });
  };

  const handlePin = async (msg) => {
    try {
      await pinStorageMedia(msg?._id, msg?.isPinned);
      await fetchMedia();
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const handleDelete = async (msg) => {
    try {
      await deleteChatMediaList(msg?._id);
      await fetchMedia();
      openSuccessNotification(`Deleted Successfully.`);
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const handlePinLinks = async (msg) => {
    try {
      await pinLinksChatMediaList(msg?._id);
      await fetchMedia();
      openSuccessNotification(`Pinned.`);
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const handleDeleteLinks = async (msg) => {
    try {
      await deleteLinksChatMediaList(msg?._id);
      await fetchMedia();
      openSuccessNotification(`Deleted Successfully.`);
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const handleForwardMessage = () => {
    handleForwardMedia();
  };

  const handleConList = ({ result }) => {
    setTimeout(() => {
      dispatched(setUserMessages({ userMessages: result.map(formatMessage), unReadMessageCounter: null }));
      setConversationData(result.map(formatMessage));
    }, 1500);
  };

  const handleRevealInConversation = (msg) => {
    setRevelingConMessage(msg);
    const exists = userMessages.some((item) => item.id === msg?.chatId);
    if (exists) {
      setTimeout(() => {
        return true
      }, 1000);
    } else {
      dispatched(setUserMessages({ userMessages:[], unReadMessageCounter: null }));
      setConversationData([]);
      socket.emit("revelInConversation", msg);
      socket.on("revelInConversationResponse", handleConList);
    }
  };

  const handleForward = (e, med) => {
    dispatch({ type: "SET_SINGLE_FORWARD", payload: med?._id });
    dispatch({ type: "SET_SHOW_USER_MODAL", payload: !state.showUserModal });
  };

  const handleForwardMedia = () => {
    let userAgent = JSON.parse(localStorage.getItem("ipData"));
    const senderId = user?._id;
    socket.emit("forwardMediaToMultipleUser", {
      selectedMedia:
        state.selectedFileMedias.length > 0
          ? state.selectedFileMedias
          : [state.singleForward],
      selectedUserList: state.selectedUsers,
      senderId,
      userAgent,
      messageType: state.tabValue === "Link" ? "link" : "media",
    });
    dispatch({ type: "SET_SELECTED_FILE_MEDIAS", payload: [] });
    dispatch({ type: "SET_SHOW_USER_MODAL", payload: !state.showUserModal });
    dispatch({ type: "SET_SELECTED_USERS", payload: [] });
    fetchMedia();
  };

  const fetchMedia = async () => {
    try {
      const { data } = await getUserChatMediaList(
        state.tabValue,
        state.searchText,
        chatRoomId,
        state.startDate,
        state.endDate,
        roomType
      );
      dispatch({ type: "SET_CONVERSATION_MEDIA", payload: [...data] });
    } catch (error) {}
  };
  const handleDateRangeChange = (start, end) => {
    dispatch({
      type: "SET_START_DATE",
      payload: moment(start).format("YYYY-MM-DD"),
    });
    dispatch({
      type: "SET_END_DATE",
      payload: moment(end).format("YYYY-MM-DD"),
    });
  };
  return (
    <>
      <div
        className="chat-sidebar chat-animate-right"
        style={{ display: "none", right: "109px" }}
        id="userFileMedia"
      >
        {state?.showUserModal && (
          <UserList
            selectedUsers={state.selectedUsers}
            handleSelect={handleSelect}
            dispatch={dispatch}
            handleForwardMessage={handleForwardMessage}
          />
        )}
        <div className="modal-dialog-scrollable">
          <div className="modal-content">
            <div className="slide-header">
              <div className="HeadSlidName d-flex justify-content-between align-items-center">
                <div className="slidName">
                  Files
                  <span className="smdia font-14 color1a d-block fw-normal">
                    Shared Media
                  </span>
                </div>
                <button
                  className=""
                  onClick={() => {
                    resetStates();
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="msg-search mx-0">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="Search"
                  aria-label="search"
                  value={state?.searchText}
                  onChange={handleSearch}
                />
                <button>
                  <i className="mdi mdi-magnify"></i>
                </button>
              </div>
              <div className="onlydaterange fonsdfrnt">
                <OnlyDateRangePicker
                  onDateRangeChange={handleDateRangeChange}
                  position={"left"}
                />
              </div>
              <MediaTabs
                tabs={tabs}
                tabValue={state?.tabValue}
                handleTabClick={handleTabClick}
              />
            </div>
            <div
              className="modal-body pt-0"
              id="scroll-container-file"
              ref={scrollref}
            >
              <div className="tab-content filetabstext" id="myTabContent">
                <Gallery
                  conversationMedia={state?.conversationMedia}
                  handleRevealInConversation={handleRevealInConversation}
                  handleSelectMedia={handleSelectMedia}
                  isSelection={state?.isSelection}
                  handleDelete={handleDelete}
                  handleForward={handleForward}
                  setIsSelection={dispatch}
                  handlePin={handlePin}
                  selectedFileMedias={state?.selectedFileMedias}
                  copyMessageToClipboard={copyMessageToClipboard}
                  tabValue={state?.tabValue}
                  handleDeleteLinks={handleDeleteLinks}
                  handlePinLinks={handlePinLinks}
                  userMediaRev={true}
                  scrollToItem={scrollToItem}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserMedia;
