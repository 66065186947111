// src/components/Giphy.js
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState, useEffect } from "react";
import axios from "axios";

const Giphy = ({ handleGiphySend }) => {
  const [gifs, setGifs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    const fetchTrendingGifs = async () => {
      try {
        const response = await axios.get(
          `https://api.giphy.com/v1/gifs/trending?api_key=X29HAH0jkN5pLA5BUzWZIpNu3ymo7Mzg&limit=10&offset=${
            page * 10
          }`
        );
        setGifs((prevGifs) => [...prevGifs, ...response.data.data]);
      } catch (error) {
        console.error("Error fetching the trending gifs", error);
      }
    };

    fetchTrendingGifs();
  }, [page]);

  useEffect(() => {
    if (!searchTerm) {
      return;
    }
    const fetchGifs = async () => {
      try {
        const response = await axios.get(
          `https://api.giphy.com/v1/gifs/search?api_key=X29HAH0jkN5pLA5BUzWZIpNu3ymo7Mzg&q=${searchTerm}&limit=10&offset=${
            page * 10
          }`
        );
        if (page === 0) {
          setGifs(response.data.data);
        } else {
          setGifs((prevGifs) => [...prevGifs, ...response.data.data]);
        }
      } catch (error) {
        console.error("Error fetching the gifs", error);
      }
    };

    fetchGifs();
  }, [searchTerm, page]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div>
      <input
        type="text"
        className="form-control"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search for gifs..."
      />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {gifs.map((gif) => (
          <div
            key={gif.id}
            style={{ margin: "10px" }}
            onClick={() => {
              handleGiphySend({
                messageText: "",
                msgHtml: ` <img src=${gif.images.fixed_height.url} alt=${gif.title} style={{width:"100%"}}/>`,
                msgJson: { image: "Gif" },
                replyToMedia: null,
                imageGif: gif.images.fixed_height.url,
              });
            }}
          >
            <img
              src={gif.images.fixed_height.url}
              alt={gif.title}
              style={{ width: "100px" }}
            />
          </div>
        ))}
      </div>
      <Button
        color="info"
        startIcon={<ExpandMoreIcon />}
        onClick={handleLoadMore}
      >
        More
      </Button>
    </div>
  );
};

export default Giphy;
