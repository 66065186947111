import React, { useState,useContext } from "react";
import {toast } from 'react-toastify';
import { UpdatePasswordAPI } from "src/api/AccountSettingApi"
import { handleLogout } from "../login-container/LoginService";
import { logout } from "../home-container/actions/authAction";
import { SocketContext } from "src/context/SocketContext";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNotification } from "src/components/Notifination/ToastNotification";

const PasswordRessetPopup = ({
 
}) => {
 
  const navigate = useNavigate();
const dispatch = useDispatch();
const socket = useContext(SocketContext);
const {  openErrorNotification } = useNotification();
const auth = useSelector((state) => state.auth.user);
const { jwtToken: authToken } = auth || {};
  const [payload,setPayload] = useState({userId:auth._id,})
  const handleChange=(e)=>{
    setPayload({...payload,[e.target.name]:e.target.value})
}
const handleLogoutClick = async () => {
  try {
    if (await handleLogout(authToken)) {
      dispatch(logout());
      navigate("/");
      socket.disconnect();
    }
  } catch (error) {
    return openErrorNotification(
      error.response.data.message ?? "Error logging out"
    );
  }
  finally{
    dispatch(logout());
    navigate("/");
    socket.disconnect();
  }
};
const handlePasswordChange=async()=>{ 
  try {
const response = await UpdatePasswordAPI(payload) 
if(response.data.status) {
toast(response.data.message);
handleLogoutClick()
} 
}
catch (error) {
  toast.error(error.response.data.message);  
}
}
  return (
    <>
    <div
    className="modal fade lbtk show"
    id="deletemsg"
    style={{ display: "block" }}
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabIndex={-1}
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      className="modal-dialog modal-md modal-dialog-centered"
      role="document"
    >
      <div className="modal-content rounded-3 shadow">
        <div className="modal-body">
          <h4>Reset Password </h4>
      <div className="chpaswd d-flex justify-content-between align-items-center flex-column gap-2">
                <input type="password" className="form-control my-2 font-14 fw-normal color66 border w-100" placeholder="Enter Old Password" name="oldPassword" onChange={handleChange}/>
                <input type="password" className="form-control my-2 font-14 fw-normal color66 border w-100" placeholder="Enter New Password" name="newPassword" onChange={handleChange}/>
                <input type="password" className="form-control my-2 font-14 fw-normal color66 border w-100" placeholder="Confirm New Password" name="confirmPassword" onChange={handleChange}/>
                <button type="button" className="btnblue my-2" onClick={handlePasswordChange}>Reset Password</button>
            </div>
            </div> 
      </div>
    </div>
  </div>
  <div className="modal-backdrop fade show"></div>
  </>
  );
};

export default PasswordRessetPopup;
