import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const RolePropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    roleName: PropTypes.string,
});

export const RoleDefaultProps = { 
    roleName: '',
    _id: undefined
};

export const RoleYupSchema = Yup.object().shape({ 
    roleName: Yup.string().required('Required'),
});

export default {
    RoleDefaultProps,
    RolePropTypes,
    RoleYupSchema,
};
