import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ConnectivityDialog = () => {

  return (
    <Dialog
      open={true}
      onClose={() => (false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">No Internet Connection</DialogTitle>
      <DialogContent>
        <p>Your internet connection appears to be offline. Please check your network settings.</p>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={() =>{}} color="primary">
          Close
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default ConnectivityDialog;
