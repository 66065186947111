import React from 'react'
import { CSVLink } from 'react-csv'


export const ExportReactCSV = ({ csvData, fileName }) => {
    return (
        <button className="border-0 bg-blue">
        <CSVLink data={csvData} filename={fileName}>
            <img
                src="/assets/images/admin/imgs/uploaddlod.svg"
                className="img-fluid"
                alt=""
            />
            </CSVLink>
        </button>
        
        
        
        
        
    )
}