import React, { useCallback } from 'react';
import UserMedia from '../Media-container/UserChatMedia/UserMedia';

const MediaContainer = ({
  toggleRightBarDrawer,
  chatRoomId,
  roomType,
  setRevelingConMessage,
  formatMessage,
  socket,
  openedRightBarDrawer,
  scrollToItem
  ,setConversationData
}) => {
  const memoizedComponent = useCallback(() => {
    return (
      <UserMedia
        toggleRightBarDrawer={toggleRightBarDrawer}
        chatRoomId={chatRoomId}
        roomType={roomType}
        setRevelingConMessage={setRevelingConMessage}
        formatMessage={formatMessage}
        socket={socket}
        openedRightBarDrawer={openedRightBarDrawer}
        scrollToItem={scrollToItem}
        setConversationData={setConversationData}
      />
    );
  }, [openedRightBarDrawer]);

  return memoizedComponent();
};

export default MediaContainer;
