import React from "react";
import $ from "jquery";
import { Helmet } from "react-helmet";
const Head = () => {
  return (
    <>
      <Helmet>
        {}
      </Helmet>
    </>
  );
};
export default Head;
