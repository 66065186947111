import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';

export const roleSearchAPI = function (data) {
    const url = `${config.baseUrl}admin/role/search`;
    return postRequest({
        url,
        data,
    });
};
export const getRoleAPI = function (data) {
    const url = `${config.baseUrl}admin/role/getRole`;
    return postRequest({
        url,
        data,
    });
};
export const roleCreateAPI = function (data) {
    const url = `${config.baseUrl}admin/role/save`;
    return postRequest({
        url,
        data,
    });
};

export const roleUpdateAPI = function ({
    roleId,
    data,
}) {
    const url = `${config.baseUrl}admin/role/update/${roleId}`;
    return putRequest({
        url,
        data,
    });
};
