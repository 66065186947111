export const ACCOUNT_ACTIONS = {
    SET_ACCOUNT_SETTING: 'SET_ACCOUNT_SETTING',
    ACCOUNT_UPSERT: 'ACCOUNT_UPSERT',
    ACCOUNT_UPSERT_SUCCESS: 'ACCOUNT_UPSERT_SUCCESS',
    ACCOUNT_UPSERT_ERROR: 'ACCOUNT_UPSERT_ERROR',   
};

export const accountUpsertAction = (payload) => ({
    type: ACCOUNT_ACTIONS.ACCOUNT_UPSERT,
    ...payload,
});
export const setAccountSettingOnLogin = (payload) => ({
    type: ACCOUNT_ACTIONS.SET_ACCOUNT_SETTING,
    ...payload,
  });

export default {
    ACCOUNT_ACTIONS,
    accountUpsertAction,
    setAccountSettingOnLogin
};
