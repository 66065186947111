import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { getAccountStorageUsageAPI } from "src/api/AccountSettingApi";
import { dashboardCounterAPI } from "src/api/DashboardApi";
import { calculateOccupiedPercentage, formatSize, getStorageUsageGroupBy,getTotalSize } from "src/utils/CommonUtils";

const DashboardContainer=({socket})=>{
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

const [groupCounter,setGroupCounter] = useState({
            countStats: {allUsers: 0,inHouseUsers: 0,orangeUsers: 0},
            statusCounts: {allUsers: 0,falseStatus: 0,trueUser: 0},
            groupCount: {allUsers: 0,falseStatus: 0,trueUser: 0}
})
const [totalFiles,setTotalFiles] =useState({totalFile:0,totalFileSize:0,totalMedia:0,totalMediaSize:0});
const [UsageData,setUsageData] = useState({
    Total: { count: 0, totalSize: 0 },
    Text: { count: 0, totalSize: 0 },
    Images: { count: 0, totalSize: 0 },
    Video: { count: 0, totalSize: 0 },
    Audio: { count: 0, totalSize: 0 },
    Documents: { count: 0, totalSize: 0 },
    Others: { count: 0, totalSize: 0 }
  });
  const [totalOccuPercentage,setTotalOccuPercentage] = useState({totalOccuPercentage:0})
    useEffect(()=>{
        (async()=>{
            const counterAPIResponse = await dashboardCounterAPI();
            setGroupCounter(counterAPIResponse.data.data);
            
            const res = await getAccountStorageUsageAPI({userId:user?.Id}); 
            if  (res && res.status) {
                const result = getStorageUsageGroupBy(res.data.data); 
                setUsageData(result)
             }
     })();
     },[])
    
     useEffect(()=>{ 
        const documentsTextSize = UsageData.Text.totalSize || '0 KB';
        const documentsTotalSize = UsageData.Documents.totalSize || '0 KB';
        const audioTotalSize = UsageData.Audio.totalSize || '0 KB';
        const videoTotalSize = UsageData.Video.totalSize || '0 KB';
        const imagesTotalSize = UsageData.Images.totalSize || '0 KB';
        const othersTotalSize = UsageData.Others.totalSize || '0 KB';

        const totalFileSize = formatSize(getTotalSize({
            DocumentstotalSize: documentsTotalSize,
            AudiototalSize: audioTotalSize,
            VideototalSize: videoTotalSize,
            ImagestotalSize: imagesTotalSize,
            OthersTotalSize:othersTotalSize
        }));  
        const totalMediaSize = formatSize(getTotalSize({
            AudiototalSize: audioTotalSize,
            VideototalSize: (UsageData.Video.totalSize!=0)?UsageData.Video.totalSize:'0 KB',
            ImagestotalSize: imagesTotalSize
        }));  
        const totalFile = (UsageData.Documents.count+UsageData.Audio.count+UsageData.Video.count+UsageData.Images.count)
        const totalMedia = (UsageData.Audio.count+UsageData.Video.count+UsageData.Images.count)
        const totalOccupoed = calculateOccupiedPercentage(totalFileSize,'50 GB')
        const totalTextOccu = calculateOccupiedPercentage(documentsTextSize,totalFileSize) 
        const totalMediaOccu = calculateOccupiedPercentage(totalMediaSize,totalFileSize)  
        const totalDocumentOccu = calculateOccupiedPercentage(documentsTotalSize,totalFileSize) 
        const totalOtherOccu = calculateOccupiedPercentage(othersTotalSize,totalFileSize) 
        
        setTotalOccuPercentage({...totalOccuPercentage,totalOccuPercentage:totalOccupoed,totalTextOccu,totalMediaOccu,totalDocumentOccu,totalOtherOccu})
        setTotalFiles({...totalFiles,totalFile,totalFileSize,totalMedia,totalMediaSize})

     },[UsageData])

const handleLogoutClick=()=>{
    socket?.emit("logoutAllUser", { 
        organizationId: user?.organization,
      });
}
const handleReloadAll=()=>{
    socket?.emit("reloadAllUser", { 
        organizationId: user?.organization,
      });
}
const handleQuitApplication=()=>{
    socket?.emit("quitAllUser", { 
        organizationId: user?.organization,
      });
}
  return (
    <>
    <div
        className="p-4 gap-3 align-items-start adminHome"
        style={{ display: "flex" }}
        id="AdminDashboard"
        >
        <div className="leftadmin">
            <div className="bg-white  p-4 card mb-3">
            <div className="d-flex align-items-center font-20 color1a fw-bold">
                Dashboard{" "}
                <span className="font-14 color80 fw-normal ms-3">
                here's what's happening with your store today.
                </span>
            </div>
            <div className="onlineOfine d-flex justify-content-end align-items-end mb-2">
            <button type="button"
                 className="btnblue font-12 addremvbtn" 
                 onClick={handleLogoutClick} 
                 >
                    Logout All{" "}
                </button>
                <button type="button"
                 className="btnblue font-12 addremvbtn" 
                 onClick={handleReloadAll} 

                 >
                    Reload All{" "}
                </button>
                <button type="button"
                 className="btnblue font-12 addremvbtn" 
                 onClick={handleQuitApplication}

                 >
                    Quit All{" "}
                </button>


                <button type="button"
                 className="btnblue font-12 addremvbtn" 
                 onClick={()=>{navigate("/admin/users");}}

                 >
                    Add Users{" "}
                </button>
            </div>
            <div className="grid3 tuonle ">
                <div className="p-4 tList tuonle1 cursor-pointer" onClick={()=>{navigate("/admin/users");}}>
                <div className="d-flex justify-content-between fw-bold colorb">
                    Total Users <span className="font-26 color1a">{groupCounter.countStats.allUsers}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center useronlimg">
                    <img
                        src="assets/images/admin/dashboard/user-img1.png"
                        className="img-fluid"
                        width={23}
                        height={23}
                        alt=""
                    />
                    <img
                        src="assets/images/admin/dashboard/user-img2.png"
                        className="img-fluid"
                        width={23}
                        height={23}
                        alt=""
                    />
                    <img
                        src="assets/images/admin/dashboard/user-img3.png"
                        className="img-fluid"
                        width={23}
                        height={23}
                        alt=""
                    />
                    <img
                        src="assets/images/admin/dashboard/user-img4.png"
                        className="img-fluid"
                        width={23}
                        height={23}
                        alt=""
                    />
                    <img
                        src="assets/images/admin/dashboard/user-img5.png"
                        className="img-fluid"
                        width={25}
                        height={24}
                        alt=""
                    />
                    </div>{" "}
                    <div >
                    <span className="font-12 colorg"> Active {groupCounter.statusCounts.trueUser} </span>
                    <span className="font-12 colory d-block"> DeActive {groupCounter.statusCounts.falseStatus}</span>                    
                    </div>
                </div>
                <div className="text-end">
                    <button className="border-0 bg-none">
                      <i className="mdi mdi-arrow-right" />
                    </button>
                </div>
                </div>
                <div className="p-4 tList tuonle2 cursor-pointer" onClick={()=>{navigate("/admin/users");}}>
                <div className="d-flex justify-content-between fw-bold colorb">
                    Orange Members <span className="font-26 color1a">{groupCounter.countStats.orangeUsers}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex align-items-center useronlimg">
                    <img
                        src="assets/images/admin/dashboard/user-img1.png"
                        className="img-fluid"
                        width={23}
                        height={23}
                        alt=""
                    />
                    <img
                        src="assets/images/admin/dashboard/user-img2.png"
                        className="img-fluid"
                        width={23}
                        height={23}
                        alt=""
                    />
                    <img
                        src="assets/images/admin/dashboard/user-img3.png"
                        className="img-fluid"
                        width={23}
                        height={23}
                        alt=""
                    />
                    <img
                        src="assets/images/admin/dashboard/user-img4.png"
                        className="img-fluid"
                        width={23}
                        height={23}
                        alt=""
                    />
                    <img
                        src="assets/images/admin/dashboard/user-img5.png"
                        className="img-fluid"
                        width={25}
                        height={24}
                        alt=""
                    />
                    </div>
                </div>
                <div className="text-end">
                    <button className="border-0 bg-none" >
                    <i className="mdi mdi-arrow-right" />
                    </button>
                </div>
                </div>
                <div className="p-4 tList tuonle3 cursor-pointer" onClick={()=>{navigate("/admin/groups");}}>
                <div className="d-flex justify-content-between fw-bold colorb">
                    Groups <span className="font-26 color1a">{groupCounter.groupCount.allUsers}</span>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                    <img
                        src="assets/images/admin/dashboard/groups.png"
                        className="img-fluid"
                        width={35}
                        height={36}
                        alt=""
                    />
                    </div>
                </div>
                <div className="text-end">
                    <button className="border-0 bg-none" >
                    <i className="mdi mdi-arrow-right" />
                    </button>
                </div>
                </div>
            </div>
            </div>
            <div className="card h-lg-100 p-4 d-none">
            <div className="card-header bg-none border-0">
                <div className="row align-items-start">
                <div className="col-auto font-20 color1a fw-bold">
                    Activity
                    <p className="font-14 fw-500 color80">
                    {" "}
                    Chat, Media, Calling, Meeting Performance Chart{" "}
                    </p>
                </div>
                <div className="col-auto d-flex ms-auto">
                    <select className="form-select form-select-sm select-month minwMonth me-2">
                    <option value={0}>January</option>
                    <option value={1}>February</option>
                    <option value={2}>March</option>
                    <option value={3}>April</option>
                    <option value={4}>May</option>
                    <option value={5}>Jun</option>
                    <option value={6}>July</option>
                    <option value={7}>August</option>
                    <option value={8}>September</option>
                    <option value={9}>October</option>
                    <option value={10}>November</option>
                    <option value={11}>December</option>
                    </select>
                    <div className="reportrange d-flex align-items-center form-control cursor-pointer border">
                    <span className="text-nowrap" />{" "}
                    <i className="mdi mdi-calendar ps-2" />
                    </div>
                </div>
                </div>
                <div className="grid4 justify-content-center align-items-center mt-4 bgtmcl4list">
                <div className="d-flex align-items-center justify-content-center bgtmcl">
                    <span className="tmcl me-2">
                    <img
                        src="assets/images/admin/dashboard/messages.svg"
                        className="img-fluid"
                        alt=""
                        width={39}
                        height={39}
                    />
                    </span>{" "}
                    <div className="font-20 fw-bold">
                    {UsageData.Text.count}
                    <span className="d-block font-14 color99 fw-500">Messages </span>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center bgtmcl">
                    <span className="tmcl me-2">
                    <img
                        src="assets/images/admin/dashboard/voice-calls.svg"
                        className="img-fluid"
                        alt=""
                        width={39}
                        height={39}
                    />
                    </span>{" "}
                    <div className="font-20 fw-bold">
                    0
                    <span className="d-block font-14 color99 fw-500">
                        Voice Calls
                    </span>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center bgtmcl">
                    <span className="tmcl me-2">
                    <img
                        src="assets/images/admin/dashboard/video-calls.svg"
                        className="img-fluid"
                        alt=""
                        width={39}
                        height={39}
                    />
                    </span>{" "}
                    <div className="font-20 fw-bold">
                    0
                    <span className="d-block font-14 color99 fw-500">
                        Video Calls{" "}
                    </span>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center bgtmcl">
                    <span className="tmcl me-2">
                    <img
                        src="assets/images/admin/dashboard/meeting.svg"
                        className="img-fluid"
                        alt=""
                        width={39}
                        height={39}
                    />
                    </span>{" "}
                    <div className="font-20 fw-bold">
                    0<span className="d-block font-14 color99 fw-500">Meeting</span>
                    </div>
                </div>
                </div>
            </div>
            {}
            </div>
        </div>
        <div className="rsideadim bg-white card p-4">
            <div className="d-flex justify-content-between align-items-center mw325px mb-3">
            <div className="">
                <div className="font-18 fw-bold color1a">Subscription</div>
                <div className="font-14 fw-500 color4d">Licenses 200</div>
                <div className="font-12 fw-500 color99">Remaining 30</div>
            </div>
            <div className="bgLightylw">
                <div className="font-14 fw-bold bg-black p-2 rounded-3 text-white">
                <span>
                    <img
                    src="assets/images/admin/dashboard/update-plan.svg"
                    className="img-fluid"
                    width={23}
                    height={23}
                    alt=""
                    />
                </span>{" "}
                <span>Upgrade plan</span>
                </div>
                <div className="font-12 colory fw-500 mt-2">Expiry in 2025-02-22</div>
            </div>
            </div>
            <div className="d-flex justify-content-between align-items-center bgncolr">
            <div className="progress m-0">
                <span
                className="title timer"
                data-from={0}
                data-to={10}
                data-speed={1800}
                >
                {totalOccuPercentage.totalOccuPercentage}
                </span>
                <div className="overlay" />
                <div className="left" />
                <div className="right" />
            </div>
            <div className="">
                <div className="font-14 fw-bold color1a">Available Storage </div>
                <div className="font-14 fw-500 color80 mb-4">{totalFiles.totalFileSize}/50GB</div>
                <div className="font-14 fw-500 color1a d-flex align-items-center">
                <img
                    src="assets/images/admin/dashboard/available.svg"
                    className="img-fluid me-2"
                    alt=""
                    width={26}
                    height={20}
                />{" "}
                Files {totalFiles.totalFile}
                </div>
            </div>
            </div>
            <div className="text-end">
            {}
            </div>
            <div className="mb-3">
            <div className="card h-lg-100 overflow-hidden border-0">
                <div className="card-body p-0">
                <div className="row g-0 align-items-start justify-content-between py-2 position-relative msg1">
                    <div className="col ps-x1 py-1 position-static mx160px">
                    <div className="d-flex align-items-start">
                        <div className="avatar avatar-xl me-3">
                        <div className="avatar-name rounded-circle text-dark">
                            <span className="fs-9 mxw18px">
                            <img
                                src="assets/images/admin/dashboard/messagesg.svg"
                                className="img-fluid"
                                width={18}
                                height={18}
                                alt=""
                            />
                            </span>
                        </div>
                        </div>
                        <div className="flex-1 w-100">
                        <h6 className="mb-0 d-flex align-items-start justify-content-between">
                            <div className="font-14 color1a">
                            Messages{" "}
                            <span className="d-block font-12 colorb3">{UsageData?.Messages?.totalSize}</span>
                            </div>
                            <span className="badge rounded-pill ms-2 bg-200 font-12 color1a fw-bold ms-3">
                            {UsageData?.Messages?.count}
                            </span>
                        </h6>
                        </div>
                    </div>
                    </div>
                    <div className="col py-1">
                    <div className="row flex-end-center g-0">
                        <div className="col-12 pe-x1 ps-2 d-flex align-items-center justify-content-end">
                        <div
                            className="progress bg-200 me-2 w-100"
                            style={{ maxWidth: 72, height: 5 }}
                            role="progressbar"
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={100}
                        >
                            <div
                            className="progress-bar rounded-pill"
                            style={{ width: `${totalOccuPercentage.totalTextOccu}%` }}
                            >
                            {" "}
                            </div>
                        </div>{" "}
                        <span className="font-10 color4d">{totalOccuPercentage.totalTextOccu}%</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row g-0 align-items-start justify-content-between py-2 position-relative msg1">
                    <div className="col ps-x1 py-1 position-static mx160px">
                    <div className="d-flex align-items-start">
                        <div className="avatar avatar-xl me-3">
                        <div className="avatar-name rounded-circle text-dark">
                            <span className="fs-9 mxw18px">
                            <img
                                src="assets/images/admin/dashboard/media.svg"
                                className="img-fluid"
                                width={18}
                                height={18}
                                alt=""
                            />
                            </span>
                        </div>
                        </div>
                        <div className="flex-1 w-100">
                        <h6 className="mb-0 d-flex align-items-start justify-content-between">
                            <div className="font-14 color1a">
                            Media{" "}
                            <span className="d-block font-12 colorb3">{totalFiles.totalMediaSize}.</span>
                            </div>
                            <span className="badge rounded-pill ms-2 bg-200 font-12 color1a fw-bold ms-3">
                             {totalFiles.totalMedia}
                            </span>
                        </h6>
                        </div>
                    </div>
                    </div>
                    <div className="col py-1">
                    <div className="row flex-end-center g-0">
                        <div className="col-12 pe-x1 ps-2 d-flex align-items-center justify-content-end">
                        <div
                            className="progress bg-200 me-2 w-100"
                            style={{ maxWidth: 72, height: 5 }}
                            role="progressbar"
                            aria-valuenow={totalOccuPercentage.totalMediaOccu}
                            aria-valuemin={0}
                            aria-valuemax={100}
                        >
                            <div
                            className="progress-bar rounded-pill"
                            style={{ width: `${totalOccuPercentage.totalMediaOccu}%` }}
                            >
                            {" "}
                            </div>
                        </div>{" "}
                        <span className="font-10 color4d">{totalOccuPercentage.totalMediaOccu}%</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row g-0 align-items-start justify-content-between py-2 position-relative msg1">
                    <div className="col ps-x1 py-1 position-static mx160px">
                    <div className="d-flex align-items-start">
                        <div className="avatar avatar-xl me-3">
                        <div className="avatar-name rounded-circle text-dark">
                            <span className="fs-9 mxw18px">
                            <img
                                src="assets/images/admin/dashboard/document.svg"
                                className="img-fluid"
                                width={18}
                                height={18}
                                alt=""
                            />
                            </span>
                        </div>
                        </div>
                        <div className="flex-1 w-100">
                        <h6 className="mb-0 d-flex align-items-start justify-content-between">
                            <div className="font-14 color1a">
                            Documents{" "}
                            <span className="d-block font-12 colorb3">{UsageData.Documents.totalSize}.</span>
                            </div>
                            <span className="badge rounded-pill ms-2 bg-200 font-12 color1a fw-bold ms-3">
                            {UsageData.Documents.count}
                            </span>
                        </h6>
                        </div>
                    </div>
                    </div>
                    <div className="col py-1">
                    <div className="row flex-end-center g-0">
                        <div className="col-12 pe-x1 ps-2 d-flex align-items-center justify-content-end">
                        <div
                            className="progress bg-200 me-2 w-100"
                            style={{ maxWidth: 72, height: 5 }}
                            role="progressbar"
                            aria-valuenow={38}
                            aria-valuemin={0}
                            aria-valuemax={100}
                        >
                            <div
                            className="progress-bar rounded-pill"
                            style={{ width: `${totalOccuPercentage.totalDocumentOccu}%` }}
                            >
                            {" "}
                            </div>
                        </div>{" "}
                        <span className="font-10 color4d">{totalOccuPercentage.totalDocumentOccu}%</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row g-0 align-items-start justify-content-between py-2 position-relative msg1">
                    <div className="col ps-x1 py-1 position-static mx160px">
                    <div className="d-flex align-items-start">
                        <div className="avatar avatar-xl me-3">
                        <div className="avatar-name rounded-circle text-dark">
                            <span className="fs-9 mxw18px">
                            <img
                                src="assets/images/admin/dashboard/others.svg"
                                className="img-fluid"
                                width={18}
                                height={18}
                                alt=""
                            />
                            </span>
                        </div>
                        </div>
                        <div className="flex-1 w-100">
                        <h6 className="mb-0 d-flex align-items-start justify-content-between">
                            <div className="font-14 color1a">
                            Others{" "}
                            <span className="d-block font-12 colorb3">{UsageData.Others.totalSize}.</span>
                            </div>
                            <span className="badge rounded-pill ms-2 bg-200 font-12 color1a fw-bold ms-3">
                            {UsageData.Others.count}
                            </span>
                        </h6>
                        </div>
                    </div>
                    </div>
                    <div className="col py-1">
                    <div className="row flex-end-center g-0">
                        <div className="col-12 pe-x1 ps-2 d-flex align-items-center justify-content-end">
                        <div
                            className="progress bg-200 me-2 w-100"
                            style={{ maxWidth: 72, height: 5 }}
                            role="progressbar"
                            aria-valuenow={38}
                            aria-valuemin={0}
                            aria-valuemax={100}
                        >
                            <div
                            className="progress-bar rounded-pill"
                            style={{ width: `${totalOccuPercentage.totalOtherOccu}%` }}
                            >
                            {" "}
                            </div>
                        </div>{" "}
                        <span className="font-10 color4d">{totalOccuPercentage.totalOtherOccu}%</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="font-18 fw-bold color1a">Quick</div>
                    <div className="adusrs">
                    <Link to="/admin/users">
                        Add Users{" "}
                        <span>
                        <i className="mdi mdi-plus-circle" />
                        </span>
                        </Link>
                    <Link to="/admin/designations">
                        Add Designation{" "}
                        <span>
                        <i className="mdi mdi-plus-circle" />
                        </span>
                        </Link>
                    <Link to="/admin/departments">
                       Add Department{" "}<span><i className="mdi mdi-plus-circle" /></span>
                    </Link>
                     {}
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>

    </>
  )
}
export default DashboardContainer