import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import App from "./App";
import CheckInternet from "./CheckInternet/CheckInternet"

import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'font-awesome/css/font-awesome.min.css'; 
import configureStore from "./redux/Store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <Provider store={configureStore()}>
    <CheckInternet />
    <App />
  </Provider>

);




reportWebVitals();
