import React, { useContext, useEffect, useState } from "react";
import AudioCall from "./AudioCall";

const Talk2Member = () => {
  
  return (
    <AudioCall />
  );
};

export default Talk2Member;
