import React, { useEffect, useState } from "react";

const CustomCheckbox = ({
  user,
  setEveryone,
  message,
  selectMessages,
  setSelectMessages,
  isMessageActionOpen,
  setIsMessageActionOpen,
  
}) => {
  const [deleteMyMessage, setDeleteMyMessage] = useState([]);

  const deletedMyMessageFun = (message) => {
    let id = message?.id;

    setDeleteMyMessage((prev) => {
      if (prev.includes(id)) {
        return prev.filter((msgId) => msgId !== id);
      } else {
        if (message?.senderDetails?._id == user?._id) {
          return [...prev, id];
        } else {
          return prev;
        }
      }
    });
  };

  const selectMessageFun = (message) => {
    

    let id = message?.id;

    const elementId = "selectMessage_" + id;
    const element = document.getElementById(elementId);

    setSelectMessages((prev) => {
      if (prev.includes(message)) {
        if (element) {
          element.checked = false;
        }
        return prev.filter((msgId) => msgId.id !== message.id);
      } else {
        if (element) {
          element.checked = true;
        }
        return [...prev, message];
      }
    });
  };

  const findById = (array, id) => {
    return array.find((item) => item.id === id);
  };

  useEffect(() => {
    if (selectMessages.length > 0) {
      setIsMessageActionOpen(true);
    }
    if (!isMessageActionOpen) {
      setSelectMessages([]);
    }

    selectMessages.forEach((item) => {
      const elementId = "selectMessage_" + item.id;
      const element = document.getElementById(elementId);
      if (element) {
        element.checked = true;
      }
    });
  }, []);

  useEffect(() => {
    if (selectMessages.length > 0) {
      setTimeout(() => {
        selectMessages.forEach((item) => {
          const elementId = "selectMessage_" + item.id;
          const element = document.getElementById(elementId);
          if (element) {
            element.checked = true;
          }
        });
      }, 300);

      const anyUnmatched = selectMessages.some(
        (item) => item?.senderDetails?._id !== user?._id
      );

      if (anyUnmatched) {
        setEveryone(false);
      } else {
        setEveryone(true);
      }
    }
  }, [selectMessages]);

  
  
  
  
  
  
  

  return (
    <>
      <label className="cstCheck fcheck" data={message?.id}>
        <input type="checkbox" id={`selectMessage_${message.id}`} />
        <span
          className="checkmark"
          onClick={() => {
            selectMessageFun(message);
            
          }}
        ></span>
      </label>
    </>
  );
};
export default CustomCheckbox;
