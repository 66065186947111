import { TEXTURE_LIST, WALLPAPER_LIST } from "./AccountConstants"
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";
import { accountUpsertAction } from "./actions/AccountActions";
import { useCallback } from "react";

const ChatSetting=({openedSubDrawer,toggleSubDrawer})=>{
   
   const dispatch = useDispatch(); 
   const userID = useSelector((state) => state.auth.user?._id);
   const onAccountUpdate = useCallback((
      background,
  ) => {
      let accountUpdatePayload  = {accountSettingInformation:{background:background}}
      dispatch(accountUpsertAction({_id:userID,accountUpdatePayload}));
  }, [
      dispatch,
  ]);
 return (
    <>
    <div className="dflex align-items-center justify-content-between Chat slist" id="Chatset" style={{display:(openedSubDrawer.isOpen)?"none":""}} onClick={()=>{toggleSubDrawer("chat",true)}}>
    <div className="font-16 color1a fw-bold">
        Chat <span className="d-block font-14 color66 fw-normal">Control your wallpaper themes</span>
    </div>
    <span className="arrow"><i className="mdi mdi-chevron-right"></i></span>
    </div>

<div className="accountstg p15 border-0"  id="Chatupdate" style={{display:(openedSubDrawer.type=="chat" && openedSubDrawer.isOpen)?"block":"none"}}>
   <div className="dflex justify-content-between align-items-center createnotes font-16 fw-bold color1a btnclr fixedtop">
      Chat wallpaper
      <button className="rounded-4 closetabpassword" onClick={()=>toggleSubDrawer("chat",false)}><i className="mdi mdi-chevron-left"></i></button>
   </div>
   <div className="Achpaswd">
      <div className="accordion accordion-flush" id="accordionFlushExample">
         <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
               <button className="accordion-button collapsed ps-0 pe-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
               <span>Color theme</span>  
               </button>
            </h2>
            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
               <div className="accordion-body p-0">
                  <div className="ColorthemeBody">
                     <div className="font-16 fw-normal color66 mb-2">Select wallpaper</div>
                     <ul className="grid2 colorthem">
                        {
                           WALLPAPER_LIST.map((colour,index)=>(<li style={{background:colour}} key={index}><button className="applybtn" onClick={()=>{onAccountUpdate(colour)}}>Apply</button></li>))
                        } 
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
               <button className="accordion-button collapsed ps-0 pe-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
               Texture theme 
               </button>
            </h2>
            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
               <div className="accordion-body p-0">
                  <div className="TexturethemeBody">
                     <div className="font-16 fw-normal color66 mb-2">Select Texture</div>
                     <ul className="grid2 Texturethem">
                           {
                           TEXTURE_LIST.map((colour,index)=>(<li style={{background:`url(${colour})`}} key={index}><button className="applybtn" onClick={()=>{onAccountUpdate(colour)}}>Apply</button></li>))
                           } 
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
    </>
 )
}
export default ChatSetting