import DepartmentDropDown from "src/business-components/DepartmentDropDown";
import DesignationDropDown from "src/business-components/DesignationDropDown";
import GroupDropDown from "src/business-components/GroupDropDown";
import ReportingManagerDropDown from "src/business-components/ReportingManagerDropDown";

const GroupWiseDropDown=({recipientsGroup,values})=>{
    if (recipientsGroup === "department") {
        return (
          <DepartmentDropDown
            className="form-select w-50"
            name={"recipients.departmentId"}
            value={values.recipients.departmentId}
            onChange={(id) => {
              values.recipients.departmentId = id;
            }}
          />
        );
      } else if (recipientsGroup === "group") {
        return (
          <GroupDropDown
            className="form-select w-50"
            name={"recipients.groupId"}
            value={values.recipients.groupId}
            onChange={(id) => {
              values.recipients.groupId = id;
            }}
          />
        );
      } else if (recipientsGroup === "user") {
        return (
          <ReportingManagerDropDown
            className="form-select w-50"
            name={"recipients.userId"}
            placeholder="Select User"
            value={values.recipients.userId}
            onChange={(id) => {
                values.recipients.userId = id;
            }}
          />
        );
      } else if (recipientsGroup === "designation") {
        return (
          <DesignationDropDown
            className="form-select w-50"
            name={"designationId"}
            value={values.recipients.designationId}
            onChange={(id) => {
                values.recipients.designationId = id;
            }}
          />
        );
      } else {
        return null;
      }
}
export default GroupWiseDropDown