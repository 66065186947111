import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../home-container/actions/authAction";
import { handleLogout } from "../login-container/LoginService";
import { useNotification } from "src/components/Notifination/ToastNotification";
import { SocketContext } from "src/context/SocketContext";

const Leftbar = ({ toggleDrawer, 
  unreadNotification,
   socket,
   totalUnReadGlobal ,
   currentActiveTab,
   setSetCurrentActiveTab
  }) => {
  const { openSuccessNotification, openErrorNotification } = useNotification();
  const auth = useSelector((state) => state.auth.user);
  const { jwtToken: authToken, otherInformation: { profilePicture } = {} } =
    auth || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  useEffect(() => {
    const tooltips = [
      ...document.querySelectorAll('[data-bs-toggle="tooltip"]'),
    ].map((el) => new window.bootstrap.Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, []);
  const renderProfilePicture = (item) => {
    const profilePicture = item?.otherInformation?.profilePicture;
    const firstNameInitial = item?.firstName?.charAt(0);
    const lastNameInitial = item?.lastName?.charAt(0);
  
    if (profilePicture) {
      return (
        <img
          className="img-fluid userimg"
          src={profilePicture}
          width="40"
          height="40"
          loading="lazy"
          alt="user img"
          style={{
            border: `solid ${item?.isOrange ? "#ff7a00" : "#00a8ff"}`,
            borderWidth: "2px",
          }}
        />
      );
    } else {
      return (
        <div
          className="placeholder-img"
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#e0e0e0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `solid ${item?.isOrange ? "#ff7a00" : "#00a8ff"}`,
            borderWidth: '2px',
            color: '#555',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          {`${firstNameInitial || ''}${lastNameInitial || ''}`}
        </div>
      );
    }
  };
  const file_close = () => {
      const mainElement = document.getElementById("main");
      const myProfileElement = document.getElementById("userFileMedia");
      if (mainElement && myProfileElement) {
        const style = document.createElement("style");
        style.type = "text/css";
        style.innerHTML = `
          #main { margin-right: 0% !important; }
          #userFileMedia { display: none !important; }
        `;
        document.head.appendChild(style);
      } else {
        console.warn("mainElement or myProfileElement not found in close");
      }
  };

  const handleLogoutClick = async () => {
    try {
      if (await handleLogout(authToken)) {
        dispatch(logout());
        navigate("/");
        socket.disconnect();
        openSuccessNotification("Logged out successfully.");
        document.body.classList.remove('BlurEffect', 'BlurEffect2');
      }
    } catch (error) {
      return openErrorNotification(
        error.response.data.message ?? "Error logging out"
      );
    } finally {
      file_close();
      dispatch(logout());
      navigate("/");
      socket.disconnect();
    }
  };

  const handleReloadAll = () => {
    window.location.reload(true);
  };
  const handleQuitApplication = () => {
    if (window.electron) {
      window.electron.ipcRenderer.send("quit-app", {});
    }
  };

  useEffect(() => {
    socket.on("logoutAll", handleLogoutClick);
    socket.on("reloadAll", handleReloadAll);
    socket.on("quitAll", handleQuitApplication);
  }, [socket]);

  const handleNotificationOpen = () => {
    
    
    
    
    
    toggleDrawer("notification");
  };

  const handleFavoriteOpen = () => {
    
    
    toggleDrawer("favoriteChats");
  };

  const sidebarItems = [
    { title: "Message", icon: "mdi-chat" },
    { title: "Orange User", icon: "mdi-star" },
  ];

  

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode');
    if (savedDarkMode === 'enabled') {
      setDarkMode(true);
      document.body.classList.add('darkmode');
    } else {
      setDarkMode(false);
      document.body.classList.remove('darkmode');
    }
  }, []);

  const enableDarkMode = () => {
    document.body.classList.add('darkmode');
    setDarkMode(true);
    localStorage.setItem('darkMode', 'enabled');
  };

  const disableDarkMode = () => {
    document.body.classList.remove('darkmode');
    setDarkMode(false);
    localStorage.removeItem('darkMode');
  };

  const handleToggle = () => {
    if (darkMode) {
      disableDarkMode();
    } else {
      enableDarkMode();
    }
  };
  

  

  
  
  
  
  
  
  
  
  
  

  
  
  
  
  

  
  
  
  
  

  
  

  
  
  

  
  
  

  return (
    <>
      <div
        className="d-flex flex-column flex-shrink-0 bg-body-tertiary align-items-center border-right leftbar"
        style={{ width: "5.5rem" }}
      >
        <a
          href="#"
          onClick={() => toggleDrawer("profile")}
          className="d-flex justify-content-center align-items-center flex-column link-body-emphasis mt-3 text-decoration-none"
          title="User"
          data-bs-toggle="tooltip"
          data-bs-placement="right"
        >
          {renderProfilePicture(auth)}
        </a>
        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
          <li>
            <a
              href="#"
              className={`nav-link border-bottom rounded-0 d-flex gap-1 ${(currentActiveTab=="chat")?"active":""}`}
              title="Message"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              onClick={
                () => {
                  toggleDrawer(null)
                  setSetCurrentActiveTab("chat")
                }}
            >
              {totalUnReadGlobal > 0 && (
                <span
                  className="badge bg-success position-absolute"
                  style={{
                    transform: "translate(-50%, -50%)",
                    fontSize: "0.75rem",
                    minWidth: "1rem",
                    zIndex: 2,
                  }}
                >
                  {totalUnReadGlobal}
                </span>
              )}
              <i className="mdi mdi-chat"></i>
            </a>
          </li>
          <li>
            <a
              href="#"
              className={`nav-link border-bottom rounded-0 ${(currentActiveTab=="Favorite")?"active":""}`}
              title="Favorite"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              onClick={() => {
                handleFavoriteOpen()
                setSetCurrentActiveTab("Favorite")
              }}
            >
              <i className="mdi mdi-star"></i>
            </a>
          </li>
          <li key="Notifications">
            <a
              href="#"
              className={`nav-link border-bottom rounded-0 ${(currentActiveTab=="Notifications")?"active":""}`}
              title="Notifications"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              onClick={()=>{
                handleNotificationOpen()
                setSetCurrentActiveTab("Notifications")
              }}
              style={{ padding: "10px 15px" }}
            >
              {unreadNotification > 0 && (
                <span
                  className="badge bg-success position-absolute"
                  style={{
                    transform: "translate(-50%, -50%)",
                    fontSize: "0.75rem",
                    minWidth: "1rem",
                    zIndex: 2,
                  }}
                >
                  {unreadNotification}
                </span>
              )}
              <i
                className="mdi mdi-bell"
                style={{ position: "relative", zIndex: 1 }}
              ></i>
            </a>
          </li>
          {auth?.isAdmin && (
            <li key="Admin">
              <a
                href="admin"
                className="nav-link border-bottom rounded-0"
                title="Admin"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
              >
                <i className="mdi mdi-key-variant"></i>
              </a>
            </li>
          )}
          <li key="CreateGroup">
            <a
              href="#"
              className={`nav-link border-bottom rounded-0 ${(currentActiveTab=="createGroup")?"active":""}`}
              title="Create New Group"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              onClick={() => {
                toggleDrawer("createGroup")
                setSetCurrentActiveTab("createGroup")
              }}
            >
              <i className="mdi mdi-plus"></i>
            </a>
          </li>
        </ul>
        <ul className="nav nav-pills nav-flush flex-column text-center mt-auto">
        <li> 
       <label htmlFor="theme" className="theme">
      <span className="theme__toggle-wrap" title="Theme Color"
              data-bs-toggle="tooltip"
              data-bs-placement="right">
        <input
          id="theme"
          className="theme__toggle"
          type="checkbox"
          role="switch"
          name="theme"
          value="dark"
          checked={darkMode}
          onChange={handleToggle}
        />
        <span className="theme__icon">
          <span className="theme__icon-part"></span>
          <span className="theme__icon-part"></span>
          <span className="theme__icon-part"></span>
          <span className="theme__icon-part"></span>
          <span className="theme__icon-part"></span>
          <span className="theme__icon-part"></span>
          <span className="theme__icon-part"></span>
          <span className="theme__icon-part"></span>
          <span className="theme__icon-part"></span>
        </span>
      </span>
    </label>
      </li>
          <li key="Settings">
            <a
              href="#"
              onClick={() => {
                toggleDrawer("setting")
                setSetCurrentActiveTab("setting")
              }}
              className={`nav-link border-bottom rounded-0 ${(currentActiveTab=="setting")?"active":""}`}
              title="Settings"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
            >
              <i className="mdi mdi-settings"></i>
            </a>
          </li>
          {} 
          <li key="Logout">
            <a
              href="#"
              className="nav-link border-bottom rounded-0 logoutftlast"
              title="Logout"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              onClick={handleLogoutClick}
            >
              <i className="mdi mdi-power"></i>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Leftbar;
