import { put, call } from 'redux-saga/effects';
import { getAllPermissionsAPI } from 'src/api/MasterPermissionsApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { get } from 'lodash';
import { PERMISSION_MASTER_ACTIONS } from '../actions/PermissionsMasterActions';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(getAllPermissionsAPI, {
            where: buildSearchWithNoCase({
                where,
                fullTextSearch,
            }),
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });
        yield put({
            type: PERMISSION_MASTER_ACTIONS.PERMISSION_MASTER_FETCH_SUCCESS,
            data: get(data, '[0].scopes', {}),
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: PERMISSION_MASTER_ACTIONS.PERMISSION_MASTER_FETCH_ERROR,
        });
        return null;
    }
}

export default [
    takeFirstSagaUtil(PERMISSION_MASTER_ACTIONS.PERMISSION_MASTER_FETCH, search),
];
