import React from "react";
import { useSelector } from "react-redux";
import { convertTimestampToTime, formatDate } from "src/utils/mediaUtils";
import ControlsList from "./ControlsList";

const MediaDocuments = ({
  id,
  key,
  media,
  handleRevealInConversation,
  handleSelectMedia,
  isSelection,
  handleDelete,
  handleForward,
  setIsSelection,
  handlePin,
  selectedFileMedias,
  copyMessageToClipboard,
  userMediaRev,
  scrollToItem
}) => {
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="filevewmg" key={key} id={id}>
      <div className="d-flex justify-content-between align-items-center dtvs">
        <div className="d-flex justify-content-between align-items-center date font-12 color80">
          {formatDate(media?.ts)} <span className=""></span>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center font-10 color80 dtvs-con">
        <div
          className="font-12 color80"
          style={{ display: "flex", marginBottom: "4px" }}
        >
          {isSelection && (
            <label className="cstCheck fcheck" data={media._id}>
              <input
                type="checkbox"
                id={`selectMessage_${media._id}`}
                onChange={(e) => handleSelectMedia(e, media)}
                checked={selectedFileMedias?.includes(media?._id)}
              />
              <span
                className="checkmark"
                style={{ width: "18px", height: "18px" }}
                onClick={() => {}}
              ></span>
            </label>
          )}
          <div style={{ display: "flex", justifyContent: "center", gap: 2 }}>
            {" "}
            {(media.senderId || media.userId) === user?._id ? "You" : null}{" "}
            <span style={{ fontWeight: "bold" }} className="dtm">
              {convertTimestampToTime(media?.ts)}
            </span>
          </div>
          {media?.isPinned && (
            <i style={{ margin: 1 }} className="mdi mdi-pin-outline" />
          )}
        </div>
        {userMediaRev && (
          <div
            onClick={() => {
              handleRevealInConversation(media);
              scrollToItem(media._id)
            }}
            style={{
              backgroundColor: "transparent",
              borderRadius: "4px",
              cursor: "pointer",
              transition: "background-color 0.3s",
              marginBottom: "4px",
              padding: "2px 6px",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#b6e0f9";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "transparent";
            }}
          >
            Reveal in conversation
          </div>
        )}
      </div>
      <div className="img mw330 position-relative rounded-2">
        <a href={media.url} target="_blank" download rel="noreferrer">
          <div className="video-container">
            <div className="d-flex justify-content-start align-items-center zipfile playbtnaudiobg">
              <img
                src="assets/images/videoimg/zip-file.svg"
                className="img-fluid"
                alt=""
              />
              <span className="textQu">{media?.title}</span>
            </div>
          </div>
        </a>
        <div className="dropdown toggleBtns toggleBtnsfile">
          <button
            className="toggledots"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="mdi mdi-dots-vertical" />
          </button>
          <ControlsList
            media={media}
            handleDelete={handleDelete}
            handleForward={handleForward}
            setIsSelection={setIsSelection}
            handlePin={handlePin}
            copyMessageToClipboard={copyMessageToClipboard}
          />
        </div>
      </div>
    </div>
  );
};
export default MediaDocuments;
