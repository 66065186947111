import React from 'react';
import FormikSearchDropdown from 'src/components/formik/FormikSearchDropdown/FormikSearchDropdown';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import PropTypes from 'prop-types';
import { getDepartmentAPI } from 'src/api/DepartmentApi';

const DepartmentDropDown = ({ value, name, onChange,...rest }) => (
    <FormikSearchDropdown
        name={name}
        placeholder="Select Department"
        displayKeyPath={[
            'departmentName',
        ]}
        valueKeyPath="_id"
        defaultValueFetchByKeyPath="_id"
        whereClauseKeysPaths={[
            'departmentName',
        ]}
        api={getDepartmentAPI}
        apiCallAddonData={{
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
        }}
        value={value}
        onChange={onChange} 
        {...rest}
    />
);

DepartmentDropDown.propTypes = {
    
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
};

DepartmentDropDown.defaultProps = {
    value: '',
    readOnly: false,
};

export default DepartmentDropDown;
