import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { TitlesOptions } from "./AccountConstants"
import { accountUpsertAction } from "./actions/AccountActions";

const TitlesDisplaySetting=({openedSubDrawer,toggleSubDrawer})=>{
   
   const dispatch = useDispatch(); 
   const accountSetting = useSelector((state) => state.AccountReducer.currentRecord);
   const userID = useSelector((state) => state.auth.user?._id);
   const [preferences,setPreferences]=useState(accountSetting);
   const handlePreferenceChange=(e)=>{
      const { name, value } = e.currentTarget;
      const newValue = value === "true" ? false : true;      
      const accountUpdatePayload = {accountSettingInformation:{...preferences,[name]:newValue}};
      setPreferences(accountUpdatePayload)
      dispatch(accountUpsertAction({_id:userID,accountUpdatePayload}));      
   }
 return (
    <>
    <div className="dflex align-items-center justify-content-between notifications slist" style={{display:(openedSubDrawer.isOpen)?"none":""}}  onClick={()=>{toggleSubDrawer("title",true)}}>
   <div className="font-16 color1a fw-bold">
      Titles Display <span className="d-block font-14 color66 fw-normal">Update Your Titles Display</span>
   </div>
   <span className="arrow"><i className="mdi mdi-chevron-right"></i></span>
</div>
<div className="accountstg p15 border-0"  id="TitlesDisplayupdate" style={{display:(openedSubDrawer.type=="title" && openedSubDrawer.isOpen)?"block":"none"}}>
   <div className="dflex justify-content-between align-items-center createnotes font-16 fw-bold color1a btnclr fixedtop">
      Titles  
      <button className="rounded-4 closetabpassword" onClick={()=>toggleSubDrawer("title",false)}><i className="mdi mdi-chevron-left"></i></button>
   </div>
   <div className="Achpaswd">
   {
         TitlesOptions.map((PreOption,index)=>{
            const isChecked = (preferences[PreOption.value]) ?? true
            return (
               <div className="dflex align-items-center justify-content-between py-2" key={index}>
               <div className="font-14 color1a fw-500">
                {PreOption.heading}
               </div>
               <div className="onofbtnwx">
                  <button 
                     type="button" 
                     className={`btn btn-toggle me-0 ${isChecked?'active':''}`}
                     data-bs-toggle="button"
                     aria-pressed="false" 
                     autoComplete="off"
                     name={PreOption.value}
                     value={isChecked}
                     onClick={handlePreferenceChange}
                  >
                     <div className="handle"></div>
                  </button>
               </div>
            </div>
            )
         })
       }
   </div>
</div>
    </>
 )
}
export default TitlesDisplaySetting