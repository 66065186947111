import {
    call, select, put,
} from 'redux-saga/effects';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { setDefaultHeaders } from 'src/utils/RequestUtil';
import { APP_ACTIONS } from '../actions/AppActions';
import { fetchUserDetailsAction, fetchUserScopesAction } from 'src/containers/auth-container/actions/AuthActions';

export function* setDefaultHeadersSaga() {
    try {
        
        const { auth: { user } } = yield select();

        setDefaultHeaders({
            Authorization: user.jwtToken,
        });
    }
    catch (error) {
        console.error('setDefaultHeadersSaga', error);
    }
}


export function* appInitSaga() {
    try {
        const { auth: { user } } = yield select();
        if (user?.jwtToken ) {
            
            yield call(setDefaultHeadersSaga);

           
            
            yield put(fetchUserDetailsAction({
                userId:user?.jwtToken,
            }));
            
            
            
            


        }
    }
    catch (error) {
        console.error('appInitSaga', error);
    }
}

export default [
    takeFirstSagaUtil(APP_ACTIONS.APP_INIT, appInitSaga),
    takeFirstSagaUtil(APP_ACTIONS.SET_DEFAULT_HEADERS, setDefaultHeadersSaga),
];
