import config from "src/config";
import {
  deleteRequest,
  patchRequest,
  postRequest,
} from "src/utils/RequestUtil";

export const getReminders = async (searchText, startDate, endDate) => {
  try {
    const url = `${config.baseUrl}reminder/user/reminders/all`;
    return postRequest({
      url,
      data: { searchText, startDate, endDate },
    });
  } catch (error) {
    return null;
  }
};
export const getRemindersHistory = async () => {
  try {
    const url = `${config.baseUrl}reminder/user/reminders/all/histories`;
    return postRequest({
      url,
      data: {},
    });
  } catch (error) {
    return null;
  }
};

export const addReminder = async (reminder) => {
  try {
    const url = `${config.baseUrl}reminder/user/reminders/add`;
    return postRequest({
      url,
      data: { reminder },
    });
  } catch (error) {
    return null;
  }
};

export const updateReminder = async (reminder, editableTodoId) => {
  try {
    const url = `${config.baseUrl}reminder/user/reminders/update`;
    return patchRequest({
      url,
      data: {
        reminderId: editableTodoId,
        updatedReminderData: reminder,
      },
    });
  } catch (error) {
    return null;
  }
};

export const deleteReminder = async (reminderId) => {
  try {
    const url = `${config.baseUrl}reminder/user/reminders/delete`;
    return deleteRequest({
      url,
      data: { reminderId },
    });
  } catch (error) {
    return null;
  }
};

export const pinReminder = async (reminderId) => {
  try {
    const url = `${config.baseUrl}reminder/user/reminders/pin`;
    return patchRequest({
      url,
      data: {
        reminderId,
      },
    });
  } catch (error) {
    return null;
  }
};

export const cancelReminder = async (reminderId) => {
  try {
    const url = `${config.baseUrl}reminder/user/reminders/expire`;
    return patchRequest({
      url,
      data: {
        reminderId,
      },
    });
  } catch (error) {
    return null;
  }
};
