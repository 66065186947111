import { useCallback, useState,useEffect } from "react";
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import { departmentEditAction, departmentSearchAction, departmentUpsertAction } from './actions/DepartmentActions';
import Pagination from "src/components/Pagination";

const DepartmentSearchList=({search})=>{
    
    const dispatch = useDispatch();
    const [where,setWhere] = useState({});
    const[pageNo,setPageNo] = useState(0);
    
    const editClick = useCallback((type,record) => {
        if (type==="edit" && departmentEditAction) {
            dispatch(departmentEditAction(record));
        }
        else{
            const deletePayload = { 
                _id:record._id,
                active:false,
                deleted:true,
                deletedTs:new Date(),
            }
            dispatch(departmentUpsertAction(deletePayload));
            
        }
    }, [
        dispatch,
        departmentEditAction,
    ]);
    const handleTextSearch = useCallback(
        debounce((searchKey, setWhere) => {
          const whereClauseKeysPaths = ['departmentName'];
          const whereWithOrCondition = {
            $or: whereClauseKeysPaths.map((keyPath) => ({ 
              [keyPath]: {
                $regex: searchKey,
                $options: 'i',
              },
            })),
          }; 
          setWhere(whereWithOrCondition);
        }, 300),
        []
      );

    const handleSearchInputChange = (e) => {
        const searchKey = e.target.value;
        handleTextSearch(searchKey, setWhere);
      };
      useEffect(()=>{ 
        const payload = {
            where, 
            currentPage:pageNo, 
            autopopulate:true, 
            fullTextSearch:true,
        }
        dispatch(departmentSearchAction(payload));
    },[where,pageNo])
    return (
        <div className="">
   <div className="d-flex justify-content-between align-items-center pt-4 pb-2">
      <div className="font-18 fw-bold color1a">{search.data.length} Department</div>
            <input
            type="search"
            id="searchInput"
            className="form-control bg-white border w-50"
            placeholder="Search..."
            onChange={handleSearchInputChange} 
            />
   </div>
   <div className="">
    {
        search.data.map((department,index)=>{
            return(
                <div className="card d-flex flex-row align-items-center px-3 py-2 my-2" key={index}>
                        <div className="l font-14 color1a fw-bold me-auto">{department.departmentName}</div>
                        <div className="r d-flex align-items-center ms-auto ruserDe">
                            <div className="ruserDe2 font-14 color80">{department.userCount} Users </div>
                            <div className="ruserDe3">
                            <button className="bg-none border-0 font-18 color1a" onClick={()=>{editClick("edit",department)}}>
                            <i className="mdi mdi-pencil-outline" />
                            </button>{" "}
                            </div>
                            <div className="ruserDe4">
                            <button className="bg-none border-0 font-18 color1a" onClick={()=>{editClick("delete",department)}}>
                            <i className="mdi mdi-delete" />
                            </button>{" "}
                            </div>
                        </div>
                    </div> 
            )
        })
    }
      {search.data && search.data?.length>0 && <Pagination setPageNo={setPageNo} pageNo={pageNo} total={search.total}/>}

   </div>
</div>
    )
}

export default DepartmentSearchList;