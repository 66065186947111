import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

const AdminPrivateRouteHOC = ({ Component,socket }) => {
  const user = useSelector((state) => state.auth.user);
  const { jwtToken: authToken } = user || {};
  return (authToken && user.isAdmin ) ? <Component socket={socket} />  : <Navigate to="/home" />;
};
export default AdminPrivateRouteHOC;
