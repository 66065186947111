

import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import $ from 'jquery';
import 'bootstrap-daterangepicker';

const DateRangePicker = ({ onDateRangeChange }) => {
  const pickerRef = useRef(null);

  useEffect(() => {
    const $picker = $(pickerRef.current);

    const start = moment().subtract(29, 'days');
    const end = moment();

    function cb(start, end) {
      onDateRangeChange(start, end);
      $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    $picker.daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);

    cb(start, end);

    return () => {
      if ($picker.data('daterangepicker')) {
        $picker.data('daterangepicker').remove();
      }
    };
  }, []);

  return (
    <div>
      <div
        id="reportrange"
        ref={pickerRef}
        className='reportrange d-flex align-items-center form-control cursor-pointer border-0 mxwxctm'
        style={{
          
          cursor: 'pointer',
          padding: '5px 10px',
          border: '1px solid #ccc',
          width: '100%'
        }}
      >
        <i className="mdi mdi-calendar"></i>
        &nbsp;
        <span />
      </div>
    </div>
  );
};

export default DateRangePicker;

