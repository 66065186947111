import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { DepartmentDefaultProps } from 'src/containers/admin-containers/department-container/DepartmentPropTypes';
import { DEPARTMENT_ACTIONS } from '../actions/DepartmentActions';

const initialState = () => ({
    currentRecord: { ...DepartmentDefaultProps },

    search: {
        data: [],
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case DEPARTMENT_ACTIONS.DEPARTMENT_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case DEPARTMENT_ACTIONS.DEPARTMENT_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case DEPARTMENT_ACTIONS.DEPARTMENT_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case DEPARTMENT_ACTIONS.DEPARTMENT_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case DEPARTMENT_ACTIONS.DEPARTMENT_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case DEPARTMENT_ACTIONS.DEPARTMENT_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case DEPARTMENT_ACTIONS.DEPARTMENT_RESET: {
            return {
                ...state,
                currentRecord: { ...DepartmentDefaultProps },
            };
        }

        default:
            return state;
    }
};
