import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrentTime } from "src/utils/CommonUtils";
import { truncateString } from "src/utils/utils";
import PropTypes from "prop-types";

const GroupChatProfile = (props) => {
  const {
    resetAndToggleModal,
    groupIndex,
    getRootProps,
    grp,
    handleGroupClick,
    groupTyping,
    user,
    item,
    setTotalUnRead,
    markAsFavUser,
    selectedRoomId,
  } = props;

  const currentGroupRedux = useSelector(
    (state) => state.chatHistoryReducer.currentGroup
  );

  useEffect(() => {
    setIsUnRead(item?.unRead);
    setTotalUnRead((prevCount) => prevCount + (item?.unRead || 0));

    return () => {
      setTotalUnRead((prevCount) => {
        if (prevCount > 0) {
          return prevCount - (item?.unRead || 0);
        } else {
          return prevCount;
        }
      });
    };
  }, [item?.unRead, setTotalUnRead]);
  const [isSelected, setIsSelected] = useState(false);
  const [isUnRead, setIsUnRead] = useState(item?.unRead);
  // useEffect(() => {
  //   setIsSelected(grp._id === currentGroupRedux._id);
  // }, [currentGroupRedux]);
  return (
    <>
      <div
        className={`chat-user-list ${(selectedRoomId===grp?._id)  ? "selectedChatBox" : ""} ${
          grp?.isDeactivated && !grp?.isDeleted ? "blurGroup" : ""
        }  ${grp?.isDeleted ? "dangerGroup" : ""}`}
        {...getRootProps()}
        onClick={(e) => e.preventDefault()}
        key={groupIndex}
      >
        <a
          href="#"
          className="d-flex align-items-start"
          onClick={(e) => {
            resetAndToggleModal();
            setIsUnRead(null);
            setTotalUnRead((prevCount) => {
              const decrement = item?.unRead || 0;
              return Math.max(prevCount - decrement, 0);
            });
            handleGroupClick(e, item, grp);
          }}
          onDrop={(e) => handleGroupClick(e, item, grp)}
        >
          <div className="flex-shrink-0 position-relative">
            <img
              className="img-fluid userimg"
              src={
                grp?.groupDP?.url ||
                "https://s3.ap-south-1.amazonaws.com/syncupteams.com/media/TroopMessenger_1715320773240-1715320835666.png"
              }
              alt={grp?.groupDP?.title || "SyncUp Teams"}
              width={45}
              height={45}
              style={{ border: "solid #c890ef", borderWidth: "2px" }}
            />
          </div>
          <div className="flex-grow-1 ms-3">
            <div className="d-flex align-items-center justify-content-between">
              {}
              <h3>
                <span className="gropu-icon" /> {grp.title}
              </h3>
            </div>
            <div className="d-flex justify-content-between grptext">
              {grp?.noParticipants &&
              grp?.noParticipants.includes(user?._id) ? (
                <div className="text onelinetext font-14 d-flex align-items-center text66">
                  Not a member
                </div>
              ) : (
                <div className="text onelinetext font-14 d-flex align-items-center text66">
                  {(item?.isDeletedFor &&
                    item?.isDeletedFor.includes(user?._id)) ||
                  item?.isDeleteForAll ? (
                    "Message deleted"
                  ) : item?.messageType == "media" ? (
                    <>
                     {(item?.isDeletedFor && item?.isDeletedFor.includes(user?._id)) ||
              item?.isDeleteForAll ?  "Message deleted" :<i className="mdi mdi-file">File</i>   } 
                    </>
                  ) : (
                    truncateString(item?.message, 20)
                  )}
                </div>
              )}
            </div>
            <div className="onlineOfine d-flex justify-content-between align-items-center">
              <div className="clock font-14 textb3">
                {groupTyping?.groupId === grp?._id &&
                groupTyping?.senderId !== user?._id &&
                groupTyping?.message?.trim() ? (
                  <span style={{color:"#02bc7d", fontSize: "14px", fontWeight:"500"}}>   {groupTyping?.message }</span>
                ) : (
                  <>
                    {} {getCurrentTime(item?.updated_at)}
                  </>
                )}
              </div>
            </div>
          </div>
        </a>
        <div className="d-flex align-items-center actvnewi flex-column-reverse">
          <button
            className={`orangestar`}
            id={`str_${item?._id}`}
            onClick={() => markAsFavUser(item)}
          >
            <i
              className={`mdi mdi-star mdi-18px ${
                item?.isFav && item?.isFav.includes(user?._id) ? "active" : ""
              }`}
            ></i>
          </button>
          {item?.unRead && parseInt(item?.unRead) > 0 && isUnRead ? (
            <span className="bg1C">{isUnRead} </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

GroupChatProfile.propTypes = {
  resetAndToggleModal: PropTypes.func.isRequired,
  groupIndex: PropTypes.number.isRequired,
  getRootProps: PropTypes.func.isRequired,
  grp: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    isDeactivated: PropTypes.bool,
    isDeleted: PropTypes.bool,
    groupDP: PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
    title: PropTypes.string,
    noParticipants: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  handleGroupClick: PropTypes.func.isRequired,
  groupTyping: PropTypes.shape({
    groupId: PropTypes.string,
    senderId: PropTypes.string,
    message: PropTypes.string,
  }),
  user: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  item: PropTypes.shape({
    unRead: PropTypes.number,
    updated_at: PropTypes.string,
    isDeletedFor: PropTypes.arrayOf(PropTypes.string),
    isDeleteForAll: PropTypes.bool,
    messageType: PropTypes.string,
    message: PropTypes.string,
    _id: PropTypes.string.isRequired,
    isFav: PropTypes.arrayOf(PropTypes.string),
    action: PropTypes.string,
    mdiIconClass: PropTypes.string,
  }).isRequired,
  setTotalUnRead: PropTypes.func.isRequired,
  markAsFavUser: PropTypes.func.isRequired,
};

export default GroupChatProfile;
