import React, { createContext, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationContext = createContext();

export const useNotification = () => {
  return useContext(NotificationContext);
};

const NotificationProvider = ({ children }) => {
  const openSuccessNotification = (message ) => {
    toast.success(message,{ autoClose: 1000 });
  };

  const openErrorNotification = (message) => {
    toast.error(message,{ autoClose: 1000 });
  };

  const openWarningNotification = (message) => {
    toast.warning(message,{ autoClose: 1000 });
  };

  const openInfoNotification = (message) => {
    toast.info(message,{ autoClose: 1000 });
  };

  const value = {
    openSuccessNotification,
    openErrorNotification,
    openWarningNotification,
    openInfoNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      <ToastContainer />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
