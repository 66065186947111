
import { CSVLink } from 'react-csv'

const ListAction=({
    recoard,
    handleOpenProfile,
    handleStatusChange,
    deleteFileAndChat,
    optionExportInExcel  
   })=>{
   const  handleActionClick=(type,value)=>{
     switch(type) {
        case "profile":
            handleOpenProfile(recoard)
          break;
        case "status":
             handleStatusChange(recoard)
          break;
        case "delete":
            deleteFileAndChat(recoard,value) 
          break;
        case "export":
           optionExportInExcel(recoard,value)
          break;
        default:
          
      }
    }
   return (
    <>
            <div className="btn-reveal-trigger position-static dotstabl">
            <button
                className="btn btn-sm dropdown-toggle dropdown-caret-none"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-bs-reference="parent"
                
                >
            <i className="mdi mdi-dots-horizontal mdi-36px" />
            </button>
            <div className="dropdown-menu dropdown-menu-end py-2">
                <a
                    className="dropdown-item font-12 fw-bold color1a"
                    href="#"
                    onClick={()=>{handleActionClick("profile")}}
                    >
                View
                </a>
                <a  className={`dropdown-item  font-12 fw-bold color1a ${(recoard.isDeactivated)?'text-success':'text-danger'}`}  href="#"
                    onClick={()=>{handleActionClick("status")}} >
                {(recoard.isDeactivated)?'Activate':'De-Activate'}
                </a>
                <a className="dropdown-item font-12 fw-bold color1a" 
                href="#"
                onClick={()=>{handleActionClick("delete","all")}}
                >
                Delete All Message & File
                </a>
                <a className="dropdown-item font-12 fw-bold color1a" 
                href="#" 
                onClick={()=>{handleActionClick("delete","media")}}
                >
                Delete All File
                </a>
                <a className="dropdown-item font-12 fw-bold color1a" 
                href="#"
                onClick={()=>{handleActionClick("delete","text")}}
                >
                Delete All Message
                </a>
                
                <a className="dropdown-item font-12 fw-bold color1a" 
                href="#" 
                onClick={()=>{handleActionClick("export","chatExport")}}
                > 
                   Export Chat To Excel
                </a>
                

                <a className="dropdown-item font-12 fw-bold color1a" 
                href="#"
                onClick={()=>{handleActionClick("export","memberExport")}}
                >
                  Export Group Members To Excel
                </a> 
            </div>
            </div>
    </>
   )
}
export default ListAction