import { put, call } from 'redux-saga/effects';
import { locationCreateAPI, locationSearchAPI, locationUpdateAPI } from 'src/api/LocationApi';
import { SEARCH_RECORD_PAGE_LIMIT } from 'src/constants/CommonConstants';
import { headerShowAction } from 'src/containers/header-container/actions/HeaderActions';
import { takeFirstSagaUtil } from 'src/utils/ReduxSagaUtils';
import { buildSearchWithNoCase } from 'src/utils/CommonUtils';
import { LOCATION_ACTIONS } from '../actions/LocationActions';
import { toast } from 'react-toastify';

function* search({
    where, currentPage = 0, autopopulate = true, fullTextSearch = true,
} = {}) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(locationSearchAPI, {
            where,
            pageLength: SEARCH_RECORD_PAGE_LIMIT,
            currentPage,
            autopopulate,
        });

        yield put({
            type: LOCATION_ACTIONS.LOCATION_SEARCH_SUCCESS,
            data,
        });

        return data;
    }
    catch (error) {
        console.error('LOG: function*search -> error', error);
        yield put({
            type: LOCATION_ACTIONS.LOCATION_SEARCH_ERROR,
        });
        return null;
    }
}

function* edit({
    _id,
}) {
    try {
        const {
            data: {
                data: {
                    data,
                },
            },
        } = yield call(locationSearchAPI, {
            where: {
                _id,
            },
            autopopulate: false,
        });

        if (!data || !data[0]) {
            
            yield put(headerShowAction({
                data: 'Unable to Edit, something when wrong',
            }));
        }

        
        yield put({
            type: LOCATION_ACTIONS.LOCATION_UPSERT_SUCCESS,
            data: data[0],
        });
    }
    catch (error) {
        console.error('LOG: error', error);

        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put(headerShowAction({
            data: error.response.data.data,
        }));
    }
}

function* insert(payload) {
    try {
        const {
            data: {
                data,
            },
        } = yield call(locationCreateAPI, payload);

        yield put(headerShowAction({
            data: 'Created',
        })); 
        toast("Location Created")
        
        yield put({
            type: LOCATION_ACTIONS.LOCATION_UPSERT_SUCCESS,
            data: {
                ...payload,
                _id: data._id,
            },
        });
        yield put({
            type: LOCATION_ACTIONS.LOCATION_SEARCH
        });
    }
    catch (error) {
        // console.error('LOG: upsert error', error);
        // yield put(headerShowAction({
        //     data: error.response.data.data,
        // }));
        toast.error(error.response.data.message);
        yield put({
            type: LOCATION_ACTIONS.LOCATION_UPSERT_ERROR,
        });
    }
}

function* update(locationId, payload) {
    try {
        yield call(locationUpdateAPI, {
            locationId,
            data: payload,
        });

        yield put(headerShowAction({
            data: 'Updated',
        }));
        if(payload.deleted===true){
            toast("Location deleted")
        }
        else{
            toast("Location updated")
        } 
        
        yield put({
            type: LOCATION_ACTIONS.LOCATION_SEARCH
        });
    }
    catch (error) {
        console.error('LOG: upsert error', error);
        yield put(headerShowAction({
            data: error.response.data.data,
        }));

        yield put({
            type: LOCATION_ACTIONS.LOCATION_UPSERT_ERROR,
        });
    }
}

function* upsert({
    _id,
    ...payload
}) {
    if (_id) {
        yield update(_id, payload);
    }
    else {
        yield insert(payload);
    }
}

export default [
    takeFirstSagaUtil(LOCATION_ACTIONS.LOCATION_SEARCH, search),
    takeFirstSagaUtil(LOCATION_ACTIONS.LOCATION_UPSERT, upsert),
    takeFirstSagaUtil(LOCATION_ACTIONS.LOCATION_EDIT, edit),
];
