import React, { useEffect } from "react";
import $ from "jquery";
import LandingFooter from "./Layout/Footer";
import LandingHead from "./Layout/Head";
import HeaderNav from "./Layout/HeaderNav";
import Testimonials from "./Testimonials";
import OurClients from "./OurClients";

const LandingPage = () => {
  useEffect(() => {
    const handleScroll = () => {
      const windowTop = $(window).scrollTop();
      windowTop > 100
        ? $(".s-tabsClick ul").css("top", "100px")
        : $(".s-tabsClick ul").css("top", "160px");
    };

    $(window).on("scroll", handleScroll);

    $('a[href*="#"]').on("click", function (e) {
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top - 100,
        },
        0
      );
      e.preventDefault();
    });

    const div = document.querySelectorAll(".samenv1");
    const lists = document.querySelectorAll(".list");

    const activeLink = (li) => {
      lists.forEach((item) => item.classList.remove("active"));
      li.classList.add("active");
    };

    lists.forEach((item) =>
      item.addEventListener("click", function () {
        activeLink(this);
      })
    );
  }, []);

  useEffect(() => {
    // Remove the 'darkmode' class from the body tag
    document.body.classList.remove('darkmode');

    // Optionally, you can clean up by re-adding the class when the component unmounts
    return () => {
      document.body.classList.add('darkmode');
    };
  }, []); // Empty dependency array means this runs once on mount and cleanup runs on unmount


  return (
    <>
      <title>Home - SyncUpTeam</title>
      <meta name="title" content="Home - SyncUpTeam" />
      <meta name="description" content="" />
      <meta name="keywords" content="" />
      <link rel="canonical" href="" />
      {/* og:Meta Title */}
      <meta property="og:title" content="Home - SyncUpTeam" />
      <meta property="og:description" content="" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="" />
      <meta property="og:locale" content="en_US" />
      <meta
        name="og_site_name"
        property="og:site_name"
        content="talk2lead.com"
      />
      <meta property="og:image" content="assets/images/landing/logo.svg" />
      {}
      <meta name="twitter:widgets:csp" content="on" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Home - SyncUpTeam" />
      <meta name="twitter:description" content="" />
      <meta name="twitter:site" content="talk2lead_Inc" />
      <meta name="twitter:image" content="assets/images/landing/logo.svg" />
      {}
      <LandingHead />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/jquery.slick/1.5.9/slick.css"
      />
      <link rel="stylesheet" href="assets/css/style.css" />
      {}
      <HeaderNav />
      {}
      <section className="sec H-banner-sec pb-0 samenv1" id="Home">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6 text-start H-banner d-flex flex-column justify-content-center align-items-start">
              <div className="text">
                <h1 className="font-52 fw-600 color1a">
                  Empower Your Team with Instant Communication
                </h1>
                <p className="font-18 fw-500 color1a">
                  Experience the future of workplace messaging with our
                  feature-rich app.
                </p>
                <button className="btnClr font-16">Try It Free Now</button>
                <ul className="clntrating pt-5 d-flex align-items-center gap-5">
                  <li className="d-flex align-items-center gap-2 py-2">
                    <img
                      src="assets/images/landing/trustpilot.png"
                      className="img-fluid"
                      alt="Trustpilot - SyncupTeams"
                    />
                  </li>
                  <li className="d-flex align-items-center gap-2 py-2">
                    <img
                      src="assets/images/landing/google.png"
                      className="img-fluid"
                      alt="Google Reviews - SyncupTeams"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 text-center Img-banner">
              <span className="bannerImg" />
            </div>
          </div>
        </div>
      </section>
      {}
      {}
      {}
      {}
      <OurClients />
      <section className="sec Features samenv1" id="Features">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center a-hding mb40">
              <h2 className="fw-600 color1a font-40">Features </h2>
              <p className="fw-500 color1a font-16">
                Traditional communication methods were failing to keep up with
                the fast-paced demands of modern work environments.
              </p>
            </div>
            <div className="col-md-6 col-lg-3 text-center f-div">
              <div className="f-col rounded-4 p-4">
                <div className="img">
                  <img
                    src="assets/images/landing/real-time-messaging.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="color1a font-18 fw-600">Real-Time Messaging</h3>
                <p className="color66 font-14 fw-500">
                  Instant communication with your team.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 text-center f-div">
              <div className="f-col rounded-4 p-4">
                <div className="img">
                  <img
                    src="assets/images/landing/file-sharing.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="color1a font-18 fw-600">File Sharing</h3>
                <p className="color66 font-14 fw-500">
                  Easily share documents and media.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 text-center f-div">
              <div className="f-col rounded-4 p-4">
                <div className="img">
                  <img
                    src="assets/images/landing/group-chats.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="color1a font-18 fw-600">Group Chats</h3>
                <p className="color66 font-14 fw-500">
                  Create group conversations for different projects or teams.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 text-center f-div">
              <div className="f-col rounded-4 p-4">
                <div className="img">
                  <img
                    src="assets/images/landing/security.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="color1a font-18 fw-600">Security</h3>
                <p className="color66 font-14 fw-500">
                  End-to-end encryption to keep your data safe.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="sec Aboutus bgLBlue samenv1 overflow-hidden"
        id="AboutUs"
      >
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-md-12 col-lg-6 text-center about-img">
              <div className="about-colImg">
                <img
                  src="assets/images/landing/innovating-communication.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6 text-start about-text d-flex justify-content-center flex-column align-items-start">
              <span className="color1a font-24 fw-normal">About Us</span>
              <h2 className="color1a font-40 fw-600">
                Innovating Communication for Modern Teams{" "}
              </h2>
              <p>
                The idea for our messenger application was born out of a simple
                observation: traditional communication methods were failing to
                keep up with the fast-paced demands of modern work environments.
                Emails were too slow, and existing messaging platforms were
                either too complex or lacked essential features. We knew there
                had to be a better way.
              </p>
              <button
                className="btnClr font-16"
                onClick="window.location.href=''"
              >
                Get Started{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section
        className="sec idea pb-0 overflow-hidden pb-0 samenv1"
        id="Download"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center a-hding mb40">
              <h2 className="color1a font-40 fw-600 w-75 ms-auto me-auto">
                The idea for our messenger application was born out of a simple
                observation
              </h2>
              <p className="color1a font-16 fw-500">
                Traditional communication methods were failing to keep up with
                the fast-paced demands of modern work environments.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-bdrtop">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 text-center app-download pe-0">
                <div className="app-colImg border-start-0">
                  <div className="appImg">
                    <img
                      src="assets/images/landing/desktop1.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="appText">
                    <h3 className="color1a font-24 fw-600">Desktop</h3>
                    <p className="color1a font-16 fw-500">
                      SyncUpTeams is the best Applications for desktop
                    </p>
                    {}
                    <div className="btn-group dropup">
                      <button type="button" className="btn downbtnBlk">
                        Download
                        <span className="d-block">
                          for Windows/mac/ubantu/linux
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn dropdown-toggle downbtnBlk dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="visually-hidden">Toggle Dropdown</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            Windows
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            mac
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            ubantu
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            linux
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 text-center app-download ps-0">
                <div className="app-colImg">
                  <div className="appImg">
                    <img
                      src="assets/images/landing/mobile.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="appText">
                    <h3 className="color1a font-24 fw-600">Mobile </h3>
                    <p className="color1a font-16 fw-500">
                      SyncUpTeams is the best Applications for mobile
                    </p>
                    <div className="downloadApp">
                      <h4 className="color1a font-20 fw-500">
                        Download our App from
                      </h4>
                      <ul className="applst d-flex justify-content-center gap-3">
                        <li className="">
                          <a href="#">
                            <img
                              src="assets/images/landing/app-store.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img
                              src="assets/images/landing/google-play.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <section className="sec PricingPlans pb-0 samenv1" id="Pricing">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center a-hding">
              <h2 className="font-40 color1a fw-600">Pricing Plans</h2>
              <p className="font-16 color1a fw-500">
                You can start using the application by selecting any package.
              </p>
            </div>
            <div className="col-md-12 col-lg-12 text-center p-div">
              <div className="p-tab mb40">
                <ul
                  className="nav nav-pills mb-3 justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-Monthly-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Monthly"
                      type="button"
                      role="tab"
                      aria-controls="pills-Monthly"
                      aria-selected="true"
                    >
                      Monthly
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-Yearly-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-Yearly"
                      type="button"
                      role="tab"
                      aria-controls="pills-Yearly"
                      aria-selected="false"
                    >
                      Yearly{" "}
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-Monthly"
                  role="tabpanel"
                  aria-labelledby="pills-Monthly-tab"
                >
                  <div>
                    <div className="row row-cols-1 row-cols-md-3 mb-3 text-center Rprilist">
                      <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm prilist">
                          <div className="card-body">
                            <div className="card-title pricing-card-title font-40">
                              Free{" "}
                              <span className="fw-500 d-block font-18 color80">
                                1 Month Free
                              </span>
                            </div>
                            <ul className="list-unstyled mt-3 mb-4 text-start">
                              <li>
                                <i className="mdi mdi-close" /> Video chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Voice chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> To be able to
                                invite users
                              </li>
                              <li>
                                <i className="mdi mdi-close" /> Unlimited file
                                exchange
                              </li>
                              <li>
                                <i className="mdi mdi-close" /> Creating a group
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Chat without
                                limit
                              </li>
                              <li>
                                <i className="mdi mdi-close" /> Team work
                              </li>
                            </ul>
                            <button
                              type="button"
                              className="w-100 btn btn-lg btn-dark font-18 fw-500"
                            >
                              Start Trial{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-info prilist overflow-hidden">
                          <div className="bg-info text-white">Recommended</div>
                          <div className="card-body">
                            <div className="card-title pricing-card-title font-40">
                              $99{" "}
                              <span className="fw-500 d-block font-18 color80">
                                Monthly
                              </span>
                            </div>
                            <ul className="list-unstyled mt-3 mb-4 text-start">
                              <li>
                                <i className="mdi mdi-close" /> Video chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Voice chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> To be able to
                                invite users
                              </li>
                              <li>
                                <i className="mdi mdi-close" /> Unlimited file
                                exchange
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Creating a group
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Chat without
                                limit
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Team work
                              </li>
                            </ul>
                            <button
                              type="button"
                              className="w-100 btn btn-lg btn-info font-18 fw-500 text-white"
                            >
                              Upgrade Now
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm prilist">
                          <div className="card-body">
                            <div className="card-title pricing-card-title font-40">
                              $199{" "}
                              <span className="fw-500 d-block font-18 color80">
                                Monthly
                              </span>
                            </div>
                            <ul className="list-unstyled mt-3 mb-4 text-start">
                              <li>
                                <i className="mdi mdi-check" /> Video chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Voice chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> To be able to
                                invite users
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Unlimited file
                                exchange
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Creating a group
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Chat without
                                limit
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Team work
                              </li>
                            </ul>
                            <button
                              type="button"
                              className="w-100 btn btn-lg btn-outline-info font-18 fw-500"
                            >
                              Upgrade Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-Yearly"
                  role="tabpanel"
                  aria-labelledby="pills-Yearly-tab"
                >
                  <main>
                    <div className="row row-cols-1 row-cols-md-3 mb-3 text-center Rprilist">
                      <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm prilist">
                          <div className="card-body">
                            <div className="card-title pricing-card-title font-40">
                              Free{" "}
                              <span className="fw-500 d-block font-18 color80">
                                1 Month Free
                              </span>
                            </div>
                            <ul className="list-unstyled mt-3 mb-4 text-start">
                              <li>
                                <i className="mdi mdi-close" /> Video chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Voice chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> To be able to
                                invite users
                              </li>
                              <li>
                                <i className="mdi mdi-close" /> Unlimited file
                                exchange
                              </li>
                              <li>
                                <i className="mdi mdi-close" /> Creating a group
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Chat without
                                limit
                              </li>
                              <li>
                                <i className="mdi mdi-close" /> Team work
                              </li>
                            </ul>
                            <button
                              type="button"
                              className="w-100 btn btn-lg btn-dark font-18 fw-500"
                            >
                              Start Trial{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-info prilist overflow-hidden">
                          <div className="bg-info text-white">Recommended</div>
                          <div className="card-body">
                            <div className="card-title pricing-card-title font-40">
                              $896{" "}
                              <span className="fw-500 d-block font-18 color80">
                                Yearly
                              </span>
                            </div>
                            <ul className="list-unstyled mt-3 mb-4 text-start">
                              <li>
                                <i className="mdi mdi-close" /> Video chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Voice chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> To be able to
                                invite users
                              </li>
                              <li>
                                <i className="mdi mdi-close" /> Unlimited file
                                exchange
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Creating a group
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Chat without
                                limit
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Team work
                              </li>
                            </ul>
                            <button
                              type="button"
                              className="w-100 btn btn-lg btn-info font-18 fw-500 text-white"
                            >
                              Upgrade Now
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm prilist">
                          <div className="card-body">
                            <div className="card-title pricing-card-title font-40">
                              $1920{" "}
                              <span className="fw-500 d-block font-18 color80">
                                Yearly
                              </span>
                            </div>
                            <ul className="list-unstyled mt-3 mb-4 text-start">
                              <li>
                                <i className="mdi mdi-check" /> Video chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Voice chat
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> To be able to
                                invite users
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Unlimited file
                                exchange
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Creating a group
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Chat without
                                limit
                              </li>
                              <li>
                                <i className="mdi mdi-check" /> Team work
                              </li>
                            </ul>
                            <button
                              type="button"
                              className="w-100 btn btn-lg btn-outline-info font-18 fw-500"
                            >
                              Upgrade Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {}
      <section className="sec faqs">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="color1a fw-600">Frequently Asked Questions</h2>
              <p>
                We have gathered your frequently asked questions. So you can
                solve your problems faster.
              </p>
            </div>
            <div className="accordion mt-4" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <span className="fawNum">1</span> How does Syncupteams call
                    monitoring software work?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Syncupteams can be downloaded directly on your team’s
                      smartphone after which you can start monitoring all their
                      call activity. You will receive daily updates on your
                      email or can access everything on a central dashboard in
                      real time to track the team’s performance, monitor their
                      daily work hours, and more.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span className="fawNum">2</span> How is my data secured?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      We will take reasonable precautions to protect the data:
                      All your data, especially mobile numbers are stored in an
                      encrypted format. Our web application is secured with
                      HTTPS. Mobile Application is approved and available on
                      Play Store. Your data is available to you only and it’s
                      not being shared with anyone. As per our policy, we don’t
                      sell data to any agency and we follow all generally
                      accepted industry standards to secure your data.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <span className="fawNum">3</span> Is Android and iOS app
                    available?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Yes, Syncupteams application is available on Google Play
                      and App Store.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {}
      <LandingFooter />
      {}
      {}
    </>
  );
};
export default LandingPage;
