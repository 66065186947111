export const DESIGNATION_ACTIONS = {
    DESIGNATION_SEARCH: 'DESIGNATION_SEARCH',
    DESIGNATION_SEARCH_SUCCESS: 'DESIGNATION_SEARCH_SUCCESS',
    DESIGNATION_SEARCH_ERROR: 'DESIGNATION_SEARCH_ERROR',

    DESIGNATION_UPSERT: 'DESIGNATION_UPSERT',
    DESIGNATION_UPSERT_SUCCESS: 'DESIGNATION_UPSERT_SUCCESS',
    DESIGNATION_UPSERT_ERROR: 'DESIGNATION_UPSERT_ERROR',

    DESIGNATION_EDIT: 'DESIGNATION_EDIT',

    DESIGNATION_RESET: 'DESIGNATION_RESET',
};

export const designationResetAction = () => ({
    type: DESIGNATION_ACTIONS.DESIGNATION_RESET,
});

export const designationSearchAction = (payload) => ({
    type: DESIGNATION_ACTIONS.DESIGNATION_SEARCH,
    ...payload,
});

export const designationEditAction = (payload) => ({
    type: DESIGNATION_ACTIONS.DESIGNATION_EDIT,
    ...payload,
});

export const designationUpsertAction = (payload) => ({
    type: DESIGNATION_ACTIONS.DESIGNATION_UPSERT,
    ...payload,
});

export default {
    DESIGNATION_ACTIONS,
    designationSearchAction,
    designationUpsertAction,
    designationEditAction,
};
