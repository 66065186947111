import { useState, useContext } from "react";
import { UpdatePasswordAPI } from "src/api/AccountSettingApi";
import { SocketContext } from "src/context/SocketContext";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../login-container/LoginService";
import { logout } from "../home-container/actions/authAction";
import { useNotification } from "src/components/Notifination/ToastNotification";

const AccountSetting = ({ openedSubDrawer, toggleSubDrawer }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);
    const { openErrorNotification } = useNotification();
    const auth = useSelector((state) => state.auth.user);
    const { jwtToken: authToken, otherInformation: { profilePicture } = {} } = auth || {};

    const [payload, setPayload] = useState({ userId: auth._id });
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        setPayload({ ...payload, [e.target.name]: e.target.value });
    };

    const handleLogoutClick = async () => {
        try {
            if (await handleLogout(authToken)) {
                dispatch(logout());
                navigate("/");
                socket.disconnect();
            }
        } catch (error) {
            return openErrorNotification(
                error.response.data.message ?? "Error logging out"
            );
        } finally {
            dispatch(logout());
            navigate("/");
            socket.disconnect();
        }
    };

    const validateInputs = () => {
        const { oldPassword, newPassword, confirmPassword } = payload;
        if (!oldPassword || !newPassword || !confirmPassword) {
            toast.error("All fields are required.");
            return false;
        }
        if (newPassword !== confirmPassword) {
            toast.error("New password and confirm password do not match.");
            return false;
        }
        return true;
    };

    const handlePasswordChange = async () => {
        if (!validateInputs()) return;

        try {
            const response = await UpdatePasswordAPI(payload);
            if (response.data.status) {
                toast(response.data.message);
                document.body.classList.remove('BlurEffect');
                handleLogoutClick();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div
                className="dflex align-items-center justify-content-between account slist"
                id="paswordset"
                style={{ display: openedSubDrawer.isOpen ? "none" : "" }}
                onClick={() => { toggleSubDrawer("account", true) }}
            >
                <div className="font-16 color1a fw-bold">
                    Account
                    <span className="d-block font-14 color66 fw-normal">Update Your Account Details</span>
                </div>
                <span className="arrow"><i className="mdi mdi-chevron-right"></i></span>
            </div>

            <div
                className="accountstg p15 border-0"
                id="paswordupdate"
                style={{ display: openedSubDrawer.type === "account" && openedSubDrawer.isOpen ? "block" : "none" }}
            >
                <div className="dflex justify-content-between align-items-center createnotes font-16 fw-bold color1a btnclr">
                    Account
                    <button
                        className="rounded-4 closetabpassword"
                        onClick={() => toggleSubDrawer("account", false)}
                    >
                        <i className="mdi mdi-chevron-left"></i>
                    </button>
                </div>
                <div className="Achpaswd">
                    <div className="font-16 fw-normal color1a">Change Password</div>
                    <div className="chpaswd">
                        <input
                            type="password"
                            className="form-control my-2 font-14 fw-normal color66 border w-100"
                            placeholder="Enter Old Password"
                            name="oldPassword"
                            onChange={handleChange}
                        />
                        <input
                            type="password"
                            className="form-control my-2 font-14 fw-normal color66 border w-100"
                            placeholder="Enter New Password"
                            name="newPassword"
                            onChange={handleChange}
                        />
                        <div className="ConfirmNewPass">
                            <input
                                type={showPassword ? "text" : "password"}
                                className="form-control my-2 font-14 fw-normal color66 border w-100"
                                placeholder="Confirm New Password"
                                name="confirmPassword"
                                onChange={handleChange}
                            />
                            <button
                                type="button"
                                className="showPassword"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                            </button>
                        </div>
                        <button
                            type="button"
                            className="btnblue my-2"
                            onClick={handlePasswordChange} 
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountSetting;
