import React from "react";

const GroupItem = ({
  group,
  handleSelectForward,
  selectedUsers,
}) => {
  const isSelected = (userId, type) => {
    return selectedUsers.some(
      (user) => user.userOrGroupId === userId && user.type === type
    );
  };

  return (
    <a href="#" className="d-flex align-items-start" onClick={(e) => handleSelectForward(e, group?._id, "group")}>
      <div className="flex-shrink-0 position-relative">
        {group?.groupDP?.url ? (
          <img
            className="img-fluid userimg"
            src={group?.groupDP?.url}
            width="45"
            height="45"
            alt="user img"
          />
        ) : (
          <span class="gropu-icon"></span>
        )}
      </div>
      <div className="flex-grow-1 ms-3" style={{cursor:"pointer"}} >
        <div className="d-flex align-items-center justify-content-between">
          <h3> {group.title} </h3>
          <input
            id={group?._id}
            class="form-check-input flex-shrink-0"
            type="checkbox"
            checked={isSelected(group?._id, "group")}
            onChange={(e) => handleSelectForward(e, group?._id, "group")}
          ></input>
        </div>
      </div>
    </a>
  );
};

export default GroupItem;
