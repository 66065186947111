import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { UserDefaultProps } from 'src/containers/admin-containers/user-container/UserPropTypes';
import { USER_ACTIONS } from '../actions/UserActions';

const initialState = () => ({
    currentRecord: { ...UserDefaultProps },

    search: {
        data: [],
        groupCounts:{allUsers:0,inHouseUsers:0,orangeUsers:0},
        currentPage: 0,
        pages: 0,
        count: 0,
    },

    upsertReqStatus: null,
    uploadReqStatus: null,
    searchReqStatus: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        
        case USER_ACTIONS.USER_SEARCH: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case USER_ACTIONS.USER_SEARCH_SUCCESS: {
            return {
                ...state,
                search: action.data,
                searchReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case USER_ACTIONS.USER_SEARCH_ERROR: {
            return {
                ...state,
                searchReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case USER_ACTIONS.USER_UPSERT: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case USER_ACTIONS.USER_UPSERT_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                upsertReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case USER_ACTIONS.USER_UPSERT_ERROR: {
            return {
                ...state,
                upsertReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case USER_ACTIONS.USER_UPLOAD: {
            return {
                ...state,
                uploadReqStatus: REQUEST_STATUS.PENDING,
            };
        }
        case USER_ACTIONS.USER_UPLOAD_SUCCESS: {
            return {
                ...state,
                currentRecord: action.data,
                uploadReqStatus: REQUEST_STATUS.SUCCESS,
            };
        }
        case USER_ACTIONS.USER_UPLOAD_ERROR: {
            return {
                ...state,
                uploadReqStatus: REQUEST_STATUS.ERROR,
            };
        }

        
        case USER_ACTIONS.USER_RESET: {
            return {
                ...state,
                currentRecord: { 
                    _id: undefined, 
                    firstName: '', 
                    lastName: '', 
                    email: '', 
                    employeeID: '',
                    phone: '', 
                    isAdmin: false,
                    status: true,
                    username: '',
                    password: 'acadecraft@123',
                    otherInformation:{
                        designationId: '',
                        departmentId: '',
                        locationId: '',
                        reportingManagerId: null,
                    }, 
                },
            };
        }

        default:
            return state;
    }
};
