import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNotification } from "src/components/Notifination/ToastNotification";
import useDebounce from "src/hooks/useDebounce";
import {
  addReminder,
  cancelReminder,
  deleteReminder,
  getReminders,
  getRemindersHistory,
  pinReminder,
  updateReminder,
} from "../home-container/apis/Remindersapis";
import { formatDateToMon } from "src/utils/utils";
import OnlyDateRangePicker from "src/components/OnlyDateRangePicker";
import moment from "moment";
import SingleDateRangePickerWithTime from "src/components/SingleDateRangePickerWithTime";
const initialSate = {
  title: { value: "", error: "" },
  description: { value: "", error: "" },
  timeSlot: { value: "10", error: "" },
  customTime: { value: null, error: "" },
  customDate: { value: null, error: "" },
  reprate: { value: "Do not repeat", error: "" },
};

const Reminders = ({ toggleRightBarDrawer, socket, openedRightBarDrawer }) => {
  const timeSlots = [10, 20, 30, 40];
  const [reminders, setReminders] = useState([]);
  const [reminderHistories, setReminderHistories] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const { openSuccessNotification, openErrorNotification } = useNotification();
  const debouncedSearch = useDebounce(searchText, 500);
  const [isEditTable, setIsEditTable] = useState(false);
  const [editableReminderId, setEditableReminderId] = useState(null);
  const [reminderDetails, setReminderDetails] = useState(initialSate);
  const resetState = () => {
    setReminders([]);
    setReminderHistories([]);
    setSearchText("");
    setStartDate("");
    setEndDate("");
    setIsEditTable(false);
    setEditableReminderId(null);
    toggleRightBarDrawer("reminder");
    setReminderDetails({
      title: { value: "", error: "" },
      description: { value: "", error: "" },
      timeSlot: { value: "10", error: "" },
      customTime: { value: null, error: "" },
      customDate: { value: null, error: "" },
      reprate: { value: "Do not repeat", error: "" },
    });
  };

  useEffect(() => {
    if (
      moment(startDate, "YYYY-MM-DD", true).isValid() &&
      moment(endDate, "YYYY-MM-DD", true).isValid() &&
      openedRightBarDrawer === "reminder"
    ) {
      fetchUserReminders();
    }
  }, [openedRightBarDrawer, startDate, endDate]);

  useEffect(() => {
    if (
      moment(startDate, "YYYY-MM-DD", true).isValid() &&
      moment(endDate, "YYYY-MM-DD", true).isValid() &&
      openedRightBarDrawer === "reminder"
    ) {
      fetchUserReminders();
    }
  }, [debouncedSearch]);
  useEffect(() => {
    fetchExpiredReminders();
  }, [toggleRightBarDrawer]);

  const fetchUserReminders = async () => {
    try {
      const { data } = await getReminders(
        searchText,
        startDate,
        endDate,
        authToken
      );
      setReminders([...data]);
    } catch (error) {
      openErrorNotification("Error while fetching reminders.");
    }
  };

  const fetchExpiredReminders = async () => {
    try {
      const { data } = await getRemindersHistory(authToken);
      setReminderHistories([...data]);
    } catch (error) {
      openErrorNotification("Error while fetching reminders.");
    }
  };
  function reminders_close() {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myReminders").style.display = "none";
    resetState();
  }
  function reminders_close_edit() {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myReminders").style.display = "none";
  }

  function remindersCreate_open() {
    document.getElementById("main").style.marginRight = "265px";
    document.getElementById("myRemindersCreate").style.width = "265px";
    document.getElementById("myRemindersCreate").style.display = "block";
  }

  function remindersCreate_close() {
    document.getElementById("main").style.marginRight = "0%";
    document.getElementById("myRemindersCreate").style.display = "none";
  }

  function SetReminder_close() {
    document.getElementById("main").style.marginRight = "265px";
    document.getElementById("myRemindersCreate").style.display = "none";
    document.getElementById("myReminders").style.display = "block";
  }

  function combinedremindersFunction() {
    remindersCreate_open();
    setReminderDetails({
      title: { value: "", error: "" },
      description: { value: "", error: "" },
      timeSlot: { value: "10", error: "" },
      customTime: { value: null, error: "" },
      customDate: { value: null, error: "" },
      reprate: { value: "Do not repeat", error: "" },
    });
  }

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReminderDetails({
      ...reminderDetails,
      [name]: {
        ...reminderDetails[name],
        value: value,
        error: "",
      },
    });
  };
  function validateTime(givenTime) {
    const currentTime = new Date();
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const [givenHours, givenMinutes] = givenTime.split(":").map(Number);
    if (
      givenHours < currentHours ||
      (givenHours === currentHours && givenMinutes <= currentMinutes)
    ) {
      openErrorNotification(
        "Error: Selected time is less than or equal to the current time"
      );
      return false;
    } else {
      return givenTime;
    }
  }
  const handleCustomTimeChange = (e) => {
    const { name, value } = e.target;
    const result = validateTime(value);
    if (result)
      setReminderDetails({
        ...reminderDetails,
        [name]: {
          ...reminderDetails[name],
          value: value || null,
          error: "",
        },
        timeSlot: { value: "", error: "" },
      });
  };

  const handleCustomDateChange = (date) => {
    setReminderDetails({
      ...reminderDetails,
      customDate: {
        ...reminderDetails.customDate,
        value: date || null,
        error: "",
      },
      timeSlot: { value: "", error: "" },
    });
  };

  const convertReminderFormat = (obj) => {
    const result = {};
    for (const key in obj) {
      if (obj[key].hasOwnProperty("value")) {
        result[key] = obj[key].value;
      }
    }
    return result;
  };

  const validateReminder = (reminderDetails) => {
    let isValid = true;
    let newReminder = { ...reminderDetails };

    if (!newReminder.title.value?.trim()) {
      newReminder.title.error = "Title is required";
      isValid = false;
    } else {
      newReminder.title.error = "";
    }
    if (!newReminder.description.value?.trim()) {
      newReminder.description.error = "Description is required";
      isValid = false;
    } else {
      newReminder.description.error = "";
    }
    if (!newReminder.timeSlot.value && !newReminder.customTime.value) {
      newReminder.customTime.error = "Custom time is required";
      isValid = false;
    } else {
      newReminder.customTime.error = "";
    }
    if (!newReminder.timeSlot.value && !newReminder.customDate.value) {
      newReminder.customDate.error = "Custom date is required";
      isValid = false;
    } else {
      newReminder.customDate.error = "";
    }
    if (!["Do not repeat", "Repeat"].includes(newReminder.reprate.value)) {
      newReminder.reprate.error = "Invalid reprate value";
      isValid = false;
    } else {
      newReminder.reprate.error = "";
    }
    setReminderDetails(newReminder);
    return isValid;
  };

  const handleReminderEdit = (reminder) => {
    setIsEditTable(true);
    setEditableReminderId(reminder?._id);
    const formattedDate = reminder?.customDate?.slice(0, 10);
    const cleanReminder = {
      title: { value: reminder.title, error: "" },
      description: { value: reminder.description, error: "" },
      timeSlot: { value: reminder.timeSlot, error: "" },
      customTime: { value: reminder.customTime, error: "" },
      customDate: { value: formattedDate, error: "" },
      reprate: { value: reminder.reprate, error: "" },
    };
    setReminderDetails(cleanReminder);
    reminders_close_edit();
    remindersCreate_open();
  };

  const handleSaveReminder = async (event) => {
    try {
      const newFormattedReminder = convertReminderFormat(reminderDetails);
      if (validateReminder(reminderDetails)) {
        const res = isEditTable
          ? await updateReminder(
              newFormattedReminder,
              editableReminderId,
              authToken
            )
          : await addReminder(newFormattedReminder);
        if (res) {
          const successMessage = isEditTable
            ? "Reminder Edited Successfully"
            : "Reminder Saved Successfully";
          openSuccessNotification(successMessage);
          SetReminder_close();
          fetchUserReminders();
          fetchExpiredReminders();
          setReminderDetails({
            title: { value: "", error: "" },
            description: { value: "", error: "" },
            timeSlot: { value: "10", error: "" },
            customTime: { value: null, error: "" },
            customDate: { value: null, error: "" },
            reprate: { value: "Do not repeat", error: "" },
          });
        } else {
          openErrorNotification("Error While Adding Reminder");
        }
      }
    } catch (error) {
      openErrorNotification("Error While Adding Reminder");
    }
  };
  const handlePin = async (reminderId) => {
    try {
      await pinReminder(reminderId, authToken);
      await fetchUserReminders();
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const handleDelete = async (reminderId) => {
    try {
      await deleteReminder(reminderId);
      await fetchUserReminders();
      await fetchExpiredReminders();
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const handleCancelExpire = async (reminderId) => {
    try {
      await cancelReminder(reminderId);
      await fetchUserReminders();
      await fetchExpiredReminders();
    } catch (error) {
      openErrorNotification("Oh something went wrong!");
    }
  };

  const expireAlarm = (reminderId) => {
    handleCancelExpire(reminderId);
  };

  const getActiveReminders = (reminder) => {
    return (
      <div key={reminder._id} className="desdisbg">
        <div className="desdis non position-relative">
          <h3>{reminder.title}</h3>
          <p>{reminder.description}</p>
          <span className="font-12 color1a d-block">
            {reminder.reminderTime} | {formatDateToMon(reminder.reminderDay)}
          </span>
          <div className="d-flex align-item center wdpin">
            {reminder?.isPinned && (
              <button className="pin-outline">
                <i className="mdi mdi-pin-outline" />
              </button>
            )}
            <div className="dropdown toggleBtns toggleBtnsfile">
              <button
                className="toggledots"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="mdi mdi-dots-vertical" />
              </button>
              <ul
                className="dropdown-menu mx-0 shadow w-220px p-0"
                data-bs-theme="light"
              >
                <li>
                  <a
                    className="dropdown-item d-flex gap-2 align-items-center"
                    href="#"
                    onClick={() => {
                      handleCancelExpire(reminder?._id);
                    }}
                  >
                    <i className="mdi mdi-close" />
                    Cancel
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item d-flex gap-2 align-items-center"
                    href="#"
                    onClick={() => handlePin(reminder._id)}
                  >
                    <i className="mdi mdi-pin-outline"></i>
                    {reminder?.isPinned === true ? "Unpin" : "Pin"}
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item d-flex gap-2 align-items-center"
                    href="#"
                    onClick={() => {
                      handleReminderEdit(reminder);
                    }}
                  >
                    <i className="mdi mdi-pencil" />
                    Edit
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item d-flex gap-2 align-items-center"
                    href="#"
                    onClick={() => handleDelete(reminder._id)}
                  >
                    <i className="mdi mdi-delete-outline" />
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getExpiredReminders = (reminder) => {
    return (
      <>
        {" "}
        <div key={reminder._id} className="desdisbg">
          <div className="desdis position-relative">
            <h3>{reminder.title}</h3>

            <p>{reminder.description}</p>
            <span className="font-12 color1a d-block">
              {reminder.reminderTime} | {formatDateToMon(reminder.reminderDay)}
            </span>
            <div className="d-flex align-item center wdpin">
              <button className="pin-outline d-none">
                <i className="mdi mdi-pin-outline" />
              </button>
              <div className="dropdown toggleBtns toggleBtnsfile">
                <button
                  className="toggledots"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="mdi mdi-dots-vertical" />
                </button>
                <ul
                  className="dropdown-menu mx-0 shadow w-220px p-0"
                  data-bs-theme="light"
                >
                  <li>
                    <a
                      className="dropdown-item d-flex gap-2 align-items-center"
                      href="#"
                      onClick={() => handleDelete(reminder._id)}
                    >
                      <i className="mdi mdi-delete-outline" />
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleDateRangeChange = (start, end) => {
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
  };
  return (
    <>
      <div
        className="chat-sidebar chat-animate-right"
        style={{ display: "none", right: 109 }}
        id="myReminders"
      >
        <div className="modal-dialog-scrollable">
          <div className="modal-content">
            <div className="slide-header">
              <div className="HeadSlidName d-flex justify-content-between align-items-center">
                <div className="slidName">Reminders</div>
                <button className="" onClick={reminders_close}>
                  ×
                </button>
              </div>

              <div className="msg-search mx-0">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="Search"
                  aria-label="search"
                  value={searchText}
                  onChange={handleSearch}
                />
                <button>
                  <i className="mdi mdi-magnify"></i>
                </button>
              </div>
              <div className="onlydaterange fonsdfrnt">
                <OnlyDateRangePicker
                  onDateRangeChange={handleDateRangeChange}
                  position={"left"}
                />
              </div>
              <div className="d-flex justify-content-between flex-column text-center align-items-center font-14 color1a reminds">
                <span className="d-block mb-3">
                  <img
                    src="assets/images/reminders.svg"
                    className="img-fluid"
                    alt=""
                  />
                </span>
                <p className="font-12 color66">
                  Never forget important tasks.
                  <span className="d-block">
                    Set personal and group reminders.
                  </span>
                </p>
                <div className="text-center mt-3 mb-3">
                  <button
                    type="button"
                    className="btnblue"
                    onClick={combinedremindersFunction}
                  >
                    Set Reminders
                  </button>{" "}
                </div>
              </div>
            </div>
            <div className="modal-body pt-0">
              {reminders.map((reminder) => getActiveReminders(reminder))}

              <div className="">
                <div className="">
                  <h3 className="d-flex justify-content-between align-items-center font-18">
                    History
                    <i className="mdi mdi-chevron-down" />
                  </h3>

                  {reminderHistories.map((history) =>
                    getExpiredReminders(history)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="chat-sidebar chat-animate-right"
        style={{ display: "none", right: 109 }}
        id="myRemindersCreate"
      >
        <div className="modal-dialog-scrollable">
          <div className="modal-content">
            <div className="slide-header">
              <div className="HeadSlidName d-flex justify-content-between align-items-center">
                <div className="slidName">Reminders </div>
                <button className="" onClick={remindersCreate_close}>
                  ×
                </button>
              </div>
              <div className="d-flex justify-content-between align-items-center createnotes font-14 color1a btnclr">
                Set reminders
                <button className="rounded-4" onClick={SetReminder_close}>
                  <i className="mdi mdi-chevron-left" />
                </button>
              </div>
            </div>
            <div className="modal-body pt-0">
              <div className="todocreat">
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Enter Title"
                  name="title"
                  value={reminderDetails.title.value}
                  onChange={handleChange}
                />
                {reminderDetails?.title?.error && (
                  <p
                    className="mb-2"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    {reminderDetails?.title?.error}
                  </p>
                )}

                <label className="form-label font-14 color1a">
                  Remind About
                </label>
                <textarea
                  className="form-control mb-2"
                  rows={4}
                  placeholder="Some details about task"
                  defaultValue={""}
                  value={reminderDetails.description.value}
                  onChange={handleChange}
                  name="description"
                />
                {reminderDetails?.description?.error && (
                  <p
                    className="mb-2"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    {reminderDetails?.description?.error}
                  </p>
                )}
              </div>
              <div className="inflxbg">
                <div className="inflxtx">
                  <label>Select Custom Time</label>
                  <div className="d-flex align-items-center justify-content-between inflx btntime mt-2">
                    {timeSlots.map((value) => (
                      <button
                        key={value}
                        className={`font-12 fw-500 color1a ${
                          value === parseInt(reminderDetails.timeSlot.value)
                            ? "active"
                            : ""
                        }`}
                        name="timeSlot"
                        onClick={() => {
                          setReminderDetails({
                            ...reminderDetails,
                            timeSlot: { value: value.toString(), error: "" },
                            customDate: { value: null, error: "" },
                            customTime: { value: null, error: "" },
                          });
                        }}
                      >
                        <span className="font-18 fw-bold color1a d-block">
                          {value}
                        </span>{" "}
                        min
                      </button>
                    ))}
                    {reminderDetails?.timeSlot?.error && (
                      <p
                        className="mb-2"
                        style={{ color: "red", fontSize: "14px" }}
                      >
                        {reminderDetails?.timeSlot?.error}
                      </p>
                    )}
                  </div>
                </div>
                <div className="inflxtx">
                  <label>Select Custom Time</label>
                  <div className="d-flex align-items-center inflx">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Custom Date"
                      value={reminderDetails.customDate.value || ""}
                      onChange={(e) => handleCustomDateChange(e.target.value)}
                      min={new Date().toISOString().split("T")[0]}
                    />
                    {reminderDetails?.customDate?.error && (
                      <p
                        className="mb-2"
                        style={{ color: "red", fontSize: "14px" }}
                      >
                        {reminderDetails?.customDate?.error}
                      </p>
                    )}
                    <input
                      type="time"
                      className="form-control"
                      value={reminderDetails.customTime.value || ""}
                      onChange={handleCustomTimeChange}
                      name="customTime"
                    />
                    {reminderDetails?.customTime?.error && (
                      <p
                        className="mb-2"
                        style={{ color: "red", fontSize: "14px" }}
                      >
                        {reminderDetails?.customTime?.error}
                      </p>
                    )}
                  </div>
                </div>
                <div className="inflxtx">
                  <select
                    value={reminderDetails.reprate.value}
                    onChange={handleChange}
                    name="reprate"
                    className="form-select font-14 color33"
                  >
                    <option value="Do not repeat">Do not repeat</option>
                  </select>
                  {reminderDetails?.reprate?.error && (
                    <p
                      className="mb-2"
                      style={{ color: "red", fontSize: "14px" }}
                    >
                      {reminderDetails?.reprate?.error}
                    </p>
                  )}
                </div>
                <div className="text-center mt-3 mb-3">
                  <button
                    type="button"
                    className="btnblue"
                    onClick={handleSaveReminder}
                  >
                    Save
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reminders;
