import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const GroupPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    title: PropTypes.string, 
    content: PropTypes.string, 
    contentHtml: PropTypes.string, 
    isPinned: PropTypes.boolean, 
    isDeleted: PropTypes.boolean, 
    userId: PropTypes.string, 
    organizationId: PropTypes.string,
    remark: PropTypes.string, 
});

export const GroupDefaultProps = {
    _id: undefined, 
    title: '', 
    content: '', 
    contentHtml: '', 
    isPinned: false,
    isDeleted: false, 
    userId:'', 
    organizationId: '', 
    remark: '', 
    participants:[], 
};


export const GroupYupSchema = Yup.object().shape({ 
    title: Yup.string().required('Required'), 
});

export default {
    GroupDefaultProps,
    GroupPropTypes,
    GroupYupSchema,
};
