import React from "react";
import SingleDateRangePickerWithTime from "src/components/SingleDateRangePickerWithTime";

const CreateTodo = ({
  todo,
  handleTitleChange,
  handleRemoveItem,
  handleChange,
  formatDateIntoNew,
  handleAddItem,
  handleSaveTodo,
  handleDateTimeRangeChange,
}) => {
  return (
    <div className="modal-body pt-0">
      <div className="todocreat">
        <input
          type="text"
          className="form-control mb-2"
          placeholder="Title "
          value={todo.title.value}
          onChange={(e) => handleTitleChange(e)}
        />
        {todo.title.error && (
          <p className="mb-2" style={{ color: "red", fontSize: "14px" }}>
            {todo.title.error}
          </p>
        )}
        {todo?.items &&
          todo?.items?.map((item, index) => {
            return (
              <>
                {index !== 0 && (
                  <div className="d-flex justify-content-between align-items-center createnotes font-14 color1a">
                    <button onClick={() => handleRemoveItem(index)}>
                      &times;
                    </button>
                  </div>
                )}
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Add task name here"
                  value={item?.name?.value}
                  onChange={(e) => handleChange(e, index, "name")}
                />
                {item?.name?.error && (
                  <p
                    className="mb-2"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    {item?.name?.error}
                  </p>
                )}
                <textarea
                  className="form-control mb-2"
                  placeholder="Add task descriptions"
                  defaultValue={""}
                  value={item?.description?.value}
                  onChange={(e) => handleChange(e, index, "description")}
                />
                {item?.description?.error && (
                  <p
                    className="mb-2"
                    style={{ color: "red", fontSize: "14px" }}
                  >
                    {item?.description?.error}
                  </p>
                )}
                <div className="inflxbg">
                  <div className="inflxtx">
                    <label>Assign Date </label>
                    <div className="d-flex align-items-center inflx datePkrn1">
                        <input
                          type="date"
                          className="form-control"
                          value={formatDateIntoNew(item?.assignedDate?.value)}
                          onChange={(e) =>
                            handleChange(e, index, "assignedDate")
                          }
                        />
                        <input
                          type="time"
                          className="form-control"
                          value={item?.assignedTime?.value}
                          onChange={(e) =>
                            handleChange(e, index, "assignedTime")
                          }
                        />
                      </div> 
                      
                    <div
                      style={{
                        display:
                          item?.dueDate?.error &&
                          item?.dueTime?.error &&
                          "flex",
                      }}
                    >
                      {item?.assignedDate?.error && (
                        <p
                          className="mb-2"
                          style={{ color: "red", fontSize: "14px" }}
                        >
                          {item?.assignedDate?.error}
                        </p>
                      )}
                      {item?.assignedTime?.error && (
                        <p
                          className="mb-2"
                          style={{ color: "red", fontSize: "14px" }}
                        >
                          {item?.assignedTime?.error}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="inflxtx datePkrn1">
                    <label>Due Date</label>
                     <div className="d-flex align-items-center inflx">
                        <input
                          type="date"
                          className="form-control"
                          value={formatDateIntoNew(item?.dueDate?.value)}
                          onChange={(e) => handleChange(e, index, "dueDate")}
                        />
                        <input
                          type="time"
                          className="form-control"
                          value={item?.dueTime?.value}
                          onChange={(e) => handleChange(e, index, "dueTime")}
                        />
                      </div> 
                    {}
                    <div
                      style={{
                        display:
                          item?.dueDate?.error &&
                          item?.dueTime?.error &&
                          "flex",
                      }}
                    >
                      {item?.dueDate?.error && (
                        <p
                          className="mb-2"
                          style={{ color: "red", fontSize: "14px" }}
                        >
                          {item?.dueDate?.error}
                        </p>
                      )}
                      {item?.dueTime?.error && (
                        <p
                          className="mb-2"
                          style={{ color: "red", fontSize: "14px" }}
                        >
                          {item?.dueTime?.error}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </div>
      <div className="inflxbg">
        <div className="inflxbtn">
          <button className="addmore" onClick={handleAddItem}>
            <i className="mdi mdi-plus" /> Add more{" "}
          </button>
        </div>
        <div className="text-center mt-3 mb-3">
          <button type="button" className="btnblue" onClick={handleSaveTodo}>
            Save
          </button>{" "}
        </div>
      </div>
    </div>
  );
};

export default CreateTodo;
