import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
const HeaderNav=()=>{
  const navigate = useNavigate();
  const token  = localStorage.getItem("token");
  const HandleHashLink=(source)=>{
    navigate(source);
  }
 return (
    <>
       <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top" id="navbar">
  <div className="container">
    <a className="navbar-brand" href="/">
        <img src="assets/images/landing/logo.svg" className="img-fluid" alt=""/></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarScroll">
      <ul className="navbar-nav ms-auto my-2 ms-5 my-lg-0 navbar-nav-scroll" > 
          
        <li className="nav-item list"> 
          <Link to="/" className="nav-link">
              Home
           </Link>
        </li>
          <li className="nav-item list">
           <a className="nav-link" href="#Features" onClick={()=>{HandleHashLink('/#Features')}}>Features</a> 
        </li>
        <li className="nav-item list"> 
        <a className="nav-link" href="#AboutUs" onClick={()=>{HandleHashLink('/#AboutUs')}}>About Us</a>
        </li>  
        <li className="nav-item list">
        <Link to="/download" className="nav-link">
           Download 
        </Link>
        </li>
          <li className="nav-item list"> 
          <a className="nav-link" href="#Pricing" onClick={()=>{HandleHashLink('/#Pricing')}}>Pricing</a>
        </li> 
        <li className="nav-item me-2 d-flex justify-content-center align-items-end flex-column">
          <button onClick={()=>{window.location.href='/contact-us'}} className="btnClr" aria-disabled="true">Let’s Connect</button>           
        </li>
        <li className="nav-item d-flex justify-content-center align-items-end flex-column">
          <button onClick={()=>{window.location.href=(token)?'/home':'/login'}} className="btnClr" aria-disabled="true">SignIn</button>           
        </li>
      </ul>  
    </div>
  </div>
</nav>
 
    </>
 )
}
export default HeaderNav