class UrlConstants extends String {
    constructor() {
      super();
      this.isProd = false;
      this.devUrl = `${process.env.REACT_APP_API}`;
      this.socketUrl = "ws://localhost:8000";
    }
  }
  
  const urls = new UrlConstants();
  export default urls;
  