import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { NotificationsOptions } from "./AccountConstants"
import { accountUpsertAction } from "./actions/AccountActions";

const NotificationsSetting=({openedSubDrawer,toggleSubDrawer})=>{
      
      const dispatch = useDispatch(); 
      const accountSetting = useSelector((state) => state.AccountReducer.currentRecord);
      const userID = useSelector((state) => state.auth.user?._id);
      const [preferences,setPreferences]=useState(accountSetting);
      const handlePreferenceChange=(e)=>{
         const { name, value } = e.currentTarget;
         const newValue = value === "true" ? false : true;      
         const accountUpdatePayload = {accountSettingInformation:{...preferences,[name]:newValue}};
         setPreferences(accountUpdatePayload)
         dispatch(accountUpsertAction({_id:userID,accountUpdatePayload}));      
      }
 return (
    <>
    <div className="dflex align-items-center justify-content-between notifications slist" style={{display:(openedSubDrawer.isOpen)?"none":""}}  onClick={()=>{toggleSubDrawer("notification",true)}}>
   <div className="font-16 color1a fw-bold">
      Notifications <span className="d-block font-14 color66 fw-normal">Update Your Notification Setting</span>
   </div>
   <span className="arrow"><i className="mdi mdi-chevron-right"></i></span>
</div>
<div className="accountstg p15 border-0"  id="Notificationsupdate" style={{display:(openedSubDrawer.type=="notification" && openedSubDrawer.isOpen)?"block":"none"}}>
   <div className="dflex justify-content-between align-items-center createnotes font-16 fw-bold color1a btnclr fixedtop">
      Notifications
      <button className="rounded-4 closetabpassword" onClick={()=>toggleSubDrawer("notification",false)}><i className="mdi mdi-chevron-left"></i></button>
   </div>
   <div className="Achpaswd">
      <div className="font-16 fw-normal color80 mb-0">Minimized</div>
      {
         NotificationsOptions.filter((PreOptionFilter)=> PreOptionFilter.section==="Minimized").map((PreOption,index)=>{
            const isChecked = (preferences[PreOption.value]) ?? true
            return (
               <div className="dflex align-items-center justify-content-between py-3" key={index}>
               <div className="font-14 color1a fw-500">
                   {PreOption.heading}
                  <span className="d-block font-14 color80 fw-normal">{PreOption.description}</span>
               </div>
               <div className="onofbtnwx">
                  <button 
                     type="button" 
                     className={`btn btn-toggle me-0 ${isChecked?'active':''}`}
                     data-bs-toggle="button" 
                     aria-pressed="false" 
                     autoComplete="off"
                     name={PreOption.value}
                     value={isChecked}
                     onClick={handlePreferenceChange}
                  >
                     <div className="handle"></div>
                  </button>
               </div>
            </div>
            )
         })
       } 
       
      <div className="font-16 fw-normal color80 mt-2">Always</div>
      {
         NotificationsOptions.filter((PreOptionFilter)=> PreOptionFilter.section==="Always").map((PreOption,index)=>{
            const isChecked = (preferences[PreOption.value]) ?? true
            return (
               <div className="dflex align-items-center justify-content-between py-3" key={index}>
               <div className="font-14 color1a fw-500">
                   {PreOption.heading}
                  <span className="d-block font-14 color80 fw-normal">{PreOption.description}</span>
               </div>
               <div className="onofbtnwx">
                  <button 
                     type="button" 
                     className={`btn btn-toggle me-0 ${isChecked?'active':''}`}
                     data-bs-toggle="button" aria-pressed="false"
                     autoComplete="off"
                     name={PreOption.value}
                     value={isChecked}
                     onClick={handlePreferenceChange}
                   >
                     <div className="handle"></div>
                  </button>
               </div>
            </div>
            )
         })
       }
   </div>
</div>
    </>
 )
}
export default NotificationsSetting