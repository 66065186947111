import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ListAction from "./ListAction"
import FilterPopup from "./FilterPopup"
import GroupProfilePopup from "./GroupProfilePopup"
import { groupEditAction, groupSearchAction, groupUpsertAction } from "./actions/GroupActions";
import { ExportReactCSV } from 'src/components/excel/ExportReactCSV';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { stampForUpdate } from "src/utils/StampUtils";
import Pagination from "src/components/Pagination";
import { deleteUserGroupChat, getDetails, getUserGroupList } from "src/containers/home-container/apis/Groupapis";
import { JSONToCSVConvertor } from "src/libs/common";
import { getGroupConvertations } from "src/api/GroupApi";
import { getCurrentTime, time_ago } from "src/utils/CommonUtils";
import GroupDetails from "src/containers/include-container/GroupDetails";

const GroupContainer=({socket})=>{
        
        const dispatch = useDispatch();
        const {
            currentRecord,
            search,
            upsertReqStatus,
        } = useSelector((state) => state.GroupReducer, shallowEqual);
        const authToken = useSelector((state) => state.auth.user?.jwtToken);



    const [isAddGroup,setIsAddGroup] = useState(false)
    const [isGroupFilter,setGroupFilter] = useState(false)
    const [isGroupProfileView,setGroupProfileView] = useState(false)
    const [groupDetails,setGroupDetails] = useState([])
    const [currentTab,setCurrentTab] = useState("active")
    const [where,setWhere] = useState({});
    const[pageNo,setPageNo] = useState(0);
    const [editGroup, setEditGroup] = useState(null);
    const [isGroupEditable, setIsGroupEditable] = useState(false);
    const [openedDrawer, setOpenedDrawer] = useState(null);


    const handleOpenProfile=async (recoard)=>{
        let { data } = await getDetails(recoard?._id);
        setGroupDetails(data.groups[0])
        setGroupProfileView(true)
    }

    const deleteFileAndChat=async(recoard,type)=>{
        if(window.confirm(`Are You Sure To Delete ${type} chat?`)){
            await deleteUserGroupChat(
                recoard?._id,
                type,
                authToken
              );
              toast(`${type} chat deleted sucesfullt.`)
        }

    }
    const fetchUserGroups = async () => {
        try {
          let selectedGroup = groupDetails;
          const { data } = await getUserGroupList("");
          if (selectedGroup) {
            let noGroup = null;
            data.map((itm, ind) => {
              if (selectedGroup?._id == itm?._id) {
                noGroup = itm;
              }
            });
          }
        } catch (error) { }
      };

    const exportChatExcel=(recoard,type)=>{
        console.log(recoard,type);
    }
    const handleStatusChange = useCallback((
        recoard,
    ) => {
        
        let ChangeStatus = {isDeleted:(!recoard.isDeleted),isDeactivated:(!recoard.isDeactivated),_id:recoard._id}
        stampForUpdate(ChangeStatus); 
        dispatch(groupUpsertAction(ChangeStatus));
    }, [
        dispatch,
    ]);

    const handleFilterChange = (key, value, isApply = false) => {
        setWhere((prevWhere) => {
            const newWhere = { ...prevWhere };
            if (key === 'isDeactivated' && value === 'all') {
                newWhere[key] = { $in: [true, false] };
            } else {
                newWhere[key] = value;
            }
            return newWhere;
        });
    }; 
    
    const handelFilterChange=(payload)=> {
        setWhere({...where,...payload})
    }

    const handleTestSearch = useCallback(
        debounce((searchKey, setWhere) => {
          const whereClauseKeysPaths = ['title'];
          const whereWithOrCondition = {
            $or: whereClauseKeysPaths.map((keyPath) => ({ 
              [keyPath]: {
                $regex: searchKey,
                $options: 'i',
              },
            })),
          }; 
          setWhere(whereWithOrCondition);
        }, 300),
        []
      );
      const handleSearchInputChange = (e) => {
        const searchKey = e.target.value;
        handleTestSearch(searchKey, setWhere);
      };

    useEffect(()=>{
        const payload = {
            where, 
            currentPage:pageNo, 
            autopopulate:true, 
            fullTextSearch:true,
        }
        dispatch(groupSearchAction(payload));
    },[where,pageNo])

    const ExportInExcel = useMemo(() => {
        if (search?.data?.length===0) {
            return [];
        }
        const results = [];
        search?.data?.forEach((group) => {
                    const createdAt = new Date(group.createdAt).getTime(); 
                    const createdBy = `${group?.userId?.firstName} ${group?.userId?.lastName}`
                     
                    results.push({
                        title:group.title, 
                        createdBy,
                        totalUser:group.participants.length, 
                        createdAt,
                    });
        });
        return results;
    }, [
        search
    ]);

    const optionExportInExcel =async (recoard,value)=>{ 

        const results = [];
        let reportTitle = "";
        if(value==="memberExport"){

            reportTitle="Member List";
            recoard.participants.forEach((participant) => {
                const fullName = `${participant.firstName} ${participant.lastName}`
                const ReportingManager = `${participant?.reportingManagerId?.firstName} ${participant?.reportingManagerId?.lastName}`                        
                results.push({
                    Name:fullName,
                    Email:participant.email,
                    Phone:participant.phone,
                    Department:participant.otherInformation.departmentId.departmentName,
                    Designation:participant.otherInformation.designationId.designationName,
                    Location:participant.otherInformation.locationId.location,
                    LastSeen:participant.lastSeen,
                    ReportingManager:(ReportingManager)?ReportingManager:"NA",
                    Role:participant.role,
                    Status:participant.status
                });
          }); 
        }
        else{
            reportTitle="Chat List";
            const {data:{data}} = await getGroupConvertations({where:{groupId:recoard._id}}) 
            data.forEach((chat) => {
                const senderFullName = `${chat.senderId.firstName} ${chat.senderId.lastName}`               
                results.push({
                    senderName:senderFullName,
                    Message:chat.message,
                    timestamp:chat.timestamp  
                });
          });
        }        
        JSONToCSVConvertor(results, `${recoard.title} ${reportTitle} ${new Date().toLocaleDateString()}`, true)
    }
    

    
    
    
    
    
    
    
    
                     
    
    
    
    
    
    
    
    
    
         
    

    const toggleDrawer = (type) => {
        let bodyTag = document.querySelector("body")
        let openedModalClass = document.querySelector(".modal-dialog-scrollable")       
        if (bodyTag) {
            bodyTag.classList.remove("BlurEffect");
        }
        if (openedModalClass) {
          openedModalClass.removeChild = `<div class="modal-backdrop fade show"></div>`
        }else{
          openedModalClass.appendChild = `<div class="modal-backdrop fade show"></div>`
        }    
        document.getElementById("main").style.marginRight = "0%";
        setOpenedDrawer((prevState) =>
          prevState === null || prevState != type ? type : null
        );

        if (type == "createGroup") { 
          if (bodyTag && type != openedDrawer) {
            bodyTag.classList.add("BlurEffect");
          }
          if (openedModalClass) {
            openedModalClass.appendChild = `<div class="modal-backdrop fade show"></div>`
          }
        }
        
        
      };


    return (
      <>

                <div className="group p-4"  id="AdminGroup">
                <div className="d-flex align-items-center justify-content-between bgadhding">
                    <div className="d-flex align-items-center adhding">
                    <span className="img">
                        <img
                        src="/assets/images/admin/imgs/gropus.svg"
                        className="img-fluid"
                        width={58}
                        height={58}
                        alt=""
                        />
                    </span>
                    <div className="adhdtext ms-2">
                        <div className="font-20 fw-bold color33 hd">Groups</div>
                        <div className="font-12 fw-bold tx">
                        here's what's happening with your store today.
                        </div>
                    </div>
                    </div>
                </div>

                <nav style={{}} aria-label="breadcrumb" className="mt-3">
                    <ol className="breadcrumb">
                    {}
                    </ol>
                </nav>

                <div className="d-flex gap-2 justify-content-start py-3 Tabactive">
                    <span className={`badge d-flex align-items-center p-1 pe-2 text-primary-emphasis bg-white border border-secondary-subtle rounded-1 ${((currentTab==="active")?"active":"")}`}
                    onClick={()=>{
                        handleFilterChange("isDeactivated",false)
                        setCurrentTab("active")
                    }}
                    >
                    <img
                        className="rounded-circle me-1"
                        width={24}
                        height={24}
                        src="/assets/images/admin/imgs/all-users.svg"
                        alt=""
                    />{" "}
                    Active Groups {search.groupStatusCounts.active}
                    </span>
                    <span className={`badge d-flex align-items-center p-1 pe-2 text-secondary-emphasis bg-white border border-secondary-subtle rounded-1 ${((currentTab==="isDeactivated")?"active":"")}`}
                    onClick={()=>{
                        handleFilterChange("isDeactivated",true)
                        setCurrentTab("isDeactivated")
                    }}
                    >
                    <img
                        className="rounded-circle me-1"
                        width={24}
                        height={24}
                        src="/assets/images/admin/imgs/all-users.svg"
                        alt=""
                    />{" "}
                     Deactivated {search.groupStatusCounts.isDeactivated}
                    </span>
                </div>

                <div className="card pt-2">
                    <div className="d-flex justify-content-between align-items-center px-4 pb-2">
                    <div className="font-14 fw-bold color1a">Groups Details</div>
                    <div className="d-flex tablebtns gap-3">
                        <div id="customFilter" className="customFilter rounded-2">
                        <input
                            type="search"
                            id="searchInput"
                            className="form-control w-100"
                            placeholder="Search..."
                            onChange={handleSearchInputChange}
                            
                        />
                        </div>
                         
                        {}
                        {}
                        
                        <ExportReactCSV 
                        csvData={ExportInExcel} 
                        fileName={"GroupList-" + new Date().toLocaleDateString()} 
                        />
                    </div>
                    </div>
                    <div className="table-responsive table-height">
                    <table
                        id="grouptable"
                        className="table table-striped datatable border-top small"
                    >
                        <thead>
                        <tr>
                            <th className="text-center"># </th>
                            <th>Group Name </th>
                            <th>Created by </th>
                            <th>Total Users </th>
                            {}
                            <th>Created Date</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                        search.data.map((group,index)=>{
                            const createdBy = `${group?.userId?.firstName} ${group?.userId?.lastName}`
                            return(
                            <tr key={index}>
                            <td className="text-center" >{(pageNo * 10) + index + 1}</td>
                            <td className="align-middle customer white-space-nowrap">
                            <a
                                className="d-flex align-items-center text-body"
                                href="#"
                                onClick={()=>{handleOpenProfile(group)}}
                            >
                                <div className="avatar avatar-l">
                                <div className="avatar-name rounded-circle">
                                    <span>{group.title.substring(0,1).toUpperCase()}</span>
                                </div>
                                </div>
                                <h6 className="mb-0 ms-1 text-body">{`${group.title}`}</h6>
                            </a>
                            </td>
                            <td>{createdBy}</td>    
                            <td>
                                
                                    <div className="d-flex align-items-center">
                                        <span className="d-inline-block me-3">{group.participants.length}</span>{" "}
                                        <div className="d-flex align-items-center useronlimg">
                                            {group.participants && (
                                                <>
                                                {group.participants.slice(0, 5).map((user, userIndex) => (
                                                    <img
                                                    key={userIndex}
                                                    src={`${
                                                        (user.otherInformation?.profilePicture)
                                                        ? user.otherInformation?.profilePicture
                                                        : "/assets/images/admin/dashboard/user-img1.png"
                                                    }`}
                                                    className="img-fluid"
                                                    width={23}
                                                    height={23}
                                                    alt=""
                                                    onClick={()=>{handleOpenProfile(group)}}
                                                    />
                                                ))}
                                                {group.participants.length > 5 && (
                                                    <span className="user-count">+{group.participants.length - 5} more</span>
                                                )}
                                                </>
                                            )}
                                            </div>
                                    </div>
                                </td>    
                            <td>{time_ago(group.createdAt)}</td>
                            <td>
                            <ListAction 
                            recoard={group}
                            handleOpenProfile={handleOpenProfile}
                            handleStatusChange={handleStatusChange}
                            deleteFileAndChat={deleteFileAndChat}
                            optionExportInExcel={optionExportInExcel}
                            socket={socket}
                            />
                            </td>
                        </tr>
                            )
                        })
                    }
                        </tbody>
                    </table>
                    </div>
                </div>
                {search.data && search.data?.length>0 && <Pagination setPageNo={setPageNo} pageNo={pageNo} total={search.total}/>}
                {}
                </div>
          
          {
          isGroupProfileView &&
          <GroupDetails
              getCurrentTime={getCurrentTime}
              groupDetails={groupDetails}              
              socket={socket}
              editGroup={editGroup}
              setEditGroup={setEditGroup}
              toggleDrawer={toggleDrawer}
              isGroupEditable={isGroupEditable}
              fetchUserGroups={fetchUserGroups}
              setIsGroupEditable={setIsGroupEditable}
              srcIsAdmin={true}
              setGroupProfileView={setGroupProfileView}
            />
          
        
        
        
        
        
           } 
      </>
    )
  }
  export default GroupContainer