import * as Yup from 'yup';

 export const ContactvalidationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Name is too short')
      .max(50, 'Name is too long')
      .required('Name is required'),
    companyName: Yup.string()
      .min(2, 'Company name is too short')
      .max(100, 'Company name is too long')
      .required('Company name is required'),
    companyWebsite: Yup.string()
      .url('Invalid URL')
      .required('Company website is required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    preferredTime: Yup.string()
      .matches(/^([1-9]|1[0-2])(am|pm)-([1-9]|1[0-2])(am|pm)$/, 'Preferred time is invalid')
      .required('Preferred time is required'),
  });

