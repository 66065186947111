import { format } from 'timeago.js';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as moment from 'moment';
import { useSelector } from "react-redux";
import { handleLogout } from 'src/containers/login-container/LoginService';
import { useNotification } from './Notifination/ToastNotification';
import { logout } from 'src/containers/home-container/actions/authAction';

const SignedDevices=({LogInfo,handleLogoutOtherDevices})=>{
    const { openSuccessNotification, openErrorNotification } = useNotification();
    const authToken = useSelector((state) => state.auth.user?.jwtToken);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogoutClick = async (token) => {
        try {
          const response = await handleLogout(token);
          if (response && authToken==token) {
            dispatch(logout());
            navigate("/");
            openSuccessNotification("Logged out successfully.");
          }
          else{
            handleLogoutOtherDevices()
            openSuccessNotification("Logged out successfully.");
          }
        } catch (error) {
          return openErrorNotification(error?.message ?? "Error logging out");
        }
      };
   return(
    <>
    {
        (LogInfo.device.loginInfo.deviceInfo?.isDesktop) && 
        <div className="list-group-item list-group-item-action d-flex gap-3 py-3 border-0" aria-current="true">
        <div className="w-100">
            <h6 className="mb-0 color1a font-14 fw-bold">{`${LogInfo.device.loginInfo.deviceInfo.osName} ${LogInfo.device.loginInfo.deviceInfo.osVersion}`} (Desktop) <i className="mdi mdi-check-circle text-green"></i></h6>
            <p className="mb-0 font-14 color66">Last launched {format(moment(LogInfo.device.lastLogin).format('x'))}</p>
            <button type="button" className="btnblue my-2 color1a bg-none border" onClick={()=>{handleLogoutClick(LogInfo.device.token)}}>Sign Out</button> 
        </div>
        <img src="assets/images/activity-usage/desktop.svg" alt="twbs" width="40" height="35" className="flex-shrink-0"/>
       </div>
    }
    {
    (LogInfo.device.loginInfo.deviceInfo?.isMobile) && <div className="list-group-item list-group-item-action d-flex gap-3 py-3 border-0" aria-current="true">
        <div className="w-100">
            <h6 className="mb-0 color1a font-14 fw-bold">{`${LogInfo.device.loginInfo.deviceInfo.osName} ${LogInfo.device.loginInfo.deviceInfo.osVersion}`} <i className="mdi mdi-check-circle text-green"></i></h6>
            <p className="mb-0 font-14 color66">Last launched {format(moment(LogInfo.device.lastLogin).format('x'))}</p>
            <button type="button" className="btnblue my-2 color1a bg-none border" onClick={()=>{handleLogoutClick(LogInfo.device.token)}}>Sign Out</button> 
        </div>
        <img src="assets/images/activity-usage/phone.svg" alt="twbs" width="24" height="44" className="flex-shrink-0"/>
    </div>
    }
    {
        (LogInfo.device.loginInfo.deviceInfo?.isTablet) && <div className="list-group-item list-group-item-action d-flex gap-3 py-3 border-0" aria-current="true">
        <div className="w-100">
            <h6 className="mb-0 color1a font-14 fw-bold">{`${LogInfo.device.loginInfo.deviceInfo.osName} ${LogInfo.device.loginInfo.deviceInfo.osVersion}`} <i className="mdi mdi-check-circle text-green"></i></h6>
            <p className="mb-0 font-14 color66">Last launched {format(moment(LogInfo.device.lastLogin).format('x'))}</p>
            <button type="button" className="btnblue my-2 color1a bg-none border" onClick={()=>{handleLogoutClick(LogInfo.device.token)}}>Sign Out</button> 
        </div>
        <img src="assets/images/activity-usage/chrome.svg" alt="twbs" width="40" height="40" className="flex-shrink-0"/>
    </div>
    }
       
        
        
    </>
   )
}
export default SignedDevices