import * as Yup from 'yup';
import PropTypes from 'prop-types';

export const ChatBoxPropTypes = PropTypes.shape({
    _id: PropTypes.string, 
    chatBoxName: PropTypes.string,
});

export const ChatBoxDefaultProps = { 
    chatBoxName: '',
    _id: undefined
};

export const ChatBoxYupSchema = Yup.object().shape({ 
    chatBoxName: Yup.string().required('Required'),
});

export default {
    ChatBoxDefaultProps,
    ChatBoxPropTypes,
    ChatBoxYupSchema,
};
