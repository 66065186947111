import { useState} from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
const PhoneNoInput=({
  phoneNo, setPhoneNo, ...rest
})=>{
  return (
    <PhoneInput
      {...rest}
      placeholder="Enter phone number"
      value={phoneNo}
      country="IN"
      onChange={setPhoneNo}/>
  )
}
export default PhoneNoInput