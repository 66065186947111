import React from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => (
  <div
    
    
    
    
    
    
    
    
    
    
    
    src={'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40"><foreignObject width="40" height="40"><div xmlns="http://www.w3.org/1999/xhtml" style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;"><div style="color: black; padding: 10px; text-align: center; border: 2px solid black; border-radius: 50%; transform: translate(-50%, -50%); background: none;">Your Text</div></div></foreignObject></svg>')}
  >
    {text}
  </div>
);

export default function GooMap({ lat, lng, texts = "" }) {
  const defaultProps = {
    center: {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    },
    zoom: 8,
  };

  return (
    <div style={{ height: "200px", width: "350px" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent lat={lat} lng={lng} text={texts} />
      </GoogleMapReact>
    </div>
  );
}
