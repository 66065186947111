import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from "../saga/authActionTypes";

export const AUTH_ACTIONS = {
  FETCH_PERMISSION_SCOPES: 'FETCH_PERMISSION_SCOPES',
  FETCH_PERMISSION_SCOPES_SUCCESS: 'FETCH_PERMISSION_SCOPES_SUCCESS',
  FETCH_PERMISSION_SCOPES_ERROR: 'FETCH_PERMISSION_SCOPES_ERROR',

  FETCH_USER_DETAILS: 'FETCH_USER_DETAILS',
  FETCH_USER_DETAILS_SUCCESS: 'FETCH_USER_DETAILS_SUCCESS',
  FETCH_USER_DETAILS_ERROR: 'FETCH_USER_DETAILS_ERROR',
};

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: { user },
});

export const loginFailure = () => ({
  type: LOGIN_FAILURE,
});

export const logout = () => ({
  type: LOGOUT,
});

export const setUserDetails = (user) => ({
  type: 'SET_USER_DETAILS',
  payload: { user },
});

export const updateUserProfile = (field, value) => ({
  type: 'UPDATE_USER_PROFILE',
  payload: { field, value },
});
