import React from 'react'
import LandingHead from '../Layout/Head'
import HeaderNav from '../Layout/HeaderNav'
import LandingFooter from '../Layout/Footer'

const Download = () => { 
    return (
        <>
            <title>Download</title>
            <LandingHead />
            <HeaderNav />
            <section className='sec bggyet'> 
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12 mbcnhsg'>
                        <div className='fw-600 color1a font-50 text-center mb-4'>Downloads</div>
                    <div className='card shadow mxwaxunb'>
                    <h4 className="fh5co-uppercase-sm heading-platform"><span>Desktop app</span></h4>
                    <div className='alltbsdol px-4'> 
                    <div className='d-flex justify-content-between align-items-center border-bottom wisal'>
                        <div className='d-flex align-items-center gap-3 txtwal'><i className='mdi mdi-windows'></i> Windows <span>(64-bit , 32 bit)</span></div>
                        <div className="ml-auto download-button">
                            <a href="/setup/SyncupWin.zip" target='_blank' id="windows_dl" className="download android d-flex gap-1 align-items-center">Download <svg className="svg-inline--fa fa-download fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg></a></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center border-bottom wisal'>
                        <div className='d-flex align-items-center gap-3 txtwal'><i className='mdi mdi-apple'></i>  Mac</div>
                        <div className="ml-auto download-button">
                            <a href="/setup/SyncupMac.dmg.zip" target='_blank' id="windows_dl" className="download android d-flex gap-1 align-items-center">Download <svg className="svg-inline--fa fa-download fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg></a></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center wisal'>
                        <div className='d-flex align-items-center gap-3 txtwal'><i className='mdi mdi-linux'></i> Linux</div>
                        <div className="ml-auto download-button">
                            <a href="/setup/setup.deb" target='_blank' id="windows_dl" className="download android d-flex gap-1 align-items-center">Download <svg className="svg-inline--fa fa-download fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg></a></div>
                    </div>
                    </div>
                    <h4 className="fh5co-uppercase-sm heading-platform"><span>MOBILE APP</span></h4>
                    <div className='alltbsdol px-4'> 
                    <div className='d-flex justify-content-between align-items-center border-bottom wisal'>
                        <div className='d-flex align-items-center gap-3 txtwal'><i className='mdi mdi-android'></i>  Android</div>
                        <div className="ml-auto download-button">
                            <a href="https://play.google.com/store/apps/details?id=com.messanger_app" target='_blank' id="windows_dl" className="download android d-flex gap-1 align-items-center">Get it now <svg className="svg-inline--fa fa-download fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg></a></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center wisal'>
                        <div className='d-flex align-items-center gap-3 txtwal'><i className='mdi mdi-apple'></i>   iOS</div>
                        <div className="ml-auto download-button">
                            <a href="https://apps.apple.com/in/app/syncup-teams/id6504904962" target='_blank' id="windows_dl" className="download android d-flex gap-1 align-items-center">Get it now <svg className="svg-inline--fa fa-download fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg></a></div>
                    </div>
                </div>
                    </div>
                    </div>
            </div>
            </div>
            </section>
            <LandingFooter mTop="mt-0" />
        </>
    )
}

export default Download
