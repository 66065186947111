import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoaderMessage = () => {
  return (
    <div className="msg-body skeleton">
      <ul>        
        <li className="sender" style={{ marginBottom: "30px" }}>
          <div className="align-items-end usergbg">
            <div className="usertypetext">
              <div className="align-items-start onhvr justify-content-start">
                <h1>{<Skeleton height={40} />}</h1>
                <Skeleton count={2}/>
              </div>
              <div className="emoji-reaction" style={{ display: "flex" }} />
            </div>
          </div>
        </li>
        <li className="repaly" style={{ marginBottom: "30px" }}>
          <div className="usergbg">
            <div className="usertypetext">
              <div className="align-items-start onhvr justify-content-end">
                <h1>{<Skeleton height={40} />}</h1>
                <Skeleton count={2}/>
              </div>
              <div className="emoji-reaction" style={{ display: "flex" }} />
            </div>
          </div>
        </li>
        <li className="sender" style={{ marginBottom: "30px" }}>
          <div className="align-items-end usergbg">
            <div className="usertypetext">
              <div className="align-items-start onhvr justify-content-start">
                <h1>{<Skeleton height={40} />}</h1>
                <Skeleton count={2}/>
              </div>
              <div className="emoji-reaction" style={{ display: "flex" }} />
            </div>
          </div>
        </li>
        <li className="repaly" style={{ marginBottom: "30px" }}>
          <div className="usergbg">
            <div className="usertypetext">
              <div className="align-items-start onhvr justify-content-end">
                <h1>{<Skeleton height={40} />}</h1>
                <Skeleton count={2}/>
              </div>
              <div className="emoji-reaction" style={{ display: "flex" }} />
            </div>
          </div>
        </li>
        <li className="sender" style={{ marginBottom: "30px" }}>
          <div className="align-items-end usergbg">
            <div className="usertypetext">
              <div className="align-items-start onhvr justify-content-start">
                <h1>{<Skeleton height={40} />}</h1>
                <Skeleton count={2}/>
              </div>
              <div className="emoji-reaction" style={{ display: "flex" }} />
            </div>
          </div>
        </li>
        <li className="repaly" style={{ marginBottom: "30px" }}>
          <div className="usergbg">
            <div className="usertypetext">
              <div className="align-items-start onhvr justify-content-end">
                <h1>{<Skeleton height={40} />}</h1>
                <Skeleton count={2}/>
              </div>
              <div className="emoji-reaction" style={{ display: "flex" }} />
            </div>
          </div>
        </li>
        <li className="sender" style={{ marginBottom: "30px" }}>
          <div className="align-items-end usergbg">
            <div className="usertypetext">
              <div className="align-items-start onhvr justify-content-start">
                <h1>{<Skeleton height={40} />}</h1>
                <Skeleton count={2}/>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SkeletonLoaderMessage;
